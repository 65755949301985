import React, { memo } from 'react';
import { Grid, Checkbox, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  checked: {
    backgroundColor: 'rgb(31,182,83, 0.08)',
  },
});

export const TargetingSelectorCheckbox = memo((props) => {
  const { checked, label, onChange } = props;
  const styles = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      className={classNames({
        [styles.checked]: checked,
        [styles.container]: true,
      })}>
      <Box ml={2} />
      <Grid item>
        <Checkbox
          color="primary"
          disableRipple
          checked={checked}
          name={label}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs>
        <label>{label}</label>
      </Grid>
    </Grid>
  );
});
