import { getToken } from '../services/authService';
import history from '../history';

export const tokenInterceptor = (config) => {
  const accessToken = getToken();
  if (accessToken) {
    config.headers = { Authorization: `${accessToken}` };
  }
  return config;
};

export const unauthorisedResponse = ((response) => {
  if(response.status === 401){
    return history.push('/');
  }
  return response;
});
export const dataExtractionInterceptor = ({ data }) => data;
export const errorExtractionInterceptor = ({ response }) => {
  console.log(response);
  if (response && response.data && response.data.message) {
    return Promise.reject(response.data.message);
  }
  return Promise.reject(null);
};