import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import {
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import history from './history';

import theme from './theme';
import './App.css';

import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import Briefing from './pages/Briefing';
import RegistrationSuccess from './pages/RegistrationSuccess';
import Admin from './pages/Admin';
import Profile from './pages/Profile';
import ForgotPassword from './pages/ForgotPassword';
import EmailVerification from './pages/EmailVerification'

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/register" exact>
            <Register />
          </Route>
          <Route path="/reset-password/:token" exact>
            <ResetPassword />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/admin" exact>
            <Admin />
          </Route>
          <Route path="/profile" exact>
            <Profile />
          </Route>
          <Route path="/register/success" exact>
            <RegistrationSuccess />
          </Route>
          <Route path="/briefing" exact>
            <Briefing />
          </Route>
          <Route path="/verify-email/:token" exact>
            <EmailVerification />
          </Route>
          <Redirect to="login" />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
