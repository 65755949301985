import React, { memo, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { TargetingSelectorCheckbox } from './targeting-selector-checkbox';
import {
  getSubCategoriesByCategories,
  getTargetingCategories,
  getTargetingsBySubCategories,
} from '../../../../helpers/targetings';
import { useTargetingSelectorStyles } from './targeting-selector';
import { greyColor, greyColorWithOpacity } from '../../../../theme.js';
import {
  subCategoriesByCategory,
  targetingsByCategory,
  targetingsBySubCategory
} from '../../../../constants/targetings';
import { TargetingsSearch } from './targetings-search';

let useStyles;
useStyles = makeStyles({
  searchInput: {
    width: 210,
  },
  underline: {
    '&&:after': {
      borderBottom: 'none"',
    },
    "&&&:before": {
      borderBottom: "none"
    },
  },
  targetingTypes: {
    borderBottom: '1px solid',
    borderBottomColor: greyColor,
    width: '100%',
  },
  targetingsSearchContainer: {
    borderBottom: '1px solid',
    borderBottomColor: greyColor,
    width: '100%',
    backgroundColor: greyColorWithOpacity,

  },
  targetingsContainer: {
    height: 400,
  },
  categoriesContainer: {
    borderRight: '1px solid',
    borderRightColor: greyColor,
  },
  overflowScroll: {
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  searchTextField: {},
});

export const MultiTargetingSelectorComponent = memo((props) => {
  const { onTargetingsChange: onTargetingsChangeCallback, selectedTargetings } = props;
  const styles = useStyles();
  const tableStyles = useTargetingSelectorStyles();
  const [categories, setCaterories] = useState([]);
  const [subCategories, setSubCaterories] = useState([]);
  const [targetings, setTargetings] = useState([]);
  const [targetingFilter, setTargetingFilter] = useState('');

  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedSubCategories, setSelectedSubCategories] = useState({});

  const showSearch = targetings.length > 0;

  const filteredTargetings = targetingFilter
    ? targetings.filter(target => target.targeting.includes(targetingFilter))
    : targetings;

  useEffect(() => {
    setCaterories(getTargetingCategories());
  }, []);

  useEffect(() => {
    const categories = Object.keys(selectedCategories).filter(key => selectedCategories[key]);
    setSubCaterories(getSubCategoriesByCategories(categories));
  }, [selectedCategories]);

  useEffect(() => {
    const subCategories = Object.keys(selectedSubCategories).filter(key => selectedSubCategories[key]);
    setTargetings(getTargetingsBySubCategories(subCategories));
  }, [selectedSubCategories]);

  const onCategoryChange = (event) => {
    const { name, checked } = event.target;

    setSelectedCategories({
      ...selectedCategories,
      [name]: checked,
    });

    if(!checked){
      deselectSubcategoriesByCategory(name);
    }
  };

  const onSubcategoryChange = (event) => {
    const { name, checked } = event.target;

    setSelectedSubCategories({
      ...selectedSubCategories,
      [name]: checked,
    });

    if (!checked) {
      deselectTargetingsBySubCategory(name);
    }

  };

  const onTargetingsChange = (event) => {
    onTargetingsChangeCallback({
      ...selectedTargetings,
      [event.target.name]: event.target.checked,
    });
  };

  const deselectTargetingsBySubCategory = (subCategory) => {
    const deselectedTargetings = targetingsBySubCategory[subCategory].reduce((acc, targeting) => ({
      ...acc,
      [targeting.targeting]: false,
    }), {});

    onTargetingsChangeCallback({
      ...selectedTargetings,
      ...deselectedTargetings,
    });
  };

  const deselectSubcategoriesByCategory = (category) => {
    const subCategories = subCategoriesByCategory(category) || [];

    const deselectedSubCategories = subCategories.reduce((acc, subCategory) => {
      return {
        ...acc,
        [subCategory]: false,
      };
    }, {});

    const deselectedTargetings = targetingsByCategory[category]
      .map(targeting => targeting.targeting)
      .reduce((acc, targeting) => ({
        ...acc,
        [targeting]: false,
      }), {});

    setSelectedSubCategories({
      ...selectedSubCategories,
      ...deselectedSubCategories,
    });

    onTargetingsChangeCallback({
      ...selectedTargetings,
      ...deselectedTargetings,
    });
  };

  return (
    <Grid
      item
      container
      className={classNames({
        [styles.targetingsContainer]: true,
      })}
      direction="row"
    >
      <Grid sm={3} item className={styles.categoriesContainer}>
        <Grid
          container
          direction="column"
          className={classNames({
            [tableStyles.tableStyles]: true,
          })}
        >
          {categories.map(category => (
            <TargetingSelectorCheckbox
              key={category}
              label={category}
              checked={selectedCategories[category] === true}
              onChange={onCategoryChange}
            />
          ))}
        </Grid>
      </Grid>

      <Grid sm={3} item className={`${styles.categoriesContainer} ${styles.overflowScroll}`}>
        <Grid
          container
          direction="column"
          className={classNames({
            [tableStyles.tableStyles]: true,
          })}
        >
          {subCategories.map(subCategory => (
            <TargetingSelectorCheckbox
              key={subCategory}
              label={subCategory}
              checked={selectedSubCategories[subCategory] === true}
              onChange={onSubcategoryChange}
            />
          ))}
        </Grid>
      </Grid>

      <Grid sm={6} item className={styles.overflowScroll}>
        <Grid
          container
          direction="column"
          className={classNames({
            [tableStyles.tableStyles]: true,
          })}
        >
          {showSearch && (
            <Grid item container direction="row">
              <TargetingsSearch searchTerm={targetingFilter} onSearchChange={setTargetingFilter} />
            </Grid>
          )}
          {filteredTargetings.map(target => (
            <TargetingSelectorCheckbox
              key={target.id}
              label={target.targeting}
              checked={selectedTargetings[target.targeting] === true}
              onChange={onTargetingsChange}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
});
