import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button, CircularProgress,
  Grid,
  IconButton,
  InputAdornment, Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';

import UnauthorisedLayout from '../../layouts/UnauthorisedLayout';
import validationMessages from '../../constants/validationMessages';
import { resetPassword } from '../../api';

const ResetPassword = () => {
  const history = useHistory();
  const { token } = useParams();
  if(!token) {
    history.push('/login');
  }

  const [customError, setCustomError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, getValues} = useForm();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isPasswordRepeatShown, setIsPasswordRepeatShown] = useState(false);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);

  const handleClickShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const handleClickShowRepeatPassword = () => {
    setIsPasswordRepeatShown(!isPasswordRepeatShown);
  };

  const onSubmit = async (form) => {
    try {
      setIsLoading(true);
      await resetPassword(form.password, token);
      setIsSnackbarShown(true);
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } catch (error) {
      setCustomError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validatePasswordConfirmation = (value) => {
    const password = getValues('password');
    const isPasswordExists = !!password;
    if (isPasswordExists) {
      return value === getValues('password') || validationMessages.requiredSimilarPasswords;
    }
    return true;
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <UnauthorisedLayout>
      <Box mr={8} mt={3} display="flex" justifyContent='flex-end' alignItems="center">
        <Typography variant="body1">
          Sie haben bereits ein Konto?
        </Typography>
        <Box mr={4}/>
        <Button variant="outlined" onClick={() => history.push('/login')}>Anmelden</Button>
      </Box>
      <Grid container item xs direction="column" justify="center" alignItems="center">
        <Box
          border={1}
          borderRadius={10}
          borderColor='#DEDEDE'
          p={6}
          minWidth={400}
          maxWidth={400}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Typography variant="subtitle1" gutterBottom>
                Passwort zurücksetzen
              </Typography>
            </Grid>
            <Grid item xs>
              <Box mt={3}/>
              <TextField
                error={!!errors?.password}
                helperText={errors?.password && errors.password.message}
                inputRef={register({
                  required: validationMessages.required
                })}
                name="password"
                fullWidth
                id="filled-password-input"
                label="Password"
                type={isPasswordShown ? 'text' : 'password'}
                autoComplete="current-password"
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isPasswordShown ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs>
              <Box mt={3}/>
              <TextField
                name="repeatPassword"
                error={!!errors?.repeatPassword}
                helperText={errors?.repeatPassword && errors.repeatPassword.message}
                inputRef={register({
                  validate: {
                    validatePasswordConfirmation
                  }
                })}
                fullWidth
                id="filled-password-input"
                label="Repeat password"
                type={isPasswordRepeatShown ? 'text' : 'password'}
                autoComplete="current-password"
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowRepeatPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isPasswordRepeatShown ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Grid>
            <Box mt={5}/>
            <Grid item>
              <Button onClick={() => setCustomError(null)}
                      disabled={isLoading}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary">
                {isLoading ? <CircularProgress color="primary" size={20} /> : 'Reset'}
              </Button>
            </Grid>
            <Box mt={3} />
            <Grid item>
              {
                customError && (
                  <Alert severity="error" color="error">
                    {customError}
                  </Alert>
                )
              }
            </Grid>
          </form>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          key={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={isSnackbarShown}
          onClose={() => setIsSnackbarShown(false)}
          message="Your password was successfully changed. Redirecting to Login page..."
        />
      </Grid>
    </UnauthorisedLayout>
  )
};

export default ResetPassword;
