import React from 'react';
import { Grid, Typography } from '@material-ui/core';


export const RadioHeader = () => {

  return (
    <Grid container direction="row" alignItems="center" justify="flex-start">
      <Grid item xs={7} />
      <Grid item xs={2}>
        <Typography variant="subtitle2">REICHWEITE</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle2">CPM</Typography>
      </Grid>
    </Grid>
  );
};
