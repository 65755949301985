import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  IconButton,
  Chip,
  Radio,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';


import headerIcon from '../../../../components/icons/svg/channel-accordion.svg'
import { Accordion } from '../../../../components/Accordion';
import { channels } from '../../../../constants/channels';
import { emptyChannel } from '../../index';
import { greenColorWithOpacity, greenColor } from '../../../../theme';

const StyledChip = withStyles({
  root: {
    backgroundColor: greenColorWithOpacity,
    color: greenColor,
    border: '1px solid #1FB653',
  },
})(Chip);

const StyledSelect = withStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 13,
  },
})(Select);

const StyledRemoveChipIcon = withStyles({
  root: {
    color: greenColor,
    width: 15,
    height: 15,
  },
})(CloseIcon);

const useStyles = makeStyles({
  selectText: {
    color: 'black',
  },
});

export const ChannelSelector = (props) => {
  const styles = useStyles();
  const { selectedChannels, onChannelsUpdate, register, errors, setValue, unregister } = props;
  const [isExpanded, setExpanded] = useState(false);
  const channelOptions = channels.map(channel => channel.name);
  const selectedChannelsKeys = Object.keys(selectedChannels).filter(key => !selectedChannels[key].removed);

  useEffect(() => {
    register({ name: 'channel-0' }, { required: true });
    register({ name: 'platform-0' }, { required: true });
  }, [register]);

  const onChannelsChange = (e) => {
    const channelIndex = e.target.name.split('-')[1];
    onChannelsUpdate({
      ...selectedChannels,
      [channelIndex]: {
        ...emptyChannel,
        channel: e.target.value,
      },
    });
    setValue(e.target.name, e.target.value);
  };

  const onPlatformChange = (e) => {
    const channelIndex = e.target.name.split('-')[1];
    onChannelsUpdate({
      ...selectedChannels,
      [channelIndex]: {
        ...selectedChannels[channelIndex],
        platforms: e.target.value,
      },
    });
    setValue(e.target.name, e.target.value);
  };

  const onAddChannel = () => {
    const newIndex = Object.keys(selectedChannels).length;
    onChannelsUpdate({
      ...selectedChannels,
      [newIndex]: { ...emptyChannel },
    });
    register({ name: `channel-${newIndex}` }, { required: true });
    register({ name: `platform-${newIndex}` }, { required: true });
  };

  const onChannelRemove = (channelKey) => {
    onChannelsUpdate({
      ...selectedChannels,
      [channelKey]: {
        ...selectedChannels[channelKey],
        removed: true,
      }
    });
    unregister(`channel-${channelKey}`);
    unregister(`platform-${channelKey}`);
  };

  const onPlatformRemove = (platformsSelectName, valueToRemove) => {
    const platformIndex = platformsSelectName.split('-')[1];
    const filteredPlatforms = selectedChannels[platformIndex].platforms.filter(platform => platform !== valueToRemove);
    onChannelsUpdate({
      ...selectedChannels,
      [platformIndex]: {
        ...selectedChannels[platformIndex],
        platforms: filteredPlatforms,
      },
    });

    setValue(platformsSelectName, filteredPlatforms.length ? filteredPlatforms : undefined);
  };

  return (
    <Accordion
      headerIcon={headerIcon}
      label="Channel"
      isExpanded={isExpanded}
      onToggle={setExpanded}
    >
      <Box p={4} width="100%">
        <Grid container direction="column">
          {selectedChannelsKeys.map((selectedChannelKey) => {
            const selectedChannel = selectedChannels[selectedChannelKey];
            const channelSelectName = `channel-${selectedChannelKey}`;
            const platformSelectName = `platform-${selectedChannelKey}`;
            const removeChannelName = `remove-${selectedChannelKey}`;
            const showRemoveIcon = selectedChannelKey !== '0';
            const platformOptions = selectedChannel.channel
              ? channels.find(({ name }) => name === selectedChannel.channel).platforms
              : [];

            return (
              <Grid item key={selectedChannelKey} container spacing={4} direction="row">
                {/**Channel*/}
                <Grid item xs={5}>
                  <Box my={3}>
                    <FormControl fullWidth required>
                      <InputLabel id={channelSelectName}>Kanal</InputLabel>
                      <Select
                        error={Boolean(!selectedChannel.channel && errors && errors[channelSelectName])}
                        name={channelSelectName}
                        labelId={channelSelectName}
                        value={selectedChannel.channel}
                        renderValue={() => selectedChannel.channel}
                        onChange={onChannelsChange}
                        required
                        fullWidth
                      >
                        {channelOptions.map(channelOption => (
                          <MenuItem key={channelOption} value={channelOption}>
                            <Radio checked={selectedChannel.channel === channelOption} color="primary" />
                            <ListItemText disableTypography className={styles.selectText}>{channelOption}</ListItemText>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {/**Platforms*/}
                <Grid item xs={5}>
                  <Box my={3}>
                    <FormControl fullWidth required>
                      <InputLabel id={platformSelectName}>Platform</InputLabel>
                      <StyledSelect
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                        error={Boolean(!selectedChannel.platforms.length && errors && errors[platformSelectName])}
                        name={platformSelectName}
                        labelId={platformSelectName}
                        value={selectedChannel.platforms}
                        onChange={onPlatformChange}
                        disabled={platformOptions.length === 0}
                        required
                        fullWidth
                        multiple
                        renderValue={(selected) => {
                          return selected.map((value) => (
                            <StyledChip
                              onMouseDown={(e) => { e.stopPropagation(); }}
                              onDelete={() => onPlatformRemove(platformSelectName, value)}
                              key={value}
                              label={value}
                              deleteIcon={<StyledRemoveChipIcon />}
                              color="primary"
                            />
                          ))
                        }}
                      >
                        {platformOptions.map(platformOption => (
                          <MenuItem key={platformOption} value={platformOption}>
                            <Checkbox checked={selectedChannel.platforms.includes(platformOption)} color="primary" />
                            <ListItemText disableTypography primary={platformOption} />
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControl>
                  </Box>
                </Grid>

                {/**Delete icon*/}
                <Grid item xs={2}>
                  {showRemoveIcon && <Box my={3} pt={1}>
                    <IconButton name={removeChannelName} onClick={() => onChannelRemove(selectedChannelKey)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>}
                </Grid>
              </Grid>
            );

          })}
          <Grid item container direction="row" justify="flex-end">
            <Button variant="contained" color="primary" onClick={onAddChannel}>ADD</Button>
          </Grid>
        </Grid>
      </Box>

    </Accordion>
  )
};
