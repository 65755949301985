import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TargetingSelectorRadioButton } from '../targeting-selector-radio-button';
import {
  situationalIntensities,
  situationalTargetings, situationalTargetingToInputMapping
} from '../../../../../constants/situational-targeting';
import { useTargetingSelectorStyles } from '../targeting-selector';
import classNames from 'classnames';
import SituationalTargetingDescription from './components/SituationalTargetingDescription';
import theme from '../../../../../theme';

const useStyles = makeStyles({
  searchInput: {
    width: 210,
  },
  underline: {
    '&&:after': {
      borderBottom: 'none"',
    },
    "&&&:before": {
      borderBottom: "none"
    },
  },
  targetingTypes: {
    borderBottom: '1px solid #E0E0E0',
    width: '100%',
  },
  targetingsSearchContainer: {
    borderBottom: '1px solid #E0E0E0',
    width: '100%',
    backgroundColor: 'rgba(232, 232, 232, 0.15)',
  },
  targetingsContainer: {
    height: 400,
  },
  textarea: {
    fontSize: 16,
    lineHeight: '26px',
  },
  textAreaContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  categoriesContainer: {
    borderRight: '1px solid #E0E0E0',
  },
  overflowScroll: {
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  searchTextField: {},
});

export const SituationalargetingSelectorComponent = (props) => {
  const { onTargetingsChange: onTargetingsChangeCallback } = props;
  const styles = useStyles();
  const tableStyles = useTargetingSelectorStyles();
  const [categories, setCategories] = useState([]);

  const [selectedCategoryKey, setSelectedCategoryKey] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedIntensity, setSelectedIntensity] = useState(null);
  const [description, setDescription] = useState(null);

  useEffect(() => {
    setCategories(situationalTargetings);
  }, []);

  useEffect(() => {
    const selectedCategory = categories.find(({ category }) => category === selectedCategoryKey);
    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
    }
  }, [selectedCategoryKey, categories]);

  const onCategoryChange = (event) => {
    setSelectedCategoryKey(event.target.value);
    onTargetingsChangeCallback({});
    setSelectedIntensity(null);
    setDescription('');
  };
  const onIntensityChange = (event) => {
    setSelectedIntensity(event.target.value);
    onTargetingsChangeCallback({
      situational: {
        category: selectedCategory,
        intensity: event.target.value,
        cpm: selectedCategory.cpm,
        reach: selectedCategory.reach,
      }
    })
  };

  const onSaveButtonClick = () => {
    onTargetingsChangeCallback({
      situational: {
        description,
        category: selectedCategory,
        cpm: selectedCategory.cpm,
        reach: selectedCategory.reach,
      }
    })
  };
  return (
    <Grid
      item
      container
      className={classNames({
        [styles.targetingsContainer]: true,
      })}
      direction="row"
    >
      <Grid sm={4} item className={styles.categoriesContainer}>
        <Grid
          container
          direction="column"
          className={classNames({
            [tableStyles.tableStyles]: true,
          })}
        >
          {categories.map(category => (
            <TargetingSelectorRadioButton
              hint={category.hint}
              key={category.category}
              label={category.category}
              name="category-select"
              value={category.category}
              checked={category.category === selectedCategoryKey}
              onChange={onCategoryChange}
            />
          ))}
        </Grid>
      </Grid>
      {
        selectedCategory && <SituationalTargetingDescription category={selectedCategory} />
      }
      <Grid sm={4}
            item
            container
            direction="column"
            className={`${styles.categoriesContainer} ${styles.overflowScroll}`}>
        <Grid item
              xs
              container
              direction="column"
              className={classNames({
                [tableStyles.tableStyles]: true,
              })}>
          {
            selectedCategory && situationalTargetingToInputMapping[selectedCategory.category] === 'radio' && (
              situationalIntensities.map((intensity) => (
                  <TargetingSelectorRadioButton
                    key={intensity.value}
                    label={intensity.name}
                    name="intensity-select"
                    value={intensity.value}
                    checked={intensity.value === selectedIntensity}
                    onChange={onIntensityChange}
                  />
                )
              )
            )
          }
          {
            selectedCategory && situationalTargetingToInputMapping[selectedCategory.category] === 'textarea' && (
              <Grid container item xs direction="column" className={styles.textAreaContainer}>
                <Grid item container xs>
                  <TextField
                    label="Beschreiben Sie Ihr Targeting szenario möglichst präzise"
                    InputProps={{
                      classes: {
                        input: styles.textarea,
                      },
                    }}
                    color='primary'
                    fullWidth
                    multiline
                    rowsMax={10}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </Grid>
                <Grid item container justify="flex-end">
                  <Box mt={3} mb={4} mr={1}>
                    <Button
                      onClick={onSaveButtonClick}
                      variant="contained"
                      color="primary">
                      Speichern
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
