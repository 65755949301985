import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  InputAdornment, IconButton
} from '@material-ui/core';
import AuthorisedLayout from '../../layouts/AuthorisedLayout';
import { getCurrentUser, updateUserByToken } from '../../api';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import validationMessages from '../../constants/validationMessages';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
  briefing: {
    height: '100%',
  },
});

const Profile = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, getValues, formState } = useForm();
  const [user, setUser] = React.useState(null);
  const [customError, setCustomError] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUserLoading, setIsUserLoading] = React.useState(true);
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);
  const [isRepeatPasswordShown, setIsRepeatPasswordShown] = React.useState(false);
  const classes = useStyles();
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      setIsUserLoading(true);
      const user = await getCurrentUser();
      setUser(user);
    } catch (error) {
      setCustomError(error)
    } finally {
      setIsUserLoading(false);
    }
  };

  const onSubmit = async (form) => {
    try {
      const { name, password, email } = form;
      const isPasswordExists = !!password;
      const userUpdate = {
        name,
        email,
      };
      if (isPasswordExists) {
        userUpdate.password = password;
      }
      setIsLoading(true);
      history.push('/');
      await updateUserByToken(userUpdate)
    } catch (error) {
      setCustomError(error)
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const handleClickShowRepeatPassword = () => {
    setIsRepeatPasswordShown(!isRepeatPasswordShown);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const validatePasswordConfirmation = (value) => {
    const password = getValues('password');
    const isPasswordExists = !!password;
    if (isPasswordExists) {
      return value === getValues('password') || validationMessages.requiredSimilarPasswords;
    }
    return true;
  };

  return (
    <AuthorisedLayout>
      <Grid className={classes.briefing} container direction="column" alignItems="center">
        <Grid item direction="column" alignItems="center">
          <Box mt={6}></Box>
          <Grid container direction="column">
            <Typography variant="h4" gutterBottom>
              Settings
            </Typography>
          </Grid>
          <Box mt={5}></Box>
          <Box
            border={1}
            borderRadius={10}
            borderColor='#DEDEDE'
            p={6}
            minWidth={400}
          >
            {
              isUserLoading && <CircularProgress color="primary" />
            }
            {
              !isUserLoading && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container direction="column">
                    <Grid item xs>
                      <TextField
                        defaultValue={user?.name}
                        error={!!errors?.name}
                        helperText={errors?.name && errors.name.message}
                        inputRef={register()}
                        fullWidth
                        name="name"
                        label="Name"
                      />
                    </Grid>
                    <Box mt={2} />
                    <Grid item xs>
                      <TextField
                        error={!!errors?.email}
                        helperText={errors?.email && errors.email.message}
                        defaultValue={user?.email}
                        inputRef={register({
                          required: validationMessages.requiredEmail,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: validationMessages.emailFormat,
                          }
                        })}
                        fullWidth
                        label="Email"
                        name="email"
                      />
                    </Grid>
                    <Box mt={2} />
                    <Grid item xs>
                      <TextField
                        error={!!errors?.password}
                        helperText={errors?.password && errors.password.message}
                        inputRef={register()}
                        type={isPasswordShown ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }}
                        fullWidth
                        label="Neu Kennwort"
                        name="password"
                      />
                    </Grid>
                    <Box mt={2} />
                    <Grid item xs>
                      <TextField
                        error={!!errors?.repeatPassword}
                        helperText={errors?.repeatPassword && errors.repeatPassword.message}
                        inputRef={register({
                          validate: {
                            validatePasswordConfirmation
                          }
                        })}
                        type={isRepeatPasswordShown ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowRepeatPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {isRepeatPasswordShown ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }}
                        fullWidth
                        label="Bestätigen Kennwort"
                        name="repeatPassword"
                      />
                    </Grid>
                    <Box mt={3} />
                    <Grid item>
                      {
                        customError && (
                          <Alert severity="error" color="error">
                            {customError}
                          </Alert>
                        )
                      }
                    </Grid>
                    <Box mt={5} />
                    <Grid container item xs justify="flex-end">
                      <Button
                        onClick={() => setCustomError(null)}
                        disabled={isLoading || !formState.dirty}
                        type="submit"
                        variant="contained"
                        color="primary">
                        {isLoading ? <CircularProgress color="primary" size={20} /> : 'Speichern'}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )
            }
          </Box>
        </Grid>
      </Grid>
    </AuthorisedLayout>
  )
};

export default Profile;
