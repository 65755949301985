import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import {
  Box,
  Button, CircularProgress,
  Grid, Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import UnauthorisedLayout from '../../layouts/UnauthorisedLayout';
import validationMessages from '../../constants/validationMessages';
import { submitForgotPassword } from '../../api';

const ForgotPassword = () => {
  const history = useHistory();

  const [customError, setCustomError] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);
  const { register, handleSubmit, errors } = useForm();


  const onSubmit = async (form) => {
    try {
      setIsLoading(true);
      await submitForgotPassword(form.email);
      setIsSnackbarShown(true);
    } catch (error) {
      setCustomError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UnauthorisedLayout>
      <Box mr={8} mt={3} display="flex" justifyContent='flex-end' alignItems="center">
        <Typography variant="body1">
          Kennwort vergessen?
        </Typography>
        <Box mr={4}/>
        <Button variant="outlined" onClick={() => history.push('/login')}>Anmelden</Button>
      </Box>
      <Grid container item xs direction="column" justify="center" alignItems="center">
        <Box
          border={1}
          borderRadius={10}
          borderColor='#DEDEDE'
          p={6}
          minWidth={400}
          maxWidth={400}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Typography variant="h4" gutterBottom>
                Kennwort vergessen?
              </Typography>
            </Grid>
            <Grid container>
              <Typography variant="subtitle1" gutterBottom>
                Geben sie ihre E-Mail Adresse ein
              </Typography>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label="E-mail"
                error={!!errors?.email}
                helperText={errors?.email && errors.email.message}
                name="email"
                inputRef={register({
                  required: validationMessages.requiredEmail,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: validationMessages.emailFormat,
                  }
                })}
                variant="outlined"
              />
            </Grid>
            <Box mt={5}/>
            <Grid item>
              <Button onClick={() => setCustomError(null)}
                      disabled={isLoading}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary">
                {isLoading ? <CircularProgress color="primary" size={20}/> : 'Senden'}
              </Button>
            </Grid>
            <Box mt={3}/>
            <Grid item>
              {
                customError && (
                  <Alert severity="error" color="error">
                    {customError}
                  </Alert>
                )
              }
            </Grid>
          </form>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          key={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={isSnackbarShown}
          onClose={() => setIsSnackbarShown(false)}
          message="Email with reset password link was sent to your email."
        />
      </Grid>
    </UnauthorisedLayout>
  )
};

export default ForgotPassword;
