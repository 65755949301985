import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { RadioButtonWithCPM } from './radio-for-single-targeting/radio';
import { TargetingSelectorRadioButton } from './targeting-selector-radio-button';
import {
  getSubCategoriesByCategories,
  getTargetingCategories,
  getTargetingsBySubCategories,
} from '../../../../helpers/targetings';
import { useTargetingSelectorStyles } from './targeting-selector';
import { greyColor } from '../../../../theme.js';
import { RadioHeader } from './radio-for-single-targeting/header';
import { TargetingsSearch } from './targetings-search';

const useStyles = makeStyles({
  targetingTypes: {
    borderBottom: '1px solid',
    borderBottomColor: greyColor,
    width: '100%',
  },
  targetingsContainer: {
    height: 400,
  },
  categoriesContainer: {
    borderRight: '1px solid',
    borderRightColor: greyColor,
  },
  overflowScroll: {
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  tableStyles: {
    marginLeft: 24,
    marginTop: 8,
  },
  noTopMargin: {
    marginTop: 0,
  },
});

export const SingleTargetingSelectorComponent = (props) => {
  const { onTargetingsChange: onTargetingsChangeCallback, selectedTargetings } = props;
  const styles = useStyles();
  const tableStyles = useTargetingSelectorStyles();
  const [categories, setCaterories] = useState([]);
  const [subCategories, setSubCaterories] = useState([]);
  const [targetings, setTargetings] = useState([]);
  const [targetingFilter, setTargetingFilter] = useState('');

  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedSubCategories, setSelectedSubCategories] = useState({});

  const showSearch = targetings.length > 0;
  const showTargetingHeader = targetings.length > 0;

  const filteredTargetings = targetingFilter
  ? targetings.filter(target => target.targeting.includes(targetingFilter))
  : targetings;

  useEffect(() => {
    setCaterories(getTargetingCategories());
  }, []);

  useEffect(() => {
    const categories = Object.keys(selectedCategories).filter(key => selectedCategories[key]);
    setSubCaterories(getSubCategoriesByCategories(categories));
    setSelectedSubCategories({});
  }, [selectedCategories]);

  useEffect(() => {
    const subCategories = Object.keys(selectedSubCategories).filter(key => selectedSubCategories[key]);
    setTargetings(getTargetingsBySubCategories(subCategories));
    onTargetingsChangeCallback({});
  }, [selectedSubCategories, onTargetingsChangeCallback]);

  const onCategoryChange = (event) => {
    setSelectedCategories({
      [event.target.value]: true,
    });
  };

  const onSubcategoryChange = (event) => {
    setSelectedSubCategories({
      [event.target.value]: true,
    });
  };

  const onTargetingsChange = (event) => {
    onTargetingsChangeCallback({
      [event.target.value]: true,
    });
  };

  return (
    <Grid
      item
      container
      className={classNames({
        [styles.targetingsContainer]: true,
      })}
      direction="row"
    >
      <Grid sm={3} item className={styles.categoriesContainer}>
        <Grid
          container
          direction="column"
          className={classNames({
            [tableStyles.tableStyles]: true,
          })}
        >
          {categories.map(category => (
            <TargetingSelectorRadioButton
              key={category}
              label={category}
              name="category-select"
              value={category}
              checked={selectedCategories[category] === true}
              onChange={onCategoryChange}
            />
          ))}
        </Grid>
      </Grid>

      <Grid sm={3} item className={`${styles.categoriesContainer} ${styles.overflowScroll}`}>
        <Grid
          container
          direction="column"
          className={classNames({
            [tableStyles.tableStyles]: true,
          })}
        >
          {subCategories.map(subCategory => (
            <TargetingSelectorRadioButton
              key={subCategory}
              label={subCategory}
              name="subcategory-select"
              value={subCategory}
              checked={selectedSubCategories[subCategory] === true}
              onChange={onSubcategoryChange}
            />
          ))}
        </Grid>
      </Grid>

      <Grid sm={6} item className={styles.overflowScroll}>
        <Grid
          container
          direction="column"
        >
          {showSearch && (
            <Grid item container direction="row">
              <TargetingsSearch searchTerm={targetingFilter} onSearchChange={setTargetingFilter} />
            </Grid>
          )}

          {showTargetingHeader && <RadioHeader />}
          {filteredTargetings.map(target => (
            <RadioButtonWithCPM
              key={target.id}
              label={target.targeting}
              name="targeting-select"
              value={target.targeting}
              checked={selectedTargetings[target.targeting] === true}
              onChange={onTargetingsChange}
              hint={target.description}
              reach={target.reach}
              cpm={target.cpm}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
