export const situationalTargetings = [
  {
    category: 'Aktienkurse',
    hint: 'Definieren Sie die auf Aktienkurse oder Indizes bezogenen Kriterien die als Bedingung für die Ausspielung genutzt werden sollen. Beispiel: Ausspielung nur wenn der DAX über 10.000 Punkten ist.',
    description: 'Adressieren Sie nur dann wenn zum aktuellen Zeitpunkt die definierten Marktbewegungen stattfinden..',
    cpm: '6,50 €',
    reach: 'variabel',

  },
  {
    category: 'Pollenflug',
    hint: 'Definieren Sie das Pollenflugszenario bei welchem eine Ausspielung vorgenommen wird.',
    description: 'Adressieren Sie nur die Nutzer in Gebieten mit dem von Ihnen definierten Pollenflug.',
    cpm: '6,50 €',
    reach: 'variabel',
  },
  {
    category: 'Feinstaubbelastung',
    hint: 'Definieren Sie das Feinstaubwerte-Szenario bei welchem eine Ausspielung vorgenommen wird (z.B. Feinstaubalarm aktiv).',
    description: 'Adressieren Sie nur die Nutzer in Gebieten wo die Feinstaubbelastung über den Grenzwerten liegt.',
    cpm: '6,50 €',
    reach: 'variabel',
  },
  {
    category: 'Sportergebnisse',
    hint: 'Definieren Sei bei welchen Sportergebnissen und/oder Vereinspaarung die Ausspielung stattfinden soll.',
    description: 'Adressieren Sie nur dann wenn bestimmte Sportergebnisse eingetroffen sind.',
    cpm: '6,50 €',
    reach: 'variabel',
  },
];

export const situationalTargetingToInputMapping = {
  Aktienkurse: 'textarea',
  Sportergebnisse: 'textarea',
  Pollenflug: 'radio',
  Feinstaubbelastung: 'radio',
};

export const situationalIntensities = [
  {
    value: "gering",
    name: "Gering"
  }, {
    value: "mittel",
    name: "Mittel"
  }, {
    value: "erhöht",
    name: "Erhöht"
  }, {
    value: "hoch",
    name: "Hoch"
  }
];