import React from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Box, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  'accordion-header': {
    height: 96,
    fontSize: 24,
    borderTop: 10,
  },
  accordion: {
    borderRadius: 10,
    '&::before' : {
      display: 'none',
    },
  },
  headerIcon: {},
  headerIconContainer: {
    backgroundColor: 'rgba(31, 182, 83, 0.07)',
    borderRadius: 10,
    width: 40,
    height: 40,
  },
});

export const Accordion = (props) => {
  const { headerIcon, label, children, isExpanded, onToggle } = props;
  const styles = useStyles();

  const onChange = (event, isExpanded) => {
    onToggle(isExpanded);
  };

  return (<>
    <ExpansionPanel
      className={styles.accordion}
      expanded={isExpanded}
      onChange={onChange}
    >
      <ExpansionPanelSummary
        className={styles['accordion-header']}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box m={4}>
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Grid
              container
              className={styles.headerIconContainer}
              justify="center"
              alignItems="center"
              direction="row"
            >
              <img src={headerIcon} alt="" width="15" height="15" />
            </Grid>
            <Box ml={2} />
            <span>{label}</span>
          </Grid>
        </Box>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </>);
};

Accordion.defaultProps = {
  expanded: true,
};
