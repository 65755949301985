import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { darkGrey, greyColor } from '../../../../../theme.js';

const useStyles = makeStyles({
  dataScienceContainer: {},
  infoBox: {
    color: darkGrey,
    borderLeft: '1px solid',
    borderLeftColor: greyColor,
  },
});


export const DataScienceComponent = (props) => {
  const { onTargetingsChange: onTargetingsChangeCallback } = props;
  const [dataScienceText, setDataScienceText] = useState();
  const [dataScienceError, setDataScienceError] = useState(false);

  const onTargetingChange = (event) => {
    if(event.target.value) {
      setDataScienceError(false)
    }
    setDataScienceText(event.target.value);
  };
  const onSubmit = () => {
    if(!dataScienceText) {
      return setDataScienceError(true)
    }
    onTargetingsChangeCallback({
      dataScience: {
        description: dataScienceText,
        reach: 'variabel',
        cpm: 'Preis auf Anfrage',
      },
    });
  };

  const styles = useStyles();

  return (
    <Grid
      item
      container
      direction="row"
      className={styles.dataScienceContainer}
    >
      <Grid item xs={10} container direction="column">
        <Box p={4}>
          <Grid item>
              <TextField
                name="customDataScience"
                error={dataScienceError}
                fullWidth
                multiline
                onChange={onTargetingChange}
                label="Custom Targeting Szenario"
                required
              />
          </Grid>
          <Grid item>
            <Box mt={4}/>
            <Button variant="contained" color="primary" onClick={onSubmit}>Speichern</Button>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={2} className={styles.infoBox} container alignItems="center">
        <Box mt={3} ml={3} mr={1}>
          Beschreiben Sie ihr ideales Targetings-Szenario so präzise wie möglich
        </Box>
        <Box mt={3} ml={3} mr={1}>
          <div>Max. Reichweite: variabel</div>
        </Box>
        <Box mt={3} ml={3} mr={1} mb={2}>
          <div>CPM</div>
          <div>Preis auf Anfrage</div>
        </Box>
      </Grid>
    </Grid>
  );
};
