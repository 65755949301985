import React, { useState } from 'react';
import { Grid, Box, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { Accordion } from '../../../../components/Accordion';
import paymentModelAccordionIcon from '../../../../components/icons/svg/payment-model-accordion.svg';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  blackLabel: {
    color: 'black',
  },
});

const StyledLabel = withStyles({
  label: {
    color: 'black',
  },
})(FormControlLabel);

export const PaymentModelSelector = ({ register, setPaymentModel }) => {
  const [isExpanded, setExpanded] = useState(false);
  const styles = useStyles();
  const onPaymentModelChange = ({target: {value}}) => {
    setPaymentModel(value);
  };

  return (
    <>
      <Accordion
        headerIcon={paymentModelAccordionIcon}
        label="Abrechnungsmodell"
        isExpanded={isExpanded}
        onToggle={setExpanded}
      >
        <Box m={4} className={styles.fullWidth}>
          <RadioGroup>
            <Grid container spacing={4} direction="row">
              <Grid item xs={6}>
                <StyledLabel
                  className={styles.blackLabel}
                  control={<Radio
                    name="paymentModel"
                    value="bundled"
                    color="primary"
                    onChange={onPaymentModelChange}
                    inputRef={register({ required: true })}
                  />}
                  label="Bundled (empfohlen)"
                />
              </Grid>

              <Grid item xs={6}>
                <StyledLabel
                  className={styles.blackLabel}
                  control={<Radio
                    name="paymentModel"
                    value="unbundled"
                    color="primary"
                    onChange={onPaymentModelChange}
                    inputRef={register({ required: true })}
                  />}
                  label="Unbundled"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Box>
      </Accordion>
    </>
  )
};
