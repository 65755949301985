import React, { memo } from 'react';
import { Box, Grid, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { HintIcon } from '../hint-icon';


const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  checked: {
    backgroundColor: 'rgb(31,182,83, 0.08)',
  },
  hintIcon: {
    color: '#AFAFAF',
  },
});

export const TargetingSelectorRadioButton = memo((props) => {
  const { checked, label, onChange, name, value, hint } = props;
  const styles = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classNames({
        [styles.checked]: checked,
        [styles.container]: true,
      })}
    >
      <Grid item>
        <Grid container alignItems="center" direction="row">
          <Box ml={2} />
          <Grid item>
            <Radio
              color="primary"
              disableRipple
              checked={checked}
              name={name}
              onChange={onChange}
              value={value}
            />
          </Grid>
          <Grid item xs>
            <label>{label}</label>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems="center">
          {Boolean(hint) && <HintIcon hint={hint} />}
          <Box mr={2} />
        </Grid>
      </Grid>
    </Grid>
  );
});
