import { userRoles } from '../enums/User';

export const getToken = () => {
  const token = sessionStorage.getItem('token') || localStorage.getItem('token');
  return token || null;
};
export const getUser = () => {
  const userString = sessionStorage.getItem('user') || localStorage.getItem('user');
  let user;
  try {
    user = JSON.parse(userString);
  } catch (e) {
  }
  return user || null;
};

export const storeToken = (token, isLocalStorage) => {
  const storage = isLocalStorage ? localStorage : sessionStorage;
  storage.setItem('token', token);
};

export const storeUser = (user, isLocalStorage) => {
  const storage = isLocalStorage ? localStorage : sessionStorage;
  storage.setItem('user', JSON.stringify(user));
};

export const isLoggedIn = () => {
  const token = getToken();
  return !!token;
};

export const isAdmin = () => {
  const user = getUser();
  return !!user && user.role === userRoles.admin;
};

export const isUserActivated = () => {
  const user = getUser();
  return !!user && user.isActivated;
};

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');

  sessionStorage.removeItem('user');
  sessionStorage.removeItem('token');
};