import React, { memo } from 'react';
import { Box, Grid, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import numeral from 'numeral';

import { HintIcon } from '../../hint-icon';


const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  checked: {
    backgroundColor: 'rgb(31,182,83, 0.08)',
  },
  hintIcon: {
    color: '#AFAFAF',
  },
  selectedInfoText: {
    color: '#1FB653',
  },
});

export const RadioButtonWithCPM = memo((props) => {
  const { checked, label, onChange, name, value, hint, reach, cpm } = props;
  const styles = useStyles();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classNames({
        [styles.checked]: checked,
        [styles.container]: true,
      })}
    >
      <Grid item xs={7}>
        <Grid container direction="row" alignItems="center" wrap="nowrap">
          <Box ml={2} />
          <Grid item>
            <Radio
              color="primary"
              disableRipple
              checked={checked}
              name={name}
              onChange={onChange}
              value={value}
            />
          </Grid>
          <Grid item>
            <label>{label}</label>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={2}
        className={classNames({
          [styles.selectedInfoText]: checked,
        })}
      >
        {numeral(reach).format('0.0 a').replace('.', ',').toUpperCase()}
      </Grid>

      <Grid
        item
        xs={2}
        className={classNames({
          [styles.selectedInfoText]: checked,
        })}
      >
        {cpm}
      </Grid>

      <Grid item xs={1}>
        <Grid container alignItems="center">
          {Boolean(hint) && <HintIcon hint={hint}/>}
        </Grid>
      </Grid>
    </Grid>
  );
});
