import React, { useState } from 'react';
import { Grid, TextField, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Accordion } from '../../../../components/Accordion';
import advertiserAccordionIcon from '../../../../components/icons/svg/advertiser-accordion.svg';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
});

export const AdvertiserSelector = ({ register, errors }) => {
  const [isExpanded, setExpanded] = useState(true);
  const styles = useStyles();

  return (
    <>
      <Accordion
        headerIcon={advertiserAccordionIcon}
        label="Advertiser"
        isExpanded={isExpanded}
        onToggle={setExpanded}
      >
        <Box m={4} className={styles.fullWidth}>
          <Grid container spacing={4} direction="row">
            <Grid item xs={6}>
              <TextField
                error={!!errors?.advertiserName}
                inputRef={register({ required: true })}
                label="Advertiser"
                name="advertiserName"
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                inputRef={register}
                label="Brand"
                name="advertiserBrand"
                fullWidth
              />
            </Grid>

          </Grid>
        </Box>
      </Accordion>
    </>
  )
};
