import axios from 'axios';

import environment from '../environments/environment';
import {
  dataExtractionInterceptor,
  errorExtractionInterceptor,
  tokenInterceptor,
  unauthorisedResponse,
} from '../interceptors';

const instance = axios.create({
  baseURL: `${environment.API}/`,
});

instance.interceptors.request.use(tokenInterceptor);
instance.interceptors.response.use(dataExtractionInterceptor, errorExtractionInterceptor);
instance.interceptors.response.use(unauthorisedResponse);

export default instance;