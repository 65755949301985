import { createMuiTheme } from '@material-ui/core/styles';

export const greenColor = '#1FB653';
export const greenColorWithOpacity = 'rgba(31,182,83,0.1)';
export const greyColor = '#E0E0E0';
export const darkGrey = '#7D7D7D';
export const greyColorWithOpacity = 'rgba(232, 232, 232, 0.15)';


export default createMuiTheme({
  palette: {
    primary: {
      main: greenColor,
    }
  },
  typography: {
    fontFamily: 'Lato, Arial'
  },
  overrides: {
    MuiChip: {
      outlined: {
        backgroundColor: greenColorWithOpacity,
      },
      root: {
        borderRadius: 6,
        marginRight: 5,
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#1975CE',
        color: "white",
      },
    },
    MuiButton: {
      containedPrimary: {
        letterSpacing: 1,
        color: 'white',
        fontSize: 14,
      },
      outlined: {
        letterSpacing: 1,
        fontSize: 14,
        border: '1px solid #E8E8E8',
        borderRadius: 4,
      },
    },
    MuiInputLabel: {
      asterisk: {
        color: 'red',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: greenColor,
      },
      root: {
        color: '#979797',
      },
    },
    MuiTab: {
      root: {
        fontSize: 14,
        marginLeft: 24,
        paddingLeft: 0,
        '&$selected': {
          color: greenColor,
        }
      },
      wrapper: {
        flexDirection: 'row',

      },
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: 'rgba(232, 232, 232, 0.15)',
        padding: 0,
        paddingRight: 32,
      }
    },
    MuiExpansionPanel: {
      root: {
        border: '1px solid #DEDEDE',
        borderRadius: 10,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        borderTop: '1px solid #E0E0E0',
        padding: 0,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: greenColor,
        },
        '&hover': {
          color: greenColor,
        },
      },
    },
    MuiInput: {
      underline: {
        minHeight: 40,
        // marginTop: 0,
      },
    },
    MuiTypography: {
      h4: {
        fontSize: 32
      },
      body1: {
        fontSize: 16,
        lineHeight: '26px',
        color: '#7D7D7D',
      },
      body2: {
        fontSize: 14,
        color: '#7D7D7D',
      },
      subtitle1: {
        fontSize: 18,
        color: '#7D7D7D'
      },
      subtitle2: {
        fontSize: 12,
        color: '#79828D',
        fontWeight: 'bold',
      }
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: greenColorWithOpacity,
        },
        '&:hover': {
          backgroundColor: greenColorWithOpacity,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'white',
        border: `solid ${greenColor} 1px`,
        color: 'black',
        fontSize: 12,
      },
      arrow: {
        color: greenColor,
      },
    },
  },
});
