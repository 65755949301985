import React from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { greyColor, greyColorWithOpacity } from '../../../../theme';

const useStyles = makeStyles({
  searchInput: {
    width: 210,
  },
  underline: {
    '&&:after': {
      borderBottom: 'none"',
    },
    "&&&:before": {
      borderBottom: "none"
    },
  },
  searchIconContainer: {
    height: 36,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  targetingsSearchContainer: {
    borderBottom: '1px solid',
    borderBottomColor: greyColor,
    width: '100%',
    backgroundColor: greyColorWithOpacity,
  },
});

export const TargetingsSearch = (props) => {
  const {onSearchChange, searchTerm} = props;
  const styles = useStyles();

  const onSearchFilterUpdate = (e) => {
    const {value} = e.target;
    onSearchChange(value);
  };

  return (
    <Grid container alignItems="flex-end" className={styles.targetingsSearchContainer}>
      <Box ml={3} />
      <Grid item className={styles.searchIconContainer}>
        <SearchIcon />
      </Grid>
      <Box ml={1} />
      <Grid item>
        <TextField
          label="Please type here for search"
          className={styles.searchInput}
          InputProps={{ classes: { underline: styles.underline } }}
          onChange={onSearchFilterUpdate}
          value={searchTerm}
        />
      </Grid>
    </Grid>
  );
};
