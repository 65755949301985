import React from 'react';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { HintIcon } from '../../../hint-icon';


const TargetingSummaryWithTableHeader = ({ onRemove, targeting }) => {
  const { targeting: targetingName, description, category, subCategory } = targeting;
  const onDeleteClick = () => {
    onRemove(targetingName);
  };

  const hint = category?.hint || description;

  return (
    <Grid container direction='row' alignItems='center'>
      <CheckCircleIcon color="primary" style={{ fontSize: 24 }} />
      <Box mr={2} />
      <Grid item container xs justify='flex-end'>
        <Grid item xs>
          <Grid item>
            <Typography variant="subtitle1" color="primary">
              {targetingName}
            </Typography>
          </Grid>
          <Grid item container alignItems="center">
            <Typography variant="body2">
              {category}
            </Typography>
            <Box ml={1} />
            <ArrowForwardIosIcon color="disabled" style={{ fontSize: 12 }} />
            <Box mr={1} />
            <Typography variant="body2">
              {subCategory}
            </Typography>
            <Box ml={1} />
            <ArrowForwardIosIcon color="disabled" style={{ fontSize: 12 }} />
            <Box mr={1} />
            <Typography variant="body2">
              {targetingName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={2} justify='flex-end' direction='row' alignItems='center'>
          <HintIcon
            hint={hint}
            style={{ fontSize: 24 }} />
          <Box mr={2} />
          <IconButton onClick={onDeleteClick}>
            <DeleteOutlineOutlinedIcon
              color="disabled"
              style={{ fontSize: 24 }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default TargetingSummaryWithTableHeader;
