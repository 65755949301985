import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { Radio } from '@material-ui/core';
import { greenColor, greyColor } from '../../../../theme.js';


const useStyles = makeStyles({
  root: {
    '& .MuiStep-completed': {
      '& .MuiStepLabel-completed': {
        color: greenColor,
      },
      '& + .MuiStepConnector-vertical': {
        '& .MuiStepConnector-lineVertical': {
          borderColor: greenColor,
        }
      }
    },
    '& .MuiStepConnector-lineVertical': {
      borderLeftStyle: 'dashed',
      marginLeft: 2
    }
  },
  stepperContainer: {
    position: 'sticky',
    top: '64px'
  },
  radioButton: {
    cursor: 'default',
    color: greyColor,
    padding: 0,
    '& svg': {
      height: 30,
      width: 30,
    },
  }
});
export const Steps = memo(({ getValues, selectedChannels, selectedTargetings }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      const formValue = getValues();
      setForm(formValue)
    }, 300);
    return () => {
      clearInterval(intervalId)
    }
  }, [getValues]);

  const { advertiserName, campaignName, campaignGoal, startDate, endDate } = form;

  const channelsCompleted = Object
    .keys(selectedChannels)
    .filter(key => !selectedChannels[key].removed)
    .every(key => {
      return form[`channel-${key}`] && form[`platform-${key}`]
    });

  const targetingsCompleted = !!Object
    .keys(selectedTargetings)
    .filter(key => selectedTargetings[key] !== false)
    .length;

  const paymentModelCompleted = Boolean(form.paymentModel);

  return (
    <div className={classes.root}>
      <Stepper className={classes.stepperContainer} activeStep={-1} orientation="vertical" nonLinear>
        <Step completed={!!advertiserName}>
          <StepLabel
            icon={
              <Radio
                className={classes.radioButton}
                color="primary"
                disableRipple
                checked={!!advertiserName}
              />
            }>
            Advertiser
          </StepLabel>
          <StepContent/>
        </Step>
        <Step completed={!!campaignName && !!campaignGoal && !!startDate && !!endDate}>
          <StepLabel
            icon={
              <Radio
                className={classes.radioButton}
                color="primary"
                disableRipple
                checked={!!campaignName && !!campaignGoal && !!startDate && !!endDate}
              />
            }
          >Campaign</StepLabel>
          <StepContent/>
        </Step>
        <Step completed={channelsCompleted}>
          <StepLabel
            icon={
              <Radio
                className={classes.radioButton}
                color="primary"
                disableRipple
                checked={channelsCompleted}
              />
            }
          >Channel</StepLabel>
          <StepContent/>
        </Step>
        <Step completed={paymentModelCompleted}>
          <StepLabel
            icon={
              <Radio
                className={classes.radioButton}
                color="primary"
                disableRipple
                checked={paymentModelCompleted}
              />
            }
          >Abrechnungsmodell</StepLabel>
          <StepContent/>
        </Step>
        <Step completed={false}>
          <StepLabel
            icon={
              <Radio
                className={classes.radioButton}
                color="primary"
                disableRipple
                checked={targetingsCompleted}
              />
            }
          >Targeting</StepLabel>
          <StepContent/>
        </Step>
      </Stepper>
    </div>
  );
});
