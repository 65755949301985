import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import UnauthorisedLayout from '../../layouts/UnauthorisedLayout';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const RegistrationSuccess = () => {
  return (
    <UnauthorisedLayout>
      <Grid container item xs direction="column" justify="center" alignItems="center">
        <Box width={390}>
          <Grid item xs>
            <CheckCircleOutlineOutlinedIcon color="primary" style={{ fontSize: 77 }} />
          </Grid>
          <Box mt={3} />
          <Grid item xs>
            <Typography variant="h4">
              Erfolgreich registriert
            </Typography>
          </Grid>
          <Box mt={3} />
          <Grid item xs>
            <Typography variant="subtitle1">
              Wir werden Sie innerhalb von 48 Stunden unter der angegebenen Email kontaktieren
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </UnauthorisedLayout>
  )
};

export default RegistrationSuccess;