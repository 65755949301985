import React, {useEffect} from 'react';
import { Grid } from '@material-ui/core';
import { ApplicationBar } from '../../components/ApplicationBar';
import { makeStyles } from '@material-ui/core/styles';

import { getCurrentUser } from '../../api';
import history from '../../history';

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
  },
});

const AuthorisedLayout = ({ children }) => {
  const classes = useStyles();

  useEffect(() => {
    getCurrentUser()
      .catch(() => {
        history.push('/login');
      });
  }, []);

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <ApplicationBar />
      </Grid>
      <Grid item container xs direction="column">
        {children}
      </Grid>
    </Grid>
  )
};

export default AuthorisedLayout;
