import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { isEmpty } from 'lodash';

import { TargetingSelectorComponent } from './components/TargetingSelector/targeting-selector';
import { AdvertiserSelector } from './components/AdvertiserSelector';
import { PaymentModelSelector } from './components/PaymentModelSelector';
import UnauthorisedLayout from '../../layouts/AuthorisedLayout';
import { CampaignSelector } from './components/CampaignSelector';
import { ChannelSelector } from './components/ChannelSelector';
import { SelectedTargeting } from './components/selected-targeting';
import { getTargetingByName } from '../../helpers/targetings';
import { isLoggedIn } from '../../services/authService';
import { Steps } from './components/Steps';
import NewBriefingModal from '../../components/NewBriefingModal';
import { createBriefing } from '../../api';
import TargetingSummaryWithTable from './components/TargetingSummaryWithTable';

const useStyles = makeStyles({
  briefing: {
    width: '100%',
    height: '100%',
  },
});

export const emptyChannel = {
  channel: '',
  platforms: [],
  removed: false,
};

function Briefing() {
  const { control, register, handleSubmit, getValues, reset, setValue, errors, formState, unregister } = useForm();
  const classes = useStyles();
  const history = useHistory();
  const [selectedTargetings, setSelectedTargetings] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);
  const [paymentModel, setPaymentModel] = useState(null);
  const [snackbarText, setSnackbarText] = useState(null);
  const [selectedChannels, setSelectedChannels] = useState({
    0: {
      ...emptyChannel,
    },
  });
  const [targetingLogicOperators, setTargetingLogicOperators] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [formResetIndex, setFormResetIndex] = useState(0);
  const isDirty = formState.dirty;

  if (!isLoggedIn()) {
    history.push('/login');
  }

  const onOperatorChange = (e) => {
    const { name, value } = e.target;
    setTargetingLogicOperators({
      ...targetingLogicOperators,
      [name]: value,
    });
  };

  const resetForm = () => {
    reset();
    setSelectedTargetings({});
    setSelectedChannels({ 0: { ...emptyChannel } });
    setFormResetIndex(formResetIndex + 1);
    setIsModalOpen(false);
  };

  const generateLogicString = () => {
    const filteredSelectedTargetings = Object
      .keys(selectedTargetings)
      .filter(key => selectedTargetings[key]);

    return filteredSelectedTargetings.reduce((acc, targeting) => {
      const operator = (targetingLogicOperators[targeting] || '').toUpperCase();
      return acc + ` ${targeting} ${operator}`
    }, '');
  };

  const onTargetingsChange = useCallback((targetings) => {
    setSelectedTargetings(targetings);
  }, [setSelectedTargetings]);

  const onSubmitButtonClick = (event) => {
    const { paymentModel } = getValues();
    if (isDirty && isEmpty(selectedTargetings) && isEmpty(errors)) {
      setSnackbarText('Bitte wählen Sie ein Targeting aus.');
      setIsSnackbarShown(true);
      event.preventDefault();
      // event.stopPropagation();
    }

    if(!paymentModel){
      setSnackbarText('Bitte wählen Sie ein Abrechnungsmodell aus.');
      setIsSnackbarShown(true);
      event.preventDefault();
    }
  };

  const onSubmit = async data => {
    // TODO change that when you move all targeting types into a single structure
    let targeting = undefined;
    if (selectedTargetings.situational) {
      targeting = {
        description: selectedTargetings.situational.description,
        category: selectedTargetings.situational.category.category,
        intensity: selectedTargetings.situational.intensity,
        type: 'Situativ',
      };
    }
    if (selectedTargetings.dataScience) {
      targeting = {
        description: selectedTargetings.dataScience.description,
        type: 'Data Science',
      };
    }
    const briefingInfo = {
      ...data,
      selectedTargetings: targeting ? JSON.stringify(targeting) : JSON.stringify(selectedTargetings),
      logicString: generateLogicString(),
    };
    try {
      setIsCreating(true);
      await createBriefing(briefingInfo);
      setSnackbarText('You briefing was successfully created.');
      setIsSnackbarShown(true);
      resetForm();
    } catch (e) {
      setSnackbarText('Something went wrong.');
      setIsSnackbarShown(true);
    } finally {
      setIsCreating(false);
    }
  };

  const onRemove = category => {
    setSelectedTargetings({
      ...selectedTargetings,
      [category]: false,
    });
  };

  const filteredSelectedTargetings = Object
    .keys(selectedTargetings)
    .filter(key => selectedTargetings[key]);

  return (
    <UnauthorisedLayout>
      <Box display="flex">
        <Steps getValues={getValues} selectedChannels={selectedChannels} selectedTargetings={selectedTargetings} />
        <Box className={classes.briefing} m={2}>

          <form id="briefingForm" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <AdvertiserSelector register={register} errors={errors} />
            <Box mt={3} />
            <CampaignSelector control={control} errors={errors} register={register} />
            <Box mt={3} />
            <ChannelSelector
              selectedChannels={selectedChannels}
              onChannelsUpdate={setSelectedChannels}
              errors={errors}
              register={register}
              unregister={unregister}
              setValue={setValue}
            />
            <Box mt={3} />
            <PaymentModelSelector
              key={formResetIndex}
              register={register}
              errors={errors}
              setPaymentModel={setPaymentModel}
            />
            <Box mt={3} />
            <TargetingSelectorComponent
              key={`payment-model-${formResetIndex}`}
              onTargetingsChange={onTargetingsChange}
              selectedTargetings={selectedTargetings}
              errors={errors}
              register={register}
              setValue={setValue}
            />
            <Box mt={3} />
            {filteredSelectedTargetings
              .map((targetingName, index) => {
                if (targetingName === 'dataScience') {
                  return (
                    <SelectedTargeting
                      key={targetingName}
                      type="dataScience"
                      targeting={selectedTargetings[targetingName]}
                      onRemove={onRemove}
                    />
                  );
                }

                if (targetingName === 'situational') {
                  return (
                    <SelectedTargeting
                      key={targetingName}
                      paymentModel={paymentModel}
                      type="situational"
                      targeting={selectedTargetings[targetingName]}
                      onRemove={onRemove}
                    />);
                }

                return (
                  <div key={targetingName}>
                    <TargetingSummaryWithTable
                      paymentModel={paymentModel}
                      targeting={getTargetingByName(targetingName)}
                      channels={selectedChannels}
                      onRemove={onRemove}
                    />
                    {Boolean(filteredSelectedTargetings.length > 0 && index < filteredSelectedTargetings.length - 1) && (
                      <>
                        <Box mt={2} />
                        <RadioGroup name={targetingName} onChange={onOperatorChange}>
                          <Grid container direction="row" alignItems="center">
                            <Typography variant="body1">Logik</Typography>
                            <Radio
                              color="primary"
                              value="and"
                            />
                            <label>Und</label>
                            <Radio
                              color="primary"
                              value="or"
                            />
                            <label>Oder</label>
                          </Grid>
                        </RadioGroup>
                        <Box mt={2} />
                      </>
                    )}
                  </div>
                )
              })
            }
            <Box mt={6} />
            <Grid container justify="space-between">
              <Button
                variant="outlined"
                onClick={() => setIsModalOpen(true)}
              >
                + NEUS BRIEFING
              </Button>
              <Button
                type="submit"
                form="briefingForm"
                variant="contained"
                color="primary"
                disabled={isCreating}
                onClick={onSubmitButtonClick}
              >
                BRIEFING EINREICHEN
              </Button>
            </Grid>
          </form>
        </Box>
      </Box>
      <NewBriefingModal
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        onModalApprove={resetForm}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        key={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={isSnackbarShown}
        onClose={() => setIsSnackbarShown(false)}
        message={snackbarText}
      />
    </UnauthorisedLayout>
  );
}

export default Briefing;
