import React, { useState } from 'react';
import UnauthorisedLayout from '../../layouts/UnauthorisedLayout';
import validationMessages from '../../constants/validationMessages';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';


import {
  useHistory
} from "react-router-dom";
import { useForm } from 'react-hook-form';
import { register as registerUser } from '../../api';
import { isLoggedIn } from '../../services/authService';

const Register = () => {

  const history = useHistory();
  const [customError, setCustomError] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = React.useState(false);

  if (isLoggedIn()) {
    history.push('/briefing');
  }
  const handleClickShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const onSubmit = async (form) => {
    try {
      setIsLoading(true);
      await registerUser(form);
      history.push('register/success');
    } catch (error) {
      setCustomError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <UnauthorisedLayout>
      <Box mr={8} mt={3} display="flex" justifyContent='flex-end' alignItems="center">
        <Typography variant="body1">
          Sie haben bereits ein Konto?
        </Typography>
        <Box mr={4} />
        <Button variant="outlined" onClick={() => history.push('/login')}>Anmelden</Button>
      </Box>
      <Grid container item xs direction="column" justify="center" alignItems="center">
        <Box
          border={1}
          borderRadius={10}
          borderColor='#DEDEDE'
          p={6}
          minWidth={400}
          maxWidth={400}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Typography variant="h4" gutterBottom>
                Loslegen
              </Typography>
            </Grid>
            <Grid container>
              <Typography variant="subtitle1" gutterBottom>
                Geben Sie Ihre Email Adresse unten ein
              </Typography>
            </Grid>
            <Grid item xs>
              <Box mt={3} />
              <TextField
                inputRef={register()}
                helperText={errors?.name && errors.name.message}
                error={!!errors?.name}
                fullWidth
                name="name"
                label="Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <Box mt={3} />
              <TextField
                error={!!errors?.email}
                helperText={errors?.email && errors.email.message}
                inputRef={register({
                  required: validationMessages.requiredEmail,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: validationMessages.emailFormat,
                  }
                })}
                fullWidth
                name="email"
                id="outlined-helperText"
                label="E-mail"
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <Box mt={3} />
              <TextField
                error={!!errors?.password}
                helperText={errors?.password && errors.password.message}
                inputRef={register({
                  required: validationMessages.requiredPassword
                })}
                name="password"
                fullWidth
                id="filled-password-input"
                label="Kennwort"
                type={isPasswordShown ? 'text' : 'password'}
                autoComplete="current-password"
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Grid>
            <Box mt={3} />
            <Grid item xs>
              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  checked={isPolicyAccepted}
                  onChange={({ target }) => setIsPolicyAccepted(target.checked)}
                  icon={<RadioButtonUncheckedOutlinedIcon color="disabled" />}
                  checkedIcon={<CheckCircleOutlineOutlinedIcon />}
                />}
                label={
                  <Typography variant="body1">
                    Indem ich auf “Anmelden” klicke, erklare ich mich mit den Nutzungsbedingungen von CX Fusion
                    einverstanden
                  </Typography>
                }
              />
            </Grid>
            <Box mt={3} />
            <Grid item>
              <Button onClick={() => setCustomError(null)}
                      disabled={!isPolicyAccepted || isLoading}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary">
                {isLoading ? <CircularProgress color="primary" size={20} /> : 'Registrieren'}
              </Button>
            </Grid>
            <Box mt={3} />
            <Grid item>
              {
                customError && (
                  <Alert severity="error" color="error">
                    {customError}
                  </Alert>
                )
              }
            </Grid>
          </form>
        </Box>
      </Grid>
    </UnauthorisedLayout>
  )
};

export default Register;
