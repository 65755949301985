import React from 'react';
import { Grid } from '@material-ui/core';

import {LayoutLeft} from '../LayoutLeft/index';
import './index.css';

const UnauthorisedLayout = ({ children }) => {
  return (
    <Grid container direction='row' wrap="nowrap">
      <Grid item>
        <LayoutLeft className="unauthorised-left-side"/>
      </Grid>
      <Grid container direction="column">
        {children}
      </Grid>
    </Grid>
  )
};

export default UnauthorisedLayout;