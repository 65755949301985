import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import UnauthorisedLayout from '../../layouts/UnauthorisedLayout';
import { verifyEmail } from '../../api';

const EmailVerification = () => {
  const history = useHistory();
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);
  const [snackBarText, setSnackbarText] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const sendVerificationRequest = async () => {
      try {
        await verifyEmail(token);
        setIsSnackbarShown(true);
        setSnackbarText('Passwort erfolgreich geändert. Weiterleitung zur Login-Seite...');
        setError(undefined);
        setTimeout(() => {
          history.push('/login');
        }, 2000);

      } catch (e) {
        setError('Wrong token');
        setIsLoading(false);
      }
    };

    sendVerificationRequest();
  }, [history, token]);


  return (
    <UnauthorisedLayout>
      <Grid container item xs direction="column" justify="center" alignItems="center">
        <Box
          border={1}
          borderRadius={10}
          borderColor='#DEDEDE'
          p={6}
          minWidth={400}
          maxWidth={400}
        >
          <Typography variant="h4">
            E-Mail Verifizierung
          </Typography>
          <Box mt={5} />
          <Grid item>
            {isLoading && <CircularProgress color="primary" size={20} />}
          </Grid>
          <Box mt={3} />
          <Grid item>
            {
              error && (
                <Alert severity="error" color="error">
                  {error}
                </Alert>
              )
            }
          </Grid>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          key={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={isSnackbarShown}
          onClose={() => setIsSnackbarShown(false)}
          message={snackBarText}
        />
      </Grid>
    </UnauthorisedLayout>
  )
};

export default EmailVerification;
