import React, { useState } from 'react';
import { Tabs, Tab, Grid, Box, makeStyles, SvgIcon } from '@material-ui/core';

import { Accordion } from '../../../../components/Accordion';
import headerIcon from '../../../../components/icons/svg/targeting-accordion.svg'
import { ReactComponent as CubeIcon } from '../../../../components/icons/svg/cube-icon.svg';
import { ReactComponent as FlaskIcon } from '../../../../components/icons/svg/flask-icon.svg';
import { ReactComponent as GearsIcon } from '../../../../components/icons/svg/gears-icon.svg';
import { ReactComponent as LayersIcon } from '../../../../components/icons/svg/layers-icon.svg';
import { MultiTargetingSelectorComponent } from './multi-targeting-selector';
import { SingleTargetingSelectorComponent } from './single-targeting-selector';
import { SituationalargetingSelectorComponent } from './situational/situational-targeting-selector';
import { greyColor, greyColorWithOpacity } from '../../../../theme.js';
import { DataScienceComponent } from './data-science/data-science-selector.jsx';

const useStyles = makeStyles({
  searchInput: {
    width: 210,
  },
  underline: {
    '&&:after': {
      borderBottom: 'none"',
    },
    "&&&:before": {
      borderBottom: "none"
    },
  },
  targetingTypes: {
    borderBottom: '1px solid',
    borderBottomColor: greyColor,
    width: '100%',
  },
  targetingsSearchContainer: {
    borderBottom: '1px solid',
    borderBottomColor: greyColor,
    width: '100%',
    backgroundColor: greyColorWithOpacity,
  },
  targetingsContainer: {
    height: 400,
  },
  categoriesContainer: {
    borderRight: '1px solid',
    borderRightColor: greyColor,
  },
  overflowScroll: {
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  searchIconContainer: {
    height: 36,
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export const useTargetingSelectorStyles = makeStyles({
  tableStyles: {
    paddingTop: 8,
  },
});

export const TargetingSelectorComponent = (props) => {
  const { onTargetingsChange: onTargetingsChangeCallback, selectedTargetings } = props;
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [isExpanded, setExpanded] = useState(false);

  const onTabClick = (event, newTabIndex) => {
    setActiveTab(newTabIndex);
    if (activeTab !== newTabIndex) {
      onTargetingsChangeCallback({});
    }
  };

  return (<>
      <Accordion
        headerIcon={headerIcon}
        label="Cookieless Targeting - bis zu 80% mehr Reichweite"
        isExpanded={isExpanded}
        onToggle={setExpanded}
      >
        <Grid container>


          <Grid item className={styles.targetingTypes}>
            <Tabs value={activeTab} onChange={onTabClick}>
              <Tab label={<><SvgIcon><CubeIcon/></SvgIcon> <Box ml={1.5} />Eindimensional</>} />
              <Tab label={<><SvgIcon><LayersIcon/></SvgIcon>  <Box ml={1.5} />Mehrdimensional</>} />
              <Tab label={<><SvgIcon><GearsIcon/></SvgIcon>  <Box ml={1.5} />Situativ</>} />
              <Tab label={<><SvgIcon><FlaskIcon/></SvgIcon>  <Box ml={1.5} />Custom Data Science</>} />
            </Tabs>
          </Grid>

          {/*<Grid item container direction="row" className={styles.withBottomBorder}>
            <Grid container alignItems="flex-end" className={styles.targetingsSearchContainer}>
              <Box ml={3} />
              <Grid item className={styles.searchIconContainer}>
                <SearchIcon />
              </Grid>
              <Box ml={1} />
              <Grid item>
                <TextField
                  label="Please type here for search"
                  className={styles.searchInput}
                  InputProps={{ classes: { underline: styles.underline } }}
                />
              </Grid>
            </Grid>
          </Grid>*/}

          {activeTab === 0 && (
            <SingleTargetingSelectorComponent
              onTargetingsChange={onTargetingsChangeCallback}
              selectedTargetings={selectedTargetings}
            />
          )}

          {activeTab === 1 && (
            <MultiTargetingSelectorComponent
              onTargetingsChange={onTargetingsChangeCallback}
              selectedTargetings={selectedTargetings}
            />
          )}

          {activeTab === 2 && (
            <SituationalargetingSelectorComponent
              onTargetingsChange={onTargetingsChangeCallback}
              selectedTargetings={selectedTargetings}
            />
          )}

          {activeTab === 3 && (
            <DataScienceComponent
              onTargetingsChange={onTargetingsChangeCallback}
            />
          )}
        </Grid>

      </Accordion>
    </>
  );
};
