import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Controller } from 'react-hook-form';

import headerIcon from '../../../../components/icons/svg/campaign-accordion.svg'
import { Accordion } from '../../../../components/Accordion';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
});

export const CampaignSelector = ({ control, register, errors }) => {
  const [isExpanded, setExpanded] = useState(false);
  const styles = useStyles();
  const {startDate} = control.getValues();

  return (
    <>
      <Accordion
        headerIcon={headerIcon}
        label="Campaign"
        isExpanded={isExpanded}
        onToggle={setExpanded}
      >
        <Box m={4} className={styles.fullWidth}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <TextField
                error={!!errors?.campaignName}
                inputRef={register({ required: true })}
                label="Campaign name"
                name="campaignName"
                margin="normal"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputRef={register}
                label="PO"
                name="campaignPO"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                error={!!errors?.campaignGoal}
                inputRef={register({ required: true })}
                label="Campaign goal"
                name="campaignGoal"
                margin="normal"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputRef={register}
                label="KPI"
                name="campaignKPI"
                margin="normal"
                fullWidth
              />
            </Grid>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={4}>
                <Controller
                  as={
                    <KeyboardDatePicker
                      error={!!errors?.startDate}
                      label="Start Date"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      disablePast
                      fullWidth
                      required
                      disableToolbar
                    />
                  }
                  name="startDate"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  as={
                    <KeyboardDatePicker
                      error={!!errors?.endDate}
                      label="End Date"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      minDate={startDate || new Date()}
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      // minDateMessage="translation"
                      disablePast
                      fullWidth
                      required
                      disableToolbar
                    />
                  }
                  name="endDate"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={4}>
              <TextField
                inputRef={register}
                label="Prognostizierte Anzahl Impressions"
                name="impressionsNumberForecast"
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>

      </Accordion>
    </>
  )
};
