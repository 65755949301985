import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Button, Typography, TextField,
  InputAdornment, IconButton, FormControlLabel, Checkbox, CircularProgress
} from '@material-ui/core';
import {
  Link,
  useHistory
} from "react-router-dom";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import UnauthorisedLayout from '../../layouts/UnauthorisedLayout';
import { useForm } from 'react-hook-form';
import { getCurrentUser, login as loginUser } from '../../api';
import { Alert } from '@material-ui/lab';
import validationMessages from '../../constants/validationMessages';
import { isLoggedIn, storeToken, storeUser } from '../../services/authService';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const Login = () => {
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [customError, setCustomError] = useState(null);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);


  const handleClickShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  useEffect(() => {
    getCurrentUser()
      .then(() => {
        history.push('/briefing');
      });
  }, []);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const onSubmit = async (form) => {
    setIsLoading(true);
    try {
      const { user, token } = await loginUser(form);
      storeToken(token, isRememberMeChecked);
      storeUser(user, isRememberMeChecked);
      history.push('/briefing');
    } catch (error) {
      setCustomError(error);
      setIsLoading(false);
    }
  };

  return (
    <UnauthorisedLayout>
      <Box mr={8} mt={3} display="flex" justifyContent='flex-end' alignItems="center">
        <Typography variant="body1">
          Sie haben kein Konto?
        </Typography>
        <Box mr={4} />
        <Button variant="outlined" onClick={() => history.push('/register')}>Loslegen</Button>
      </Box>
      <Grid container item xs direction="column" justify="center" alignItems="center">
        <Box border={1} borderRadius={10} borderColor='#DEDEDE' p={6} mt={-5} minWidth={400}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Typography variant="h4" gutterBottom>
                Anmelden bei CX Fusion
              </Typography>
            </Grid>
            <Grid container>
              <Typography variant="subtitle1" gutterBottom>
                Geben Sie Ihre Daten unten ein
              </Typography>
            </Grid>
            <Box mt={3} />
            <Grid item xs>
              <TextField
                fullWidth
                label="E-mail"
                error={!!errors?.email}
                helperText={errors?.email && errors.email.message}
                name="email"
                inputRef={register({
                  required: validationMessages.requiredEmail,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: validationMessages.emailFormat,
                  }
                })}
                variant="outlined"
              />
            </Grid>
            <Box mt={3} />
            <Grid item xs container justify="flex-end">
              <Link to="/forgot-password">
                <Typography variant="body1" gutterBottom>
                  Kennwort vergessen?
                </Typography>
              </Link>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                error={!!errors?.password}
                helperText={errors?.password && errors.password.message}
                inputRef={register({
                  required: validationMessages.requiredPassword,
                })}
                name="password"
                label="Kennwort"
                type={isPasswordShown ? 'text' : 'password'}
                autoComplete="current-password"
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
              <Box mt={3} />
              <Grid item xs>
                <FormControlLabel
                  control={<Checkbox
                    color="primary"
                    checked={isRememberMeChecked}
                    onChange={({ target }) => setIsRememberMeChecked(target.checked)}
                    icon={<RadioButtonUncheckedOutlinedIcon color="disabled" />}
                    checkedIcon={<CheckCircleOutlineOutlinedIcon />}
                  />}
                  label="Login Daten speichem"
                />
              </Grid>
              <Box mt={3} />
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  variant="contained"
                  onClick={() => setCustomError(null)}
                  color="primary">
                  {isLoading ? <CircularProgress color="primary" size={20} /> : 'Anmelden'}
                </Button>
              </Grid>
              <Box mt={3} />
              <Grid item>
                {
                  customError && (
                    <Alert severity="error" color="error">
                      {customError}
                    </Alert>
                  )
                }
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </UnauthorisedLayout>
  )
};

export default Login;
