import { uniq, uniqBy } from 'lodash';
import { targetings, targetingsByCategory, targetingsBySubCategory } from '../constants/targetings';

export const getTargetingCategories = () => {
  return Object.keys(targetingsByCategory);
};

export const getSubCategoriesByCategories = (categories) => {
  const subCategories = categories.reduce((acc, category) => {
    const subcategories = targetingsByCategory[category].map(targeting => targeting.subCategory);
    return [...acc, ...subcategories]
  }, []);

  return uniq(subCategories);
};

export const getTargetingsBySubCategories = (subCategories) => {
  const targetings =  subCategories.reduce((acc, subCategory) => {
    return [...acc, ...targetingsBySubCategory[subCategory]]
  }, []);

  return uniqBy(targetings, 'targeting');
};

export const getTargetingByName = (targetingName) => targetings.find(targeting => targeting.targeting === targetingName);