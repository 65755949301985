import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTargetingSelectorStyles } from '../../../targeting-selector';
import classNames from 'classnames';

const useStyles = makeStyles({
  description: {
    fontSize: 16,
    lineHeight: '26px',
  },
  key: {
    color: '#696969',
    fontSize: 12,
    lineHeight: '15px',
  },
  categoriesContainer: {
    borderRight: '1px solid #E0E0E0',
  },
});

const SituationalTargetingDescription = ({ category }) => {
  const styles = useStyles();
  const tableStyles = useTargetingSelectorStyles();
  return (
    <Grid sm={4} item className={styles.categoriesContainer}>
      <Box pl={3} pr={3}>
        <Grid
          container
          direction="column"
          className={classNames({
            [tableStyles.tableStyles]: true,
          })}
        >
          <Grid item>
                  <span className={styles.description}>
                    {category.hint}
                  </span>
          </Grid>
          <Box mt={3} />
          <Grid item container direction='column'>
            <Grid item className={styles.key}>
              <span>Maximale Reichweite</span>
            </Grid>
            <Box mt={1} />
            <Grid item className={styles.description}>
              <span>{category.reach}</span>
            </Grid>
          </Grid>
          <Box mt={3} />
          <Grid item container direction='column'>
            <Grid item className={styles.key}>
              <span>CPM</span>
            </Grid>
            <Box mt={1} />
            <Grid item className={styles.description}>
              <span>{category.cpm}</span>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default SituationalTargetingDescription;