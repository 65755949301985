import React from 'react';
import { ExpansionPanel, ExpansionPanelDetails, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  'panel-header': {
    fontSize: 24,
    borderTop: 10,
    margin: 0,
    paddingRight: 20,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '.Mui-expanded': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  panel: {
    borderRadius: 10,
    '&::before': {
      display: 'none',
    },
  },
  headerIcon: {},
  headerIconContainer: {
    backgroundColor: 'rgba(31, 182, 83, 0.07)',
    borderRadius: 10,
    width: 40,
    height: 40,
  },
}));

const Panel = (props) => {
  const styles = useStyles();
  const { children } = props;
  const [header, body] = children;

  return (<>
    <ExpansionPanel
      className={styles.panel}
      expanded={true}
    >
      <div
        className={styles['panel-header']}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
        >
          {header}
        </Grid>
      </div>
      <ExpansionPanelDetails>
        {body}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </>);
};

export default Panel;
