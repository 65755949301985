export const channels = [
  {
    name: 'Desktop - Display',
    platforms: ['DV360', 'theTradeDesk', 'Adform', 'Verizon', 'Xandr'],
    icon: '',
  },
  {
    name: 'Desktop - Bewegtbild',
    platforms: ['DV360', 'theTradeDesk', 'Adform', 'Verizon', 'Xandr'],
    icon: '',
  },
  {
    name: 'Desktop - Native',
    platforms: ['DV360', 'theTradeDesk', 'Adform', 'Verizon', 'Xandr'],
    icon: '',
  },
  {
    name: 'Mobile - Display',
    platforms: ['DV360', 'theTradeDesk', 'Adform', 'Verizon', 'Xandr'],
    icon: '',
  },
  {
    name: 'Mobile - Bewegtbild',
    platforms: ['DV360', 'theTradeDesk', 'Adform', 'Verizon', 'Xandr'],
    icon: '',
  },
  {
    name: 'Mobile - Native',
    platforms: ['DV360', 'theTradeDesk', 'Adform', 'Verizon', 'Xandr'],
    icon: '',
  },
  {
    name: 'Programmatic/ Advanced TV',
    platforms: ['DV360', 'theTradeDesk', 'Adform', 'Verizon', 'Xandr'],
    icon: '',
  },
  {
    name: 'Programmatic Audio',
    platforms: ['Spotify', 'RMS'],
    icon: '',
  },
  {
    name: 'Digital Out-of-Home',
    platforms: ['Ströer (DOOH)', 'WallDecaux (DOOH)'],
    icon: '',
  },
  {
    name: 'In-Game',
    platforms: ['DV360', 'theTradeDesk', 'Adform', 'Verizon', 'Xandr'],
    icon: '',
  },
  {
    name: 'Social & Social Video',
    platforms: ['Facebook', 'Instagram', 'YouTube', 'Snapchat'],
    icon: '',
  },
];
