import { groupBy, uniqBy } from 'lodash';

export const targetings = [{
  "id": 1,
  "category": "Lebenssituation",
  "subCategory": "Wohnsituation",
  "targeting": "1 Familienhäuser",
  "description": "Adressieren Sie Personen die in Einfamilienhäusern leben.",
  "cpm": "1,20 €",
  "reach": "8560000"
}, {
  "id": 2,
  "category": "Lebenssituation",
  "subCategory": "Wohnsituation",
  "targeting": "2 Familienhäuser (Doppelhaushälfte)",
  "description": "Adressieren Sie Personen die in Zweifamilienhäusern leben.",
  "cpm": "1,20 €",
  "reach": "5068000"
}, {
  "id": 3,
  "category": "Lebenssituation",
  "subCategory": "Wohnsituation",
  "targeting": "3-9 Familienhäuser",
  "description": "Adressieren Sie Personen die in Mehrfamilienhäusern (bis zu 9 Wohneinheiten) leben.",
  "cpm": "1,20 €",
  "reach": "4796000"
}, {
  "id": 4,
  "category": "Lebenssituation",
  "subCategory": "Wohnsituation",
  "targeting": "Wohnblocks mit bis zu 20 Haushalten",
  "description": "Adressieren Sie Personen die in Wohnblocks (bis zu 20 Wohneinheiten) leben.",
  "cpm": "1,20 €",
  "reach": "2271000"
}, {
  "id": 5,
  "category": "Lebenssituation",
  "subCategory": "Wohnsituation",
  "targeting": "Hochhäuser mit 20 Haushalten und mehr",
  "description": "Adressieren Sie Personen die in Wohnblocks (mehr als 20 Wohneinheiten) leben.",
  "cpm": "1,20 €",
  "reach": "2375000"
}, {
  "id": 6,
  "category": "Lebenssituation",
  "subCategory": "Zahlungsindex",
  "targeting": "Niedrigste Zahlungsausfallwahrscheinlichkeit",
  "description": "Adressieren Sie sehr zuverlässige Schuldner/ Personen mit erhöhter Kaufkraft.",
  "cpm": "1,80 €",
  "reach": "1553000"
}, {
  "id": 7,
  "category": "Lebenssituation",
  "subCategory": "Zahlungsindex",
  "targeting": "Unterdurchschnittliche Zahlungsausfallwahrscheinlichkeit",
  "description": "Adressieren Sie zuverlässige Schuldner/ Personen mit tendenziell höhere Kaufkraft.",
  "cpm": "1,80 €",
  "reach": "4593000"
}, {
  "id": 8,
  "category": "Lebenssituation",
  "subCategory": "Zahlungsindex",
  "targeting": "Durchschnittliche Zahlungsausfallwahrscheinlichkeit",
  "description": "Adressieren Sie Schuldner mit durchschnittlicher Zahlungsmoral.",
  "cpm": "1,80 €",
  "reach": "2863000"
}, {
  "id": 9,
  "category": "Lebenssituation",
  "subCategory": "Zahlungsindex",
  "targeting": "Überdurchschnittliche Zahlungsausfallwahrscheinlichkeit",
  "description": "Adressieren Sie Schuldner mit Potenzial für höhere Verzinsung/ Personen mit vergleichsweise geringer Kaufkraft.",
  "cpm": "1,80 €",
  "reach": "8899000"
}, {
  "id": 10,
  "category": "Lebenssituation",
  "subCategory": "Zahlungsindex",
  "targeting": "Höchste Zahlungsausfallwahrscheinlichkeit",
  "description": "Adressieren Sie Schuldner mit Potenzial für hohe Verzinsung/ Personen mit vergleichsweise sehr geringer Kaufkraft.",
  "cpm": "1,80 €",
  "reach": "9442000"
}, {
  "id": 11,
  "category": "Lebenssituation",
  "subCategory": "Wohnsituation",
  "targeting": "Kürzlich umgezogen",
  "description": "Adressieren Sie Personen die kürzlich umgezogen sind.",
  "cpm": "1,80 €",
  "reach": "888000"
}, {
  "id": 12,
  "category": "Lebenssituation",
  "subCategory": "Wohnsituation",
  "targeting": "Länger wohnhaft",
  "description": "Adressieren Sie Personen die mindestens seit einem Jahr nicht umgezogen sind.",
  "cpm": "1,80 €",
  "reach": "3729000"
}, {
  "id": 13,
  "category": "Lebenssituation",
  "subCategory": "Arbeitsmarkt",
  "targeting": "Erwerbslose",
  "description": "Adressieren Sie Personen die aktuell keiner beruflichen Tätigkeit nachgehen.",
  "cpm": "1,40 €",
  "reach": "1529000"
}, {
  "id": 14,
  "category": "Lebenssituation",
  "subCategory": "Arbeitsmarkt",
  "targeting": "Erwerbstätige",
  "description": "Adressieren Sie Personen die aktuell einer beruflichen Tätigkeit nachgehen.",
  "cpm": "1,40 €",
  "reach": "4334000"
}, {
  "id": 15,
  "category": "Lebenssituation",
  "subCategory": "Pendler",
  "targeting": "Pendler",
  "description": "Adressieren Sie Personen die mindestens 40 Km zwischen Arbeitsstätte und Zuhause zurücklegen.",
  "cpm": "1,30 €",
  "reach": "6893000"
}, {
  "id": 16,
  "category": "Einstellungen",
  "subCategory": "Politische Präferenz",
  "targeting": "CDU/CSU",
  "description": "Adressieren Sie Personen mit politischer Präferenz für: CDU/CSU.",
  "cpm": "1,20 €",
  "reach": "10924000"
}, {
  "id": 17,
  "category": "Einstellungen",
  "subCategory": "Politische Präferenz",
  "targeting": "SPD",
  "description": "Adressieren Sie Personen mit politischer Präferenz für: SPD.",
  "cpm": "1,20 €",
  "reach": "7065000"
}, {
  "id": 18,
  "category": "Einstellungen",
  "subCategory": "Politische Präferenz",
  "targeting": "Grüne",
  "description": "Adressieren Sie Personen mit politischer Präferenz für: Bündnis 90 Die Grünen.",
  "cpm": "1,20 €",
  "reach": "2762000"
}, {
  "id": 19,
  "category": "Einstellungen",
  "subCategory": "Politische Präferenz",
  "targeting": "FDP",
  "description": "Adressieren Sie Personen mit politischer Präferenz für: FDP.",
  "cpm": "1,20 €",
  "reach": "3768000"
}, {
  "id": 20,
  "category": "Einstellungen",
  "subCategory": "Politische Präferenz",
  "targeting": "Linke",
  "description": "Adressieren Sie Personen mit politischer Präferenz für: die Linke.",
  "cpm": "1,20 €",
  "reach": "2656000"
}, {
  "id": 21,
  "category": "Einstellungen",
  "subCategory": "Politische Präferenz",
  "targeting": "AfD",
  "description": "Adressieren Sie Personen mit politischer Präferenz für: AfD.",
  "cpm": "1,20 €",
  "reach": "4430000"
}, {
  "id": 22,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Konservativ-Etablierte",
  "description": "Adressieren Sie Personen die tendenziell der Oberschicht/oberen Mittelschicht angehören und eher traditionelle Wertevorstellungen vertreten.",
  "cpm": "1,40 €",
  "reach": "8572000"
}, {
  "id": 23,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Liberal-Intellektuelle",
  "description": "Adressieren Sie Personen die tendenziell der Oberschicht/oberen Mittelschicht angehören und eher moderne Wertevorstellungen vertreten.",
  "cpm": "1,40 €",
  "reach": "5593000"
}, {
  "id": 24,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Performer",
  "description": "Adressieren Sie Personen die tendenziell der Oberschicht/oberen Mittelschicht angehören und aufgeschlossen gegenüber Neuerungen sind.",
  "cpm": "1,40 €",
  "reach": "6504000"
}, {
  "id": 25,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Expeditive",
  "description": "Adressieren Sie Personen die tendenziell der oberen/mittleren Mittelschicht angehören und eher zukunftsorientiert denken und handeln.",
  "cpm": "1,40 €",
  "reach": "8517000"
}, {
  "id": 26,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Bürgerliche Mitte",
  "description": "Adressieren Sie Personen die tendenziell der mittleren Mittelschicht angehören und eher moderne Wertvorstellungen vertreten.",
  "cpm": "1,40 €",
  "reach": "9006000"
}, {
  "id": 27,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Adaptiv-Pragmatische",
  "description": "Adressieren Sie Person die tendenziell der mittleren Mittelschicht angehören und eher zukunftsorientiert denken und handeln.",
  "cpm": "1,40 €",
  "reach": "9150000"
}, {
  "id": 28,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Sozialökologische",
  "description": "Adressieren Sie Personen die tendenziell der mittleren/oberen Mittelschicht angehören und moderne Wertvorstellungen vertreten.",
  "cpm": "1,40 €",
  "reach": "5672000"
}, {
  "id": 29,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Traditionelle",
  "description": "Adressieren Sie Personen die tendenziell der unteren/mittleren Mittelschicht oder der Unterschicht angehören und traditionelle und/oder moderne Wertvorstellungen vertreten.",
  "cpm": "1,40 €",
  "reach": "8858000"
}, {
  "id": 30,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Prekäre",
  "description": "Adressieren Sie Personen die tendenziell der unteren Mittelschicht oder der Unterschicht angehören und moderne Wertvorstellungen vertreten.",
  "cpm": "1,40 €",
  "reach": "7425000"
}, {
  "id": 31,
  "category": "Einstellungen",
  "subCategory": "Milieus",
  "targeting": "Hedonisten",
  "description": "Adressieren Sie Personen die tendenziell der unteren/mittleren Mittelschicht oder der Unterschicht angehören und gegenüber Neuerungen aufgeschlossen sind.",
  "cpm": "1,40 €",
  "reach": "10550000"
}, {
  "id": 32,
  "category": "Einstellungen",
  "subCategory": "Limbic",
  "targeting": "Abenteurer",
  "description": "Adressieren Sie Personen die besonders risikofreudig und impulsiv entscheiden.",
  "cpm": "1,40 €",
  "reach": "4964000"
}, {
  "id": 33,
  "category": "Einstellungen",
  "subCategory": "Limbic",
  "targeting": "Performer",
  "description": "Adressieren Sie Personen die besonderen Fokus auf Leistung und Prestige legen.",
  "cpm": "1,40 €",
  "reach": "8791000"
}, {
  "id": 34,
  "category": "Einstellungen",
  "subCategory": "Limbic",
  "targeting": "Disziplinierte",
  "description": "Adressieren Sie Personen die besonderen Fokus auf Nachhaltigkeit und ein angemessenes Preis-/Leistungsverhältnis legen.",
  "cpm": "1,40 €",
  "reach": "10073000"
}, {
  "id": 35,
  "category": "Einstellungen",
  "subCategory": "Limbic",
  "targeting": "Traditionalisten",
  "description": "Adressieren Sie Personen die sich besonderen Fokus auf Qualität legen wobei sie Faktoren wie Prestige eher als unwichtig empfinden.",
  "cpm": "1,40 €",
  "reach": "12439000"
}, {
  "id": 36,
  "category": "Einstellungen",
  "subCategory": "Limbic",
  "targeting": "Harmoniser",
  "description": "Adressieren Sie Personen die besonders nach familiengerechten Attributen entscheiden.",
  "cpm": "1,40 €",
  "reach": "22441000"
}, {
  "id": 37,
  "category": "Einstellungen",
  "subCategory": "Limbic",
  "targeting": "Offene",
  "description": "Adressieren Sie Personen für die der Genuss und das Wohlfühlerlebnis an erster Stelle kommen und Status eher unwichtig ist.",
  "cpm": "1,40 €",
  "reach": "7934000"
}, {
  "id": 38,
  "category": "Einstellungen",
  "subCategory": "Limbic",
  "targeting": "Hedonisten",
  "description": "Adressieren Sie Personen die besonders neugierig sind und eher spontan entscheiden.",
  "cpm": "1,40 €",
  "reach": "13462000"
}, {
  "id": 39,
  "category": "Einstellungen",
  "subCategory": "Geldspenden",
  "targeting": "Spendenbereitschaft niedrig",
  "description": "Adressieren Sie Personen die selten und/oder eher geringe Geldspenden leisten.",
  "cpm": "1,50 €",
  "reach": "7978000"
}, {
  "id": 40,
  "category": "Einstellungen",
  "subCategory": "Geldspenden",
  "targeting": "Spendenbereitschaft mittel",
  "description": "Adressieren Sie Personen die gelegentlich überdurchschnittliche bzw. regelmäßig vergleichsweise niedrigere Geldspenden leisten.",
  "cpm": "1,50 €",
  "reach": "6296000"
}, {
  "id": 41,
  "category": "Einstellungen",
  "subCategory": "Geldspenden",
  "targeting": "Spendenbereitschaft hoch",
  "description": "Adressieren Sie Personen die regelmäßig überdurchschnittliche Geldspenden leisten oder gelegentlich sehr hohe Geldspenden leisten.",
  "cpm": "1,50 €",
  "reach": "7069000"
}, {
  "id": 42,
  "category": "Einstellungen",
  "subCategory": "Ernährung",
  "targeting": "Vegetarier",
  "description": "Adressieren Sie Personen die sich bewusst fleischlos ernähren.",
  "cpm": "1,40 €",
  "reach": "5072000"
}, {
  "id": 43,
  "category": "Einstellungen",
  "subCategory": "Ernährung",
  "targeting": "Veganer",
  "description": "Adressieren Sie Personen die bewusst auf sämtliche tierische Lebensmittel verzichten.",
  "cpm": "1,40 €",
  "reach": "1536000"
}, {
  "id": 44,
  "category": "Einstellungen",
  "subCategory": "Ernährung",
  "targeting": "Omnivore",
  "description": "Adressieren Sie Personen mit Präferenz einer abwechslungsreichen Ernährung ohne bewussten Verzicht auf tierische Lebensmittel bzw. nicht durch Allergien eingeschränkt werden.",
  "cpm": "1,40 €",
  "reach": "40280000"
}, {
  "id": 45,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Kreditkarte",
  "description": "Adressieren Sie Personen die über mindestens eine gültige Kreditkarte verfügen.",
  "cpm": "3,40 €",
  "reach": "15977000"
}, {
  "id": 46,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Konsumentenkredit",
  "description": "Adressieren Sie Personen die laufende/n Konsumkredit/e haben und/oder zur hier nach aktuell suchen.",
  "cpm": "3,40 €",
  "reach": "2727000"
}, {
  "id": 47,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Baufinanzierung",
  "description": "Adressieren Sie Personen die laufende/n Baufinanzierungskredit/e haben und/oder zur hier nach aktuell suchen.",
  "cpm": "3,40 €",
  "reach": "3691000"
}, {
  "id": 48,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Banking Präferenz-Filiale",
  "description": "Adressieren Sie Personen die regelmäßig Bankfilialen aufsuchen.",
  "cpm": "2,80 €",
  "reach": "15559000"
}, {
  "id": 49,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Banking Präferenz-Online",
  "description": "Adressieren Sie Personen mit Präferenz für Online-Banking.",
  "cpm": "2,80 €",
  "reach": "26626000"
}, {
  "id": 50,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Rat vom Fachmann",
  "description": "Adressieren Sie Personen die bei Kaufentscheidungen besonderen Wert auf fachmännische Beratung legen.",
  "cpm": "2,80 €",
  "reach": "17537000"
}, {
  "id": 51,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Rat von Freunden und Familie",
  "description": "Adressieren Sie Personen die bei Kaufentscheidungen besonders den Rat von Freunden und Familienangehörigen berücksichtigen.",
  "cpm": "2,80 €",
  "reach": "19779000"
}, {
  "id": 52,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Bevorzugte Zahlungsart Online:Paypal",
  "description": "Adressieren Sie Personen die bei Online-Käufen präferiert mit PayPal bezahlen.",
  "cpm": "2,80 €",
  "reach": "18038000"
}, {
  "id": 53,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Bevorzugte Zahlungsart Online:Kreditkarte",
  "description": "Adressieren Sie Personen die bei Online-Käufen präferiert mit Kreditkarte bezahlen.",
  "cpm": "2,80 €",
  "reach": "9909000"
}, {
  "id": 54,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Spekulative Geldanlagen",
  "description": "Adressieren Sie Personen mit Affinität für spekulative Geldanlagen (Aktien, geschlossene und offene Fonds, Private Equity und Hebelprodukte).",
  "cpm": "2,80 €",
  "reach": "12525000"
}, {
  "id": 55,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Konservative Geldanlagen",
  "description": "Adressieren Sie Personen die eher langfristig und möglichst sicher Geld anlegen möchten (Sparbuch, Girokonto, Bausparvertrag etc.).",
  "cpm": "2,80 €",
  "reach": "30660000"
}, {
  "id": 56,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Private Krankenversicherung",
  "description": "Adressieren Sie Personen die sich für private Krankenversicherungen interessieren oder bereits abgeschlossen haben.",
  "cpm": "3,40 €",
  "reach": "7797000"
}, {
  "id": 57,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Krankenzusatzversicherung",
  "description": "Adressieren Sie Personen die sich für Krankenzusatzversicherungen interessieren oder bereits abgeschlossen haben.",
  "cpm": "3,40 €",
  "reach": "12735000"
}, {
  "id": 58,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Lebensversicherung",
  "description": "Adressieren Sie Personen die sich für Lebensversicherungen interessieren oder bereits abgeschlossen haben.",
  "cpm": "3,40 €",
  "reach": "3396000"
}, {
  "id": 59,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Berufsunfähigkeitsversicherung",
  "description": "Adressieren Sie Personen die sich für Berufsunfähigkeitsversicherungen interessieren oder bereits abgeschlossen haben.",
  "cpm": "3,40 €",
  "reach": "4026000"
}, {
  "id": 60,
  "category": "B2C",
  "subCategory": "Banken & Versicherungen",
  "targeting": "Private Rentenversicherung",
  "description": "Adressieren Sie Personen die sich für private Rentenversicherungen interessieren oder bereits abgeschlossen haben.",
  "cpm": "3,40 €",
  "reach": "15201000"
}, {
  "id": 61,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "PKW-Halter",
  "description": "Adressieren Sie Personen die aktuell mindestens einen PKW besitzen.",
  "cpm": "2,80 €",
  "reach": "23527000"
}, {
  "id": 62,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Kein PKW",
  "description": "Adressieren Sie Personen die aktuell keinen PKW besitzen.",
  "cpm": "2,80 €",
  "reach": "33239000"
}, {
  "id": 63,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Klassisch (Benzin, Diesel)",
  "description": "Adressieren Sie Personen die mindestens einen PKW mit klassischen Antrieb (Benzin, Diesel) besitzen.",
  "cpm": "2,80 €",
  "reach": "33389000"
}, {
  "id": 64,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Hybrid, Elektro",
  "description": "Adressieren Sie Personen die mindestens einen PKW mit alternativem Antrieb (Hybrid, Elektro) besitzen.",
  "cpm": "2,80 €",
  "reach": "1000000"
}, {
  "id": 65,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "überdurchschnittlicher Anteil Gebrauchtwagen",
  "description": "Adressieren Sie Personen die einen Gebrauchtwagen erworben haben oder daran interessiert sind.",
  "cpm": "2,80 €",
  "reach": "2551000"
}, {
  "id": 66,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "überdurchschnittlicher Anteil Neuwagen",
  "description": "Adressieren Sie Personen die einen Neuwagen erworben haben oder daran interessiert sind.",
  "cpm": "2,80 €",
  "reach": "303000"
}, {
  "id": 67,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Audi-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Audi fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "2805000"
}, {
  "id": 68,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "BMW-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke BMW fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "3292000"
}, {
  "id": 69,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Fiat (einschl. Alfa Romeo, Lancia)-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Fiat fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "1347000"
}, {
  "id": 70,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Ford-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Ford fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "2450000"
}, {
  "id": 71,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Mazda-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Mazda fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "773000"
}, {
  "id": 72,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Mercedes-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Ford fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "4430000"
}, {
  "id": 73,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Nissan-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Nissan fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "490000"
}, {
  "id": 74,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Opel-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Opel fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "4328000"
}, {
  "id": 75,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Peugeot (einschl. Citroën)-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Peugeot fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "1716000"
}, {
  "id": 76,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Renault-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Renault fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "1785000"
}, {
  "id": 77,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Sonstige asiatische PKW-Marken",
  "description": "Adressieren Sie Personen die aktuell einen PKW von sonstigen asiatischen Marken fahren und/oder besonders an Fabrikaten dieser Marken interessiert sind.",
  "cpm": "2,90 €",
  "reach": "4607000"
}, {
  "id": 78,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Toyota (einschl. Lexus)-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Toyota/Lexus fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "1322000"
}, {
  "id": 79,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "VW-Fahrer",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke VW fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,90 €",
  "reach": "9019000"
}, {
  "id": 80,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Miniwagen",
  "description": "Adressieren Sie Personen die aktuell einen PKW der Marke Mini fahren und/oder allgemein ein gesteigertes Interesse für Fabrikate dieser Marke aufweisen.",
  "cpm": "2,80 €",
  "reach": "2971000"
}, {
  "id": 81,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Kleinwagen",
  "description": "Adressieren Sie Personen die aktuell einen Kleinwagen besitzen und/oder ein Kaufinteresse aufweisen.",
  "cpm": "2,80 €",
  "reach": "7685000"
}, {
  "id": 82,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Untere Mittelklassewagen",
  "description": "Adressieren Sie Personen die aktuell einen unteren Mittelklassewagen besitzen und/oder ein Kaufinteresse aufweisen.",
  "cpm": "2,80 €",
  "reach": "8941000"
}, {
  "id": 83,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Mittelklassewagen",
  "description": "Adressieren Sie Personen die aktuell einen Mittelklassewagen besitzen und/oder ein Kaufinteresse aufweisen.",
  "cpm": "2,80 €",
  "reach": "2597000"
}, {
  "id": 84,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Obere Mittelklassewagen",
  "description": "Adressieren Sie Personen die aktuell einen oberen Mittelklassewagen besitzen und/oder ein Kaufinteresse aufweisen.",
  "cpm": "2,80 €",
  "reach": "1189000"
}, {
  "id": 85,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Oberklassewagen",
  "description": "Adressieren Sie Personen die aktuell einen Oberklassewagen besitzen und/oder ein Kaufinteresse aufweisen.",
  "cpm": "2,80 €",
  "reach": "127000"
}, {
  "id": 86,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Geländewagen",
  "description": "Adressieren Sie Personen die aktuell einen Geländewagen(SUV) besitzen und/oder ein Kaufinteresse aufweisen.",
  "cpm": "2,80 €",
  "reach": "3356000"
}, {
  "id": 87,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Cabriolets",
  "description": "Adressieren Sie Personen die aktuell ein Cabriolets besitzen und/oder ein Kaufinteresse aufweisen.",
  "cpm": "2,80 €",
  "reach": "371000"
}, {
  "id": 88,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Kombiwagen",
  "description": "Adressieren Sie Personen die aktuell einen Kombiwagen besitzen und/oder ein Kaufinteresse aufweisen.",
  "cpm": "2,80 €",
  "reach": "5450000"
}, {
  "id": 89,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Vans",
  "description": "Adressieren Sie Personen die aktuell einen Van besitzen und/oder ein Kaufinteresse aufweisen.",
  "cpm": "2,80 €",
  "reach": "2451000"
}, {
  "id": 90,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Der preisbewusste Rationalist (Preissensibel)",
  "description": "Adressieren Sie Personen die beim Kauf eines PKW besonderen Wert auf das Preis-Leistungsverhältnis legen.",
  "cpm": "2,90 €",
  "reach": "5186000"
}, {
  "id": 91,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Der jüngere Kleinwagenfahrer (geringe Kaufkraft)",
  "description": "Adressieren Sie Personen unter 30 die aktuell mindestens einen Kleinwagen besitzen.",
  "cpm": "2,90 €",
  "reach": "5064000"
}, {
  "id": 92,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Der zweckorientierte Gebrauchtwagenfahrer (",
  "description": "Adressieren Sie Personen die beim Kauf eines PKW Ihren Fokus auf Gebrauchtwagen legen und für die Funktionalität entscheidend ist, preissensibel.",
  "cpm": "2,90 €",
  "reach": "7868000"
}, {
  "id": 93,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Der konservative untere Mittelklassenfahrer",
  "description": "Adressieren Sie Personen die beim Kauf eines PKW Ihren Fokus auf Funktionalität und eine gute Preis-/Leistungs-ratio liegen, eher preissensibel.",
  "cpm": "2,90 €",
  "reach": "6436000"
}, {
  "id": 94,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Der qualitätsbewusste Mittelklassenfahrer",
  "description": "Adressieren Sie Personen die beim Kauf eines PKW Ihren Fokus auf eine besonders gute Verarbeitung legen aber dennoch preissensibel sind.",
  "cpm": "2,90 €",
  "reach": "5533000"
}, {
  "id": 95,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Der familienorientierte Kombifahrer",
  "description": "Adressieren Sie Personen die beim Kauf eines PKW besonderen Fokus auf ausreichenden Platz und Funktionalität achten.",
  "cpm": "2,90 €",
  "reach": "5880000"
}, {
  "id": 96,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Der markenbewusste Oberklassenfahrer",
  "description": "Adressieren Sie Personen die beim Kauf eines PKW besonders an Fabrikaten von Premiumanbietern interessiert sind und weniger Preissensibel sind.",
  "cpm": "2,90 €",
  "reach": "3077000"
}, {
  "id": 97,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Der prestige- und Leistungsorientierte Fahrer",
  "description": "Adressieren Sie Personen die beim Kauf eines PKW besonderen Wert auf Performance und Image legen und deutlich weniger Preissensibel sind.",
  "cpm": "2,90 €",
  "reach": "2564000"
}, {
  "id": 98,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Wechselbereitschaft -niedrig",
  "description": "Adressieren Sie Personen die präferiert die Marke Ihres derzeitigen Fabrikats beibehalten möchten.",
  "cpm": "3,00 €",
  "reach": "9176000"
}, {
  "id": 99,
  "category": "B2C",
  "subCategory": "Automobile",
  "targeting": "Wechselbereitschaft -hoch",
  "description": "Adressieren Sie Personen die bereit sind zu der Marke eines anderen Herstellers zu wechseln .",
  "cpm": "3,00 €",
  "reach": "14351000"
}, {
  "id": 100,
  "category": "B2C",
  "subCategory": "Unterhaltung",
  "targeting": "Kino & Film - Interessierte",
  "description": "Adressieren Sie Personen die mindestens gelegentlich in Kinos gehen und/oder ein besonderes Interesse für Filme haben.",
  "cpm": "3,10 €",
  "reach": "16048000"
}, {
  "id": 101,
  "category": "B2C",
  "subCategory": "Unterhaltung",
  "targeting": "Kneipen, Bar und Nachtclub- Besucher",
  "description": "Adressieren Sie Personen die mindestens gelegentlich in Kneipen, Bars oder Nachtclubs gehen.",
  "cpm": "3,10 €",
  "reach": "4069000"
}, {
  "id": 102,
  "category": "B2C",
  "subCategory": "Elektronik & Technologie",
  "targeting": "Elektrogroß- und Kleingeräte",
  "description": "Adressieren Sie Personen die ein besonderes Interesse an Elektrogeräten aller Art haben.",
  "cpm": "2,50 €",
  "reach": "15160000"
}, {
  "id": 103,
  "category": "B2C",
  "subCategory": "Elektronik & Technologie",
  "targeting": "Telekommunikation",
  "description": "Adressieren Sie Personen die aktuell in einem Vertragsverhältnis mit einem Telekommunikationsanbieter stehen oder an Dienstleistungen hierzu allgemein interessiert sind.",
  "cpm": "2,80 €",
  "reach": "33183000"
}, {
  "id": 104,
  "category": "B2C",
  "subCategory": "Elektronik & Technologie",
  "targeting": "Informationstechnologie",
  "description": "Adressieren Sie Personen mit besonderem Interesse an Informationstechnologie aller Art.",
  "cpm": "2,80 €",
  "reach": "584000"
}, {
  "id": 105,
  "category": "B2C",
  "subCategory": "Elektronik & Technologie",
  "targeting": "Unterhaltungselektronik",
  "description": "Adressieren Sie Personen mit besonderem Interesse an Unterhaltungselektronik aller Art.",
  "cpm": "2,80 €",
  "reach": "31960000"
}, {
  "id": 106,
  "category": "B2C",
  "subCategory": "Elektronik & Technologie",
  "targeting": "Foto",
  "description": "Adressieren Sie Personen die privat und/oder beruflich ein besonders Interesse an allen Produkten rund um Fotografie haben.",
  "cpm": "2,80 €",
  "reach": "17216000"
}, {
  "id": 107,
  "category": "B2C",
  "subCategory": "Elektronik & Technologie",
  "targeting": "Elektronik, Computer & Elektrogroßgeräte - niedrige Kaufkraft",
  "description": "Adressieren Sie Personen die sich besonders für elektronische Geräte aller Art interessieren und eine vergleichsweise niedrige Kaufkraft aufweisen.",
  "cpm": "2,90 €",
  "reach": "12590000"
}, {
  "id": 108,
  "category": "B2C",
  "subCategory": "Elektronik & Technologie",
  "targeting": "Elektronik, Computer & Elektrogroßgeräte - mittlere Kaufkraft",
  "description": "Adressieren Sie Personen die sich besonders für elektronische Geräte aller Art interessieren und eine durchschnittliche Kaufkraft aufweisen.",
  "cpm": "2,90 €",
  "reach": "6260000"
}, {
  "id": 109,
  "category": "B2C",
  "subCategory": "Elektronik & Technologie",
  "targeting": "Elektronik, Computer & Elektrogroßgeräte - hohe Kaufkraft",
  "description": "Adressieren Sie Personen die sich besonders für elektronische Geräte aller Art interessieren und eine vergleichsweise hohe Kaufkraft aufweisen.",
  "cpm": "2,90 €",
  "reach": "259000"
}, {
  "id": 110,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Fast-Food: Spontankunden",
  "description": "Adressieren Sie Personen die gelegentlich Fast-Food Produkte konsumieren (seltener als monatlich).",
  "cpm": "3,10 €",
  "reach": "16095000"
}, {
  "id": 111,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Fast-Food: Regulars",
  "description": "Adressieren Sie Personen die regelmäßig Fast-Food Produkte konsumieren (monatlich).",
  "cpm": "3,10 €",
  "reach": "6006000"
}, {
  "id": 112,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Fast-Food: Enthusiasten",
  "description": "Adressieren Sie Personen die überdurchschnittlich oft Fast-Food Produkte konsumieren (wöchentlich).",
  "cpm": "3,10 €",
  "reach": "1521000"
}, {
  "id": 113,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Nahrungsmittel",
  "description": "Adressieren Sie Personen die besonders interessiert an Angeboten zu Nahrungsmitteln haben.",
  "cpm": "2,80 €",
  "reach": "41848000"
}, {
  "id": 114,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Nahrungsmittel - Premium Präferenz",
  "description": "Adressieren Sie Personen die beim Erwerb von Lebensmitteln besonderen Wert auf Premiumhersteller legen.",
  "cpm": "2,80 €",
  "reach": "7750000"
}, {
  "id": 115,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Alkoholfreie Getränke",
  "description": "Adressieren Sie Personen die ein besonderes Interesse an Angeboten für alkoholfreie Getränken aller Art haben.",
  "cpm": "2,80 €",
  "reach": "42828000"
}, {
  "id": 116,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Alkoholische Getränke",
  "description": "Adressieren Sie Personen die mindestens gelegentlich alkoholische Getränke kaufen und konsumieren.",
  "cpm": "2,80 €",
  "reach": "30573000"
}, {
  "id": 117,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Tabakwaren",
  "description": "Adressieren Sie Personen die rauchen und/oder regelmäßig Tabakwaren kaufen.",
  "cpm": "2,80 €",
  "reach": "8803000"
}, {
  "id": 118,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Wasch-, Putz- und Reinigungsmittel",
  "description": "Adressieren Sie Personen die regelmäßig Wasch-, Putz- und Reinigungsmittel kaufen.",
  "cpm": "2,90 €",
  "reach": "21169000"
}, {
  "id": 119,
  "category": "B2C",
  "subCategory": "FMCG",
  "targeting": "Körperpflege",
  "description": "Adressieren Sie Personen die Kaufentscheidungen für Körperpflegeprodukte treffen (bspw. In Wohnverbänden/Partnerschaften/Familien usw.).",
  "cpm": "2,90 €",
  "reach": "37985000"
}, {
  "id": 120,
  "category": "B2C",
  "subCategory": "Kleidung & Textilien",
  "targeting": "Damenmode",
  "description": "Adressieren Sie Personen die Kaufentscheidungen für Damenbekleidung treffen.",
  "cpm": "2,90 €",
  "reach": "15038000"
}, {
  "id": 121,
  "category": "B2C",
  "subCategory": "Kleidung & Textilien",
  "targeting": "Herrenmode",
  "description": "Adressieren Sie Personen die Kaufentscheidungen für Herrenbekleidung treffen.",
  "cpm": "2,90 €",
  "reach": "19270000"
}, {
  "id": 122,
  "category": "B2C",
  "subCategory": "Kleidung & Textilien",
  "targeting": "Kindermode",
  "description": "Adressieren Sie Personen die Kaufentscheidungen für Kinderkleidung treffen.",
  "cpm": "2,90 €",
  "reach": "5478000"
}, {
  "id": 123,
  "category": "B2C",
  "subCategory": "Kleidung & Textilien",
  "targeting": "Wäsche",
  "description": "Adressieren Sie Personen die Kaufentscheidungen für Wäsche treffen (bspw. In Wohnverbänden/Partnerschaften/Familien usw.).",
  "cpm": "2,90 €",
  "reach": "15719000"
}, {
  "id": 124,
  "category": "B2C",
  "subCategory": "Kleidung & Textilien",
  "targeting": "Strumpfwaren",
  "description": "Adressieren Sie Personen die Kaufentscheidungen für Strumpfwaren treffen (bspw. In Wohnverbänden/Partnerschaften/Familien usw.).",
  "cpm": "2,90 €",
  "reach": "38759000"
}, {
  "id": 125,
  "category": "B2C",
  "subCategory": "Kleidung & Textilien",
  "targeting": "Schuhe",
  "description": "Adressieren Sie Personen die Kaufentscheidungen für Schuhe treffen (bspw. In Wohnverbänden/Partnerschaften/Familien usw.).",
  "cpm": "2,90 €",
  "reach": "40483000"
}, {
  "id": 126,
  "category": "B2C",
  "subCategory": "Kleidung & Textilien",
  "targeting": "Lederwaren",
  "description": "Adressieren Sie Personen die Kaufentscheidungen für Lederwaren treffen (bspw. In Wohnverbänden/Partnerschaften/Familien usw.).",
  "cpm": "2,90 €",
  "reach": "1280000"
}, {
  "id": 127,
  "category": "B2C",
  "subCategory": "Wohnung & Garten",
  "targeting": "Hausrat, Glas, Porzellan",
  "description": "Adressieren Sie Personen die Kaufentscheidungen für Hausrat, Glas, Porzellan treffen (bspw. In Wohnverbänden/Partnerschaften/Familien usw.).",
  "cpm": "2,90 €",
  "reach": "19023000"
}, {
  "id": 128,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Gesundheit",
  "description": "Adressieren Sie Personen mit besonderem Interesse an Produkten die eine gesundheitsfördernde Wirkung versprechen.",
  "cpm": "2,90 €",
  "reach": "13999000"
}, {
  "id": 129,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Optik - Accessoire",
  "description": "Adressieren Sie Personen die besonderes Interesse an Sonnenbrillen oder Brillen aus modischen oder funktionalen Gründen haben.",
  "cpm": "2,90 €",
  "reach": "11059450"
}, {
  "id": 130,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Optik - Sehhilfen",
  "description": "Adressieren Sie Personen die auf Sehhilfen angewiesen sind (Kontaktlinsen, Brillen usw.).",
  "cpm": "2,90 €",
  "reach": "11866000"
}, {
  "id": 131,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Spielwaren",
  "description": "Adressieren Sie Personen die ein besonderes Interesse an Spielwaren haben.",
  "cpm": "2,90 €",
  "reach": "5357000"
}, {
  "id": 132,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Hobbys, Musikinstrumente",
  "description": "Adressieren Sie Personen die mindestens ein Musikinstrument spielen und/oder sich besonders für Musikinstrumente interessieren.",
  "cpm": "2,90 €",
  "reach": "7588000"
}, {
  "id": 133,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Bücher, Zeitungen, Zeitschriften,",
  "description": "Adressieren Sie Personen die sich für besonders für Bücher, Zeitungen und Zeitschriften interessieren.",
  "cpm": "2,90 €",
  "reach": "34733000"
}, {
  "id": 134,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Schreibwaren",
  "description": "Adressieren Sie Personen die besonderen Wert auf analoges dokumentieren, zeichen usw. legen.",
  "cpm": "2,90 €",
  "reach": "11695000"
}, {
  "id": 135,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Uhren und Schmuck - Normalpreisig",
  "description": "Adressieren Sie Personen die sich besonders für funktionale Uhren und mittelpreisigen Schmuck interessieren.",
  "cpm": "2,90 €",
  "reach": "13215000"
}, {
  "id": 136,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Uhren und Schmuck - Premium Präferenz",
  "description": "Adressieren Sie Personen die sich besonders für Uhren von Premiumherstellern und hochpreisigen Schmuck interessieren.",
  "cpm": "2,90 €",
  "reach": "3125000"
}, {
  "id": 137,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Baumarktspezifisches Sortiment",
  "description": "Adressieren Sie Personen die regelmäßig Baumärkte besuchen und/oder ein gesteigertes Interesse an baumarkt spezifischem Sortiment haben.",
  "cpm": "2,90 €",
  "reach": "8412000"
}, {
  "id": 138,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Kfz-Ersatzteile und Zubehör",
  "description": "Adressieren Sie Personen mit einem gesteigerten Interesse an KFZ und KFZ-Zubehör/Ersatzteilen.",
  "cpm": "2,90 €",
  "reach": "13541000"
}, {
  "id": 139,
  "category": "B2C",
  "subCategory": "Sortimentsbezogen",
  "targeting": "Fahrräder und Zubehör",
  "description": "Adressieren Sie Personen mit einem gesteigerten Interesse an Fahrrädern und Fahrradzubehör.",
  "cpm": "2,90 €",
  "reach": "20691000"
}, {
  "id": 140,
  "category": "B2C",
  "subCategory": "Sport",
  "targeting": "Sportgeräte",
  "description": "Adressieren Sie Personen die ein besonderes Interesse an Sportgeräten für Zuhause haben.",
  "cpm": "2,90 €",
  "reach": "14293000"
}, {
  "id": 141,
  "category": "B2C",
  "subCategory": "Sport",
  "targeting": "Sportbekleidung",
  "description": "Adressieren Sie Personen die ein besonderes Interesse an Sportbekleidung haben.",
  "cpm": "2,90 €",
  "reach": "20927000"
}, {
  "id": 142,
  "category": "B2C",
  "subCategory": "Sport",
  "targeting": "Sportschuhe",
  "description": "Adressieren Sie Personen die ein besonderes Interesse an Sportschuhen haben.",
  "cpm": "2,90 €",
  "reach": "21651000"
}, {
  "id": 143,
  "category": "B2C",
  "subCategory": "Unterhaltung",
  "targeting": "bespielte Tonträger",
  "description": "Adressieren Sie Personen die physische Tonträger (Schallplatten, CDs, usw.) präferieren.",
  "cpm": "2,90 €",
  "reach": "6825000"
}, {
  "id": 144,
  "category": "B2C",
  "subCategory": "Wohnung & Garten",
  "targeting": "Möbel",
  "description": "Adressieren Sie Personen die besonders an Möbeln interessiert sind.",
  "cpm": "2,90 €",
  "reach": "24432000"
}, {
  "id": 145,
  "category": "B2C",
  "subCategory": "Wohnung & Garten",
  "targeting": "Haus- & Heimtextilien, sonst. Einrichtungsgegenstände",
  "description": "Adressieren Sie Personen die besonders an Haus- & Heimtextilien, sonst. Einrichtungsgegenstände interessiert sind.",
  "cpm": "2,90 €",
  "reach": "25524000"
}, {
  "id": 146,
  "category": "B2C",
  "subCategory": "Wohnung & Garten",
  "targeting": "Möbel & Einrichtung - niedrige Kaufkraft",
  "description": "Adressieren Sie Personen die besonders an Möbel & Einrichtungsgegenständen interessiert sind und eine vergleichsweise niedrigere Kaufkraft aufweisen.",
  "cpm": "2,90 €",
  "reach": "15990000"
}, {
  "id": 147,
  "category": "B2C",
  "subCategory": "Wohnung & Garten",
  "targeting": "Möbel & Einrichtung - mittlere Kaufkraft",
  "description": "Adressieren Sie Personen die besonders an Möbel & Einrichtungsgegenständen interessiert sind und eine durchschnittliche Kaufkraft aufweisen.",
  "cpm": "2,90 €",
  "reach": "10524000"
}, {
  "id": 148,
  "category": "B2C",
  "subCategory": "Wohnung & Garten",
  "targeting": "Möbel & Einrichtung - hohe Kaufkraft",
  "description": "Adressieren Sie Personen die besonders an Möbel & Einrichtungsgegenständen interessiert sind und eine vergleichsweise hohe Kaufkraft aufweisen.",
  "cpm": "2,90 €",
  "reach": "2569000"
}, {
  "id": 149,
  "category": "Demographie",
  "subCategory": "Alter",
  "targeting": "18-24",
  "description": "Adressieren Sie Personen zwischen 18 und 24.",
  "cpm": "1,00 €",
  "reach": "5650000"
}, {
  "id": 150,
  "category": "Demographie",
  "subCategory": "Alter",
  "targeting": "25-39",
  "description": "Adressieren Sie Personen zwischen 25 und 39.",
  "cpm": "1,00 €",
  "reach": "16275000"
}, {
  "id": 151,
  "category": "Demographie",
  "subCategory": "Alter",
  "targeting": "40-59",
  "description": "Adressieren Sie Personen zwischen 40 und 59.",
  "cpm": "1,00 €",
  "reach": "24138000"
}, {
  "id": 152,
  "category": "Demographie",
  "subCategory": "Alter",
  "targeting": "60+",
  "description": "Adressieren Sie Personen über 60.",
  "cpm": "1,00 €",
  "reach": "13015000"
}, {
  "id": 153,
  "category": "Demographie",
  "subCategory": "Höchster Bildungsgrad",
  "targeting": "Hochschulreife",
  "description": "Adressieren Sie Personen mit höchstem Bildungsniveau: Hochschulreife.",
  "cpm": "1,10 €",
  "reach": "10711000"
}, {
  "id": 154,
  "category": "Demographie",
  "subCategory": "Höchster Bildungsgrad",
  "targeting": "Berufsausbildung",
  "description": "Adressieren Sie Personen mit höchstem Bildungsniveau: berufliche Ausbildung.",
  "cpm": "1,10 €",
  "reach": "16859000"
}, {
  "id": 155,
  "category": "Demographie",
  "subCategory": "Höchster Bildungsgrad",
  "targeting": "Hochschulabschluss",
  "description": "Adressieren Sie Personen mit höchstem Bildungsniveau: Hochschulabschluss.",
  "cpm": "1,10 €",
  "reach": "12032000"
}, {
  "id": 156,
  "category": "Demographie",
  "subCategory": "Studenten",
  "targeting": "Studenten",
  "description": "Adressieren Sie Personen die aktuell an einer staatlichen/privaten Universität/Hochschule studieren.",
  "cpm": "1,10 €",
  "reach": "1706000"
}, {
  "id": 157,
  "category": "Demographie",
  "subCategory": "Lebensphase",
  "targeting": "Alleinstehende Senioren",
  "description": "Adressieren Sie Personen die allein leben und 65 Jahre oder älter sind.",
  "cpm": "1,25 €",
  "reach": "2977000"
}, {
  "id": 158,
  "category": "Demographie",
  "subCategory": "Lebensphase",
  "targeting": "Ältere Paare",
  "description": "Adressieren Sie Zusammenlebende Paare über 65 Jahre.",
  "cpm": "1,25 €",
  "reach": "9643000"
}, {
  "id": 159,
  "category": "Demographie",
  "subCategory": "Lebensphase",
  "targeting": "Ältere Mehrpersonenhaushalte",
  "description": "Adressieren Sie Personen über 65 die in Wohngemeinschaft ähnlichen Verhältnissen zusammen wohnen.",
  "cpm": "1,25 €",
  "reach": "8429000"
}, {
  "id": 160,
  "category": "Demographie",
  "subCategory": "Lebensphase",
  "targeting": "Singles",
  "description": "Adressieren Sie Personen die aktuell alleine wohnen .",
  "cpm": "1,25 €",
  "reach": "8916000"
}, {
  "id": 161,
  "category": "Demographie",
  "subCategory": "Lebensphase",
  "targeting": "Paare",
  "description": "Adressieren Sie Zusammenlebende Paare.",
  "cpm": "1,25 €",
  "reach": "27868000"
}, {
  "id": 162,
  "category": "Demographie",
  "subCategory": "Lebensphase",
  "targeting": "Familien mit Kind",
  "description": "Adressieren Sie Zusammenlebende Familien.",
  "cpm": "1,25 €",
  "reach": "16152000"
}, {
  "id": 163,
  "category": "Demographie",
  "subCategory": "Lebensphase",
  "targeting": "Junge Singles",
  "description": "Adressieren Sie Allein wohnende Personen unter 30.",
  "cpm": "1,25 €",
  "reach": "3474000"
}, {
  "id": 164,
  "category": "Demographie",
  "subCategory": "Lebensphase",
  "targeting": "Junge Paare",
  "description": "Adressieren Sie Zusammenlebende Paare unter 30.",
  "cpm": "1,25 €",
  "reach": "10836000"
}, {
  "id": 165,
  "category": "Demographie",
  "subCategory": "Lebensphase",
  "targeting": "Junge Familien mit Kind",
  "description": "Adressieren Sie Frischgebackene Eltern unter 30.",
  "cpm": "1,25 €",
  "reach": "5019000"
}, {
  "id": 166,
  "category": "Demographie",
  "subCategory": "Finanzieller Status",
  "targeting": "Niedrigster Status",
  "description": "Adressieren Sie Personen mit einem HHNE i.H.v. maximal 1667€.",
  "cpm": "1,25 €",
  "reach": "9099000"
}, {
  "id": 167,
  "category": "Demographie",
  "subCategory": "Finanzieller Status",
  "targeting": "Unterdurchschnittlicher Status",
  "description": "Adressieren Sie Personen mit einem HHNE i.H.v. maximal 2009€.",
  "cpm": "1,25 €",
  "reach": "10798000"
}, {
  "id": 168,
  "category": "Demographie",
  "subCategory": "Finanzieller Status",
  "targeting": "Durchschnittlicher Status",
  "description": "Adressieren Sie Personen mit einem HHNE i.H.v. maximal 2811€.",
  "cpm": "1,25 €",
  "reach": "11323000"
}, {
  "id": 169,
  "category": "Demographie",
  "subCategory": "Finanzieller Status",
  "targeting": "Überdurchschnittlicher Status",
  "description": "Adressieren Sie Personen mit einem HHNE i.H.v. maximal 3009€.",
  "cpm": "1,25 €",
  "reach": "2675000"
}, {
  "id": 170,
  "category": "Demographie",
  "subCategory": "Finanzieller Status",
  "targeting": "Höchster Status",
  "description": "Adressieren Sie Personen mit einem HHNE i.H.v über 3010€.",
  "cpm": "1,25 €",
  "reach": "14722000"
}, {
  "id": 171,
  "category": "Demographie",
  "subCategory": "Beruf",
  "targeting": "Gelernte und Facharbeiter",
  "description": "Adressieren Sie Personen mit beruflicher Ausbildung die sich in einem Angestelltenverhältnis befinden.",
  "cpm": "1,25 €",
  "reach": "8063000"
}, {
  "id": 172,
  "category": "Demographie",
  "subCategory": "Beruf",
  "targeting": "Freiberufler / mit akademischem Abschluss",
  "description": "Adressieren Sie Personen mit akademischem Abschluss die einer selbstständigen Tätigkeit nachgehen.",
  "cpm": "1,25 €",
  "reach": "5391000"
}, {
  "id": 173,
  "category": "Demographie",
  "subCategory": "Beruf",
  "targeting": "Agrarwirte",
  "description": "Adressieren Sie Eigentümer/Betreiber eines landwirtschaftlichen Betriebs.",
  "cpm": "1,25 €",
  "reach": "278000"
}, {
  "id": 174,
  "category": "Demographie",
  "subCategory": "Beruf",
  "targeting": "Wissenschaftler",
  "description": "Adressieren Sie Personen die in einem wissenschaftlichen Bereich arbeiten.",
  "cpm": "1,40 €",
  "reach": "432000"
}, {
  "id": 175,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Verlage, Telekommunikation und IT",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "3,00 €",
  "reach": "1729000"
}, {
  "id": 176,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Finanzen und Versicherungen",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "3,50 €",
  "reach": "1162000"
}, {
  "id": 177,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Grundstücks- und Wohnungswesen",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "3,50 €",
  "reach": "3634000"
}, {
  "id": 178,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Dienstleistungen (alle)",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "2,50 €",
  "reach": "7219000"
}, {
  "id": 179,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Gesundheitswesen und Sozialwesen",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "2,50 €",
  "reach": "3111000"
}, {
  "id": 180,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Land- und Forstwirtschaft",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "4,00 €",
  "reach": "604000"
}, {
  "id": 181,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Verarbeitendes und Produzierendes Gewerbe",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "2,50 €",
  "reach": "3580000"
}, {
  "id": 182,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Handwerk",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "2,50 €",
  "reach": "3286000"
}, {
  "id": 183,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Baugewerbe",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "3,00 €",
  "reach": "2194000"
}, {
  "id": 184,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Logistik",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "2,50 €",
  "reach": "2792000"
}, {
  "id": 185,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Behörden",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "2,50 €",
  "reach": "3027000"
}, {
  "id": 186,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Ver- und Entsorger",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "2,50 €",
  "reach": "1369000"
}, {
  "id": 187,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Groß- und Einzelhandel",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "2,50 €",
  "reach": "4275000"
}, {
  "id": 188,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Gastgewerbe",
  "description": "Adressieren Sie Personen die aktuell in dem angegebenen Bereich beruflich tätig sind.",
  "cpm": "2,50 €",
  "reach": "902000"
}, {
  "id": 189,
  "category": "B2B",
  "subCategory": "Branche",
  "targeting": "Schulen",
  "description": "Adressieren Sie Lehrer und Verwaltungspersonal in staatlichen und privaten Schuleinrichtungen.",
  "cpm": "2,50 €",
  "reach": "577000"
}, {
  "id": 190,
  "category": "B2B",
  "subCategory": "Untenehmensgröße",
  "targeting": "1 bis 9 Mitarbeiter",
  "description": "Adressieren Sie Entscheidungsträger/ leitende Angestellte in vergleichsweise kleineren Betrieben mit einem Personalstamm von bis zu 9 Mitarbeitern.",
  "cpm": "1,50 €",
  "reach": "1946000"
}, {
  "id": 191,
  "category": "B2B",
  "subCategory": "Untenehmensgröße",
  "targeting": "10 bis 49 Mitarbeiter",
  "description": "Adressieren Sie Entscheidungsträger/ leitende Angestellte in vergleichsweise kleineren Betrieben und Unternehmen mit einem Personalstamm von bis zu 49 Mitarbeitern.",
  "cpm": "1,50 €",
  "reach": "485000"
}, {
  "id": 192,
  "category": "B2B",
  "subCategory": "Untenehmensgröße",
  "targeting": "50 bis 399 Mitarbeiter",
  "description": "Adressieren Sie Entscheidungsträger/ leitende Angestellte in mittelständischen Betrieben und Unternehmen mit einem Personalstamm von bis zu 399 Mitarbeitern.",
  "cpm": "1,50 €",
  "reach": "76000"
}, {
  "id": 193,
  "category": "B2B",
  "subCategory": "Untenehmensgröße",
  "targeting": "400 und mehr Mitarbeiter",
  "description": "Adressieren Sie Entscheidungsträger/ leitende Angestellte in mittelständischen und Großunternehmen mit einem Personalstamm von bis zu 399 Mitarbeitern.",
  "cpm": "1,50 €",
  "reach": "18000"
}, {
  "id": 194,
  "category": "Mobilität",
  "subCategory": "Transportmittel",
  "targeting": "Pendler",
  "description": "Adressieren Sie nur Pendler während Sie pendeln.",
  "cpm": "7,00 €",
  "reach": "19000000"
}, {
  "id": 195,
  "category": "Mobilität",
  "subCategory": "Zu Fuß",
  "targeting": "Fußgänger",
  "description": "Adressieren Sie nur Nutzer in Gebieten mit hoher Fußgängerfrequenz.",
  "cpm": "5,00 €",
  "reach": "30000 bis 1200000"
}, {
  "id": 196,
  "category": "Mobilität",
  "subCategory": "Transportimittel",
  "targeting": "Stausteher",
  "description": "Adressieren Sie nur Nutzer die aktuell im Stau stehen.",
  "cpm": "6,00 €",
  "reach": "280000 bis 28000000"
}];

export const targetingsByCategory = groupBy(targetings, 'category');
export const targetingsBySubCategory = groupBy(targetings, 'subCategory');
export const subCategoriesByCategory = (category) => {
  return uniqBy(targetingsByCategory[category], 'subCategory').map(targeting => targeting.subCategory);
};