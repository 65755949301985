import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles({
  hintIcon: {
    color: '#AFAFAF',
  },
  tooltipText: {
    fontSize: 12,
  },
  hintContainer: {
    backgroundColor: 'red',
  },
});

export const HintIcon = (props) => {
  const { hint } = props;
  const styles = useStyles();

  return (
    <Tooltip
      arrow
      title={
        <div
          className={classNames({
            [styles.tooltipText]: true,
          })}>
          {hint}
        </div>
      }
    >
      <InfoOutlinedIcon className={styles.hintIcon} />
    </Tooltip>
  );
};
