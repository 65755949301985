import instance from './axiosInstance';

export const login = async (loginInfo) => {
  return instance.post('login', loginInfo)
};

export const register = async (registerInfo) => {
  return instance.post('register', registerInfo)
};

export const getAllUsers = async () => {
  return instance.get('users')
};

export const updateUser = async (id, update) => {
  return instance.put(`user/${id}`, update)
};

export const deleteUser = async (id) => {
  return instance.delete(`user/${id}`)
};

export const getCurrentUser = async () => {
  return instance.get(`user`)
};

export const updateUserByToken = async (update) => {
  return instance.put('user', update)
};

export const submitForgotPassword = async (email) => {
  return instance.post('forgot-password', { email })
};

export const resetPassword = async (newPassword, token) => {
  return instance.post(`reset-password/${token}`, {newPassword})
};

export const createBriefing = async (briefing) => {
  return instance.post(`briefing`, {briefing})
};

export const verifyEmail = async (token) => {
  return instance.post(`verify-email/${token}`)
};