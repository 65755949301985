import React from 'react';
import { Modal, Grid, Button, Box, Fade, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '10px',
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    '&:focus': {
      outline: 'none'
    },
  },
  iconWrapper: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  }
}));


export default function NewBriefingModal(
  {
    isModalOpen,
    onModalClose,
    onModalApprove,
  }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  return (
    <Modal
      open={isModalOpen}
      onClose={onModalClose}
    >
      <Fade in={isModalOpen}>
        <div style={modalStyle} className={classes.paper}>
          <Box className={classes.iconWrapper}>
            <IconButton onClick={onModalClose}>
              <CloseIcon
                color="disabled"
                style={{ fontSize: 24 }}
              />
            </IconButton>
          </Box>
          <Grid container direction="column">
            <Grid item>
              Durch die Erstellung eines neuen Briefings werden alle Felder zuruckgesetzt
            </Grid>
            <Box mt={4} />
            <Grid item>
              <Button color="primary" variant="contained" fullWidth onClick={onModalApprove}>Neues Briefing
                erstellen</Button>
            </Grid>
            <Box mt={3} />
            <Grid item>
              <Button variant="outlined" fullWidth onClick={onModalClose}>Abbrechen</Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}
