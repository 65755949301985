export const platformsReach = {
  "1 Familienhäuser": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "12.524.000"
      },
      "theTradeDesk": {
        "reach": "9.267.000"
      },
      "Adform": {
        "reach": "8.463.000"
      },
      "Verizon": {
        "reach": "8.352.000"
      },
      "Xandr": {
        "reach": "9.145.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.288.000"
      },
      "theTradeDesk": {
        "reach": "4.197.000"
      },
      "Adform": {
        "reach": "3.551.000"
      },
      "Verizon": {
        "reach": "3.584.000"
      },
      "Xandr": {
        "reach": "4.227.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "16.006.000"
      },
      "theTradeDesk": {
        "reach": "12.009.000"
      },
      "Adform": {
        "reach": "11.174.000"
      },
      "Verizon": {
        "reach": "10.793.000"
      },
      "Xandr": {
        "reach": "12.087.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "19.024.000"
      },
      "theTradeDesk": {
        "reach": "15.143.000"
      },
      "Adform": {
        "reach": "12.765.000"
      },
      "Verizon": {
        "reach": "13.000.000"
      },
      "Xandr": {
        "reach": "14.856.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "7.765.000"
      },
      "theTradeDesk": {
        "reach": "2.584.000"
      },
      "Adform": {
        "reach": "5.501.000"
      },
      "Verizon": {
        "reach": "5.525.000"
      },
      "Xandr": {
        "reach": "6.295.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "22.814.000"
      },
      "theTradeDesk": {
        "reach": "17.466.000"
      },
      "Adform": {
        "reach": "15.261.000"
      },
      "Verizon": {
        "reach": "16.133.000"
      },
      "Xandr": {
        "reach": "18.214.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.557.000"
      },
      "theTradeDesk": {
        "reach": "4.336.000"
      },
      "Adform": {
        "reach": "3.802.000"
      },
      "Verizon": {
        "reach": "3.809.000"
      },
      "Xandr": {
        "reach": "4.338.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.084.000"
      },
      "RMS": {
        "reach": "3.422.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "10.869.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.280.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.081.000"
      },
      "theTradeDesk": {
        "reach": "4.785.000"
      },
      "Adform": {
        "reach": "4.092.000"
      },
      "Verizon": {
        "reach": "4.143.000"
      },
      "Xandr": {
        "reach": "4.842.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "10.979.000"
      },
      "Instagram": {
        "reach": "5.817.000"
      },
      "YouTube": {
        "reach": "21.639.000"
      },
      "Snapchat": {
        "reach": "1.277.000"
      }
    }
  },
  "2 Familienhäuser (Doppelhaushälfte)": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "7.782.000"
      },
      "theTradeDesk": {
        "reach": "6.230.000"
      },
      "Adform": {
        "reach": "5.427.000"
      },
      "Verizon": {
        "reach": "5.504.000"
      },
      "Xandr": {
        "reach": "6.080.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.321.000"
      },
      "theTradeDesk": {
        "reach": "2.587.000"
      },
      "Adform": {
        "reach": "2.345.000"
      },
      "Verizon": {
        "reach": "2.257.000"
      },
      "Xandr": {
        "reach": "2.601.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "10.249.000"
      },
      "theTradeDesk": {
        "reach": "8.096.000"
      },
      "Adform": {
        "reach": "6.848.000"
      },
      "Verizon": {
        "reach": "7.259.000"
      },
      "Xandr": {
        "reach": "8.001.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "11.355.000"
      },
      "theTradeDesk": {
        "reach": "9.726.000"
      },
      "Adform": {
        "reach": "8.110.000"
      },
      "Verizon": {
        "reach": "8.220.000"
      },
      "Xandr": {
        "reach": "9.739.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.083.000"
      },
      "theTradeDesk": {
        "reach": "1.668.000"
      },
      "Adform": {
        "reach": "3.385.000"
      },
      "Verizon": {
        "reach": "3.510.000"
      },
      "Xandr": {
        "reach": "4.072.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "14.106.000"
      },
      "theTradeDesk": {
        "reach": "11.063.000"
      },
      "Adform": {
        "reach": "10.543.000"
      },
      "Verizon": {
        "reach": "10.245.000"
      },
      "Xandr": {
        "reach": "11.296.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.625.000"
      },
      "theTradeDesk": {
        "reach": "2.762.000"
      },
      "Adform": {
        "reach": "2.507.000"
      },
      "Verizon": {
        "reach": "2.553.000"
      },
      "Xandr": {
        "reach": "2.811.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.965.000"
      },
      "RMS": {
        "reach": "2.150.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "7.217.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.951.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.794.000"
      },
      "theTradeDesk": {
        "reach": "3.150.000"
      },
      "Adform": {
        "reach": "2.629.000"
      },
      "Verizon": {
        "reach": "2.725.000"
      },
      "Xandr": {
        "reach": "3.220.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "7.006.000"
      },
      "Instagram": {
        "reach": "3.645.000"
      },
      "YouTube": {
        "reach": "13.230.000"
      },
      "Snapchat": {
        "reach": "814.000"
      }
    }
  },
  "3-9 Familienhäuser": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.465.000"
      },
      "theTradeDesk": {
        "reach": "5.146.000"
      },
      "Adform": {
        "reach": "4.513.000"
      },
      "Verizon": {
        "reach": "4.583.000"
      },
      "Xandr": {
        "reach": "5.123.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.686.000"
      },
      "theTradeDesk": {
        "reach": "2.116.000"
      },
      "Adform": {
        "reach": "1.889.000"
      },
      "Verizon": {
        "reach": "1.914.000"
      },
      "Xandr": {
        "reach": "2.159.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "8.127.000"
      },
      "theTradeDesk": {
        "reach": "6.723.000"
      },
      "Adform": {
        "reach": "5.771.000"
      },
      "Verizon": {
        "reach": "5.715.000"
      },
      "Xandr": {
        "reach": "6.436.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "10.012.000"
      },
      "theTradeDesk": {
        "reach": "7.832.000"
      },
      "Adform": {
        "reach": "7.079.000"
      },
      "Verizon": {
        "reach": "6.676.000"
      },
      "Xandr": {
        "reach": "7.597.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.103.000"
      },
      "theTradeDesk": {
        "reach": "1.332.000"
      },
      "Adform": {
        "reach": "2.855.000"
      },
      "Verizon": {
        "reach": "2.924.000"
      },
      "Xandr": {
        "reach": "3.378.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "11.762.000"
      },
      "theTradeDesk": {
        "reach": "8.885.000"
      },
      "Adform": {
        "reach": "8.130.000"
      },
      "Verizon": {
        "reach": "8.229.000"
      },
      "Xandr": {
        "reach": "9.362.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.917.000"
      },
      "theTradeDesk": {
        "reach": "2.348.000"
      },
      "Adform": {
        "reach": "2.026.000"
      },
      "Verizon": {
        "reach": "2.062.000"
      },
      "Xandr": {
        "reach": "2.289.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.625.000"
      },
      "RMS": {
        "reach": "1.759.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.728.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.352.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.188.000"
      },
      "theTradeDesk": {
        "reach": "2.593.000"
      },
      "Adform": {
        "reach": "2.250.000"
      },
      "Verizon": {
        "reach": "2.247.000"
      },
      "Xandr": {
        "reach": "2.560.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.978.000"
      },
      "Instagram": {
        "reach": "3.081.000"
      },
      "YouTube": {
        "reach": "10.787.000"
      },
      "Snapchat": {
        "reach": "680.000"
      }
    }
  },
  "Wohnblocks mit bis zu 20 Haushalten": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.110.000"
      },
      "theTradeDesk": {
        "reach": "2.505.000"
      },
      "Adform": {
        "reach": "2.087.000"
      },
      "Verizon": {
        "reach": "2.222.000"
      },
      "Xandr": {
        "reach": "2.495.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.313.000"
      },
      "theTradeDesk": {
        "reach": "1.091.000"
      },
      "Adform": {
        "reach": "921.000"
      },
      "Verizon": {
        "reach": "960.000"
      },
      "Xandr": {
        "reach": "1.093.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.025.000"
      },
      "theTradeDesk": {
        "reach": "3.177.000"
      },
      "Adform": {
        "reach": "2.793.000"
      },
      "Verizon": {
        "reach": "2.744.000"
      },
      "Xandr": {
        "reach": "3.215.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.002.000"
      },
      "theTradeDesk": {
        "reach": "3.752.000"
      },
      "Adform": {
        "reach": "3.317.000"
      },
      "Verizon": {
        "reach": "3.309.000"
      },
      "Xandr": {
        "reach": "3.895.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.066.000"
      },
      "theTradeDesk": {
        "reach": "669.000"
      },
      "Adform": {
        "reach": "1.407.000"
      },
      "Verizon": {
        "reach": "1.431.000"
      },
      "Xandr": {
        "reach": "1.631.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.759.000"
      },
      "theTradeDesk": {
        "reach": "4.562.000"
      },
      "Adform": {
        "reach": "3.949.000"
      },
      "Verizon": {
        "reach": "3.959.000"
      },
      "Xandr": {
        "reach": "4.610.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.420.000"
      },
      "theTradeDesk": {
        "reach": "1.117.000"
      },
      "Adform": {
        "reach": "986.000"
      },
      "Verizon": {
        "reach": "987.000"
      },
      "Xandr": {
        "reach": "1.133.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "800.000"
      },
      "RMS": {
        "reach": "886.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.760.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.108.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.501.000"
      },
      "theTradeDesk": {
        "reach": "1.224.000"
      },
      "Adform": {
        "reach": "1.079.000"
      },
      "Verizon": {
        "reach": "1.089.000"
      },
      "Xandr": {
        "reach": "1.283.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.882.000"
      },
      "Instagram": {
        "reach": "1.511.000"
      },
      "YouTube": {
        "reach": "5.536.000"
      },
      "Snapchat": {
        "reach": "331.000"
      }
    }
  },
  "Hochhäuser mit 20 Haushalten und mehr": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.632.000"
      },
      "theTradeDesk": {
        "reach": "2.815.000"
      },
      "Adform": {
        "reach": "2.445.000"
      },
      "Verizon": {
        "reach": "2.443.000"
      },
      "Xandr": {
        "reach": "2.977.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.543.000"
      },
      "theTradeDesk": {
        "reach": "1.248.000"
      },
      "Adform": {
        "reach": "1.051.000"
      },
      "Verizon": {
        "reach": "1.070.000"
      },
      "Xandr": {
        "reach": "1.248.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.556.000"
      },
      "theTradeDesk": {
        "reach": "3.629.000"
      },
      "Adform": {
        "reach": "3.287.000"
      },
      "Verizon": {
        "reach": "3.204.000"
      },
      "Xandr": {
        "reach": "3.723.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.347.000"
      },
      "theTradeDesk": {
        "reach": "4.445.000"
      },
      "Adform": {
        "reach": "3.824.000"
      },
      "Verizon": {
        "reach": "3.950.000"
      },
      "Xandr": {
        "reach": "4.372.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.275.000"
      },
      "theTradeDesk": {
        "reach": "755.000"
      },
      "Adform": {
        "reach": "1.640.000"
      },
      "Verizon": {
        "reach": "1.700.000"
      },
      "Xandr": {
        "reach": "1.836.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.712.000"
      },
      "theTradeDesk": {
        "reach": "5.228.000"
      },
      "Adform": {
        "reach": "4.482.000"
      },
      "Verizon": {
        "reach": "4.695.000"
      },
      "Xandr": {
        "reach": "5.246.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.595.000"
      },
      "theTradeDesk": {
        "reach": "1.281.000"
      },
      "Adform": {
        "reach": "1.156.000"
      },
      "Verizon": {
        "reach": "1.152.000"
      },
      "Xandr": {
        "reach": "1.328.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "924.000"
      },
      "RMS": {
        "reach": "1.024.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.239.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.350.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.813.000"
      },
      "theTradeDesk": {
        "reach": "1.432.000"
      },
      "Adform": {
        "reach": "1.271.000"
      },
      "Verizon": {
        "reach": "1.256.000"
      },
      "Xandr": {
        "reach": "1.434.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.288.000"
      },
      "Instagram": {
        "reach": "1.668.000"
      },
      "YouTube": {
        "reach": "6.107.000"
      },
      "Snapchat": {
        "reach": "519.000"
      }
    }
  },
  "Niedrigste Zahlungsausfallwahrscheinlichkeit": {
    "cpm": "1,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.001.000"
      },
      "theTradeDesk": {
        "reach": "1.638.000"
      },
      "Adform": {
        "reach": "1.413.000"
      },
      "Verizon": {
        "reach": "1.455.000"
      },
      "Xandr": {
        "reach": "1.592.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "871.000"
      },
      "theTradeDesk": {
        "reach": "685.000"
      },
      "Adform": {
        "reach": "609.000"
      },
      "Verizon": {
        "reach": "626.000"
      },
      "Xandr": {
        "reach": "680.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.586.000"
      },
      "theTradeDesk": {
        "reach": "2.023.000"
      },
      "Adform": {
        "reach": "1.848.000"
      },
      "Verizon": {
        "reach": "1.817.000"
      },
      "Xandr": {
        "reach": "2.049.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.073.000"
      },
      "theTradeDesk": {
        "reach": "2.372.000"
      },
      "Adform": {
        "reach": "2.245.000"
      },
      "Verizon": {
        "reach": "2.186.000"
      },
      "Xandr": {
        "reach": "2.490.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.310.000"
      },
      "theTradeDesk": {
        "reach": "420.000"
      },
      "Adform": {
        "reach": "916.000"
      },
      "Verizon": {
        "reach": "919.000"
      },
      "Xandr": {
        "reach": "1.060.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.727.000"
      },
      "theTradeDesk": {
        "reach": "3.013.000"
      },
      "Adform": {
        "reach": "2.542.000"
      },
      "Verizon": {
        "reach": "2.653.000"
      },
      "Xandr": {
        "reach": "2.928.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "915.000"
      },
      "theTradeDesk": {
        "reach": "713.000"
      },
      "Adform": {
        "reach": "637.000"
      },
      "Verizon": {
        "reach": "636.000"
      },
      "Xandr": {
        "reach": "716.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "506.000"
      },
      "RMS": {
        "reach": "591.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.873.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "708.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "990.000"
      },
      "theTradeDesk": {
        "reach": "824.000"
      },
      "Adform": {
        "reach": "712.000"
      },
      "Verizon": {
        "reach": "685.000"
      },
      "Xandr": {
        "reach": "787.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.888.000"
      },
      "Instagram": {
        "reach": "993.000"
      },
      "YouTube": {
        "reach": "3.472.000"
      },
      "Snapchat": {
        "reach": "420.000"
      }
    }
  },
  "Unterdurchschnittliche Zahlungsausfallwahrscheinlichkeit": {
    "cpm": "1,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.498.000"
      },
      "theTradeDesk": {
        "reach": "5.410.000"
      },
      "Adform": {
        "reach": "4.591.000"
      },
      "Verizon": {
        "reach": "4.519.000"
      },
      "Xandr": {
        "reach": "5.236.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.755.000"
      },
      "theTradeDesk": {
        "reach": "2.285.000"
      },
      "Adform": {
        "reach": "1.968.000"
      },
      "Verizon": {
        "reach": "2.024.000"
      },
      "Xandr": {
        "reach": "2.297.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "8.251.000"
      },
      "theTradeDesk": {
        "reach": "6.796.000"
      },
      "Adform": {
        "reach": "5.869.000"
      },
      "Verizon": {
        "reach": "5.829.000"
      },
      "Xandr": {
        "reach": "6.940.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "9.909.000"
      },
      "theTradeDesk": {
        "reach": "8.003.000"
      },
      "Adform": {
        "reach": "6.938.000"
      },
      "Verizon": {
        "reach": "6.904.000"
      },
      "Xandr": {
        "reach": "8.230.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.241.000"
      },
      "theTradeDesk": {
        "reach": "1.347.000"
      },
      "Adform": {
        "reach": "3.063.000"
      },
      "Verizon": {
        "reach": "2.999.000"
      },
      "Xandr": {
        "reach": "3.423.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "12.090.000"
      },
      "theTradeDesk": {
        "reach": "9.872.000"
      },
      "Adform": {
        "reach": "8.357.000"
      },
      "Verizon": {
        "reach": "8.405.000"
      },
      "Xandr": {
        "reach": "9.589.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.071.000"
      },
      "theTradeDesk": {
        "reach": "2.325.000"
      },
      "Adform": {
        "reach": "2.037.000"
      },
      "Verizon": {
        "reach": "1.995.000"
      },
      "Xandr": {
        "reach": "2.393.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "955.000"
      },
      "RMS": {
        "reach": "1.886.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "6.102.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.371.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.086.000"
      },
      "theTradeDesk": {
        "reach": "2.625.000"
      },
      "Adform": {
        "reach": "2.297.000"
      },
      "Verizon": {
        "reach": "2.260.000"
      },
      "Xandr": {
        "reach": "2.598.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "6.250.000"
      },
      "Instagram": {
        "reach": "3.213.000"
      },
      "YouTube": {
        "reach": "11.411.000"
      },
      "Snapchat": {
        "reach": "1.436.000"
      }
    }
  },
  "Durchschnittliche Zahlungsausfallwahrscheinlichkeit": {
    "cpm": "1,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.577.000"
      },
      "theTradeDesk": {
        "reach": "2.841.000"
      },
      "Adform": {
        "reach": "2.393.000"
      },
      "Verizon": {
        "reach": "2.427.000"
      },
      "Xandr": {
        "reach": "2.823.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.490.000"
      },
      "theTradeDesk": {
        "reach": "1.220.000"
      },
      "Adform": {
        "reach": "1.106.000"
      },
      "Verizon": {
        "reach": "1.032.000"
      },
      "Xandr": {
        "reach": "1.207.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.507.000"
      },
      "theTradeDesk": {
        "reach": "3.582.000"
      },
      "Adform": {
        "reach": "3.120.000"
      },
      "Verizon": {
        "reach": "3.106.000"
      },
      "Xandr": {
        "reach": "3.626.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.341.000"
      },
      "theTradeDesk": {
        "reach": "4.245.000"
      },
      "Adform": {
        "reach": "3.769.000"
      },
      "Verizon": {
        "reach": "3.826.000"
      },
      "Xandr": {
        "reach": "4.327.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.250.000"
      },
      "theTradeDesk": {
        "reach": "733.000"
      },
      "Adform": {
        "reach": "1.618.000"
      },
      "Verizon": {
        "reach": "1.574.000"
      },
      "Xandr": {
        "reach": "1.847.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.433.000"
      },
      "theTradeDesk": {
        "reach": "5.057.000"
      },
      "Adform": {
        "reach": "4.321.000"
      },
      "Verizon": {
        "reach": "4.461.000"
      },
      "Xandr": {
        "reach": "5.269.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.551.000"
      },
      "theTradeDesk": {
        "reach": "1.264.000"
      },
      "Adform": {
        "reach": "1.127.000"
      },
      "Verizon": {
        "reach": "1.102.000"
      },
      "Xandr": {
        "reach": "1.278.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "609.000"
      },
      "RMS": {
        "reach": "1.013.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.172.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.273.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.756.000"
      },
      "theTradeDesk": {
        "reach": "1.323.000"
      },
      "Adform": {
        "reach": "1.214.000"
      },
      "Verizon": {
        "reach": "1.241.000"
      },
      "Xandr": {
        "reach": "1.369.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.276.000"
      },
      "Instagram": {
        "reach": "1.622.000"
      },
      "YouTube": {
        "reach": "5.973.000"
      },
      "Snapchat": {
        "reach": "734.000"
      }
    }
  },
  "Überdurchschnittliche Zahlungsausfallwahrscheinlichkeit": {
    "cpm": "1,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "10.184.000"
      },
      "theTradeDesk": {
        "reach": "8.388.000"
      },
      "Adform": {
        "reach": "7.313.000"
      },
      "Verizon": {
        "reach": "7.264.000"
      },
      "Xandr": {
        "reach": "8.430.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.502.000"
      },
      "theTradeDesk": {
        "reach": "3.598.000"
      },
      "Adform": {
        "reach": "3.119.000"
      },
      "Verizon": {
        "reach": "3.038.000"
      },
      "Xandr": {
        "reach": "3.688.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "13.469.000"
      },
      "theTradeDesk": {
        "reach": "10.629.000"
      },
      "Adform": {
        "reach": "9.318.000"
      },
      "Verizon": {
        "reach": "9.229.000"
      },
      "Xandr": {
        "reach": "10.558.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "15.611.000"
      },
      "theTradeDesk": {
        "reach": "12.416.000"
      },
      "Adform": {
        "reach": "10.824.000"
      },
      "Verizon": {
        "reach": "11.423.000"
      },
      "Xandr": {
        "reach": "13.177.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "6.726.000"
      },
      "theTradeDesk": {
        "reach": "2.283.000"
      },
      "Adform": {
        "reach": "4.791.000"
      },
      "Verizon": {
        "reach": "4.731.000"
      },
      "Xandr": {
        "reach": "5.358.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "19.266.000"
      },
      "theTradeDesk": {
        "reach": "15.346.000"
      },
      "Adform": {
        "reach": "13.089.000"
      },
      "Verizon": {
        "reach": "13.150.000"
      },
      "Xandr": {
        "reach": "15.287.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.801.000"
      },
      "theTradeDesk": {
        "reach": "3.856.000"
      },
      "Adform": {
        "reach": "3.157.000"
      },
      "Verizon": {
        "reach": "3.231.000"
      },
      "Xandr": {
        "reach": "3.727.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.515.000"
      },
      "RMS": {
        "reach": "2.964.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "9.531.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.797.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "5.207.000"
      },
      "theTradeDesk": {
        "reach": "3.964.000"
      },
      "Adform": {
        "reach": "3.598.000"
      },
      "Verizon": {
        "reach": "3.574.000"
      },
      "Xandr": {
        "reach": "4.200.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "9.491.000"
      },
      "Instagram": {
        "reach": "4.976.000"
      },
      "YouTube": {
        "reach": "17.998.000"
      },
      "Snapchat": {
        "reach": "2.258.000"
      }
    }
  },
  "Höchste Zahlungsausfallwahrscheinlichkeit": {
    "cpm": "1,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "10.862.000"
      },
      "theTradeDesk": {
        "reach": "8.713.000"
      },
      "Adform": {
        "reach": "7.261.000"
      },
      "Verizon": {
        "reach": "7.453.000"
      },
      "Xandr": {
        "reach": "8.906.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.848.000"
      },
      "theTradeDesk": {
        "reach": "3.754.000"
      },
      "Adform": {
        "reach": "3.358.000"
      },
      "Verizon": {
        "reach": "3.258.000"
      },
      "Xandr": {
        "reach": "3.706.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "13.981.000"
      },
      "theTradeDesk": {
        "reach": "11.318.000"
      },
      "Adform": {
        "reach": "10.059.000"
      },
      "Verizon": {
        "reach": "9.423.000"
      },
      "Xandr": {
        "reach": "11.102.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "16.750.000"
      },
      "theTradeDesk": {
        "reach": "13.098.000"
      },
      "Adform": {
        "reach": "11.832.000"
      },
      "Verizon": {
        "reach": "11.109.000"
      },
      "Xandr": {
        "reach": "13.813.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "7.197.000"
      },
      "theTradeDesk": {
        "reach": "2.240.000"
      },
      "Adform": {
        "reach": "5.024.000"
      },
      "Verizon": {
        "reach": "5.010.000"
      },
      "Xandr": {
        "reach": "5.665.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "19.569.000"
      },
      "theTradeDesk": {
        "reach": "15.913.000"
      },
      "Adform": {
        "reach": "13.992.000"
      },
      "Verizon": {
        "reach": "13.660.000"
      },
      "Xandr": {
        "reach": "16.046.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.707.000"
      },
      "theTradeDesk": {
        "reach": "3.910.000"
      },
      "Adform": {
        "reach": "3.449.000"
      },
      "Verizon": {
        "reach": "3.366.000"
      },
      "Xandr": {
        "reach": "3.924.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.278.000"
      },
      "RMS": {
        "reach": "3.002.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "9.653.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.808.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "5.458.000"
      },
      "theTradeDesk": {
        "reach": "4.179.000"
      },
      "Adform": {
        "reach": "3.817.000"
      },
      "Verizon": {
        "reach": "3.895.000"
      },
      "Xandr": {
        "reach": "4.314.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "10.339.000"
      },
      "Instagram": {
        "reach": "5.169.000"
      },
      "YouTube": {
        "reach": "19.434.000"
      },
      "Snapchat": {
        "reach": "2.280.000"
      }
    }
  },
  "Kürzlich umgezogen": {
    "cpm": "1,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "317.000"
      },
      "theTradeDesk": {
        "reach": "243.000"
      },
      "Adform": {
        "reach": "222.000"
      },
      "Verizon": {
        "reach": "218.000"
      },
      "Xandr": {
        "reach": "247.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "135.000"
      },
      "theTradeDesk": {
        "reach": "106.000"
      },
      "Adform": {
        "reach": "95.000"
      },
      "Verizon": {
        "reach": "97.000"
      },
      "Xandr": {
        "reach": "109.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "385.000"
      },
      "theTradeDesk": {
        "reach": "329.000"
      },
      "Adform": {
        "reach": "279.000"
      },
      "Verizon": {
        "reach": "284.000"
      },
      "Xandr": {
        "reach": "320.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "476.000"
      },
      "theTradeDesk": {
        "reach": "389.000"
      },
      "Adform": {
        "reach": "343.000"
      },
      "Verizon": {
        "reach": "327.000"
      },
      "Xandr": {
        "reach": "377.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "208.000"
      },
      "theTradeDesk": {
        "reach": "67.000"
      },
      "Adform": {
        "reach": "148.000"
      },
      "Verizon": {
        "reach": "138.000"
      },
      "Xandr": {
        "reach": "164.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "584.000"
      },
      "theTradeDesk": {
        "reach": "463.000"
      },
      "Adform": {
        "reach": "404.000"
      },
      "Verizon": {
        "reach": "391.000"
      },
      "Xandr": {
        "reach": "436.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "139.000"
      },
      "theTradeDesk": {
        "reach": "114.000"
      },
      "Adform": {
        "reach": "99.000"
      },
      "Verizon": {
        "reach": "98.000"
      },
      "Xandr": {
        "reach": "111.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "77.000"
      },
      "RMS": {
        "reach": "91.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "279.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "116.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "161.000"
      },
      "theTradeDesk": {
        "reach": "126.000"
      },
      "Adform": {
        "reach": "111.000"
      },
      "Verizon": {
        "reach": "110.000"
      },
      "Xandr": {
        "reach": "128.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "295.000"
      },
      "Instagram": {
        "reach": "147.000"
      },
      "YouTube": {
        "reach": "542.000"
      },
      "Snapchat": {
        "reach": "67.000"
      }
    }
  },
  "Länger wohnhaft": {
    "cpm": "1,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "33.164.000"
      },
      "theTradeDesk": {
        "reach": "26.160.000"
      },
      "Adform": {
        "reach": "22.610.000"
      },
      "Verizon": {
        "reach": "21.745.000"
      },
      "Xandr": {
        "reach": "25.323.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "13.703.000"
      },
      "theTradeDesk": {
        "reach": "11.367.000"
      },
      "Adform": {
        "reach": "9.540.000"
      },
      "Verizon": {
        "reach": "9.822.000"
      },
      "Xandr": {
        "reach": "10.913.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "42.622.000"
      },
      "theTradeDesk": {
        "reach": "31.378.000"
      },
      "Adform": {
        "reach": "28.512.000"
      },
      "Verizon": {
        "reach": "29.594.000"
      },
      "Xandr": {
        "reach": "32.397.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "48.011.000"
      },
      "theTradeDesk": {
        "reach": "38.131.000"
      },
      "Adform": {
        "reach": "33.001.000"
      },
      "Verizon": {
        "reach": "34.172.000"
      },
      "Xandr": {
        "reach": "39.186.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "20.624.000"
      },
      "theTradeDesk": {
        "reach": "6.943.000"
      },
      "Adform": {
        "reach": "14.303.000"
      },
      "Verizon": {
        "reach": "15.155.000"
      },
      "Xandr": {
        "reach": "16.697.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "58.835.000"
      },
      "theTradeDesk": {
        "reach": "46.172.000"
      },
      "Adform": {
        "reach": "40.855.000"
      },
      "Verizon": {
        "reach": "41.738.000"
      },
      "Xandr": {
        "reach": "47.476.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "14.362.000"
      },
      "theTradeDesk": {
        "reach": "11.288.000"
      },
      "Adform": {
        "reach": "10.141.000"
      },
      "Verizon": {
        "reach": "10.330.000"
      },
      "Xandr": {
        "reach": "11.571.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "5.810.000"
      },
      "RMS": {
        "reach": "9.329.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "28.288.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "11.987.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "15.808.000"
      },
      "theTradeDesk": {
        "reach": "13.060.000"
      },
      "Adform": {
        "reach": "11.055.000"
      },
      "Verizon": {
        "reach": "11.473.000"
      },
      "Xandr": {
        "reach": "12.860.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "29.319.000"
      },
      "Instagram": {
        "reach": "15.332.000"
      },
      "YouTube": {
        "reach": "56.372.000"
      },
      "Snapchat": {
        "reach": "4.742.000"
      }
    }
  },
  "Erwerbslose": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "615.000"
      },
      "theTradeDesk": {
        "reach": "493.000"
      },
      "Adform": {
        "reach": "413.000"
      },
      "Verizon": {
        "reach": "428.000"
      },
      "Xandr": {
        "reach": "490.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "256.000"
      },
      "theTradeDesk": {
        "reach": "202.000"
      },
      "Adform": {
        "reach": "185.000"
      },
      "Verizon": {
        "reach": "183.000"
      },
      "Xandr": {
        "reach": "209.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "787.000"
      },
      "theTradeDesk": {
        "reach": "634.000"
      },
      "Adform": {
        "reach": "534.000"
      },
      "Verizon": {
        "reach": "552.000"
      },
      "Xandr": {
        "reach": "609.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "902.000"
      },
      "theTradeDesk": {
        "reach": "743.000"
      },
      "Adform": {
        "reach": "649.000"
      },
      "Verizon": {
        "reach": "649.000"
      },
      "Xandr": {
        "reach": "752.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "396.000"
      },
      "theTradeDesk": {
        "reach": "127.000"
      },
      "Adform": {
        "reach": "272.000"
      },
      "Verizon": {
        "reach": "268.000"
      },
      "Xandr": {
        "reach": "320.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.099.000"
      },
      "theTradeDesk": {
        "reach": "885.000"
      },
      "Adform": {
        "reach": "771.000"
      },
      "Verizon": {
        "reach": "781.000"
      },
      "Xandr": {
        "reach": "899.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "273.000"
      },
      "theTradeDesk": {
        "reach": "221.000"
      },
      "Adform": {
        "reach": "193.000"
      },
      "Verizon": {
        "reach": "190.000"
      },
      "Xandr": {
        "reach": "215.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "74.000"
      },
      "RMS": {
        "reach": "167.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "548.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "220.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "296.000"
      },
      "theTradeDesk": {
        "reach": "246.000"
      },
      "Adform": {
        "reach": "210.000"
      },
      "Verizon": {
        "reach": "199.000"
      },
      "Xandr": {
        "reach": "244.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "564.000"
      },
      "Instagram": {
        "reach": "298.000"
      },
      "YouTube": {
        "reach": "1.074.000"
      },
      "Snapchat": {
        "reach": "128.000"
      }
    }
  },
  "Erwerbstätige": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.872.000"
      },
      "theTradeDesk": {
        "reach": "1.453.000"
      },
      "Adform": {
        "reach": "1.281.000"
      },
      "Verizon": {
        "reach": "1.264.000"
      },
      "Xandr": {
        "reach": "1.469.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "776.000"
      },
      "theTradeDesk": {
        "reach": "621.000"
      },
      "Adform": {
        "reach": "544.000"
      },
      "Verizon": {
        "reach": "542.000"
      },
      "Xandr": {
        "reach": "629.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.270.000"
      },
      "theTradeDesk": {
        "reach": "1.827.000"
      },
      "Adform": {
        "reach": "1.642.000"
      },
      "Verizon": {
        "reach": "1.657.000"
      },
      "Xandr": {
        "reach": "1.857.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.808.000"
      },
      "theTradeDesk": {
        "reach": "2.191.000"
      },
      "Adform": {
        "reach": "1.984.000"
      },
      "Verizon": {
        "reach": "1.904.000"
      },
      "Xandr": {
        "reach": "2.209.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.168.000"
      },
      "theTradeDesk": {
        "reach": "384.000"
      },
      "Adform": {
        "reach": "832.000"
      },
      "Verizon": {
        "reach": "844.000"
      },
      "Xandr": {
        "reach": "936.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.305.000"
      },
      "theTradeDesk": {
        "reach": "2.593.000"
      },
      "Adform": {
        "reach": "2.282.000"
      },
      "Verizon": {
        "reach": "2.329.000"
      },
      "Xandr": {
        "reach": "2.649.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "810.000"
      },
      "theTradeDesk": {
        "reach": "640.000"
      },
      "Adform": {
        "reach": "555.000"
      },
      "Verizon": {
        "reach": "581.000"
      },
      "Xandr": {
        "reach": "668.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "440.000"
      },
      "RMS": {
        "reach": "529.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.595.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "655.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "909.000"
      },
      "theTradeDesk": {
        "reach": "709.000"
      },
      "Adform": {
        "reach": "640.000"
      },
      "Verizon": {
        "reach": "609.000"
      },
      "Xandr": {
        "reach": "709.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.680.000"
      },
      "Instagram": {
        "reach": "866.000"
      },
      "YouTube": {
        "reach": "3.128.000"
      },
      "Snapchat": {
        "reach": "112.000"
      }
    }
  },
  "Pendler": {
    "cpm": "7,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "variabel"
      },
      "RMS": {
        "reach": "variabel"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "variabel"
      },
      "WallDecaux (DOOH)": {
        "reach": "variabel"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "variabel"
      },
      "Instagram": {
        "reach": "variabel"
      },
      "YouTube": {
        "reach": "variabel"
      },
      "Snapchat": {
        "reach": "variabel"
      }
    }
  },
  "CDU/CSU": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "9.265.000"
      },
      "theTradeDesk": {
        "reach": "7.281.000"
      },
      "Adform": {
        "reach": "6.616.000"
      },
      "Verizon": {
        "reach": "6.586.000"
      },
      "Xandr": {
        "reach": "7.381.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.898.000"
      },
      "theTradeDesk": {
        "reach": "3.137.000"
      },
      "Adform": {
        "reach": "2.733.000"
      },
      "Verizon": {
        "reach": "2.746.000"
      },
      "Xandr": {
        "reach": "3.071.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "11.794.000"
      },
      "theTradeDesk": {
        "reach": "9.416.000"
      },
      "Adform": {
        "reach": "8.110.000"
      },
      "Verizon": {
        "reach": "8.255.000"
      },
      "Xandr": {
        "reach": "9.366.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "13.891.000"
      },
      "theTradeDesk": {
        "reach": "11.404.000"
      },
      "Adform": {
        "reach": "10.015.000"
      },
      "Verizon": {
        "reach": "10.077.000"
      },
      "Xandr": {
        "reach": "11.267.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.976.000"
      },
      "theTradeDesk": {
        "reach": "1.924.000"
      },
      "Adform": {
        "reach": "4.145.000"
      },
      "Verizon": {
        "reach": "4.189.000"
      },
      "Xandr": {
        "reach": "4.779.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "16.345.000"
      },
      "theTradeDesk": {
        "reach": "13.772.000"
      },
      "Adform": {
        "reach": "11.949.000"
      },
      "Verizon": {
        "reach": "11.582.000"
      },
      "Xandr": {
        "reach": "13.242.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.142.000"
      },
      "theTradeDesk": {
        "reach": "3.330.000"
      },
      "Adform": {
        "reach": "2.795.000"
      },
      "Verizon": {
        "reach": "2.786.000"
      },
      "Xandr": {
        "reach": "3.344.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.792.000"
      },
      "RMS": {
        "reach": "2.514.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "8.307.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.255.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "4.445.000"
      },
      "theTradeDesk": {
        "reach": "3.740.000"
      },
      "Adform": {
        "reach": "3.165.000"
      },
      "Verizon": {
        "reach": "3.171.000"
      },
      "Xandr": {
        "reach": "3.675.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "8.593.000"
      },
      "Instagram": {
        "reach": "4.281.000"
      },
      "YouTube": {
        "reach": "16.335.000"
      },
      "Snapchat": {
        "reach": "374.000"
      }
    }
  },
  "SPD": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.800.000"
      },
      "theTradeDesk": {
        "reach": "4.642.000"
      },
      "Adform": {
        "reach": "4.080.000"
      },
      "Verizon": {
        "reach": "4.082.000"
      },
      "Xandr": {
        "reach": "4.483.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.423.000"
      },
      "theTradeDesk": {
        "reach": "2.015.000"
      },
      "Adform": {
        "reach": "1.707.000"
      },
      "Verizon": {
        "reach": "1.702.000"
      },
      "Xandr": {
        "reach": "2.032.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "7.221.000"
      },
      "theTradeDesk": {
        "reach": "6.019.000"
      },
      "Adform": {
        "reach": "5.187.000"
      },
      "Verizon": {
        "reach": "5.204.000"
      },
      "Xandr": {
        "reach": "5.890.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "8.492.000"
      },
      "theTradeDesk": {
        "reach": "6.957.000"
      },
      "Adform": {
        "reach": "5.954.000"
      },
      "Verizon": {
        "reach": "6.037.000"
      },
      "Xandr": {
        "reach": "7.007.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.751.000"
      },
      "theTradeDesk": {
        "reach": "1.205.000"
      },
      "Adform": {
        "reach": "2.558.000"
      },
      "Verizon": {
        "reach": "2.608.000"
      },
      "Xandr": {
        "reach": "2.950.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "10.826.000"
      },
      "theTradeDesk": {
        "reach": "8.392.000"
      },
      "Adform": {
        "reach": "7.349.000"
      },
      "Verizon": {
        "reach": "7.479.000"
      },
      "Xandr": {
        "reach": "8.228.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.491.000"
      },
      "theTradeDesk": {
        "reach": "2.031.000"
      },
      "Adform": {
        "reach": "1.818.000"
      },
      "Verizon": {
        "reach": "1.815.000"
      },
      "Xandr": {
        "reach": "2.066.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "957.000"
      },
      "RMS": {
        "reach": "1.505.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.105.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.051.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.790.000"
      },
      "theTradeDesk": {
        "reach": "2.227.000"
      },
      "Adform": {
        "reach": "2.037.000"
      },
      "Verizon": {
        "reach": "1.962.000"
      },
      "Xandr": {
        "reach": "2.277.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.236.000"
      },
      "Instagram": {
        "reach": "2.733.000"
      },
      "YouTube": {
        "reach": "9.819.000"
      },
      "Snapchat": {
        "reach": "362.000"
      }
    }
  },
  "Grüne": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.495.000"
      },
      "theTradeDesk": {
        "reach": "1.986.000"
      },
      "Adform": {
        "reach": "1.702.000"
      },
      "Verizon": {
        "reach": "1.719.000"
      },
      "Xandr": {
        "reach": "1.957.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.090.000"
      },
      "theTradeDesk": {
        "reach": "830.000"
      },
      "Adform": {
        "reach": "726.000"
      },
      "Verizon": {
        "reach": "735.000"
      },
      "Xandr": {
        "reach": "836.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.060.000"
      },
      "theTradeDesk": {
        "reach": "2.556.000"
      },
      "Adform": {
        "reach": "2.279.000"
      },
      "Verizon": {
        "reach": "2.294.000"
      },
      "Xandr": {
        "reach": "2.615.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.688.000"
      },
      "theTradeDesk": {
        "reach": "3.016.000"
      },
      "Adform": {
        "reach": "2.617.000"
      },
      "Verizon": {
        "reach": "2.647.000"
      },
      "Xandr": {
        "reach": "2.931.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.620.000"
      },
      "theTradeDesk": {
        "reach": "536.000"
      },
      "Adform": {
        "reach": "1.108.000"
      },
      "Verizon": {
        "reach": "1.139.000"
      },
      "Xandr": {
        "reach": "1.312.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.431.000"
      },
      "theTradeDesk": {
        "reach": "3.639.000"
      },
      "Adform": {
        "reach": "3.175.000"
      },
      "Verizon": {
        "reach": "3.141.000"
      },
      "Xandr": {
        "reach": "3.711.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.133.000"
      },
      "theTradeDesk": {
        "reach": "893.000"
      },
      "Adform": {
        "reach": "793.000"
      },
      "Verizon": {
        "reach": "807.000"
      },
      "Xandr": {
        "reach": "907.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "184.000"
      },
      "RMS": {
        "reach": "708.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.223.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "877.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.238.000"
      },
      "theTradeDesk": {
        "reach": "1.005.000"
      },
      "Adform": {
        "reach": "872.000"
      },
      "Verizon": {
        "reach": "844.000"
      },
      "Xandr": {
        "reach": "949.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.343.000"
      },
      "Instagram": {
        "reach": "1.177.000"
      },
      "YouTube": {
        "reach": "4.257.000"
      },
      "Snapchat": {
        "reach": "512.000"
      }
    }
  },
  "FDP": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.939.000"
      },
      "theTradeDesk": {
        "reach": "2.386.000"
      },
      "Adform": {
        "reach": "2.078.000"
      },
      "Verizon": {
        "reach": "1.995.000"
      },
      "Xandr": {
        "reach": "2.293.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.298.000"
      },
      "theTradeDesk": {
        "reach": "1.023.000"
      },
      "Adform": {
        "reach": "897.000"
      },
      "Verizon": {
        "reach": "910.000"
      },
      "Xandr": {
        "reach": "1.020.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.747.000"
      },
      "theTradeDesk": {
        "reach": "3.099.000"
      },
      "Adform": {
        "reach": "2.683.000"
      },
      "Verizon": {
        "reach": "2.664.000"
      },
      "Xandr": {
        "reach": "3.062.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.706.000"
      },
      "theTradeDesk": {
        "reach": "3.662.000"
      },
      "Adform": {
        "reach": "3.231.000"
      },
      "Verizon": {
        "reach": "3.191.000"
      },
      "Xandr": {
        "reach": "3.677.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.961.000"
      },
      "theTradeDesk": {
        "reach": "608.000"
      },
      "Adform": {
        "reach": "1.318.000"
      },
      "Verizon": {
        "reach": "1.366.000"
      },
      "Xandr": {
        "reach": "1.535.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.450.000"
      },
      "theTradeDesk": {
        "reach": "4.461.000"
      },
      "Adform": {
        "reach": "3.774.000"
      },
      "Verizon": {
        "reach": "3.820.000"
      },
      "Xandr": {
        "reach": "4.449.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.287.000"
      },
      "theTradeDesk": {
        "reach": "1.061.000"
      },
      "Adform": {
        "reach": "954.000"
      },
      "Verizon": {
        "reach": "928.000"
      },
      "Xandr": {
        "reach": "1.048.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "756.000"
      },
      "RMS": {
        "reach": "823.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.622.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.083.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.449.000"
      },
      "theTradeDesk": {
        "reach": "1.190.000"
      },
      "Adform": {
        "reach": "1.034.000"
      },
      "Verizon": {
        "reach": "1.014.000"
      },
      "Xandr": {
        "reach": "1.190.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.718.000"
      },
      "Instagram": {
        "reach": "1.428.000"
      },
      "YouTube": {
        "reach": "5.342.000"
      },
      "Snapchat": {
        "reach": "124.000"
      }
    }
  },
  "Linke": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.528.000"
      },
      "theTradeDesk": {
        "reach": "2.036.000"
      },
      "Adform": {
        "reach": "1.814.000"
      },
      "Verizon": {
        "reach": "1.837.000"
      },
      "Xandr": {
        "reach": "2.047.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.086.000"
      },
      "theTradeDesk": {
        "reach": "882.000"
      },
      "Adform": {
        "reach": "749.000"
      },
      "Verizon": {
        "reach": "782.000"
      },
      "Xandr": {
        "reach": "864.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.238.000"
      },
      "theTradeDesk": {
        "reach": "2.562.000"
      },
      "Adform": {
        "reach": "2.300.000"
      },
      "Verizon": {
        "reach": "2.262.000"
      },
      "Xandr": {
        "reach": "2.612.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.856.000"
      },
      "theTradeDesk": {
        "reach": "3.074.000"
      },
      "Adform": {
        "reach": "2.735.000"
      },
      "Verizon": {
        "reach": "2.724.000"
      },
      "Xandr": {
        "reach": "3.098.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.640.000"
      },
      "theTradeDesk": {
        "reach": "545.000"
      },
      "Adform": {
        "reach": "1.174.000"
      },
      "Verizon": {
        "reach": "1.146.000"
      },
      "Xandr": {
        "reach": "1.334.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.658.000"
      },
      "theTradeDesk": {
        "reach": "3.807.000"
      },
      "Adform": {
        "reach": "3.318.000"
      },
      "Verizon": {
        "reach": "3.172.000"
      },
      "Xandr": {
        "reach": "3.916.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.162.000"
      },
      "theTradeDesk": {
        "reach": "904.000"
      },
      "Adform": {
        "reach": "784.000"
      },
      "Verizon": {
        "reach": "787.000"
      },
      "Xandr": {
        "reach": "930.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "435.000"
      },
      "RMS": {
        "reach": "741.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.373.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "930.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.264.000"
      },
      "theTradeDesk": {
        "reach": "1.070.000"
      },
      "Adform": {
        "reach": "899.000"
      },
      "Verizon": {
        "reach": "913.000"
      },
      "Xandr": {
        "reach": "1.008.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.283.000"
      },
      "Instagram": {
        "reach": "1.263.000"
      },
      "YouTube": {
        "reach": "4.423.000"
      },
      "Snapchat": {
        "reach": "162.000"
      }
    }
  },
  "AfD": {
    "cpm": "1,20€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.476.000"
      },
      "theTradeDesk": {
        "reach": "2.799.000"
      },
      "Adform": {
        "reach": "2.451.000"
      },
      "Verizon": {
        "reach": "2.440.000"
      },
      "Xandr": {
        "reach": "2.743.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.480.000"
      },
      "theTradeDesk": {
        "reach": "1.226.000"
      },
      "Adform": {
        "reach": "1.037.000"
      },
      "Verizon": {
        "reach": "1.064.000"
      },
      "Xandr": {
        "reach": "1.194.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.557.000"
      },
      "theTradeDesk": {
        "reach": "3.652.000"
      },
      "Adform": {
        "reach": "3.161.000"
      },
      "Verizon": {
        "reach": "3.126.000"
      },
      "Xandr": {
        "reach": "3.623.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.360.000"
      },
      "theTradeDesk": {
        "reach": "4.339.000"
      },
      "Adform": {
        "reach": "3.676.000"
      },
      "Verizon": {
        "reach": "3.719.000"
      },
      "Xandr": {
        "reach": "4.203.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.252.000"
      },
      "theTradeDesk": {
        "reach": "725.000"
      },
      "Adform": {
        "reach": "1.651.000"
      },
      "Verizon": {
        "reach": "1.559.000"
      },
      "Xandr": {
        "reach": "1.828.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.355.000"
      },
      "theTradeDesk": {
        "reach": "5.246.000"
      },
      "Adform": {
        "reach": "4.465.000"
      },
      "Verizon": {
        "reach": "4.417.000"
      },
      "Xandr": {
        "reach": "5.150.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.606.000"
      },
      "theTradeDesk": {
        "reach": "1.253.000"
      },
      "Adform": {
        "reach": "1.123.000"
      },
      "Verizon": {
        "reach": "1.106.000"
      },
      "Xandr": {
        "reach": "1.266.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "592.000"
      },
      "RMS": {
        "reach": "1.019.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.238.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.283.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.757.000"
      },
      "theTradeDesk": {
        "reach": "1.408.000"
      },
      "Adform": {
        "reach": "1.242.000"
      },
      "Verizon": {
        "reach": "1.167.000"
      },
      "Xandr": {
        "reach": "1.387.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.107.000"
      },
      "Instagram": {
        "reach": "1.660.000"
      },
      "YouTube": {
        "reach": "6.186.000"
      },
      "Snapchat": {
        "reach": "143.000"
      }
    }
  },
  "Konservativ-Etablierte": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.355.000"
      },
      "theTradeDesk": {
        "reach": "2.705.000"
      },
      "Adform": {
        "reach": "2.311.000"
      },
      "Verizon": {
        "reach": "2.422.000"
      },
      "Xandr": {
        "reach": "2.706.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.439.000"
      },
      "theTradeDesk": {
        "reach": "1.147.000"
      },
      "Adform": {
        "reach": "989.000"
      },
      "Verizon": {
        "reach": "992.000"
      },
      "Xandr": {
        "reach": "1.191.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.320.000"
      },
      "theTradeDesk": {
        "reach": "3.442.000"
      },
      "Adform": {
        "reach": "2.985.000"
      },
      "Verizon": {
        "reach": "2.990.000"
      },
      "Xandr": {
        "reach": "3.276.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.150.000"
      },
      "theTradeDesk": {
        "reach": "4.031.000"
      },
      "Adform": {
        "reach": "3.591.000"
      },
      "Verizon": {
        "reach": "3.549.000"
      },
      "Xandr": {
        "reach": "4.152.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.197.000"
      },
      "theTradeDesk": {
        "reach": "698.000"
      },
      "Adform": {
        "reach": "1.487.000"
      },
      "Verizon": {
        "reach": "1.509.000"
      },
      "Xandr": {
        "reach": "1.758.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.104.000"
      },
      "theTradeDesk": {
        "reach": "4.812.000"
      },
      "Adform": {
        "reach": "4.293.000"
      },
      "Verizon": {
        "reach": "4.168.000"
      },
      "Xandr": {
        "reach": "4.742.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.541.000"
      },
      "theTradeDesk": {
        "reach": "1.204.000"
      },
      "Adform": {
        "reach": "992.000"
      },
      "Verizon": {
        "reach": "1.064.000"
      },
      "Xandr": {
        "reach": "1.211.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "586.000"
      },
      "RMS": {
        "reach": "942.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.080.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.212.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.708.000"
      },
      "theTradeDesk": {
        "reach": "1.312.000"
      },
      "Adform": {
        "reach": "1.127.000"
      },
      "Verizon": {
        "reach": "1.171.000"
      },
      "Xandr": {
        "reach": "1.310.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.116.000"
      },
      "Instagram": {
        "reach": "1.612.000"
      },
      "YouTube": {
        "reach": "5.957.000"
      },
      "Snapchat": {
        "reach": "74.000"
      }
    }
  },
  "Liberal-Intellektuelle": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.193.000"
      },
      "theTradeDesk": {
        "reach": "1.798.000"
      },
      "Adform": {
        "reach": "1.587.000"
      },
      "Verizon": {
        "reach": "1.499.000"
      },
      "Xandr": {
        "reach": "1.873.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "967.000"
      },
      "theTradeDesk": {
        "reach": "760.000"
      },
      "Adform": {
        "reach": "688.000"
      },
      "Verizon": {
        "reach": "659.000"
      },
      "Xandr": {
        "reach": "774.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.994.000"
      },
      "theTradeDesk": {
        "reach": "2.302.000"
      },
      "Adform": {
        "reach": "1.961.000"
      },
      "Verizon": {
        "reach": "2.104.000"
      },
      "Xandr": {
        "reach": "2.270.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.439.000"
      },
      "theTradeDesk": {
        "reach": "2.741.000"
      },
      "Adform": {
        "reach": "2.288.000"
      },
      "Verizon": {
        "reach": "2.382.000"
      },
      "Xandr": {
        "reach": "2.823.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.505.000"
      },
      "theTradeDesk": {
        "reach": "480.000"
      },
      "Adform": {
        "reach": "1.006.000"
      },
      "Verizon": {
        "reach": "1.028.000"
      },
      "Xandr": {
        "reach": "1.189.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.212.000"
      },
      "theTradeDesk": {
        "reach": "3.310.000"
      },
      "Adform": {
        "reach": "2.823.000"
      },
      "Verizon": {
        "reach": "2.707.000"
      },
      "Xandr": {
        "reach": "3.325.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.016.000"
      },
      "theTradeDesk": {
        "reach": "793.000"
      },
      "Adform": {
        "reach": "698.000"
      },
      "Verizon": {
        "reach": "708.000"
      },
      "Xandr": {
        "reach": "816.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "396.000"
      },
      "RMS": {
        "reach": "639.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.077.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "825.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.112.000"
      },
      "theTradeDesk": {
        "reach": "908.000"
      },
      "Adform": {
        "reach": "786.000"
      },
      "Verizon": {
        "reach": "791.000"
      },
      "Xandr": {
        "reach": "884.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.163.000"
      },
      "Instagram": {
        "reach": "1.077.000"
      },
      "YouTube": {
        "reach": "3.961.000"
      },
      "Snapchat": {
        "reach": "48.000"
      }
    }
  },
  "Performer": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.509.000"
      },
      "theTradeDesk": {
        "reach": "2.722.000"
      },
      "Adform": {
        "reach": "2.466.000"
      },
      "Verizon": {
        "reach": "2.438.000"
      },
      "Xandr": {
        "reach": "2.846.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.500.000"
      },
      "theTradeDesk": {
        "reach": "1.195.000"
      },
      "Adform": {
        "reach": "1.053.000"
      },
      "Verizon": {
        "reach": "1.078.000"
      },
      "Xandr": {
        "reach": "1.136.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.436.000"
      },
      "theTradeDesk": {
        "reach": "3.568.000"
      },
      "Adform": {
        "reach": "3.285.000"
      },
      "Verizon": {
        "reach": "3.088.000"
      },
      "Xandr": {
        "reach": "3.583.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.327.000"
      },
      "theTradeDesk": {
        "reach": "4.234.000"
      },
      "Adform": {
        "reach": "3.761.000"
      },
      "Verizon": {
        "reach": "3.738.000"
      },
      "Xandr": {
        "reach": "4.568.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.252.000"
      },
      "theTradeDesk": {
        "reach": "759.000"
      },
      "Adform": {
        "reach": "1.574.000"
      },
      "Verizon": {
        "reach": "1.603.000"
      },
      "Xandr": {
        "reach": "1.840.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.643.000"
      },
      "theTradeDesk": {
        "reach": "5.045.000"
      },
      "Adform": {
        "reach": "4.574.000"
      },
      "Verizon": {
        "reach": "4.671.000"
      },
      "Xandr": {
        "reach": "5.131.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.590.000"
      },
      "theTradeDesk": {
        "reach": "1.270.000"
      },
      "Adform": {
        "reach": "1.149.000"
      },
      "Verizon": {
        "reach": "1.120.000"
      },
      "Xandr": {
        "reach": "1.241.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "701.000"
      },
      "RMS": {
        "reach": "996.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.130.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.288.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.719.000"
      },
      "theTradeDesk": {
        "reach": "1.407.000"
      },
      "Adform": {
        "reach": "1.244.000"
      },
      "Verizon": {
        "reach": "1.246.000"
      },
      "Xandr": {
        "reach": "1.374.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.364.000"
      },
      "Instagram": {
        "reach": "1.703.000"
      },
      "YouTube": {
        "reach": "6.011.000"
      },
      "Snapchat": {
        "reach": "225.000"
      }
    }
  },
  "Expeditive": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.966.000"
      },
      "theTradeDesk": {
        "reach": "2.514.000"
      },
      "Adform": {
        "reach": "2.056.000"
      },
      "Verizon": {
        "reach": "2.125.000"
      },
      "Xandr": {
        "reach": "2.438.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.284.000"
      },
      "theTradeDesk": {
        "reach": "1.053.000"
      },
      "Adform": {
        "reach": "920.000"
      },
      "Verizon": {
        "reach": "877.000"
      },
      "Xandr": {
        "reach": "1.036.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.029.000"
      },
      "theTradeDesk": {
        "reach": "3.136.000"
      },
      "Adform": {
        "reach": "2.590.000"
      },
      "Verizon": {
        "reach": "2.691.000"
      },
      "Xandr": {
        "reach": "3.111.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.689.000"
      },
      "theTradeDesk": {
        "reach": "3.563.000"
      },
      "Adform": {
        "reach": "3.285.000"
      },
      "Verizon": {
        "reach": "3.368.000"
      },
      "Xandr": {
        "reach": "3.830.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.967.000"
      },
      "theTradeDesk": {
        "reach": "645.000"
      },
      "Adform": {
        "reach": "1.363.000"
      },
      "Verizon": {
        "reach": "1.370.000"
      },
      "Xandr": {
        "reach": "1.591.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.561.000"
      },
      "theTradeDesk": {
        "reach": "4.509.000"
      },
      "Adform": {
        "reach": "3.854.000"
      },
      "Verizon": {
        "reach": "3.821.000"
      },
      "Xandr": {
        "reach": "4.239.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.363.000"
      },
      "theTradeDesk": {
        "reach": "1.096.000"
      },
      "Adform": {
        "reach": "947.000"
      },
      "Verizon": {
        "reach": "925.000"
      },
      "Xandr": {
        "reach": "1.073.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "688.000"
      },
      "RMS": {
        "reach": "861.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.620.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.088.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.442.000"
      },
      "theTradeDesk": {
        "reach": "1.143.000"
      },
      "Adform": {
        "reach": "1.044.000"
      },
      "Verizon": {
        "reach": "1.076.000"
      },
      "Xandr": {
        "reach": "1.222.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.711.000"
      },
      "Instagram": {
        "reach": "1.436.000"
      },
      "YouTube": {
        "reach": "5.338.000"
      },
      "Snapchat": {
        "reach": "126.000"
      }
    }
  },
  "Bürgerliche Mitte": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.562.000"
      },
      "theTradeDesk": {
        "reach": "3.041.000"
      },
      "Adform": {
        "reach": "2.507.000"
      },
      "Verizon": {
        "reach": "2.542.000"
      },
      "Xandr": {
        "reach": "2.935.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.511.000"
      },
      "theTradeDesk": {
        "reach": "1.269.000"
      },
      "Adform": {
        "reach": "1.076.000"
      },
      "Verizon": {
        "reach": "1.061.000"
      },
      "Xandr": {
        "reach": "1.230.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.678.000"
      },
      "theTradeDesk": {
        "reach": "3.877.000"
      },
      "Adform": {
        "reach": "3.317.000"
      },
      "Verizon": {
        "reach": "3.258.000"
      },
      "Xandr": {
        "reach": "3.613.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.521.000"
      },
      "theTradeDesk": {
        "reach": "4.285.000"
      },
      "Adform": {
        "reach": "3.913.000"
      },
      "Verizon": {
        "reach": "3.892.000"
      },
      "Xandr": {
        "reach": "4.370.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.411.000"
      },
      "theTradeDesk": {
        "reach": "750.000"
      },
      "Adform": {
        "reach": "1.625.000"
      },
      "Verizon": {
        "reach": "1.657.000"
      },
      "Xandr": {
        "reach": "1.905.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.503.000"
      },
      "theTradeDesk": {
        "reach": "5.326.000"
      },
      "Adform": {
        "reach": "4.698.000"
      },
      "Verizon": {
        "reach": "4.663.000"
      },
      "Xandr": {
        "reach": "5.454.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.675.000"
      },
      "theTradeDesk": {
        "reach": "1.317.000"
      },
      "Adform": {
        "reach": "1.127.000"
      },
      "Verizon": {
        "reach": "1.153.000"
      },
      "Xandr": {
        "reach": "1.311.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "904.000"
      },
      "RMS": {
        "reach": "1.023.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.357.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.303.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.856.000"
      },
      "theTradeDesk": {
        "reach": "1.404.000"
      },
      "Adform": {
        "reach": "1.316.000"
      },
      "Verizon": {
        "reach": "1.290.000"
      },
      "Xandr": {
        "reach": "1.405.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.345.000"
      },
      "Instagram": {
        "reach": "1.737.000"
      },
      "YouTube": {
        "reach": "6.347.000"
      },
      "Snapchat": {
        "reach": "219.000"
      }
    }
  },
  "Adaptiv-Pragmatische": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.767.000"
      },
      "theTradeDesk": {
        "reach": "3.028.000"
      },
      "Adform": {
        "reach": "2.638.000"
      },
      "Verizon": {
        "reach": "2.678.000"
      },
      "Xandr": {
        "reach": "2.909.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.573.000"
      },
      "theTradeDesk": {
        "reach": "1.287.000"
      },
      "Adform": {
        "reach": "1.079.000"
      },
      "Verizon": {
        "reach": "1.124.000"
      },
      "Xandr": {
        "reach": "1.280.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.594.000"
      },
      "theTradeDesk": {
        "reach": "3.741.000"
      },
      "Adform": {
        "reach": "3.265.000"
      },
      "Verizon": {
        "reach": "3.196.000"
      },
      "Xandr": {
        "reach": "3.813.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.473.000"
      },
      "theTradeDesk": {
        "reach": "4.472.000"
      },
      "Adform": {
        "reach": "3.964.000"
      },
      "Verizon": {
        "reach": "3.818.000"
      },
      "Xandr": {
        "reach": "4.131.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.415.000"
      },
      "theTradeDesk": {
        "reach": "760.000"
      },
      "Adform": {
        "reach": "1.663.000"
      },
      "Verizon": {
        "reach": "1.632.000"
      },
      "Xandr": {
        "reach": "1.949.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.877.000"
      },
      "theTradeDesk": {
        "reach": "5.390.000"
      },
      "Adform": {
        "reach": "4.805.000"
      },
      "Verizon": {
        "reach": "4.754.000"
      },
      "Xandr": {
        "reach": "5.626.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.685.000"
      },
      "theTradeDesk": {
        "reach": "1.294.000"
      },
      "Adform": {
        "reach": "1.178.000"
      },
      "Verizon": {
        "reach": "1.128.000"
      },
      "Xandr": {
        "reach": "1.328.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "735.000"
      },
      "RMS": {
        "reach": "1.058.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.373.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.316.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.816.000"
      },
      "theTradeDesk": {
        "reach": "1.497.000"
      },
      "Adform": {
        "reach": "1.275.000"
      },
      "Verizon": {
        "reach": "1.237.000"
      },
      "Xandr": {
        "reach": "1.506.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.570.000"
      },
      "Instagram": {
        "reach": "1.751.000"
      },
      "YouTube": {
        "reach": "6.449.000"
      },
      "Snapchat": {
        "reach": "153.000"
      }
    }
  },
  "Sozialökologische": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.306.000"
      },
      "theTradeDesk": {
        "reach": "1.791.000"
      },
      "Adform": {
        "reach": "1.584.000"
      },
      "Verizon": {
        "reach": "1.618.000"
      },
      "Xandr": {
        "reach": "1.820.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "972.000"
      },
      "theTradeDesk": {
        "reach": "788.000"
      },
      "Adform": {
        "reach": "695.000"
      },
      "Verizon": {
        "reach": "664.000"
      },
      "Xandr": {
        "reach": "801.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.912.000"
      },
      "theTradeDesk": {
        "reach": "2.278.000"
      },
      "Adform": {
        "reach": "1.978.000"
      },
      "Verizon": {
        "reach": "2.008.000"
      },
      "Xandr": {
        "reach": "2.314.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.471.000"
      },
      "theTradeDesk": {
        "reach": "2.791.000"
      },
      "Adform": {
        "reach": "2.467.000"
      },
      "Verizon": {
        "reach": "2.387.000"
      },
      "Xandr": {
        "reach": "2.771.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.499.000"
      },
      "theTradeDesk": {
        "reach": "478.000"
      },
      "Adform": {
        "reach": "1.034.000"
      },
      "Verizon": {
        "reach": "1.051.000"
      },
      "Xandr": {
        "reach": "1.182.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.225.000"
      },
      "theTradeDesk": {
        "reach": "3.552.000"
      },
      "Adform": {
        "reach": "2.948.000"
      },
      "Verizon": {
        "reach": "2.912.000"
      },
      "Xandr": {
        "reach": "3.334.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.030.000"
      },
      "theTradeDesk": {
        "reach": "833.000"
      },
      "Adform": {
        "reach": "718.000"
      },
      "Verizon": {
        "reach": "711.000"
      },
      "Xandr": {
        "reach": "796.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "398.000"
      },
      "RMS": {
        "reach": "660.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.135.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "835.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.137.000"
      },
      "theTradeDesk": {
        "reach": "909.000"
      },
      "Adform": {
        "reach": "803.000"
      },
      "Verizon": {
        "reach": "789.000"
      },
      "Xandr": {
        "reach": "929.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.210.000"
      },
      "Instagram": {
        "reach": "1.090.000"
      },
      "YouTube": {
        "reach": "4.026.000"
      },
      "Snapchat": {
        "reach": "97.000"
      }
    }
  },
  "Traditionelle": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.542.000"
      },
      "theTradeDesk": {
        "reach": "2.829.000"
      },
      "Adform": {
        "reach": "2.479.000"
      },
      "Verizon": {
        "reach": "2.470.000"
      },
      "Xandr": {
        "reach": "2.921.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.528.000"
      },
      "theTradeDesk": {
        "reach": "1.188.000"
      },
      "Adform": {
        "reach": "1.090.000"
      },
      "Verizon": {
        "reach": "1.067.000"
      },
      "Xandr": {
        "reach": "1.182.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.636.000"
      },
      "theTradeDesk": {
        "reach": "3.765.000"
      },
      "Adform": {
        "reach": "3.195.000"
      },
      "Verizon": {
        "reach": "3.245.000"
      },
      "Xandr": {
        "reach": "3.698.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.393.000"
      },
      "theTradeDesk": {
        "reach": "4.380.000"
      },
      "Adform": {
        "reach": "3.943.000"
      },
      "Verizon": {
        "reach": "3.774.000"
      },
      "Xandr": {
        "reach": "4.483.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.317.000"
      },
      "theTradeDesk": {
        "reach": "746.000"
      },
      "Adform": {
        "reach": "1.645.000"
      },
      "Verizon": {
        "reach": "1.614.000"
      },
      "Xandr": {
        "reach": "1.910.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.583.000"
      },
      "theTradeDesk": {
        "reach": "5.163.000"
      },
      "Adform": {
        "reach": "4.545.000"
      },
      "Verizon": {
        "reach": "4.601.000"
      },
      "Xandr": {
        "reach": "5.244.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.595.000"
      },
      "theTradeDesk": {
        "reach": "1.329.000"
      },
      "Adform": {
        "reach": "1.131.000"
      },
      "Verizon": {
        "reach": "1.139.000"
      },
      "Xandr": {
        "reach": "1.285.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "619.000"
      },
      "RMS": {
        "reach": "1.001.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.113.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.273.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.739.000"
      },
      "theTradeDesk": {
        "reach": "1.434.000"
      },
      "Adform": {
        "reach": "1.293.000"
      },
      "Verizon": {
        "reach": "1.240.000"
      },
      "Xandr": {
        "reach": "1.389.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.249.000"
      },
      "Instagram": {
        "reach": "1.729.000"
      },
      "YouTube": {
        "reach": "6.219.000"
      },
      "Snapchat": {
        "reach": "153.000"
      }
    }
  },
  "Prekäre": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.022.000"
      },
      "theTradeDesk": {
        "reach": "2.400.000"
      },
      "Adform": {
        "reach": "2.121.000"
      },
      "Verizon": {
        "reach": "2.055.000"
      },
      "Xandr": {
        "reach": "2.468.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.265.000"
      },
      "theTradeDesk": {
        "reach": "1.036.000"
      },
      "Adform": {
        "reach": "863.000"
      },
      "Verizon": {
        "reach": "887.000"
      },
      "Xandr": {
        "reach": "981.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.776.000"
      },
      "theTradeDesk": {
        "reach": "3.138.000"
      },
      "Adform": {
        "reach": "2.725.000"
      },
      "Verizon": {
        "reach": "2.678.000"
      },
      "Xandr": {
        "reach": "2.859.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.556.000"
      },
      "theTradeDesk": {
        "reach": "3.499.000"
      },
      "Adform": {
        "reach": "3.132.000"
      },
      "Verizon": {
        "reach": "3.180.000"
      },
      "Xandr": {
        "reach": "3.729.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.962.000"
      },
      "theTradeDesk": {
        "reach": "610.000"
      },
      "Adform": {
        "reach": "1.383.000"
      },
      "Verizon": {
        "reach": "1.376.000"
      },
      "Xandr": {
        "reach": "1.588.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.476.000"
      },
      "theTradeDesk": {
        "reach": "4.384.000"
      },
      "Adform": {
        "reach": "3.790.000"
      },
      "Verizon": {
        "reach": "3.784.000"
      },
      "Xandr": {
        "reach": "4.553.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.385.000"
      },
      "theTradeDesk": {
        "reach": "1.066.000"
      },
      "Adform": {
        "reach": "970.000"
      },
      "Verizon": {
        "reach": "933.000"
      },
      "Xandr": {
        "reach": "1.077.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "507.000"
      },
      "RMS": {
        "reach": "869.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.682.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.039.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.491.000"
      },
      "theTradeDesk": {
        "reach": "1.170.000"
      },
      "Adform": {
        "reach": "1.002.000"
      },
      "Verizon": {
        "reach": "1.044.000"
      },
      "Xandr": {
        "reach": "1.223.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.755.000"
      },
      "Instagram": {
        "reach": "1.466.000"
      },
      "YouTube": {
        "reach": "5.200.000"
      },
      "Snapchat": {
        "reach": "61.000"
      }
    }
  },
  "Hedonisten": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.217.000"
      },
      "theTradeDesk": {
        "reach": "4.462.000"
      },
      "Adform": {
        "reach": "3.659.000"
      },
      "Verizon": {
        "reach": "3.812.000"
      },
      "Xandr": {
        "reach": "4.286.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.295.000"
      },
      "theTradeDesk": {
        "reach": "1.830.000"
      },
      "Adform": {
        "reach": "1.694.000"
      },
      "Verizon": {
        "reach": "1.602.000"
      },
      "Xandr": {
        "reach": "1.897.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "6.893.000"
      },
      "theTradeDesk": {
        "reach": "5.442.000"
      },
      "Adform": {
        "reach": "4.692.000"
      },
      "Verizon": {
        "reach": "4.860.000"
      },
      "Xandr": {
        "reach": "5.648.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "8.224.000"
      },
      "theTradeDesk": {
        "reach": "6.753.000"
      },
      "Adform": {
        "reach": "5.890.000"
      },
      "Verizon": {
        "reach": "5.785.000"
      },
      "Xandr": {
        "reach": "6.644.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.466.000"
      },
      "theTradeDesk": {
        "reach": "1.123.000"
      },
      "Adform": {
        "reach": "2.484.000"
      },
      "Verizon": {
        "reach": "2.469.000"
      },
      "Xandr": {
        "reach": "2.775.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "9.934.000"
      },
      "theTradeDesk": {
        "reach": "8.138.000"
      },
      "Adform": {
        "reach": "6.931.000"
      },
      "Verizon": {
        "reach": "7.148.000"
      },
      "Xandr": {
        "reach": "7.755.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.384.000"
      },
      "theTradeDesk": {
        "reach": "1.911.000"
      },
      "Adform": {
        "reach": "1.680.000"
      },
      "Verizon": {
        "reach": "1.713.000"
      },
      "Xandr": {
        "reach": "1.955.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.047.000"
      },
      "RMS": {
        "reach": "1.505.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.141.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.957.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.585.000"
      },
      "theTradeDesk": {
        "reach": "2.196.000"
      },
      "Adform": {
        "reach": "1.914.000"
      },
      "Verizon": {
        "reach": "1.910.000"
      },
      "Xandr": {
        "reach": "2.215.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.009.000"
      },
      "Instagram": {
        "reach": "2.581.000"
      },
      "YouTube": {
        "reach": "9.376.000"
      },
      "Snapchat": {
        "reach": "555.000"
      }
    }
  },
  "Abenteurer": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.067.000"
      },
      "theTradeDesk": {
        "reach": "1.593.000"
      },
      "Adform": {
        "reach": "1.347.000"
      },
      "Verizon": {
        "reach": "1.443.000"
      },
      "Xandr": {
        "reach": "1.640.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "876.000"
      },
      "theTradeDesk": {
        "reach": "692.000"
      },
      "Adform": {
        "reach": "610.000"
      },
      "Verizon": {
        "reach": "596.000"
      },
      "Xandr": {
        "reach": "701.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.559.000"
      },
      "theTradeDesk": {
        "reach": "2.076.000"
      },
      "Adform": {
        "reach": "1.803.000"
      },
      "Verizon": {
        "reach": "1.821.000"
      },
      "Xandr": {
        "reach": "2.041.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.006.000"
      },
      "theTradeDesk": {
        "reach": "2.370.000"
      },
      "Adform": {
        "reach": "2.181.000"
      },
      "Verizon": {
        "reach": "2.144.000"
      },
      "Xandr": {
        "reach": "2.339.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.260.000"
      },
      "theTradeDesk": {
        "reach": "412.000"
      },
      "Adform": {
        "reach": "909.000"
      },
      "Verizon": {
        "reach": "895.000"
      },
      "Xandr": {
        "reach": "1.023.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.751.000"
      },
      "theTradeDesk": {
        "reach": "2.969.000"
      },
      "Adform": {
        "reach": "2.614.000"
      },
      "Verizon": {
        "reach": "2.626.000"
      },
      "Xandr": {
        "reach": "3.030.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "878.000"
      },
      "theTradeDesk": {
        "reach": "736.000"
      },
      "Adform": {
        "reach": "653.000"
      },
      "Verizon": {
        "reach": "644.000"
      },
      "Xandr": {
        "reach": "714.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "347.000"
      },
      "RMS": {
        "reach": "558.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.898.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "737.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "969.000"
      },
      "theTradeDesk": {
        "reach": "793.000"
      },
      "Adform": {
        "reach": "660.000"
      },
      "Verizon": {
        "reach": "710.000"
      },
      "Xandr": {
        "reach": "786.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.872.000"
      },
      "Instagram": {
        "reach": "955.000"
      },
      "YouTube": {
        "reach": "3.496.000"
      },
      "Snapchat": {
        "reach": "210.000"
      }
    }
  },
  "Disziplinierte": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "4.024.000"
      },
      "theTradeDesk": {
        "reach": "3.194.000"
      },
      "Adform": {
        "reach": "2.806.000"
      },
      "Verizon": {
        "reach": "2.874.000"
      },
      "Xandr": {
        "reach": "3.187.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.728.000"
      },
      "theTradeDesk": {
        "reach": "1.401.000"
      },
      "Adform": {
        "reach": "1.190.000"
      },
      "Verizon": {
        "reach": "1.179.000"
      },
      "Xandr": {
        "reach": "1.424.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "5.170.000"
      },
      "theTradeDesk": {
        "reach": "4.123.000"
      },
      "Adform": {
        "reach": "3.564.000"
      },
      "Verizon": {
        "reach": "3.586.000"
      },
      "Xandr": {
        "reach": "4.216.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "6.128.000"
      },
      "theTradeDesk": {
        "reach": "5.005.000"
      },
      "Adform": {
        "reach": "4.354.000"
      },
      "Verizon": {
        "reach": "4.114.000"
      },
      "Xandr": {
        "reach": "4.988.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.649.000"
      },
      "theTradeDesk": {
        "reach": "859.000"
      },
      "Adform": {
        "reach": "1.896.000"
      },
      "Verizon": {
        "reach": "1.847.000"
      },
      "Xandr": {
        "reach": "2.067.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "7.411.000"
      },
      "theTradeDesk": {
        "reach": "5.919.000"
      },
      "Adform": {
        "reach": "5.234.000"
      },
      "Verizon": {
        "reach": "5.214.000"
      },
      "Xandr": {
        "reach": "5.918.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.799.000"
      },
      "theTradeDesk": {
        "reach": "1.423.000"
      },
      "Adform": {
        "reach": "1.309.000"
      },
      "Verizon": {
        "reach": "1.261.000"
      },
      "Xandr": {
        "reach": "1.446.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "674.000"
      },
      "RMS": {
        "reach": "1.203.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.623.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.503.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.065.000"
      },
      "theTradeDesk": {
        "reach": "1.607.000"
      },
      "Adform": {
        "reach": "1.372.000"
      },
      "Verizon": {
        "reach": "1.440.000"
      },
      "Xandr": {
        "reach": "1.656.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.709.000"
      },
      "Instagram": {
        "reach": "1.902.000"
      },
      "YouTube": {
        "reach": "6.987.000"
      },
      "Snapchat": {
        "reach": "171.000"
      }
    }
  },
  "Traditionalisten": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.016.000"
      },
      "theTradeDesk": {
        "reach": "4.058.000"
      },
      "Adform": {
        "reach": "3.560.000"
      },
      "Verizon": {
        "reach": "3.497.000"
      },
      "Xandr": {
        "reach": "3.989.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.071.000"
      },
      "theTradeDesk": {
        "reach": "1.710.000"
      },
      "Adform": {
        "reach": "1.463.000"
      },
      "Verizon": {
        "reach": "1.490.000"
      },
      "Xandr": {
        "reach": "1.666.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "6.362.000"
      },
      "theTradeDesk": {
        "reach": "5.281.000"
      },
      "Adform": {
        "reach": "4.455.000"
      },
      "Verizon": {
        "reach": "4.463.000"
      },
      "Xandr": {
        "reach": "5.155.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "7.556.000"
      },
      "theTradeDesk": {
        "reach": "5.950.000"
      },
      "Adform": {
        "reach": "5.395.000"
      },
      "Verizon": {
        "reach": "5.430.000"
      },
      "Xandr": {
        "reach": "5.880.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.222.000"
      },
      "theTradeDesk": {
        "reach": "1.040.000"
      },
      "Adform": {
        "reach": "2.250.000"
      },
      "Verizon": {
        "reach": "2.307.000"
      },
      "Xandr": {
        "reach": "2.642.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "9.247.000"
      },
      "theTradeDesk": {
        "reach": "7.327.000"
      },
      "Adform": {
        "reach": "6.506.000"
      },
      "Verizon": {
        "reach": "6.369.000"
      },
      "Xandr": {
        "reach": "7.402.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.248.000"
      },
      "theTradeDesk": {
        "reach": "1.797.000"
      },
      "Adform": {
        "reach": "1.588.000"
      },
      "Verizon": {
        "reach": "1.622.000"
      },
      "Xandr": {
        "reach": "1.800.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "659.000"
      },
      "RMS": {
        "reach": "1.431.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "4.346.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.874.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.461.000"
      },
      "theTradeDesk": {
        "reach": "1.931.000"
      },
      "Adform": {
        "reach": "1.800.000"
      },
      "Verizon": {
        "reach": "1.783.000"
      },
      "Xandr": {
        "reach": "1.972.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "4.744.000"
      },
      "Instagram": {
        "reach": "2.366.000"
      },
      "YouTube": {
        "reach": "8.543.000"
      },
      "Snapchat": {
        "reach": "105.000"
      }
    }
  },
  "Harmoniser": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "9.042.000"
      },
      "theTradeDesk": {
        "reach": "6.953.000"
      },
      "Adform": {
        "reach": "6.440.000"
      },
      "Verizon": {
        "reach": "6.423.000"
      },
      "Xandr": {
        "reach": "7.138.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.893.000"
      },
      "theTradeDesk": {
        "reach": "3.109.000"
      },
      "Adform": {
        "reach": "2.759.000"
      },
      "Verizon": {
        "reach": "2.707.000"
      },
      "Xandr": {
        "reach": "3.075.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "11.618.000"
      },
      "theTradeDesk": {
        "reach": "9.236.000"
      },
      "Adform": {
        "reach": "8.055.000"
      },
      "Verizon": {
        "reach": "7.870.000"
      },
      "Xandr": {
        "reach": "9.395.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "13.916.000"
      },
      "theTradeDesk": {
        "reach": "10.903.000"
      },
      "Adform": {
        "reach": "9.633.000"
      },
      "Verizon": {
        "reach": "9.582.000"
      },
      "Xandr": {
        "reach": "10.924.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.693.000"
      },
      "theTradeDesk": {
        "reach": "1.834.000"
      },
      "Adform": {
        "reach": "4.017.000"
      },
      "Verizon": {
        "reach": "4.105.000"
      },
      "Xandr": {
        "reach": "4.556.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "16.073.000"
      },
      "theTradeDesk": {
        "reach": "13.656.000"
      },
      "Adform": {
        "reach": "11.442.000"
      },
      "Verizon": {
        "reach": "11.656.000"
      },
      "Xandr": {
        "reach": "13.305.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.149.000"
      },
      "theTradeDesk": {
        "reach": "3.160.000"
      },
      "Adform": {
        "reach": "2.816.000"
      },
      "Verizon": {
        "reach": "2.878.000"
      },
      "Xandr": {
        "reach": "3.234.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.391.000"
      },
      "RMS": {
        "reach": "2.582.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "8.175.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.341.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "4.554.000"
      },
      "theTradeDesk": {
        "reach": "3.645.000"
      },
      "Adform": {
        "reach": "3.110.000"
      },
      "Verizon": {
        "reach": "3.153.000"
      },
      "Xandr": {
        "reach": "3.624.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "8.071.000"
      },
      "Instagram": {
        "reach": "4.289.000"
      },
      "YouTube": {
        "reach": "15.649.000"
      },
      "Snapchat": {
        "reach": "370.000"
      }
    }
  },
  "Offene": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.230.000"
      },
      "theTradeDesk": {
        "reach": "2.570.000"
      },
      "Adform": {
        "reach": "2.193.000"
      },
      "Verizon": {
        "reach": "2.200.000"
      },
      "Xandr": {
        "reach": "2.501.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.386.000"
      },
      "theTradeDesk": {
        "reach": "1.081.000"
      },
      "Adform": {
        "reach": "927.000"
      },
      "Verizon": {
        "reach": "979.000"
      },
      "Xandr": {
        "reach": "1.115.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.017.000"
      },
      "theTradeDesk": {
        "reach": "3.181.000"
      },
      "Adform": {
        "reach": "2.756.000"
      },
      "Verizon": {
        "reach": "3.023.000"
      },
      "Xandr": {
        "reach": "3.313.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.893.000"
      },
      "theTradeDesk": {
        "reach": "3.952.000"
      },
      "Adform": {
        "reach": "3.369.000"
      },
      "Verizon": {
        "reach": "3.475.000"
      },
      "Xandr": {
        "reach": "3.900.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.120.000"
      },
      "theTradeDesk": {
        "reach": "672.000"
      },
      "Adform": {
        "reach": "1.437.000"
      },
      "Verizon": {
        "reach": "1.468.000"
      },
      "Xandr": {
        "reach": "1.663.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.890.000"
      },
      "theTradeDesk": {
        "reach": "4.836.000"
      },
      "Adform": {
        "reach": "4.064.000"
      },
      "Verizon": {
        "reach": "4.026.000"
      },
      "Xandr": {
        "reach": "4.655.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.460.000"
      },
      "theTradeDesk": {
        "reach": "1.107.000"
      },
      "Adform": {
        "reach": "996.000"
      },
      "Verizon": {
        "reach": "999.000"
      },
      "Xandr": {
        "reach": "1.128.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "534.000"
      },
      "RMS": {
        "reach": "905.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.955.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.133.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.562.000"
      },
      "theTradeDesk": {
        "reach": "1.321.000"
      },
      "Adform": {
        "reach": "1.138.000"
      },
      "Verizon": {
        "reach": "1.165.000"
      },
      "Xandr": {
        "reach": "1.322.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.989.000"
      },
      "Instagram": {
        "reach": "1.570.000"
      },
      "YouTube": {
        "reach": "5.694.000"
      },
      "Snapchat": {
        "reach": "136.000"
      }
    }
  },
  "Spendenbereitschaft niedrig": {
    "cpm": "1,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.220.000"
      },
      "theTradeDesk": {
        "reach": "2.619.000"
      },
      "Adform": {
        "reach": "2.261.000"
      },
      "Verizon": {
        "reach": "2.216.000"
      },
      "Xandr": {
        "reach": "2.641.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.433.000"
      },
      "theTradeDesk": {
        "reach": "1.070.000"
      },
      "Adform": {
        "reach": "928.000"
      },
      "Verizon": {
        "reach": "945.000"
      },
      "Xandr": {
        "reach": "1.095.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.085.000"
      },
      "theTradeDesk": {
        "reach": "3.350.000"
      },
      "Adform": {
        "reach": "2.919.000"
      },
      "Verizon": {
        "reach": "2.870.000"
      },
      "Xandr": {
        "reach": "3.351.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.865.000"
      },
      "theTradeDesk": {
        "reach": "3.929.000"
      },
      "Adform": {
        "reach": "3.377.000"
      },
      "Verizon": {
        "reach": "3.591.000"
      },
      "Xandr": {
        "reach": "3.817.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.089.000"
      },
      "theTradeDesk": {
        "reach": "674.000"
      },
      "Adform": {
        "reach": "1.461.000"
      },
      "Verizon": {
        "reach": "1.504.000"
      },
      "Xandr": {
        "reach": "1.651.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.824.000"
      },
      "theTradeDesk": {
        "reach": "4.835.000"
      },
      "Adform": {
        "reach": "4.119.000"
      },
      "Verizon": {
        "reach": "4.155.000"
      },
      "Xandr": {
        "reach": "4.654.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.422.000"
      },
      "theTradeDesk": {
        "reach": "1.198.000"
      },
      "Adform": {
        "reach": "988.000"
      },
      "Verizon": {
        "reach": "998.000"
      },
      "Xandr": {
        "reach": "1.141.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "399.000"
      },
      "RMS": {
        "reach": "871.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.976.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.160.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.611.000"
      },
      "theTradeDesk": {
        "reach": "1.250.000"
      },
      "Adform": {
        "reach": "1.129.000"
      },
      "Verizon": {
        "reach": "1.136.000"
      },
      "Xandr": {
        "reach": "1.250.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.960.000"
      },
      "Instagram": {
        "reach": "1.558.000"
      },
      "YouTube": {
        "reach": "5.525.000"
      },
      "Snapchat": {
        "reach": "332.000"
      }
    }
  },
  "Spendenbereitschaft mittel": {
    "cpm": "1,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.518.000"
      },
      "theTradeDesk": {
        "reach": "2.000.000"
      },
      "Adform": {
        "reach": "1.794.000"
      },
      "Verizon": {
        "reach": "1.825.000"
      },
      "Xandr": {
        "reach": "2.000.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.080.000"
      },
      "theTradeDesk": {
        "reach": "887.000"
      },
      "Adform": {
        "reach": "761.000"
      },
      "Verizon": {
        "reach": "777.000"
      },
      "Xandr": {
        "reach": "885.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.299.000"
      },
      "theTradeDesk": {
        "reach": "2.617.000"
      },
      "Adform": {
        "reach": "2.291.000"
      },
      "Verizon": {
        "reach": "2.267.000"
      },
      "Xandr": {
        "reach": "2.642.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.737.000"
      },
      "theTradeDesk": {
        "reach": "3.046.000"
      },
      "Adform": {
        "reach": "2.695.000"
      },
      "Verizon": {
        "reach": "2.771.000"
      },
      "Xandr": {
        "reach": "3.031.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.636.000"
      },
      "theTradeDesk": {
        "reach": "523.000"
      },
      "Adform": {
        "reach": "1.133.000"
      },
      "Verizon": {
        "reach": "1.125.000"
      },
      "Xandr": {
        "reach": "1.347.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.643.000"
      },
      "theTradeDesk": {
        "reach": "3.804.000"
      },
      "Adform": {
        "reach": "3.254.000"
      },
      "Verizon": {
        "reach": "3.217.000"
      },
      "Xandr": {
        "reach": "3.845.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.094.000"
      },
      "theTradeDesk": {
        "reach": "926.000"
      },
      "Adform": {
        "reach": "826.000"
      },
      "Verizon": {
        "reach": "801.000"
      },
      "Xandr": {
        "reach": "936.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "372.000"
      },
      "RMS": {
        "reach": "709.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.374.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "943.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.229.000"
      },
      "theTradeDesk": {
        "reach": "1.022.000"
      },
      "Adform": {
        "reach": "898.000"
      },
      "Verizon": {
        "reach": "895.000"
      },
      "Xandr": {
        "reach": "1.011.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.386.000"
      },
      "Instagram": {
        "reach": "1.247.000"
      },
      "YouTube": {
        "reach": "4.342.000"
      },
      "Snapchat": {
        "reach": "261.000"
      }
    }
  },
  "Spendenbereitschaft hoch": {
    "cpm": "1,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.843.000"
      },
      "theTradeDesk": {
        "reach": "2.226.000"
      },
      "Adform": {
        "reach": "1.941.000"
      },
      "Verizon": {
        "reach": "1.957.000"
      },
      "Xandr": {
        "reach": "2.204.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.206.000"
      },
      "theTradeDesk": {
        "reach": "973.000"
      },
      "Adform": {
        "reach": "843.000"
      },
      "Verizon": {
        "reach": "842.000"
      },
      "Xandr": {
        "reach": "954.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.733.000"
      },
      "theTradeDesk": {
        "reach": "2.861.000"
      },
      "Adform": {
        "reach": "2.543.000"
      },
      "Verizon": {
        "reach": "2.509.000"
      },
      "Xandr": {
        "reach": "2.942.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.320.000"
      },
      "theTradeDesk": {
        "reach": "3.506.000"
      },
      "Adform": {
        "reach": "2.911.000"
      },
      "Verizon": {
        "reach": "2.955.000"
      },
      "Xandr": {
        "reach": "3.512.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.803.000"
      },
      "theTradeDesk": {
        "reach": "594.000"
      },
      "Adform": {
        "reach": "1.274.000"
      },
      "Verizon": {
        "reach": "1.308.000"
      },
      "Xandr": {
        "reach": "1.466.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.040.000"
      },
      "theTradeDesk": {
        "reach": "4.034.000"
      },
      "Adform": {
        "reach": "3.566.000"
      },
      "Verizon": {
        "reach": "3.726.000"
      },
      "Xandr": {
        "reach": "4.068.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.238.000"
      },
      "theTradeDesk": {
        "reach": "1.008.000"
      },
      "Adform": {
        "reach": "893.000"
      },
      "Verizon": {
        "reach": "903.000"
      },
      "Xandr": {
        "reach": "993.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "357.000"
      },
      "RMS": {
        "reach": "791.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.530.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.032.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.411.000"
      },
      "theTradeDesk": {
        "reach": "1.134.000"
      },
      "Adform": {
        "reach": "981.000"
      },
      "Verizon": {
        "reach": "966.000"
      },
      "Xandr": {
        "reach": "1.100.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.570.000"
      },
      "Instagram": {
        "reach": "1.359.000"
      },
      "YouTube": {
        "reach": "4.873.000"
      },
      "Snapchat": {
        "reach": "118.000"
      }
    }
  },
  "Vegetarier": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.054.000"
      },
      "theTradeDesk": {
        "reach": "1.645.000"
      },
      "Adform": {
        "reach": "1.396.000"
      },
      "Verizon": {
        "reach": "1.401.000"
      },
      "Xandr": {
        "reach": "1.651.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "845.000"
      },
      "theTradeDesk": {
        "reach": "691.000"
      },
      "Adform": {
        "reach": "608.000"
      },
      "Verizon": {
        "reach": "621.000"
      },
      "Xandr": {
        "reach": "695.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.551.000"
      },
      "theTradeDesk": {
        "reach": "2.092.000"
      },
      "Adform": {
        "reach": "1.878.000"
      },
      "Verizon": {
        "reach": "1.846.000"
      },
      "Xandr": {
        "reach": "2.114.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.134.000"
      },
      "theTradeDesk": {
        "reach": "2.421.000"
      },
      "Adform": {
        "reach": "2.127.000"
      },
      "Verizon": {
        "reach": "2.175.000"
      },
      "Xandr": {
        "reach": "2.396.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.307.000"
      },
      "theTradeDesk": {
        "reach": "425.000"
      },
      "Adform": {
        "reach": "951.000"
      },
      "Verizon": {
        "reach": "925.000"
      },
      "Xandr": {
        "reach": "1.055.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.743.000"
      },
      "theTradeDesk": {
        "reach": "3.016.000"
      },
      "Adform": {
        "reach": "2.570.000"
      },
      "Verizon": {
        "reach": "2.624.000"
      },
      "Xandr": {
        "reach": "3.076.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "917.000"
      },
      "theTradeDesk": {
        "reach": "747.000"
      },
      "Adform": {
        "reach": "655.000"
      },
      "Verizon": {
        "reach": "665.000"
      },
      "Xandr": {
        "reach": "739.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "406.000"
      },
      "RMS": {
        "reach": "573.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.848.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "734.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.011.000"
      },
      "theTradeDesk": {
        "reach": "810.000"
      },
      "Adform": {
        "reach": "704.000"
      },
      "Verizon": {
        "reach": "710.000"
      },
      "Xandr": {
        "reach": "820.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.848.000"
      },
      "Instagram": {
        "reach": "983.000"
      },
      "YouTube": {
        "reach": "3.602.000"
      },
      "Snapchat": {
        "reach": "128.000"
      }
    }
  },
  "Veganer": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "655.000"
      },
      "theTradeDesk": {
        "reach": "526.000"
      },
      "Adform": {
        "reach": "456.000"
      },
      "Verizon": {
        "reach": "454.000"
      },
      "Xandr": {
        "reach": "524.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "270.000"
      },
      "theTradeDesk": {
        "reach": "219.000"
      },
      "Adform": {
        "reach": "188.000"
      },
      "Verizon": {
        "reach": "192.000"
      },
      "Xandr": {
        "reach": "219.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "853.000"
      },
      "theTradeDesk": {
        "reach": "668.000"
      },
      "Adform": {
        "reach": "583.000"
      },
      "Verizon": {
        "reach": "575.000"
      },
      "Xandr": {
        "reach": "657.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.015.000"
      },
      "theTradeDesk": {
        "reach": "795.000"
      },
      "Adform": {
        "reach": "717.000"
      },
      "Verizon": {
        "reach": "662.000"
      },
      "Xandr": {
        "reach": "802.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "426.000"
      },
      "theTradeDesk": {
        "reach": "132.000"
      },
      "Adform": {
        "reach": "292.000"
      },
      "Verizon": {
        "reach": "291.000"
      },
      "Xandr": {
        "reach": "350.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.167.000"
      },
      "theTradeDesk": {
        "reach": "937.000"
      },
      "Adform": {
        "reach": "850.000"
      },
      "Verizon": {
        "reach": "827.000"
      },
      "Xandr": {
        "reach": "977.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "291.000"
      },
      "theTradeDesk": {
        "reach": "232.000"
      },
      "Adform": {
        "reach": "203.000"
      },
      "Verizon": {
        "reach": "208.000"
      },
      "Xandr": {
        "reach": "232.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "125.000"
      },
      "RMS": {
        "reach": "179.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "571.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "233.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "318.000"
      },
      "theTradeDesk": {
        "reach": "259.000"
      },
      "Adform": {
        "reach": "218.000"
      },
      "Verizon": {
        "reach": "227.000"
      },
      "Xandr": {
        "reach": "260.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "594.000"
      },
      "Instagram": {
        "reach": "313.000"
      },
      "YouTube": {
        "reach": "1.120.000"
      },
      "Snapchat": {
        "reach": "41.000"
      }
    }
  },
  "Omnivore": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "16.215.000"
      },
      "theTradeDesk": {
        "reach": "13.450.000"
      },
      "Adform": {
        "reach": "11.422.000"
      },
      "Verizon": {
        "reach": "11.512.000"
      },
      "Xandr": {
        "reach": "12.938.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "6.665.000"
      },
      "theTradeDesk": {
        "reach": "5.523.000"
      },
      "Adform": {
        "reach": "4.739.000"
      },
      "Verizon": {
        "reach": "4.853.000"
      },
      "Xandr": {
        "reach": "5.590.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "19.973.000"
      },
      "theTradeDesk": {
        "reach": "16.807.000"
      },
      "Adform": {
        "reach": "14.662.000"
      },
      "Verizon": {
        "reach": "14.346.000"
      },
      "Xandr": {
        "reach": "16.942.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "25.651.000"
      },
      "theTradeDesk": {
        "reach": "20.224.000"
      },
      "Adform": {
        "reach": "17.296.000"
      },
      "Verizon": {
        "reach": "17.330.000"
      },
      "Xandr": {
        "reach": "19.990.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "10.691.000"
      },
      "theTradeDesk": {
        "reach": "3.306.000"
      },
      "Adform": {
        "reach": "7.377.000"
      },
      "Verizon": {
        "reach": "7.419.000"
      },
      "Xandr": {
        "reach": "8.326.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "29.421.000"
      },
      "theTradeDesk": {
        "reach": "23.202.000"
      },
      "Adform": {
        "reach": "20.637.000"
      },
      "Verizon": {
        "reach": "21.184.000"
      },
      "Xandr": {
        "reach": "23.075.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "7.073.000"
      },
      "theTradeDesk": {
        "reach": "5.703.000"
      },
      "Adform": {
        "reach": "5.134.000"
      },
      "Verizon": {
        "reach": "5.120.000"
      },
      "Xandr": {
        "reach": "5.909.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.272.000"
      },
      "RMS": {
        "reach": "4.715.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "14.347.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.953.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "7.952.000"
      },
      "theTradeDesk": {
        "reach": "6.275.000"
      },
      "Adform": {
        "reach": "5.654.000"
      },
      "Verizon": {
        "reach": "5.595.000"
      },
      "Xandr": {
        "reach": "6.492.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "15.191.000"
      },
      "Instagram": {
        "reach": "7.814.000"
      },
      "YouTube": {
        "reach": "28.042.000"
      },
      "Snapchat": {
        "reach": "1.306.000"
      }
    }
  },
  "Kreditkarte": {
    "cpm": "3,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "8.956.000"
      },
      "theTradeDesk": {
        "reach": "7.416.000"
      },
      "Adform": {
        "reach": "6.379.000"
      },
      "Verizon": {
        "reach": "6.333.000"
      },
      "Xandr": {
        "reach": "6.976.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.786.000"
      },
      "theTradeDesk": {
        "reach": "3.130.000"
      },
      "Adform": {
        "reach": "2.728.000"
      },
      "Verizon": {
        "reach": "2.659.000"
      },
      "Xandr": {
        "reach": "3.071.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "11.369.000"
      },
      "theTradeDesk": {
        "reach": "9.488.000"
      },
      "Adform": {
        "reach": "7.898.000"
      },
      "Verizon": {
        "reach": "8.167.000"
      },
      "Xandr": {
        "reach": "9.320.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "13.795.000"
      },
      "theTradeDesk": {
        "reach": "11.085.000"
      },
      "Adform": {
        "reach": "9.798.000"
      },
      "Verizon": {
        "reach": "9.666.000"
      },
      "Xandr": {
        "reach": "11.425.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.833.000"
      },
      "theTradeDesk": {
        "reach": "1.896.000"
      },
      "Adform": {
        "reach": "4.153.000"
      },
      "Verizon": {
        "reach": "4.043.000"
      },
      "Xandr": {
        "reach": "4.585.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "15.701.000"
      },
      "theTradeDesk": {
        "reach": "13.202.000"
      },
      "Adform": {
        "reach": "11.730.000"
      },
      "Verizon": {
        "reach": "11.301.000"
      },
      "Xandr": {
        "reach": "12.868.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.994.000"
      },
      "theTradeDesk": {
        "reach": "3.251.000"
      },
      "Adform": {
        "reach": "2.862.000"
      },
      "Verizon": {
        "reach": "2.890.000"
      },
      "Xandr": {
        "reach": "3.312.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.949.000"
      },
      "RMS": {
        "reach": "2.505.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "8.117.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.364.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "4.559.000"
      },
      "theTradeDesk": {
        "reach": "3.492.000"
      },
      "Adform": {
        "reach": "3.123.000"
      },
      "Verizon": {
        "reach": "3.074.000"
      },
      "Xandr": {
        "reach": "3.480.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "8.513.000"
      },
      "Instagram": {
        "reach": "4.305.000"
      },
      "YouTube": {
        "reach": "15.875.000"
      },
      "Snapchat": {
        "reach": "736.000"
      }
    }
  },
  "Konsumentenkredit": {
    "cpm": "3,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.111.000"
      },
      "theTradeDesk": {
        "reach": "899.000"
      },
      "Adform": {
        "reach": "788.000"
      },
      "Verizon": {
        "reach": "792.000"
      },
      "Xandr": {
        "reach": "914.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "482.000"
      },
      "theTradeDesk": {
        "reach": "393.000"
      },
      "Adform": {
        "reach": "346.000"
      },
      "Verizon": {
        "reach": "333.000"
      },
      "Xandr": {
        "reach": "381.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.421.000"
      },
      "theTradeDesk": {
        "reach": "1.152.000"
      },
      "Adform": {
        "reach": "987.000"
      },
      "Verizon": {
        "reach": "1.049.000"
      },
      "Xandr": {
        "reach": "1.181.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.712.000"
      },
      "theTradeDesk": {
        "reach": "1.366.000"
      },
      "Adform": {
        "reach": "1.193.000"
      },
      "Verizon": {
        "reach": "1.217.000"
      },
      "Xandr": {
        "reach": "1.419.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "729.000"
      },
      "theTradeDesk": {
        "reach": "240.000"
      },
      "Adform": {
        "reach": "513.000"
      },
      "Verizon": {
        "reach": "500.000"
      },
      "Xandr": {
        "reach": "593.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.054.000"
      },
      "theTradeDesk": {
        "reach": "1.639.000"
      },
      "Adform": {
        "reach": "1.446.000"
      },
      "Verizon": {
        "reach": "1.425.000"
      },
      "Xandr": {
        "reach": "1.651.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "506.000"
      },
      "theTradeDesk": {
        "reach": "413.000"
      },
      "Adform": {
        "reach": "344.000"
      },
      "Verizon": {
        "reach": "363.000"
      },
      "Xandr": {
        "reach": "405.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "268.000"
      },
      "RMS": {
        "reach": "326.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.033.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "414.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "563.000"
      },
      "theTradeDesk": {
        "reach": "442.000"
      },
      "Adform": {
        "reach": "386.000"
      },
      "Verizon": {
        "reach": "395.000"
      },
      "Xandr": {
        "reach": "447.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.045.000"
      },
      "Instagram": {
        "reach": "556.000"
      },
      "YouTube": {
        "reach": "1.930.000"
      },
      "Snapchat": {
        "reach": "71.000"
      }
    }
  },
  "Baufinanzierung": {
    "cpm": "3,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.524.000"
      },
      "theTradeDesk": {
        "reach": "1.192.000"
      },
      "Adform": {
        "reach": "1.049.000"
      },
      "Verizon": {
        "reach": "1.038.000"
      },
      "Xandr": {
        "reach": "1.222.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "650.000"
      },
      "theTradeDesk": {
        "reach": "521.000"
      },
      "Adform": {
        "reach": "432.000"
      },
      "Verizon": {
        "reach": "433.000"
      },
      "Xandr": {
        "reach": "524.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.862.000"
      },
      "theTradeDesk": {
        "reach": "1.549.000"
      },
      "Adform": {
        "reach": "1.312.000"
      },
      "Verizon": {
        "reach": "1.348.000"
      },
      "Xandr": {
        "reach": "1.541.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.247.000"
      },
      "theTradeDesk": {
        "reach": "1.821.000"
      },
      "Adform": {
        "reach": "1.584.000"
      },
      "Verizon": {
        "reach": "1.610.000"
      },
      "Xandr": {
        "reach": "1.804.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "999.000"
      },
      "theTradeDesk": {
        "reach": "310.000"
      },
      "Adform": {
        "reach": "678.000"
      },
      "Verizon": {
        "reach": "687.000"
      },
      "Xandr": {
        "reach": "775.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.778.000"
      },
      "theTradeDesk": {
        "reach": "2.256.000"
      },
      "Adform": {
        "reach": "1.846.000"
      },
      "Verizon": {
        "reach": "1.954.000"
      },
      "Xandr": {
        "reach": "2.177.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "684.000"
      },
      "theTradeDesk": {
        "reach": "518.000"
      },
      "Adform": {
        "reach": "466.000"
      },
      "Verizon": {
        "reach": "480.000"
      },
      "Xandr": {
        "reach": "545.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "286.000"
      },
      "RMS": {
        "reach": "416.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.382.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "543.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "749.000"
      },
      "theTradeDesk": {
        "reach": "595.000"
      },
      "Adform": {
        "reach": "527.000"
      },
      "Verizon": {
        "reach": "519.000"
      },
      "Xandr": {
        "reach": "587.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.370.000"
      },
      "Instagram": {
        "reach": "711.000"
      },
      "YouTube": {
        "reach": "2.610.000"
      },
      "Snapchat": {
        "reach": "60.000"
      }
    }
  },
  "Banking Präferenz-Filiale": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "7.742.000"
      },
      "theTradeDesk": {
        "reach": "6.221.000"
      },
      "Adform": {
        "reach": "5.341.000"
      },
      "Verizon": {
        "reach": "5.413.000"
      },
      "Xandr": {
        "reach": "6.106.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.267.000"
      },
      "theTradeDesk": {
        "reach": "2.682.000"
      },
      "Adform": {
        "reach": "2.361.000"
      },
      "Verizon": {
        "reach": "2.279.000"
      },
      "Xandr": {
        "reach": "2.645.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "9.890.000"
      },
      "theTradeDesk": {
        "reach": "7.721.000"
      },
      "Adform": {
        "reach": "6.677.000"
      },
      "Verizon": {
        "reach": "7.116.000"
      },
      "Xandr": {
        "reach": "7.920.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "12.035.000"
      },
      "theTradeDesk": {
        "reach": "9.810.000"
      },
      "Adform": {
        "reach": "8.228.000"
      },
      "Verizon": {
        "reach": "8.463.000"
      },
      "Xandr": {
        "reach": "9.531.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.016.000"
      },
      "theTradeDesk": {
        "reach": "1.630.000"
      },
      "Adform": {
        "reach": "3.488.000"
      },
      "Verizon": {
        "reach": "3.426.000"
      },
      "Xandr": {
        "reach": "4.082.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "14.599.000"
      },
      "theTradeDesk": {
        "reach": "11.481.000"
      },
      "Adform": {
        "reach": "9.940.000"
      },
      "Verizon": {
        "reach": "9.867.000"
      },
      "Xandr": {
        "reach": "11.662.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.472.000"
      },
      "theTradeDesk": {
        "reach": "2.836.000"
      },
      "Adform": {
        "reach": "2.396.000"
      },
      "Verizon": {
        "reach": "2.427.000"
      },
      "Xandr": {
        "reach": "2.662.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.303.000"
      },
      "RMS": {
        "reach": "2.240.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "7.076.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.818.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.776.000"
      },
      "theTradeDesk": {
        "reach": "3.080.000"
      },
      "Adform": {
        "reach": "2.661.000"
      },
      "Verizon": {
        "reach": "2.673.000"
      },
      "Xandr": {
        "reach": "3.028.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "7.160.000"
      },
      "Instagram": {
        "reach": "3.775.000"
      },
      "YouTube": {
        "reach": "13.112.000"
      },
      "Snapchat": {
        "reach": "636.000"
      }
    }
  },
  "Banking Präferenz-Online": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "15.513.000"
      },
      "theTradeDesk": {
        "reach": "12.792.000"
      },
      "Adform": {
        "reach": "10.920.000"
      },
      "Verizon": {
        "reach": "11.304.000"
      },
      "Xandr": {
        "reach": "12.525.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "6.545.000"
      },
      "theTradeDesk": {
        "reach": "5.264.000"
      },
      "Adform": {
        "reach": "4.713.000"
      },
      "Verizon": {
        "reach": "4.627.000"
      },
      "Xandr": {
        "reach": "5.237.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "20.481.000"
      },
      "theTradeDesk": {
        "reach": "16.307.000"
      },
      "Adform": {
        "reach": "13.749.000"
      },
      "Verizon": {
        "reach": "14.235.000"
      },
      "Xandr": {
        "reach": "15.902.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "24.015.000"
      },
      "theTradeDesk": {
        "reach": "18.515.000"
      },
      "Adform": {
        "reach": "16.680.000"
      },
      "Verizon": {
        "reach": "16.267.000"
      },
      "Xandr": {
        "reach": "20.339.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "10.192.000"
      },
      "theTradeDesk": {
        "reach": "3.415.000"
      },
      "Adform": {
        "reach": "7.329.000"
      },
      "Verizon": {
        "reach": "7.135.000"
      },
      "Xandr": {
        "reach": "8.205.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "28.738.000"
      },
      "theTradeDesk": {
        "reach": "22.593.000"
      },
      "Adform": {
        "reach": "19.800.000"
      },
      "Verizon": {
        "reach": "20.255.000"
      },
      "Xandr": {
        "reach": "22.755.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "6.930.000"
      },
      "theTradeDesk": {
        "reach": "5.818.000"
      },
      "Adform": {
        "reach": "4.852.000"
      },
      "Verizon": {
        "reach": "5.022.000"
      },
      "Xandr": {
        "reach": "5.475.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.697.000"
      },
      "RMS": {
        "reach": "4.286.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "14.329.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.663.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "7.694.000"
      },
      "theTradeDesk": {
        "reach": "6.130.000"
      },
      "Adform": {
        "reach": "5.452.000"
      },
      "Verizon": {
        "reach": "5.468.000"
      },
      "Xandr": {
        "reach": "5.961.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "14.335.000"
      },
      "Instagram": {
        "reach": "7.573.000"
      },
      "YouTube": {
        "reach": "27.224.000"
      },
      "Snapchat": {
        "reach": "1.254.000"
      }
    }
  },
  "Rat vom Fachmann": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "10.583.000"
      },
      "theTradeDesk": {
        "reach": "8.324.000"
      },
      "Adform": {
        "reach": "7.754.000"
      },
      "Verizon": {
        "reach": "7.503.000"
      },
      "Xandr": {
        "reach": "8.247.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.583.000"
      },
      "theTradeDesk": {
        "reach": "3.618.000"
      },
      "Adform": {
        "reach": "3.063.000"
      },
      "Verizon": {
        "reach": "3.153.000"
      },
      "Xandr": {
        "reach": "3.515.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "13.367.000"
      },
      "theTradeDesk": {
        "reach": "11.482.000"
      },
      "Adform": {
        "reach": "9.304.000"
      },
      "Verizon": {
        "reach": "9.552.000"
      },
      "Xandr": {
        "reach": "11.165.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "16.087.000"
      },
      "theTradeDesk": {
        "reach": "12.975.000"
      },
      "Adform": {
        "reach": "11.609.000"
      },
      "Verizon": {
        "reach": "11.420.000"
      },
      "Xandr": {
        "reach": "12.912.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "6.891.000"
      },
      "theTradeDesk": {
        "reach": "2.214.000"
      },
      "Adform": {
        "reach": "4.925.000"
      },
      "Verizon": {
        "reach": "4.574.000"
      },
      "Xandr": {
        "reach": "5.636.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "18.594.000"
      },
      "theTradeDesk": {
        "reach": "15.288.000"
      },
      "Adform": {
        "reach": "13.788.000"
      },
      "Verizon": {
        "reach": "13.418.000"
      },
      "Xandr": {
        "reach": "15.278.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.771.000"
      },
      "theTradeDesk": {
        "reach": "3.810.000"
      },
      "Adform": {
        "reach": "3.350.000"
      },
      "Verizon": {
        "reach": "3.211.000"
      },
      "Xandr": {
        "reach": "3.950.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.745.000"
      },
      "RMS": {
        "reach": "2.929.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "9.358.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.772.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "5.127.000"
      },
      "theTradeDesk": {
        "reach": "4.174.000"
      },
      "Adform": {
        "reach": "3.703.000"
      },
      "Verizon": {
        "reach": "3.669.000"
      },
      "Xandr": {
        "reach": "4.208.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "9.796.000"
      },
      "Instagram": {
        "reach": "5.171.000"
      },
      "YouTube": {
        "reach": "18.456.000"
      },
      "Snapchat": {
        "reach": "669.000"
      }
    }
  },
  "Rat von Freunden und Familie": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "9.334.000"
      },
      "theTradeDesk": {
        "reach": "7.616.000"
      },
      "Adform": {
        "reach": "6.835.000"
      },
      "Verizon": {
        "reach": "6.234.000"
      },
      "Xandr": {
        "reach": "7.648.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.011.000"
      },
      "theTradeDesk": {
        "reach": "3.298.000"
      },
      "Adform": {
        "reach": "2.794.000"
      },
      "Verizon": {
        "reach": "2.801.000"
      },
      "Xandr": {
        "reach": "3.302.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "11.966.000"
      },
      "theTradeDesk": {
        "reach": "9.368.000"
      },
      "Adform": {
        "reach": "8.374.000"
      },
      "Verizon": {
        "reach": "8.715.000"
      },
      "Xandr": {
        "reach": "9.702.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "14.553.000"
      },
      "theTradeDesk": {
        "reach": "11.363.000"
      },
      "Adform": {
        "reach": "9.935.000"
      },
      "Verizon": {
        "reach": "10.448.000"
      },
      "Xandr": {
        "reach": "11.166.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.991.000"
      },
      "theTradeDesk": {
        "reach": "2.001.000"
      },
      "Adform": {
        "reach": "4.232.000"
      },
      "Verizon": {
        "reach": "4.292.000"
      },
      "Xandr": {
        "reach": "4.864.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "17.568.000"
      },
      "theTradeDesk": {
        "reach": "13.858.000"
      },
      "Adform": {
        "reach": "11.949.000"
      },
      "Verizon": {
        "reach": "12.140.000"
      },
      "Xandr": {
        "reach": "13.872.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.306.000"
      },
      "theTradeDesk": {
        "reach": "3.360.000"
      },
      "Adform": {
        "reach": "3.012.000"
      },
      "Verizon": {
        "reach": "3.000.000"
      },
      "Xandr": {
        "reach": "3.385.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.669.000"
      },
      "RMS": {
        "reach": "2.691.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "8.813.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.506.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "4.768.000"
      },
      "theTradeDesk": {
        "reach": "3.683.000"
      },
      "Adform": {
        "reach": "3.214.000"
      },
      "Verizon": {
        "reach": "3.300.000"
      },
      "Xandr": {
        "reach": "3.698.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "8.802.000"
      },
      "Instagram": {
        "reach": "4.642.000"
      },
      "YouTube": {
        "reach": "16.646.000"
      },
      "Snapchat": {
        "reach": "790.000"
      }
    }
  },
  "Bevorzugte Zahlungsart Online:Paypal": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "7.337.000"
      },
      "theTradeDesk": {
        "reach": "5.909.000"
      },
      "Adform": {
        "reach": "4.908.000"
      },
      "Verizon": {
        "reach": "5.194.000"
      },
      "Xandr": {
        "reach": "5.682.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.134.000"
      },
      "theTradeDesk": {
        "reach": "2.402.000"
      },
      "Adform": {
        "reach": "2.154.000"
      },
      "Verizon": {
        "reach": "2.224.000"
      },
      "Xandr": {
        "reach": "2.473.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "9.342.000"
      },
      "theTradeDesk": {
        "reach": "7.442.000"
      },
      "Adform": {
        "reach": "6.409.000"
      },
      "Verizon": {
        "reach": "6.293.000"
      },
      "Xandr": {
        "reach": "7.451.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "11.153.000"
      },
      "theTradeDesk": {
        "reach": "9.055.000"
      },
      "Adform": {
        "reach": "7.855.000"
      },
      "Verizon": {
        "reach": "8.060.000"
      },
      "Xandr": {
        "reach": "8.779.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.885.000"
      },
      "theTradeDesk": {
        "reach": "1.566.000"
      },
      "Adform": {
        "reach": "3.384.000"
      },
      "Verizon": {
        "reach": "3.395.000"
      },
      "Xandr": {
        "reach": "3.696.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "12.747.000"
      },
      "theTradeDesk": {
        "reach": "10.611.000"
      },
      "Adform": {
        "reach": "9.060.000"
      },
      "Verizon": {
        "reach": "9.265.000"
      },
      "Xandr": {
        "reach": "10.862.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.229.000"
      },
      "theTradeDesk": {
        "reach": "2.605.000"
      },
      "Adform": {
        "reach": "2.278.000"
      },
      "Verizon": {
        "reach": "2.288.000"
      },
      "Xandr": {
        "reach": "2.618.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.818.000"
      },
      "RMS": {
        "reach": "2.029.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "6.636.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.544.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.527.000"
      },
      "theTradeDesk": {
        "reach": "2.810.000"
      },
      "Adform": {
        "reach": "2.659.000"
      },
      "Verizon": {
        "reach": "2.486.000"
      },
      "Xandr": {
        "reach": "2.830.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "6.896.000"
      },
      "Instagram": {
        "reach": "3.426.000"
      },
      "YouTube": {
        "reach": "12.398.000"
      },
      "Snapchat": {
        "reach": "760.000"
      }
    }
  },
  "Bevorzugte Zahlungsart Online:Kreditkarte": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.971.000"
      },
      "theTradeDesk": {
        "reach": "3.180.000"
      },
      "Adform": {
        "reach": "2.836.000"
      },
      "Verizon": {
        "reach": "2.763.000"
      },
      "Xandr": {
        "reach": "3.193.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.741.000"
      },
      "theTradeDesk": {
        "reach": "1.413.000"
      },
      "Adform": {
        "reach": "1.197.000"
      },
      "Verizon": {
        "reach": "1.170.000"
      },
      "Xandr": {
        "reach": "1.330.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "5.126.000"
      },
      "theTradeDesk": {
        "reach": "4.030.000"
      },
      "Adform": {
        "reach": "3.670.000"
      },
      "Verizon": {
        "reach": "3.541.000"
      },
      "Xandr": {
        "reach": "4.130.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "6.218.000"
      },
      "theTradeDesk": {
        "reach": "4.971.000"
      },
      "Adform": {
        "reach": "4.351.000"
      },
      "Verizon": {
        "reach": "4.257.000"
      },
      "Xandr": {
        "reach": "4.715.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.602.000"
      },
      "theTradeDesk": {
        "reach": "806.000"
      },
      "Adform": {
        "reach": "1.840.000"
      },
      "Verizon": {
        "reach": "1.795.000"
      },
      "Xandr": {
        "reach": "2.017.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "7.444.000"
      },
      "theTradeDesk": {
        "reach": "5.920.000"
      },
      "Adform": {
        "reach": "5.123.000"
      },
      "Verizon": {
        "reach": "5.050.000"
      },
      "Xandr": {
        "reach": "5.781.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.756.000"
      },
      "theTradeDesk": {
        "reach": "1.413.000"
      },
      "Adform": {
        "reach": "1.285.000"
      },
      "Verizon": {
        "reach": "1.315.000"
      },
      "Xandr": {
        "reach": "1.458.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "989.000"
      },
      "RMS": {
        "reach": "1.081.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.607.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.435.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.030.000"
      },
      "theTradeDesk": {
        "reach": "1.607.000"
      },
      "Adform": {
        "reach": "1.382.000"
      },
      "Verizon": {
        "reach": "1.340.000"
      },
      "Xandr": {
        "reach": "1.552.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.542.000"
      },
      "Instagram": {
        "reach": "1.871.000"
      },
      "YouTube": {
        "reach": "6.829.000"
      },
      "Snapchat": {
        "reach": "414.000"
      }
    }
  },
  "Spekulative Geldanlagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.121.000"
      },
      "theTradeDesk": {
        "reach": "3.979.000"
      },
      "Adform": {
        "reach": "3.465.000"
      },
      "Verizon": {
        "reach": "3.476.000"
      },
      "Xandr": {
        "reach": "4.152.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.130.000"
      },
      "theTradeDesk": {
        "reach": "1.680.000"
      },
      "Adform": {
        "reach": "1.503.000"
      },
      "Verizon": {
        "reach": "1.498.000"
      },
      "Xandr": {
        "reach": "1.712.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "6.405.000"
      },
      "theTradeDesk": {
        "reach": "5.059.000"
      },
      "Adform": {
        "reach": "4.363.000"
      },
      "Verizon": {
        "reach": "4.506.000"
      },
      "Xandr": {
        "reach": "5.112.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "7.346.000"
      },
      "theTradeDesk": {
        "reach": "6.091.000"
      },
      "Adform": {
        "reach": "5.303.000"
      },
      "Verizon": {
        "reach": "5.342.000"
      },
      "Xandr": {
        "reach": "6.213.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.218.000"
      },
      "theTradeDesk": {
        "reach": "1.054.000"
      },
      "Adform": {
        "reach": "2.342.000"
      },
      "Verizon": {
        "reach": "2.360.000"
      },
      "Xandr": {
        "reach": "2.568.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "9.297.000"
      },
      "theTradeDesk": {
        "reach": "7.338.000"
      },
      "Adform": {
        "reach": "6.202.000"
      },
      "Verizon": {
        "reach": "6.515.000"
      },
      "Xandr": {
        "reach": "7.394.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.223.000"
      },
      "theTradeDesk": {
        "reach": "1.858.000"
      },
      "Adform": {
        "reach": "1.621.000"
      },
      "Verizon": {
        "reach": "1.547.000"
      },
      "Xandr": {
        "reach": "1.822.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.050.000"
      },
      "RMS": {
        "reach": "1.444.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "4.484.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.826.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.538.000"
      },
      "theTradeDesk": {
        "reach": "2.028.000"
      },
      "Adform": {
        "reach": "1.721.000"
      },
      "Verizon": {
        "reach": "1.733.000"
      },
      "Xandr": {
        "reach": "1.954.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "4.576.000"
      },
      "Instagram": {
        "reach": "2.426.000"
      },
      "YouTube": {
        "reach": "8.698.000"
      },
      "Snapchat": {
        "reach": "322.000"
      }
    }
  },
  "Konservative Geldanlagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "12.065.000"
      },
      "theTradeDesk": {
        "reach": "9.936.000"
      },
      "Adform": {
        "reach": "8.575.000"
      },
      "Verizon": {
        "reach": "8.943.000"
      },
      "Xandr": {
        "reach": "9.534.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.338.000"
      },
      "theTradeDesk": {
        "reach": "4.234.000"
      },
      "Adform": {
        "reach": "3.759.000"
      },
      "Verizon": {
        "reach": "3.659.000"
      },
      "Xandr": {
        "reach": "4.329.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "16.368.000"
      },
      "theTradeDesk": {
        "reach": "12.277.000"
      },
      "Adform": {
        "reach": "10.687.000"
      },
      "Verizon": {
        "reach": "10.945.000"
      },
      "Xandr": {
        "reach": "12.907.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "18.429.000"
      },
      "theTradeDesk": {
        "reach": "15.462.000"
      },
      "Adform": {
        "reach": "12.965.000"
      },
      "Verizon": {
        "reach": "13.237.000"
      },
      "Xandr": {
        "reach": "15.259.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "8.153.000"
      },
      "theTradeDesk": {
        "reach": "2.661.000"
      },
      "Adform": {
        "reach": "5.620.000"
      },
      "Verizon": {
        "reach": "5.674.000"
      },
      "Xandr": {
        "reach": "6.364.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "22.497.000"
      },
      "theTradeDesk": {
        "reach": "18.042.000"
      },
      "Adform": {
        "reach": "15.905.000"
      },
      "Verizon": {
        "reach": "16.014.000"
      },
      "Xandr": {
        "reach": "17.808.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.495.000"
      },
      "theTradeDesk": {
        "reach": "4.469.000"
      },
      "Adform": {
        "reach": "3.928.000"
      },
      "Verizon": {
        "reach": "3.884.000"
      },
      "Xandr": {
        "reach": "4.359.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.027.000"
      },
      "RMS": {
        "reach": "3.532.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "10.817.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.323.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.069.000"
      },
      "theTradeDesk": {
        "reach": "4.578.000"
      },
      "Adform": {
        "reach": "4.305.000"
      },
      "Verizon": {
        "reach": "4.173.000"
      },
      "Xandr": {
        "reach": "4.814.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "10.952.000"
      },
      "Instagram": {
        "reach": "6.020.000"
      },
      "YouTube": {
        "reach": "21.462.000"
      },
      "Snapchat": {
        "reach": "529.000"
      }
    }
  },
  "Private Krankenversicherung": {
    "cpm": "3,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.422.000"
      },
      "theTradeDesk": {
        "reach": "2.822.000"
      },
      "Adform": {
        "reach": "2.386.000"
      },
      "Verizon": {
        "reach": "2.373.000"
      },
      "Xandr": {
        "reach": "2.881.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.507.000"
      },
      "theTradeDesk": {
        "reach": "1.180.000"
      },
      "Adform": {
        "reach": "1.020.000"
      },
      "Verizon": {
        "reach": "1.062.000"
      },
      "Xandr": {
        "reach": "1.185.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.460.000"
      },
      "theTradeDesk": {
        "reach": "3.689.000"
      },
      "Adform": {
        "reach": "3.110.000"
      },
      "Verizon": {
        "reach": "3.125.000"
      },
      "Xandr": {
        "reach": "3.561.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.393.000"
      },
      "theTradeDesk": {
        "reach": "4.344.000"
      },
      "Adform": {
        "reach": "3.738.000"
      },
      "Verizon": {
        "reach": "3.847.000"
      },
      "Xandr": {
        "reach": "4.251.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.271.000"
      },
      "theTradeDesk": {
        "reach": "716.000"
      },
      "Adform": {
        "reach": "1.535.000"
      },
      "Verizon": {
        "reach": "1.578.000"
      },
      "Xandr": {
        "reach": "1.851.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.556.000"
      },
      "theTradeDesk": {
        "reach": "5.165.000"
      },
      "Adform": {
        "reach": "4.550.000"
      },
      "Verizon": {
        "reach": "4.380.000"
      },
      "Xandr": {
        "reach": "5.048.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.585.000"
      },
      "theTradeDesk": {
        "reach": "1.279.000"
      },
      "Adform": {
        "reach": "1.128.000"
      },
      "Verizon": {
        "reach": "1.096.000"
      },
      "Xandr": {
        "reach": "1.242.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "863.000"
      },
      "RMS": {
        "reach": "967.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.141.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.313.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.731.000"
      },
      "theTradeDesk": {
        "reach": "1.407.000"
      },
      "Adform": {
        "reach": "1.205.000"
      },
      "Verizon": {
        "reach": "1.268.000"
      },
      "Xandr": {
        "reach": "1.403.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.282.000"
      },
      "Instagram": {
        "reach": "1.632.000"
      },
      "YouTube": {
        "reach": "6.001.000"
      },
      "Snapchat": {
        "reach": "218.000"
      }
    }
  },
  "Krankenzusatzversicherung": {
    "cpm": "3,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.216.000"
      },
      "theTradeDesk": {
        "reach": "4.211.000"
      },
      "Adform": {
        "reach": "3.564.000"
      },
      "Verizon": {
        "reach": "3.672.000"
      },
      "Xandr": {
        "reach": "4.073.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.185.000"
      },
      "theTradeDesk": {
        "reach": "1.771.000"
      },
      "Adform": {
        "reach": "1.465.000"
      },
      "Verizon": {
        "reach": "1.539.000"
      },
      "Xandr": {
        "reach": "1.746.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "6.579.000"
      },
      "theTradeDesk": {
        "reach": "5.134.000"
      },
      "Adform": {
        "reach": "4.546.000"
      },
      "Verizon": {
        "reach": "4.605.000"
      },
      "Xandr": {
        "reach": "5.227.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "7.831.000"
      },
      "theTradeDesk": {
        "reach": "5.955.000"
      },
      "Adform": {
        "reach": "5.534.000"
      },
      "Verizon": {
        "reach": "5.581.000"
      },
      "Xandr": {
        "reach": "6.340.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.380.000"
      },
      "theTradeDesk": {
        "reach": "1.070.000"
      },
      "Adform": {
        "reach": "2.314.000"
      },
      "Verizon": {
        "reach": "2.306.000"
      },
      "Xandr": {
        "reach": "2.682.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "9.522.000"
      },
      "theTradeDesk": {
        "reach": "7.643.000"
      },
      "Adform": {
        "reach": "6.570.000"
      },
      "Verizon": {
        "reach": "6.571.000"
      },
      "Xandr": {
        "reach": "7.582.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.300.000"
      },
      "theTradeDesk": {
        "reach": "1.836.000"
      },
      "Adform": {
        "reach": "1.603.000"
      },
      "Verizon": {
        "reach": "1.603.000"
      },
      "Xandr": {
        "reach": "1.817.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.320.000"
      },
      "RMS": {
        "reach": "1.445.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "4.575.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.877.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.589.000"
      },
      "theTradeDesk": {
        "reach": "2.027.000"
      },
      "Adform": {
        "reach": "1.785.000"
      },
      "Verizon": {
        "reach": "1.705.000"
      },
      "Xandr": {
        "reach": "2.087.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "4.827.000"
      },
      "Instagram": {
        "reach": "2.475.000"
      },
      "YouTube": {
        "reach": "8.984.000"
      },
      "Snapchat": {
        "reach": "1.073.000"
      }
    }
  },
  "Lebensversicherung": {
    "cpm": "3,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.416.000"
      },
      "theTradeDesk": {
        "reach": "1.102.000"
      },
      "Adform": {
        "reach": "951.000"
      },
      "Verizon": {
        "reach": "957.000"
      },
      "Xandr": {
        "reach": "1.131.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "588.000"
      },
      "theTradeDesk": {
        "reach": "472.000"
      },
      "Adform": {
        "reach": "408.000"
      },
      "Verizon": {
        "reach": "414.000"
      },
      "Xandr": {
        "reach": "491.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.836.000"
      },
      "theTradeDesk": {
        "reach": "1.480.000"
      },
      "Adform": {
        "reach": "1.264.000"
      },
      "Verizon": {
        "reach": "1.275.000"
      },
      "Xandr": {
        "reach": "1.488.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.135.000"
      },
      "theTradeDesk": {
        "reach": "1.714.000"
      },
      "Adform": {
        "reach": "1.493.000"
      },
      "Verizon": {
        "reach": "1.460.000"
      },
      "Xandr": {
        "reach": "1.719.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "919.000"
      },
      "theTradeDesk": {
        "reach": "288.000"
      },
      "Adform": {
        "reach": "656.000"
      },
      "Verizon": {
        "reach": "637.000"
      },
      "Xandr": {
        "reach": "727.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.601.000"
      },
      "theTradeDesk": {
        "reach": "2.055.000"
      },
      "Adform": {
        "reach": "1.831.000"
      },
      "Verizon": {
        "reach": "1.860.000"
      },
      "Xandr": {
        "reach": "2.051.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "636.000"
      },
      "theTradeDesk": {
        "reach": "510.000"
      },
      "Adform": {
        "reach": "444.000"
      },
      "Verizon": {
        "reach": "431.000"
      },
      "Xandr": {
        "reach": "506.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "355.000"
      },
      "RMS": {
        "reach": "401.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.239.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "500.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "716.000"
      },
      "theTradeDesk": {
        "reach": "562.000"
      },
      "Adform": {
        "reach": "488.000"
      },
      "Verizon": {
        "reach": "504.000"
      },
      "Xandr": {
        "reach": "545.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.317.000"
      },
      "Instagram": {
        "reach": "673.000"
      },
      "YouTube": {
        "reach": "2.459.000"
      },
      "Snapchat": {
        "reach": "87.000"
      }
    }
  },
  "Berufsunfähigkeitsversicherung": {
    "cpm": "3,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.785.000"
      },
      "theTradeDesk": {
        "reach": "1.431.000"
      },
      "Adform": {
        "reach": "1.265.000"
      },
      "Verizon": {
        "reach": "1.261.000"
      },
      "Xandr": {
        "reach": "1.429.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "785.000"
      },
      "theTradeDesk": {
        "reach": "625.000"
      },
      "Adform": {
        "reach": "519.000"
      },
      "Verizon": {
        "reach": "546.000"
      },
      "Xandr": {
        "reach": "609.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.274.000"
      },
      "theTradeDesk": {
        "reach": "1.925.000"
      },
      "Adform": {
        "reach": "1.603.000"
      },
      "Verizon": {
        "reach": "1.651.000"
      },
      "Xandr": {
        "reach": "1.891.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.805.000"
      },
      "theTradeDesk": {
        "reach": "2.167.000"
      },
      "Adform": {
        "reach": "1.915.000"
      },
      "Verizon": {
        "reach": "1.926.000"
      },
      "Xandr": {
        "reach": "2.227.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.170.000"
      },
      "theTradeDesk": {
        "reach": "378.000"
      },
      "Adform": {
        "reach": "807.000"
      },
      "Verizon": {
        "reach": "808.000"
      },
      "Xandr": {
        "reach": "953.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.356.000"
      },
      "theTradeDesk": {
        "reach": "2.673.000"
      },
      "Adform": {
        "reach": "2.335.000"
      },
      "Verizon": {
        "reach": "2.288.000"
      },
      "Xandr": {
        "reach": "2.580.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "801.000"
      },
      "theTradeDesk": {
        "reach": "638.000"
      },
      "Adform": {
        "reach": "561.000"
      },
      "Verizon": {
        "reach": "572.000"
      },
      "Xandr": {
        "reach": "640.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "357.000"
      },
      "RMS": {
        "reach": "512.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.651.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "653.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "892.000"
      },
      "theTradeDesk": {
        "reach": "729.000"
      },
      "Adform": {
        "reach": "655.000"
      },
      "Verizon": {
        "reach": "616.000"
      },
      "Xandr": {
        "reach": "742.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.724.000"
      },
      "Instagram": {
        "reach": "879.000"
      },
      "YouTube": {
        "reach": "3.075.000"
      },
      "Snapchat": {
        "reach": "113.000"
      }
    }
  },
  "Private Rentenversicherung": {
    "cpm": "3,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.456.000"
      },
      "theTradeDesk": {
        "reach": "5.332.000"
      },
      "Adform": {
        "reach": "4.671.000"
      },
      "Verizon": {
        "reach": "4.654.000"
      },
      "Xandr": {
        "reach": "5.207.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.822.000"
      },
      "theTradeDesk": {
        "reach": "2.276.000"
      },
      "Adform": {
        "reach": "1.939.000"
      },
      "Verizon": {
        "reach": "2.056.000"
      },
      "Xandr": {
        "reach": "2.275.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "8.776.000"
      },
      "theTradeDesk": {
        "reach": "6.868.000"
      },
      "Adform": {
        "reach": "5.908.000"
      },
      "Verizon": {
        "reach": "5.942.000"
      },
      "Xandr": {
        "reach": "6.755.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "9.899.000"
      },
      "theTradeDesk": {
        "reach": "8.305.000"
      },
      "Adform": {
        "reach": "6.926.000"
      },
      "Verizon": {
        "reach": "7.085.000"
      },
      "Xandr": {
        "reach": "7.870.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.426.000"
      },
      "theTradeDesk": {
        "reach": "1.395.000"
      },
      "Adform": {
        "reach": "3.046.000"
      },
      "Verizon": {
        "reach": "3.078.000"
      },
      "Xandr": {
        "reach": "3.487.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "12.281.000"
      },
      "theTradeDesk": {
        "reach": "9.923.000"
      },
      "Adform": {
        "reach": "8.622.000"
      },
      "Verizon": {
        "reach": "8.661.000"
      },
      "Xandr": {
        "reach": "9.717.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.941.000"
      },
      "theTradeDesk": {
        "reach": "2.504.000"
      },
      "Adform": {
        "reach": "2.099.000"
      },
      "Verizon": {
        "reach": "2.119.000"
      },
      "Xandr": {
        "reach": "2.312.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.612.000"
      },
      "RMS": {
        "reach": "1.853.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "6.070.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.428.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.199.000"
      },
      "theTradeDesk": {
        "reach": "2.801.000"
      },
      "Adform": {
        "reach": "2.179.000"
      },
      "Verizon": {
        "reach": "2.327.000"
      },
      "Xandr": {
        "reach": "2.551.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "6.326.000"
      },
      "Instagram": {
        "reach": "3.132.000"
      },
      "YouTube": {
        "reach": "11.913.000"
      },
      "Snapchat": {
        "reach": "278.000"
      }
    }
  },
  "PKW-Halter": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "16.227.000"
      },
      "theTradeDesk": {
        "reach": "12.973.000"
      },
      "Adform": {
        "reach": "11.103.000"
      },
      "Verizon": {
        "reach": "11.421.000"
      },
      "Xandr": {
        "reach": "13.017.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "6.911.000"
      },
      "theTradeDesk": {
        "reach": "5.591.000"
      },
      "Adform": {
        "reach": "4.726.000"
      },
      "Verizon": {
        "reach": "4.839.000"
      },
      "Xandr": {
        "reach": "5.636.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "20.688.000"
      },
      "theTradeDesk": {
        "reach": "16.918.000"
      },
      "Adform": {
        "reach": "13.942.000"
      },
      "Verizon": {
        "reach": "14.936.000"
      },
      "Xandr": {
        "reach": "16.423.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "24.663.000"
      },
      "theTradeDesk": {
        "reach": "20.095.000"
      },
      "Adform": {
        "reach": "16.987.000"
      },
      "Verizon": {
        "reach": "17.199.000"
      },
      "Xandr": {
        "reach": "19.784.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "10.752.000"
      },
      "theTradeDesk": {
        "reach": "3.431.000"
      },
      "Adform": {
        "reach": "7.465.000"
      },
      "Verizon": {
        "reach": "7.475.000"
      },
      "Xandr": {
        "reach": "8.157.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "29.610.000"
      },
      "theTradeDesk": {
        "reach": "24.232.000"
      },
      "Adform": {
        "reach": "21.372.000"
      },
      "Verizon": {
        "reach": "20.704.000"
      },
      "Xandr": {
        "reach": "23.118.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "7.196.000"
      },
      "theTradeDesk": {
        "reach": "5.898.000"
      },
      "Adform": {
        "reach": "5.095.000"
      },
      "Verizon": {
        "reach": "5.060.000"
      },
      "Xandr": {
        "reach": "5.701.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.663.000"
      },
      "RMS": {
        "reach": "4.607.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "14.981.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.790.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "8.080.000"
      },
      "theTradeDesk": {
        "reach": "6.263.000"
      },
      "Adform": {
        "reach": "5.589.000"
      },
      "Verizon": {
        "reach": "5.536.000"
      },
      "Xandr": {
        "reach": "6.397.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "14.680.000"
      },
      "Instagram": {
        "reach": "7.769.000"
      },
      "YouTube": {
        "reach": "28.152.000"
      },
      "Snapchat": {
        "reach": "1.312.000"
      }
    }
  },
  "Kein PKW": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "17.709.000"
      },
      "theTradeDesk": {
        "reach": "13.911.000"
      },
      "Adform": {
        "reach": "12.179.000"
      },
      "Verizon": {
        "reach": "12.231.000"
      },
      "Xandr": {
        "reach": "14.144.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "7.661.000"
      },
      "theTradeDesk": {
        "reach": "5.834.000"
      },
      "Adform": {
        "reach": "5.175.000"
      },
      "Verizon": {
        "reach": "5.139.000"
      },
      "Xandr": {
        "reach": "6.122.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "22.630.000"
      },
      "theTradeDesk": {
        "reach": "17.739.000"
      },
      "Adform": {
        "reach": "15.510.000"
      },
      "Verizon": {
        "reach": "15.418.000"
      },
      "Xandr": {
        "reach": "17.221.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "26.932.000"
      },
      "theTradeDesk": {
        "reach": "21.250.000"
      },
      "Adform": {
        "reach": "18.772.000"
      },
      "Verizon": {
        "reach": "18.624.000"
      },
      "Xandr": {
        "reach": "21.086.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "11.417.000"
      },
      "theTradeDesk": {
        "reach": "3.602.000"
      },
      "Adform": {
        "reach": "7.908.000"
      },
      "Verizon": {
        "reach": "8.092.000"
      },
      "Xandr": {
        "reach": "9.074.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "31.669.000"
      },
      "theTradeDesk": {
        "reach": "25.300.000"
      },
      "Adform": {
        "reach": "22.076.000"
      },
      "Verizon": {
        "reach": "22.406.000"
      },
      "Xandr": {
        "reach": "25.312.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "7.874.000"
      },
      "theTradeDesk": {
        "reach": "6.174.000"
      },
      "Adform": {
        "reach": "5.528.000"
      },
      "Verizon": {
        "reach": "5.477.000"
      },
      "Xandr": {
        "reach": "6.223.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.999.000"
      },
      "RMS": {
        "reach": "4.747.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "15.887.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "6.167.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "8.552.000"
      },
      "theTradeDesk": {
        "reach": "6.795.000"
      },
      "Adform": {
        "reach": "5.758.000"
      },
      "Verizon": {
        "reach": "6.083.000"
      },
      "Xandr": {
        "reach": "6.916.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "16.453.000"
      },
      "Instagram": {
        "reach": "8.531.000"
      },
      "YouTube": {
        "reach": "31.011.000"
      },
      "Snapchat": {
        "reach": "2.160.000"
      }
    }
  },
  "Klassisch (Benzin, Diesel)": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "15.233.000"
      },
      "theTradeDesk": {
        "reach": "12.598.000"
      },
      "Adform": {
        "reach": "11.191.000"
      },
      "Verizon": {
        "reach": "10.990.000"
      },
      "Xandr": {
        "reach": "12.613.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "6.742.000"
      },
      "theTradeDesk": {
        "reach": "5.542.000"
      },
      "Adform": {
        "reach": "4.643.000"
      },
      "Verizon": {
        "reach": "4.672.000"
      },
      "Xandr": {
        "reach": "5.262.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "20.872.000"
      },
      "theTradeDesk": {
        "reach": "15.690.000"
      },
      "Adform": {
        "reach": "14.431.000"
      },
      "Verizon": {
        "reach": "14.005.000"
      },
      "Xandr": {
        "reach": "16.365.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "22.603.000"
      },
      "theTradeDesk": {
        "reach": "18.771.000"
      },
      "Adform": {
        "reach": "16.444.000"
      },
      "Verizon": {
        "reach": "17.058.000"
      },
      "Xandr": {
        "reach": "19.344.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "10.558.000"
      },
      "theTradeDesk": {
        "reach": "3.192.000"
      },
      "Adform": {
        "reach": "7.005.000"
      },
      "Verizon": {
        "reach": "7.316.000"
      },
      "Xandr": {
        "reach": "8.011.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "28.473.000"
      },
      "theTradeDesk": {
        "reach": "22.449.000"
      },
      "Adform": {
        "reach": "20.461.000"
      },
      "Verizon": {
        "reach": "19.715.000"
      },
      "Xandr": {
        "reach": "23.312.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "7.088.000"
      },
      "theTradeDesk": {
        "reach": "5.731.000"
      },
      "Adform": {
        "reach": "5.007.000"
      },
      "Verizon": {
        "reach": "5.075.000"
      },
      "Xandr": {
        "reach": "5.509.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.793.000"
      },
      "RMS": {
        "reach": "4.541.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "14.435.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.821.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "7.728.000"
      },
      "theTradeDesk": {
        "reach": "6.268.000"
      },
      "Adform": {
        "reach": "5.666.000"
      },
      "Verizon": {
        "reach": "5.419.000"
      },
      "Xandr": {
        "reach": "6.306.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "14.529.000"
      },
      "Instagram": {
        "reach": "7.599.000"
      },
      "YouTube": {
        "reach": "26.988.000"
      },
      "Snapchat": {
        "reach": "3.376.000"
      }
    }
  },
  "Hybrid, Elektro": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "375.000"
      },
      "theTradeDesk": {
        "reach": "310.000"
      },
      "Adform": {
        "reach": "272.000"
      },
      "Verizon": {
        "reach": "272.000"
      },
      "Xandr": {
        "reach": "315.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "165.000"
      },
      "theTradeDesk": {
        "reach": "136.000"
      },
      "Adform": {
        "reach": "120.000"
      },
      "Verizon": {
        "reach": "118.000"
      },
      "Xandr": {
        "reach": "134.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "502.000"
      },
      "theTradeDesk": {
        "reach": "387.000"
      },
      "Adform": {
        "reach": "355.000"
      },
      "Verizon": {
        "reach": "345.000"
      },
      "Xandr": {
        "reach": "393.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "597.000"
      },
      "theTradeDesk": {
        "reach": "464.000"
      },
      "Adform": {
        "reach": "418.000"
      },
      "Verizon": {
        "reach": "416.000"
      },
      "Xandr": {
        "reach": "460.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "249.000"
      },
      "theTradeDesk": {
        "reach": "81.000"
      },
      "Adform": {
        "reach": "177.000"
      },
      "Verizon": {
        "reach": "176.000"
      },
      "Xandr": {
        "reach": "203.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "711.000"
      },
      "theTradeDesk": {
        "reach": "571.000"
      },
      "Adform": {
        "reach": "479.000"
      },
      "Verizon": {
        "reach": "495.000"
      },
      "Xandr": {
        "reach": "571.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "172.000"
      },
      "theTradeDesk": {
        "reach": "136.000"
      },
      "Adform": {
        "reach": "125.000"
      },
      "Verizon": {
        "reach": "126.000"
      },
      "Xandr": {
        "reach": "141.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "95.000"
      },
      "RMS": {
        "reach": "115.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "326.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "141.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "196.000"
      },
      "theTradeDesk": {
        "reach": "155.000"
      },
      "Adform": {
        "reach": "135.000"
      },
      "Verizon": {
        "reach": "135.000"
      },
      "Xandr": {
        "reach": "154.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "355.000"
      },
      "Instagram": {
        "reach": "185.000"
      },
      "YouTube": {
        "reach": "690.000"
      },
      "Snapchat": {
        "reach": "33.000"
      }
    }
  },
  "überdurchschnittlicher Anteil Gebrauchtwagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.118.000"
      },
      "theTradeDesk": {
        "reach": "904.000"
      },
      "Adform": {
        "reach": "766.000"
      },
      "Verizon": {
        "reach": "788.000"
      },
      "Xandr": {
        "reach": "877.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "478.000"
      },
      "theTradeDesk": {
        "reach": "374.000"
      },
      "Adform": {
        "reach": "332.000"
      },
      "Verizon": {
        "reach": "336.000"
      },
      "Xandr": {
        "reach": "385.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.436.000"
      },
      "theTradeDesk": {
        "reach": "1.162.000"
      },
      "Adform": {
        "reach": "1.032.000"
      },
      "Verizon": {
        "reach": "995.000"
      },
      "Xandr": {
        "reach": "1.172.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.707.000"
      },
      "theTradeDesk": {
        "reach": "1.358.000"
      },
      "Adform": {
        "reach": "1.233.000"
      },
      "Verizon": {
        "reach": "1.208.000"
      },
      "Xandr": {
        "reach": "1.408.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "698.000"
      },
      "theTradeDesk": {
        "reach": "225.000"
      },
      "Adform": {
        "reach": "532.000"
      },
      "Verizon": {
        "reach": "504.000"
      },
      "Xandr": {
        "reach": "590.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.038.000"
      },
      "theTradeDesk": {
        "reach": "1.642.000"
      },
      "Adform": {
        "reach": "1.456.000"
      },
      "Verizon": {
        "reach": "1.453.000"
      },
      "Xandr": {
        "reach": "1.674.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "505.000"
      },
      "theTradeDesk": {
        "reach": "400.000"
      },
      "Adform": {
        "reach": "347.000"
      },
      "Verizon": {
        "reach": "347.000"
      },
      "Xandr": {
        "reach": "400.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "276.000"
      },
      "RMS": {
        "reach": "308.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.016.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "395.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "544.000"
      },
      "theTradeDesk": {
        "reach": "444.000"
      },
      "Adform": {
        "reach": "402.000"
      },
      "Verizon": {
        "reach": "389.000"
      },
      "Xandr": {
        "reach": "465.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.025.000"
      },
      "Instagram": {
        "reach": "538.000"
      },
      "YouTube": {
        "reach": "1.945.000"
      },
      "Snapchat": {
        "reach": "239.000"
      }
    }
  },
  "überdurchschnittlicher Anteil Neuwagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "109.000"
      },
      "theTradeDesk": {
        "reach": "96.000"
      },
      "Adform": {
        "reach": "82.000"
      },
      "Verizon": {
        "reach": "86.000"
      },
      "Xandr": {
        "reach": "94.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "49.000"
      },
      "theTradeDesk": {
        "reach": "40.000"
      },
      "Adform": {
        "reach": "35.000"
      },
      "Verizon": {
        "reach": "34.000"
      },
      "Xandr": {
        "reach": "40.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "148.000"
      },
      "theTradeDesk": {
        "reach": "120.000"
      },
      "Adform": {
        "reach": "105.000"
      },
      "Verizon": {
        "reach": "103.000"
      },
      "Xandr": {
        "reach": "121.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "179.000"
      },
      "theTradeDesk": {
        "reach": "142.000"
      },
      "Adform": {
        "reach": "125.000"
      },
      "Verizon": {
        "reach": "124.000"
      },
      "Xandr": {
        "reach": "142.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "78.000"
      },
      "theTradeDesk": {
        "reach": "23.000"
      },
      "Adform": {
        "reach": "52.000"
      },
      "Verizon": {
        "reach": "52.000"
      },
      "Xandr": {
        "reach": "62.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "215.000"
      },
      "theTradeDesk": {
        "reach": "170.000"
      },
      "Adform": {
        "reach": "151.000"
      },
      "Verizon": {
        "reach": "146.000"
      },
      "Xandr": {
        "reach": "171.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "52.000"
      },
      "theTradeDesk": {
        "reach": "43.000"
      },
      "Adform": {
        "reach": "35.000"
      },
      "Verizon": {
        "reach": "38.000"
      },
      "Xandr": {
        "reach": "43.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "29.000"
      },
      "RMS": {
        "reach": "33.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "104.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "43.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "59.000"
      },
      "theTradeDesk": {
        "reach": "45.000"
      },
      "Adform": {
        "reach": "41.000"
      },
      "Verizon": {
        "reach": "41.000"
      },
      "Xandr": {
        "reach": "46.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "108.000"
      },
      "Instagram": {
        "reach": "56.000"
      },
      "YouTube": {
        "reach": "197.000"
      },
      "Snapchat": {
        "reach": "10.000"
      }
    }
  },
  "Audi-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.137.000"
      },
      "theTradeDesk": {
        "reach": "918.000"
      },
      "Adform": {
        "reach": "818.000"
      },
      "Verizon": {
        "reach": "796.000"
      },
      "Xandr": {
        "reach": "889.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "487.000"
      },
      "theTradeDesk": {
        "reach": "384.000"
      },
      "Adform": {
        "reach": "347.000"
      },
      "Verizon": {
        "reach": "341.000"
      },
      "Xandr": {
        "reach": "392.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.411.000"
      },
      "theTradeDesk": {
        "reach": "1.145.000"
      },
      "Adform": {
        "reach": "1.028.000"
      },
      "Verizon": {
        "reach": "988.000"
      },
      "Xandr": {
        "reach": "1.158.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.744.000"
      },
      "theTradeDesk": {
        "reach": "1.365.000"
      },
      "Adform": {
        "reach": "1.239.000"
      },
      "Verizon": {
        "reach": "1.245.000"
      },
      "Xandr": {
        "reach": "1.330.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "733.000"
      },
      "theTradeDesk": {
        "reach": "236.000"
      },
      "Adform": {
        "reach": "530.000"
      },
      "Verizon": {
        "reach": "522.000"
      },
      "Xandr": {
        "reach": "590.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.051.000"
      },
      "theTradeDesk": {
        "reach": "1.666.000"
      },
      "Adform": {
        "reach": "1.452.000"
      },
      "Verizon": {
        "reach": "1.442.000"
      },
      "Xandr": {
        "reach": "1.700.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "506.000"
      },
      "theTradeDesk": {
        "reach": "394.000"
      },
      "Adform": {
        "reach": "347.000"
      },
      "Verizon": {
        "reach": "348.000"
      },
      "Xandr": {
        "reach": "393.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "226.000"
      },
      "RMS": {
        "reach": "323.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.022.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "400.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "563.000"
      },
      "theTradeDesk": {
        "reach": "457.000"
      },
      "Adform": {
        "reach": "402.000"
      },
      "Verizon": {
        "reach": "389.000"
      },
      "Xandr": {
        "reach": "460.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.066.000"
      },
      "Instagram": {
        "reach": "546.000"
      },
      "YouTube": {
        "reach": "1.948.000"
      },
      "Snapchat": {
        "reach": "70.000"
      }
    }
  },
  "BMW-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.334.000"
      },
      "theTradeDesk": {
        "reach": "1.049.000"
      },
      "Adform": {
        "reach": "911.000"
      },
      "Verizon": {
        "reach": "906.000"
      },
      "Xandr": {
        "reach": "1.075.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "578.000"
      },
      "theTradeDesk": {
        "reach": "449.000"
      },
      "Adform": {
        "reach": "394.000"
      },
      "Verizon": {
        "reach": "384.000"
      },
      "Xandr": {
        "reach": "442.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.725.000"
      },
      "theTradeDesk": {
        "reach": "1.369.000"
      },
      "Adform": {
        "reach": "1.199.000"
      },
      "Verizon": {
        "reach": "1.178.000"
      },
      "Xandr": {
        "reach": "1.348.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.001.000"
      },
      "theTradeDesk": {
        "reach": "1.625.000"
      },
      "Adform": {
        "reach": "1.428.000"
      },
      "Verizon": {
        "reach": "1.383.000"
      },
      "Xandr": {
        "reach": "1.651.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "867.000"
      },
      "theTradeDesk": {
        "reach": "284.000"
      },
      "Adform": {
        "reach": "591.000"
      },
      "Verizon": {
        "reach": "608.000"
      },
      "Xandr": {
        "reach": "670.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.445.000"
      },
      "theTradeDesk": {
        "reach": "1.876.000"
      },
      "Adform": {
        "reach": "1.711.000"
      },
      "Verizon": {
        "reach": "1.660.000"
      },
      "Xandr": {
        "reach": "1.985.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "585.000"
      },
      "theTradeDesk": {
        "reach": "467.000"
      },
      "Adform": {
        "reach": "393.000"
      },
      "Verizon": {
        "reach": "436.000"
      },
      "Xandr": {
        "reach": "468.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "264.000"
      },
      "RMS": {
        "reach": "363.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.192.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "477.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "647.000"
      },
      "theTradeDesk": {
        "reach": "533.000"
      },
      "Adform": {
        "reach": "445.000"
      },
      "Verizon": {
        "reach": "475.000"
      },
      "Xandr": {
        "reach": "537.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.240.000"
      },
      "Instagram": {
        "reach": "620.000"
      },
      "YouTube": {
        "reach": "2.337.000"
      },
      "Snapchat": {
        "reach": "81.000"
      }
    }
  },
  "Fiat (einschl. Alfa Romeo, Lancia)-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "541.000"
      },
      "theTradeDesk": {
        "reach": "446.000"
      },
      "Adform": {
        "reach": "382.000"
      },
      "Verizon": {
        "reach": "373.000"
      },
      "Xandr": {
        "reach": "420.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "234.000"
      },
      "theTradeDesk": {
        "reach": "185.000"
      },
      "Adform": {
        "reach": "162.000"
      },
      "Verizon": {
        "reach": "168.000"
      },
      "Xandr": {
        "reach": "188.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "676.000"
      },
      "theTradeDesk": {
        "reach": "575.000"
      },
      "Adform": {
        "reach": "473.000"
      },
      "Verizon": {
        "reach": "466.000"
      },
      "Xandr": {
        "reach": "558.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "824.000"
      },
      "theTradeDesk": {
        "reach": "650.000"
      },
      "Adform": {
        "reach": "572.000"
      },
      "Verizon": {
        "reach": "557.000"
      },
      "Xandr": {
        "reach": "673.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "357.000"
      },
      "theTradeDesk": {
        "reach": "115.000"
      },
      "Adform": {
        "reach": "244.000"
      },
      "Verizon": {
        "reach": "240.000"
      },
      "Xandr": {
        "reach": "281.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.005.000"
      },
      "theTradeDesk": {
        "reach": "800.000"
      },
      "Adform": {
        "reach": "694.000"
      },
      "Verizon": {
        "reach": "692.000"
      },
      "Xandr": {
        "reach": "829.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "247.000"
      },
      "theTradeDesk": {
        "reach": "197.000"
      },
      "Adform": {
        "reach": "171.000"
      },
      "Verizon": {
        "reach": "172.000"
      },
      "Xandr": {
        "reach": "197.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "103.000"
      },
      "RMS": {
        "reach": "149.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "505.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "202.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "266.000"
      },
      "theTradeDesk": {
        "reach": "223.000"
      },
      "Adform": {
        "reach": "187.000"
      },
      "Verizon": {
        "reach": "188.000"
      },
      "Xandr": {
        "reach": "220.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "511.000"
      },
      "Instagram": {
        "reach": "261.000"
      },
      "YouTube": {
        "reach": "922.000"
      },
      "Snapchat": {
        "reach": "35.000"
      }
    }
  },
  "Ford-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.154.000"
      },
      "theTradeDesk": {
        "reach": "927.000"
      },
      "Adform": {
        "reach": "837.000"
      },
      "Verizon": {
        "reach": "822.000"
      },
      "Xandr": {
        "reach": "912.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "493.000"
      },
      "theTradeDesk": {
        "reach": "405.000"
      },
      "Adform": {
        "reach": "345.000"
      },
      "Verizon": {
        "reach": "352.000"
      },
      "Xandr": {
        "reach": "386.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.470.000"
      },
      "theTradeDesk": {
        "reach": "1.188.000"
      },
      "Adform": {
        "reach": "1.024.000"
      },
      "Verizon": {
        "reach": "1.029.000"
      },
      "Xandr": {
        "reach": "1.185.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.738.000"
      },
      "theTradeDesk": {
        "reach": "1.441.000"
      },
      "Adform": {
        "reach": "1.234.000"
      },
      "Verizon": {
        "reach": "1.227.000"
      },
      "Xandr": {
        "reach": "1.408.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "772.000"
      },
      "theTradeDesk": {
        "reach": "242.000"
      },
      "Adform": {
        "reach": "546.000"
      },
      "Verizon": {
        "reach": "540.000"
      },
      "Xandr": {
        "reach": "596.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.058.000"
      },
      "theTradeDesk": {
        "reach": "1.685.000"
      },
      "Adform": {
        "reach": "1.481.000"
      },
      "Verizon": {
        "reach": "1.494.000"
      },
      "Xandr": {
        "reach": "1.697.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "502.000"
      },
      "theTradeDesk": {
        "reach": "407.000"
      },
      "Adform": {
        "reach": "363.000"
      },
      "Verizon": {
        "reach": "360.000"
      },
      "Xandr": {
        "reach": "417.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "226.000"
      },
      "RMS": {
        "reach": "313.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.010.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "432.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "566.000"
      },
      "theTradeDesk": {
        "reach": "459.000"
      },
      "Adform": {
        "reach": "409.000"
      },
      "Verizon": {
        "reach": "393.000"
      },
      "Xandr": {
        "reach": "458.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.089.000"
      },
      "Instagram": {
        "reach": "561.000"
      },
      "YouTube": {
        "reach": "2.032.000"
      },
      "Snapchat": {
        "reach": "74.000"
      }
    }
  },
  "Mazda-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "319.000"
      },
      "theTradeDesk": {
        "reach": "251.000"
      },
      "Adform": {
        "reach": "215.000"
      },
      "Verizon": {
        "reach": "218.000"
      },
      "Xandr": {
        "reach": "240.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "138.000"
      },
      "theTradeDesk": {
        "reach": "106.000"
      },
      "Adform": {
        "reach": "93.000"
      },
      "Verizon": {
        "reach": "94.000"
      },
      "Xandr": {
        "reach": "107.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "401.000"
      },
      "theTradeDesk": {
        "reach": "319.000"
      },
      "Adform": {
        "reach": "281.000"
      },
      "Verizon": {
        "reach": "278.000"
      },
      "Xandr": {
        "reach": "314.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "484.000"
      },
      "theTradeDesk": {
        "reach": "369.000"
      },
      "Adform": {
        "reach": "323.000"
      },
      "Verizon": {
        "reach": "339.000"
      },
      "Xandr": {
        "reach": "386.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "206.000"
      },
      "theTradeDesk": {
        "reach": "65.000"
      },
      "Adform": {
        "reach": "141.000"
      },
      "Verizon": {
        "reach": "141.000"
      },
      "Xandr": {
        "reach": "165.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "575.000"
      },
      "theTradeDesk": {
        "reach": "448.000"
      },
      "Adform": {
        "reach": "402.000"
      },
      "Verizon": {
        "reach": "403.000"
      },
      "Xandr": {
        "reach": "470.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "140.000"
      },
      "theTradeDesk": {
        "reach": "113.000"
      },
      "Adform": {
        "reach": "98.000"
      },
      "Verizon": {
        "reach": "100.000"
      },
      "Xandr": {
        "reach": "112.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "61.000"
      },
      "RMS": {
        "reach": "89.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "289.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "112.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "150.000"
      },
      "theTradeDesk": {
        "reach": "125.000"
      },
      "Adform": {
        "reach": "107.000"
      },
      "Verizon": {
        "reach": "111.000"
      },
      "Xandr": {
        "reach": "124.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "282.000"
      },
      "Instagram": {
        "reach": "150.000"
      },
      "YouTube": {
        "reach": "542.000"
      },
      "Snapchat": {
        "reach": "19.000"
      }
    }
  },
  "Mercedes-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.775.000"
      },
      "theTradeDesk": {
        "reach": "1.429.000"
      },
      "Adform": {
        "reach": "1.202.000"
      },
      "Verizon": {
        "reach": "1.241.000"
      },
      "Xandr": {
        "reach": "1.380.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "773.000"
      },
      "theTradeDesk": {
        "reach": "612.000"
      },
      "Adform": {
        "reach": "534.000"
      },
      "Verizon": {
        "reach": "540.000"
      },
      "Xandr": {
        "reach": "605.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.343.000"
      },
      "theTradeDesk": {
        "reach": "1.857.000"
      },
      "Adform": {
        "reach": "1.635.000"
      },
      "Verizon": {
        "reach": "1.637.000"
      },
      "Xandr": {
        "reach": "1.824.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.759.000"
      },
      "theTradeDesk": {
        "reach": "2.168.000"
      },
      "Adform": {
        "reach": "1.903.000"
      },
      "Verizon": {
        "reach": "1.896.000"
      },
      "Xandr": {
        "reach": "2.134.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.170.000"
      },
      "theTradeDesk": {
        "reach": "371.000"
      },
      "Adform": {
        "reach": "818.000"
      },
      "Verizon": {
        "reach": "859.000"
      },
      "Xandr": {
        "reach": "958.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.264.000"
      },
      "theTradeDesk": {
        "reach": "2.650.000"
      },
      "Adform": {
        "reach": "2.293.000"
      },
      "Verizon": {
        "reach": "2.236.000"
      },
      "Xandr": {
        "reach": "2.667.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "814.000"
      },
      "theTradeDesk": {
        "reach": "652.000"
      },
      "Adform": {
        "reach": "567.000"
      },
      "Verizon": {
        "reach": "550.000"
      },
      "Xandr": {
        "reach": "626.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "358.000"
      },
      "RMS": {
        "reach": "504.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.681.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "651.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "902.000"
      },
      "theTradeDesk": {
        "reach": "701.000"
      },
      "Adform": {
        "reach": "604.000"
      },
      "Verizon": {
        "reach": "612.000"
      },
      "Xandr": {
        "reach": "720.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.670.000"
      },
      "Instagram": {
        "reach": "871.000"
      },
      "YouTube": {
        "reach": "3.079.000"
      },
      "Snapchat": {
        "reach": "111.000"
      }
    }
  },
  "Nissan-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "202.000"
      },
      "theTradeDesk": {
        "reach": "163.000"
      },
      "Adform": {
        "reach": "145.000"
      },
      "Verizon": {
        "reach": "141.000"
      },
      "Xandr": {
        "reach": "160.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "88.000"
      },
      "theTradeDesk": {
        "reach": "67.000"
      },
      "Adform": {
        "reach": "60.000"
      },
      "Verizon": {
        "reach": "61.000"
      },
      "Xandr": {
        "reach": "72.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "256.000"
      },
      "theTradeDesk": {
        "reach": "204.000"
      },
      "Adform": {
        "reach": "181.000"
      },
      "Verizon": {
        "reach": "182.000"
      },
      "Xandr": {
        "reach": "211.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "313.000"
      },
      "theTradeDesk": {
        "reach": "244.000"
      },
      "Adform": {
        "reach": "212.000"
      },
      "Verizon": {
        "reach": "214.000"
      },
      "Xandr": {
        "reach": "248.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "134.000"
      },
      "theTradeDesk": {
        "reach": "41.000"
      },
      "Adform": {
        "reach": "89.000"
      },
      "Verizon": {
        "reach": "90.000"
      },
      "Xandr": {
        "reach": "103.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "378.000"
      },
      "theTradeDesk": {
        "reach": "285.000"
      },
      "Adform": {
        "reach": "258.000"
      },
      "Verizon": {
        "reach": "262.000"
      },
      "Xandr": {
        "reach": "300.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "86.000"
      },
      "theTradeDesk": {
        "reach": "71.000"
      },
      "Adform": {
        "reach": "65.000"
      },
      "Verizon": {
        "reach": "63.000"
      },
      "Xandr": {
        "reach": "76.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "41.000"
      },
      "RMS": {
        "reach": "57.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "180.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "75.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "95.000"
      },
      "theTradeDesk": {
        "reach": "83.000"
      },
      "Adform": {
        "reach": "68.000"
      },
      "Verizon": {
        "reach": "74.000"
      },
      "Xandr": {
        "reach": "82.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "187.000"
      },
      "Instagram": {
        "reach": "98.000"
      },
      "YouTube": {
        "reach": "337.000"
      },
      "Snapchat": {
        "reach": "12.000"
      }
    }
  },
  "Opel-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.716.000"
      },
      "theTradeDesk": {
        "reach": "1.382.000"
      },
      "Adform": {
        "reach": "1.191.000"
      },
      "Verizon": {
        "reach": "1.221.000"
      },
      "Xandr": {
        "reach": "1.382.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "738.000"
      },
      "theTradeDesk": {
        "reach": "593.000"
      },
      "Adform": {
        "reach": "518.000"
      },
      "Verizon": {
        "reach": "537.000"
      },
      "Xandr": {
        "reach": "593.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.297.000"
      },
      "theTradeDesk": {
        "reach": "1.734.000"
      },
      "Adform": {
        "reach": "1.586.000"
      },
      "Verizon": {
        "reach": "1.584.000"
      },
      "Xandr": {
        "reach": "1.812.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.553.000"
      },
      "theTradeDesk": {
        "reach": "2.143.000"
      },
      "Adform": {
        "reach": "1.866.000"
      },
      "Verizon": {
        "reach": "1.852.000"
      },
      "Xandr": {
        "reach": "2.205.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.137.000"
      },
      "theTradeDesk": {
        "reach": "369.000"
      },
      "Adform": {
        "reach": "802.000"
      },
      "Verizon": {
        "reach": "803.000"
      },
      "Xandr": {
        "reach": "876.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.209.000"
      },
      "theTradeDesk": {
        "reach": "2.604.000"
      },
      "Adform": {
        "reach": "2.297.000"
      },
      "Verizon": {
        "reach": "2.212.000"
      },
      "Xandr": {
        "reach": "2.538.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "797.000"
      },
      "theTradeDesk": {
        "reach": "615.000"
      },
      "Adform": {
        "reach": "547.000"
      },
      "Verizon": {
        "reach": "555.000"
      },
      "Xandr": {
        "reach": "644.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "341.000"
      },
      "RMS": {
        "reach": "486.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.577.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "645.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "872.000"
      },
      "theTradeDesk": {
        "reach": "687.000"
      },
      "Adform": {
        "reach": "603.000"
      },
      "Verizon": {
        "reach": "606.000"
      },
      "Xandr": {
        "reach": "696.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.630.000"
      },
      "Instagram": {
        "reach": "842.000"
      },
      "YouTube": {
        "reach": "2.963.000"
      },
      "Snapchat": {
        "reach": "109.000"
      }
    }
  },
  "Peugeot (einschl. Citroën)-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "678.000"
      },
      "theTradeDesk": {
        "reach": "558.000"
      },
      "Adform": {
        "reach": "475.000"
      },
      "Verizon": {
        "reach": "492.000"
      },
      "Xandr": {
        "reach": "552.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "300.000"
      },
      "theTradeDesk": {
        "reach": "234.000"
      },
      "Adform": {
        "reach": "213.000"
      },
      "Verizon": {
        "reach": "209.000"
      },
      "Xandr": {
        "reach": "240.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "891.000"
      },
      "theTradeDesk": {
        "reach": "730.000"
      },
      "Adform": {
        "reach": "621.000"
      },
      "Verizon": {
        "reach": "636.000"
      },
      "Xandr": {
        "reach": "714.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.063.000"
      },
      "theTradeDesk": {
        "reach": "836.000"
      },
      "Adform": {
        "reach": "719.000"
      },
      "Verizon": {
        "reach": "753.000"
      },
      "Xandr": {
        "reach": "841.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "442.000"
      },
      "theTradeDesk": {
        "reach": "144.000"
      },
      "Adform": {
        "reach": "317.000"
      },
      "Verizon": {
        "reach": "316.000"
      },
      "Xandr": {
        "reach": "361.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.248.000"
      },
      "theTradeDesk": {
        "reach": "991.000"
      },
      "Adform": {
        "reach": "909.000"
      },
      "Verizon": {
        "reach": "902.000"
      },
      "Xandr": {
        "reach": "1.006.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "318.000"
      },
      "theTradeDesk": {
        "reach": "243.000"
      },
      "Adform": {
        "reach": "216.000"
      },
      "Verizon": {
        "reach": "215.000"
      },
      "Xandr": {
        "reach": "250.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "138.000"
      },
      "RMS": {
        "reach": "195.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "617.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "254.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "343.000"
      },
      "theTradeDesk": {
        "reach": "279.000"
      },
      "Adform": {
        "reach": "237.000"
      },
      "Verizon": {
        "reach": "248.000"
      },
      "Xandr": {
        "reach": "285.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "655.000"
      },
      "Instagram": {
        "reach": "327.000"
      },
      "YouTube": {
        "reach": "1.164.000"
      },
      "Snapchat": {
        "reach": "44.000"
      }
    }
  },
  "Renault-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "725.000"
      },
      "theTradeDesk": {
        "reach": "579.000"
      },
      "Adform": {
        "reach": "512.000"
      },
      "Verizon": {
        "reach": "493.000"
      },
      "Xandr": {
        "reach": "587.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "305.000"
      },
      "theTradeDesk": {
        "reach": "241.000"
      },
      "Adform": {
        "reach": "213.000"
      },
      "Verizon": {
        "reach": "222.000"
      },
      "Xandr": {
        "reach": "243.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "896.000"
      },
      "theTradeDesk": {
        "reach": "753.000"
      },
      "Adform": {
        "reach": "641.000"
      },
      "Verizon": {
        "reach": "646.000"
      },
      "Xandr": {
        "reach": "727.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.132.000"
      },
      "theTradeDesk": {
        "reach": "865.000"
      },
      "Adform": {
        "reach": "765.000"
      },
      "Verizon": {
        "reach": "762.000"
      },
      "Xandr": {
        "reach": "866.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "466.000"
      },
      "theTradeDesk": {
        "reach": "148.000"
      },
      "Adform": {
        "reach": "328.000"
      },
      "Verizon": {
        "reach": "323.000"
      },
      "Xandr": {
        "reach": "371.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.309.000"
      },
      "theTradeDesk": {
        "reach": "1.051.000"
      },
      "Adform": {
        "reach": "906.000"
      },
      "Verizon": {
        "reach": "918.000"
      },
      "Xandr": {
        "reach": "1.057.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "327.000"
      },
      "theTradeDesk": {
        "reach": "262.000"
      },
      "Adform": {
        "reach": "228.000"
      },
      "Verizon": {
        "reach": "226.000"
      },
      "Xandr": {
        "reach": "255.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "141.000"
      },
      "RMS": {
        "reach": "203.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "672.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "253.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "348.000"
      },
      "theTradeDesk": {
        "reach": "291.000"
      },
      "Adform": {
        "reach": "250.000"
      },
      "Verizon": {
        "reach": "257.000"
      },
      "Xandr": {
        "reach": "282.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "649.000"
      },
      "Instagram": {
        "reach": "342.000"
      },
      "YouTube": {
        "reach": "1.340.000"
      },
      "Snapchat": {
        "reach": "46.000"
      }
    }
  },
  "Sonstige asiatische PKW-Marken": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.893.000"
      },
      "theTradeDesk": {
        "reach": "1.483.000"
      },
      "Adform": {
        "reach": "1.288.000"
      },
      "Verizon": {
        "reach": "1.323.000"
      },
      "Xandr": {
        "reach": "1.471.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "813.000"
      },
      "theTradeDesk": {
        "reach": "627.000"
      },
      "Adform": {
        "reach": "537.000"
      },
      "Verizon": {
        "reach": "568.000"
      },
      "Xandr": {
        "reach": "651.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.402.000"
      },
      "theTradeDesk": {
        "reach": "1.878.000"
      },
      "Adform": {
        "reach": "1.663.000"
      },
      "Verizon": {
        "reach": "1.658.000"
      },
      "Xandr": {
        "reach": "1.834.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.906.000"
      },
      "theTradeDesk": {
        "reach": "2.247.000"
      },
      "Adform": {
        "reach": "1.886.000"
      },
      "Verizon": {
        "reach": "1.926.000"
      },
      "Xandr": {
        "reach": "2.247.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.216.000"
      },
      "theTradeDesk": {
        "reach": "393.000"
      },
      "Adform": {
        "reach": "888.000"
      },
      "Verizon": {
        "reach": "858.000"
      },
      "Xandr": {
        "reach": "951.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.303.000"
      },
      "theTradeDesk": {
        "reach": "2.722.000"
      },
      "Adform": {
        "reach": "2.372.000"
      },
      "Verizon": {
        "reach": "2.438.000"
      },
      "Xandr": {
        "reach": "2.654.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "857.000"
      },
      "theTradeDesk": {
        "reach": "667.000"
      },
      "Adform": {
        "reach": "576.000"
      },
      "Verizon": {
        "reach": "586.000"
      },
      "Xandr": {
        "reach": "675.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "384.000"
      },
      "RMS": {
        "reach": "521.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.636.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "653.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "892.000"
      },
      "theTradeDesk": {
        "reach": "750.000"
      },
      "Adform": {
        "reach": "629.000"
      },
      "Verizon": {
        "reach": "623.000"
      },
      "Xandr": {
        "reach": "741.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.719.000"
      },
      "Instagram": {
        "reach": "893.000"
      },
      "YouTube": {
        "reach": "3.224.000"
      },
      "Snapchat": {
        "reach": "116.000"
      }
    }
  },
  "Toyota (einschl. Lexus)-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "522.000"
      },
      "theTradeDesk": {
        "reach": "434.000"
      },
      "Adform": {
        "reach": "370.000"
      },
      "Verizon": {
        "reach": "364.000"
      },
      "Xandr": {
        "reach": "427.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "236.000"
      },
      "theTradeDesk": {
        "reach": "181.000"
      },
      "Adform": {
        "reach": "159.000"
      },
      "Verizon": {
        "reach": "156.000"
      },
      "Xandr": {
        "reach": "180.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "701.000"
      },
      "theTradeDesk": {
        "reach": "529.000"
      },
      "Adform": {
        "reach": "459.000"
      },
      "Verizon": {
        "reach": "476.000"
      },
      "Xandr": {
        "reach": "530.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "832.000"
      },
      "theTradeDesk": {
        "reach": "633.000"
      },
      "Adform": {
        "reach": "554.000"
      },
      "Verizon": {
        "reach": "589.000"
      },
      "Xandr": {
        "reach": "645.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "342.000"
      },
      "theTradeDesk": {
        "reach": "112.000"
      },
      "Adform": {
        "reach": "248.000"
      },
      "Verizon": {
        "reach": "243.000"
      },
      "Xandr": {
        "reach": "282.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.002.000"
      },
      "theTradeDesk": {
        "reach": "806.000"
      },
      "Adform": {
        "reach": "658.000"
      },
      "Verizon": {
        "reach": "700.000"
      },
      "Xandr": {
        "reach": "789.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "247.000"
      },
      "theTradeDesk": {
        "reach": "189.000"
      },
      "Adform": {
        "reach": "166.000"
      },
      "Verizon": {
        "reach": "171.000"
      },
      "Xandr": {
        "reach": "187.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "105.000"
      },
      "RMS": {
        "reach": "147.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "484.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "199.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "259.000"
      },
      "theTradeDesk": {
        "reach": "210.000"
      },
      "Adform": {
        "reach": "185.000"
      },
      "Verizon": {
        "reach": "187.000"
      },
      "Xandr": {
        "reach": "210.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "490.000"
      },
      "Instagram": {
        "reach": "257.000"
      },
      "YouTube": {
        "reach": "880.000"
      },
      "Snapchat": {
        "reach": "34.000"
      }
    }
  },
  "VW-Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.716.000"
      },
      "theTradeDesk": {
        "reach": "2.947.000"
      },
      "Adform": {
        "reach": "2.537.000"
      },
      "Verizon": {
        "reach": "2.615.000"
      },
      "Xandr": {
        "reach": "2.896.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.560.000"
      },
      "theTradeDesk": {
        "reach": "1.249.000"
      },
      "Adform": {
        "reach": "1.110.000"
      },
      "Verizon": {
        "reach": "1.120.000"
      },
      "Xandr": {
        "reach": "1.247.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.653.000"
      },
      "theTradeDesk": {
        "reach": "3.683.000"
      },
      "Adform": {
        "reach": "3.233.000"
      },
      "Verizon": {
        "reach": "3.212.000"
      },
      "Xandr": {
        "reach": "3.825.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.662.000"
      },
      "theTradeDesk": {
        "reach": "4.611.000"
      },
      "Adform": {
        "reach": "3.869.000"
      },
      "Verizon": {
        "reach": "3.839.000"
      },
      "Xandr": {
        "reach": "4.370.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.347.000"
      },
      "theTradeDesk": {
        "reach": "755.000"
      },
      "Adform": {
        "reach": "1.735.000"
      },
      "Verizon": {
        "reach": "1.681.000"
      },
      "Xandr": {
        "reach": "1.871.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.681.000"
      },
      "theTradeDesk": {
        "reach": "5.307.000"
      },
      "Adform": {
        "reach": "4.621.000"
      },
      "Verizon": {
        "reach": "4.711.000"
      },
      "Xandr": {
        "reach": "5.524.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.652.000"
      },
      "theTradeDesk": {
        "reach": "1.321.000"
      },
      "Adform": {
        "reach": "1.178.000"
      },
      "Verizon": {
        "reach": "1.130.000"
      },
      "Xandr": {
        "reach": "1.280.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "721.000"
      },
      "RMS": {
        "reach": "1.067.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.283.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.273.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.796.000"
      },
      "theTradeDesk": {
        "reach": "1.403.000"
      },
      "Adform": {
        "reach": "1.217.000"
      },
      "Verizon": {
        "reach": "1.257.000"
      },
      "Xandr": {
        "reach": "1.477.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.387.000"
      },
      "Instagram": {
        "reach": "1.753.000"
      },
      "YouTube": {
        "reach": "6.070.000"
      },
      "Snapchat": {
        "reach": "228.000"
      }
    }
  },
  "Miniwagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.194.000"
      },
      "theTradeDesk": {
        "reach": "966.000"
      },
      "Adform": {
        "reach": "830.000"
      },
      "Verizon": {
        "reach": "813.000"
      },
      "Xandr": {
        "reach": "969.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "517.000"
      },
      "theTradeDesk": {
        "reach": "407.000"
      },
      "Adform": {
        "reach": "378.000"
      },
      "Verizon": {
        "reach": "364.000"
      },
      "Xandr": {
        "reach": "419.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.538.000"
      },
      "theTradeDesk": {
        "reach": "1.231.000"
      },
      "Adform": {
        "reach": "1.098.000"
      },
      "Verizon": {
        "reach": "1.136.000"
      },
      "Xandr": {
        "reach": "1.193.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.810.000"
      },
      "theTradeDesk": {
        "reach": "1.467.000"
      },
      "Adform": {
        "reach": "1.252.000"
      },
      "Verizon": {
        "reach": "1.268.000"
      },
      "Xandr": {
        "reach": "1.454.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "765.000"
      },
      "theTradeDesk": {
        "reach": "243.000"
      },
      "Adform": {
        "reach": "550.000"
      },
      "Verizon": {
        "reach": "552.000"
      },
      "Xandr": {
        "reach": "624.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.191.000"
      },
      "theTradeDesk": {
        "reach": "1.796.000"
      },
      "Adform": {
        "reach": "1.596.000"
      },
      "Verizon": {
        "reach": "1.522.000"
      },
      "Xandr": {
        "reach": "1.724.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "550.000"
      },
      "theTradeDesk": {
        "reach": "435.000"
      },
      "Adform": {
        "reach": "369.000"
      },
      "Verizon": {
        "reach": "367.000"
      },
      "Xandr": {
        "reach": "425.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "235.000"
      },
      "RMS": {
        "reach": "332.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.086.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "437.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "612.000"
      },
      "theTradeDesk": {
        "reach": "495.000"
      },
      "Adform": {
        "reach": "417.000"
      },
      "Verizon": {
        "reach": "407.000"
      },
      "Xandr": {
        "reach": "481.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.109.000"
      },
      "Instagram": {
        "reach": "583.000"
      },
      "YouTube": {
        "reach": "2.098.000"
      },
      "Snapchat": {
        "reach": "129.000"
      }
    }
  },
  "Kleinwagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.967.000"
      },
      "theTradeDesk": {
        "reach": "2.488.000"
      },
      "Adform": {
        "reach": "2.245.000"
      },
      "Verizon": {
        "reach": "2.213.000"
      },
      "Xandr": {
        "reach": "2.446.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.368.000"
      },
      "theTradeDesk": {
        "reach": "1.095.000"
      },
      "Adform": {
        "reach": "913.000"
      },
      "Verizon": {
        "reach": "920.000"
      },
      "Xandr": {
        "reach": "1.057.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.058.000"
      },
      "theTradeDesk": {
        "reach": "3.140.000"
      },
      "Adform": {
        "reach": "2.791.000"
      },
      "Verizon": {
        "reach": "2.800.000"
      },
      "Xandr": {
        "reach": "3.209.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.708.000"
      },
      "theTradeDesk": {
        "reach": "3.741.000"
      },
      "Adform": {
        "reach": "3.247.000"
      },
      "Verizon": {
        "reach": "3.302.000"
      },
      "Xandr": {
        "reach": "3.800.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.047.000"
      },
      "theTradeDesk": {
        "reach": "660.000"
      },
      "Adform": {
        "reach": "1.427.000"
      },
      "Verizon": {
        "reach": "1.378.000"
      },
      "Xandr": {
        "reach": "1.644.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.633.000"
      },
      "theTradeDesk": {
        "reach": "4.528.000"
      },
      "Adform": {
        "reach": "3.904.000"
      },
      "Verizon": {
        "reach": "3.896.000"
      },
      "Xandr": {
        "reach": "4.623.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.398.000"
      },
      "theTradeDesk": {
        "reach": "1.114.000"
      },
      "Adform": {
        "reach": "963.000"
      },
      "Verizon": {
        "reach": "980.000"
      },
      "Xandr": {
        "reach": "1.114.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "620.000"
      },
      "RMS": {
        "reach": "882.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.811.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.150.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.517.000"
      },
      "theTradeDesk": {
        "reach": "1.211.000"
      },
      "Adform": {
        "reach": "1.093.000"
      },
      "Verizon": {
        "reach": "1.062.000"
      },
      "Xandr": {
        "reach": "1.247.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.832.000"
      },
      "Instagram": {
        "reach": "1.502.000"
      },
      "YouTube": {
        "reach": "5.297.000"
      },
      "Snapchat": {
        "reach": "325.000"
      }
    }
  },
  "Untere Mittelklassewagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.475.000"
      },
      "theTradeDesk": {
        "reach": "2.673.000"
      },
      "Adform": {
        "reach": "2.394.000"
      },
      "Verizon": {
        "reach": "2.383.000"
      },
      "Xandr": {
        "reach": "2.742.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.517.000"
      },
      "theTradeDesk": {
        "reach": "1.192.000"
      },
      "Adform": {
        "reach": "1.033.000"
      },
      "Verizon": {
        "reach": "1.078.000"
      },
      "Xandr": {
        "reach": "1.207.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.553.000"
      },
      "theTradeDesk": {
        "reach": "3.474.000"
      },
      "Adform": {
        "reach": "3.191.000"
      },
      "Verizon": {
        "reach": "3.157.000"
      },
      "Xandr": {
        "reach": "3.567.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.470.000"
      },
      "theTradeDesk": {
        "reach": "4.218.000"
      },
      "Adform": {
        "reach": "3.884.000"
      },
      "Verizon": {
        "reach": "3.712.000"
      },
      "Xandr": {
        "reach": "4.339.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.334.000"
      },
      "theTradeDesk": {
        "reach": "731.000"
      },
      "Adform": {
        "reach": "1.577.000"
      },
      "Verizon": {
        "reach": "1.628.000"
      },
      "Xandr": {
        "reach": "1.829.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.384.000"
      },
      "theTradeDesk": {
        "reach": "5.105.000"
      },
      "Adform": {
        "reach": "4.485.000"
      },
      "Verizon": {
        "reach": "4.484.000"
      },
      "Xandr": {
        "reach": "5.046.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.589.000"
      },
      "theTradeDesk": {
        "reach": "1.230.000"
      },
      "Adform": {
        "reach": "1.052.000"
      },
      "Verizon": {
        "reach": "1.074.000"
      },
      "Xandr": {
        "reach": "1.258.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "700.000"
      },
      "RMS": {
        "reach": "976.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.113.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.213.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.748.000"
      },
      "theTradeDesk": {
        "reach": "1.322.000"
      },
      "Adform": {
        "reach": "1.183.000"
      },
      "Verizon": {
        "reach": "1.183.000"
      },
      "Xandr": {
        "reach": "1.405.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.274.000"
      },
      "Instagram": {
        "reach": "1.637.000"
      },
      "YouTube": {
        "reach": "6.059.000"
      },
      "Snapchat": {
        "reach": "222.000"
      }
    }
  },
  "Mittelklassewagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.043.000"
      },
      "theTradeDesk": {
        "reach": "831.000"
      },
      "Adform": {
        "reach": "721.000"
      },
      "Verizon": {
        "reach": "767.000"
      },
      "Xandr": {
        "reach": "839.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "460.000"
      },
      "theTradeDesk": {
        "reach": "346.000"
      },
      "Adform": {
        "reach": "318.000"
      },
      "Verizon": {
        "reach": "317.000"
      },
      "Xandr": {
        "reach": "364.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.333.000"
      },
      "theTradeDesk": {
        "reach": "1.129.000"
      },
      "Adform": {
        "reach": "974.000"
      },
      "Verizon": {
        "reach": "963.000"
      },
      "Xandr": {
        "reach": "1.062.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.617.000"
      },
      "theTradeDesk": {
        "reach": "1.331.000"
      },
      "Adform": {
        "reach": "1.112.000"
      },
      "Verizon": {
        "reach": "1.117.000"
      },
      "Xandr": {
        "reach": "1.274.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "698.000"
      },
      "theTradeDesk": {
        "reach": "217.000"
      },
      "Adform": {
        "reach": "482.000"
      },
      "Verizon": {
        "reach": "464.000"
      },
      "Xandr": {
        "reach": "562.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.964.000"
      },
      "theTradeDesk": {
        "reach": "1.550.000"
      },
      "Adform": {
        "reach": "1.360.000"
      },
      "Verizon": {
        "reach": "1.372.000"
      },
      "Xandr": {
        "reach": "1.552.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "459.000"
      },
      "theTradeDesk": {
        "reach": "372.000"
      },
      "Adform": {
        "reach": "329.000"
      },
      "Verizon": {
        "reach": "332.000"
      },
      "Xandr": {
        "reach": "388.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "208.000"
      },
      "RMS": {
        "reach": "303.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "937.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "381.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "529.000"
      },
      "theTradeDesk": {
        "reach": "422.000"
      },
      "Adform": {
        "reach": "357.000"
      },
      "Verizon": {
        "reach": "355.000"
      },
      "Xandr": {
        "reach": "437.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.022.000"
      },
      "Instagram": {
        "reach": "504.000"
      },
      "YouTube": {
        "reach": "1.830.000"
      },
      "Snapchat": {
        "reach": "45.000"
      }
    }
  },
  "Obere Mittelklassewagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "487.000"
      },
      "theTradeDesk": {
        "reach": "389.000"
      },
      "Adform": {
        "reach": "350.000"
      },
      "Verizon": {
        "reach": "352.000"
      },
      "Xandr": {
        "reach": "398.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "215.000"
      },
      "theTradeDesk": {
        "reach": "172.000"
      },
      "Adform": {
        "reach": "151.000"
      },
      "Verizon": {
        "reach": "155.000"
      },
      "Xandr": {
        "reach": "168.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "638.000"
      },
      "theTradeDesk": {
        "reach": "505.000"
      },
      "Adform": {
        "reach": "452.000"
      },
      "Verizon": {
        "reach": "439.000"
      },
      "Xandr": {
        "reach": "520.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "712.000"
      },
      "theTradeDesk": {
        "reach": "607.000"
      },
      "Adform": {
        "reach": "539.000"
      },
      "Verizon": {
        "reach": "551.000"
      },
      "Xandr": {
        "reach": "602.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "325.000"
      },
      "theTradeDesk": {
        "reach": "105.000"
      },
      "Adform": {
        "reach": "232.000"
      },
      "Verizon": {
        "reach": "230.000"
      },
      "Xandr": {
        "reach": "253.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "908.000"
      },
      "theTradeDesk": {
        "reach": "744.000"
      },
      "Adform": {
        "reach": "605.000"
      },
      "Verizon": {
        "reach": "644.000"
      },
      "Xandr": {
        "reach": "728.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "229.000"
      },
      "theTradeDesk": {
        "reach": "180.000"
      },
      "Adform": {
        "reach": "153.000"
      },
      "Verizon": {
        "reach": "158.000"
      },
      "Xandr": {
        "reach": "182.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "127.000"
      },
      "RMS": {
        "reach": "141.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "444.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "182.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "246.000"
      },
      "theTradeDesk": {
        "reach": "195.000"
      },
      "Adform": {
        "reach": "179.000"
      },
      "Verizon": {
        "reach": "179.000"
      },
      "Xandr": {
        "reach": "204.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "471.000"
      },
      "Instagram": {
        "reach": "237.000"
      },
      "YouTube": {
        "reach": "867.000"
      },
      "Snapchat": {
        "reach": "21.000"
      }
    }
  },
  "Oberklassewagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "51.000"
      },
      "theTradeDesk": {
        "reach": "41.000"
      },
      "Adform": {
        "reach": "37.000"
      },
      "Verizon": {
        "reach": "35.000"
      },
      "Xandr": {
        "reach": "42.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "22.000"
      },
      "theTradeDesk": {
        "reach": "18.000"
      },
      "Adform": {
        "reach": "15.000"
      },
      "Verizon": {
        "reach": "15.000"
      },
      "Xandr": {
        "reach": "18.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "64.000"
      },
      "theTradeDesk": {
        "reach": "52.000"
      },
      "Adform": {
        "reach": "47.000"
      },
      "Verizon": {
        "reach": "47.000"
      },
      "Xandr": {
        "reach": "53.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "79.000"
      },
      "theTradeDesk": {
        "reach": "63.000"
      },
      "Adform": {
        "reach": "56.000"
      },
      "Verizon": {
        "reach": "54.000"
      },
      "Xandr": {
        "reach": "62.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "33.000"
      },
      "theTradeDesk": {
        "reach": "11.000"
      },
      "Adform": {
        "reach": "24.000"
      },
      "Verizon": {
        "reach": "23.000"
      },
      "Xandr": {
        "reach": "27.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "94.000"
      },
      "theTradeDesk": {
        "reach": "75.000"
      },
      "Adform": {
        "reach": "67.000"
      },
      "Verizon": {
        "reach": "67.000"
      },
      "Xandr": {
        "reach": "76.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "23.000"
      },
      "theTradeDesk": {
        "reach": "18.000"
      },
      "Adform": {
        "reach": "16.000"
      },
      "Verizon": {
        "reach": "17.000"
      },
      "Xandr": {
        "reach": "17.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "13.000"
      },
      "RMS": {
        "reach": "15.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "48.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "19.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "25.000"
      },
      "theTradeDesk": {
        "reach": "20.000"
      },
      "Adform": {
        "reach": "18.000"
      },
      "Verizon": {
        "reach": "18.000"
      },
      "Xandr": {
        "reach": "20.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "49.000"
      },
      "Instagram": {
        "reach": "25.000"
      },
      "YouTube": {
        "reach": "91.000"
      },
      "Snapchat": {
        "reach": "1.000"
      }
    }
  },
  "Geländewagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.417.000"
      },
      "theTradeDesk": {
        "reach": "1.210.000"
      },
      "Adform": {
        "reach": "1.013.000"
      },
      "Verizon": {
        "reach": "1.022.000"
      },
      "Xandr": {
        "reach": "1.163.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "611.000"
      },
      "theTradeDesk": {
        "reach": "488.000"
      },
      "Adform": {
        "reach": "425.000"
      },
      "Verizon": {
        "reach": "420.000"
      },
      "Xandr": {
        "reach": "496.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.840.000"
      },
      "theTradeDesk": {
        "reach": "1.505.000"
      },
      "Adform": {
        "reach": "1.302.000"
      },
      "Verizon": {
        "reach": "1.312.000"
      },
      "Xandr": {
        "reach": "1.541.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.196.000"
      },
      "theTradeDesk": {
        "reach": "1.787.000"
      },
      "Adform": {
        "reach": "1.571.000"
      },
      "Verizon": {
        "reach": "1.594.000"
      },
      "Xandr": {
        "reach": "1.819.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "940.000"
      },
      "theTradeDesk": {
        "reach": "301.000"
      },
      "Adform": {
        "reach": "667.000"
      },
      "Verizon": {
        "reach": "675.000"
      },
      "Xandr": {
        "reach": "766.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.601.000"
      },
      "theTradeDesk": {
        "reach": "2.043.000"
      },
      "Adform": {
        "reach": "1.878.000"
      },
      "Verizon": {
        "reach": "1.834.000"
      },
      "Xandr": {
        "reach": "2.114.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "634.000"
      },
      "theTradeDesk": {
        "reach": "516.000"
      },
      "Adform": {
        "reach": "443.000"
      },
      "Verizon": {
        "reach": "462.000"
      },
      "Xandr": {
        "reach": "515.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "366.000"
      },
      "RMS": {
        "reach": "419.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.322.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "512.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "719.000"
      },
      "theTradeDesk": {
        "reach": "585.000"
      },
      "Adform": {
        "reach": "517.000"
      },
      "Verizon": {
        "reach": "499.000"
      },
      "Xandr": {
        "reach": "582.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.396.000"
      },
      "Instagram": {
        "reach": "678.000"
      },
      "YouTube": {
        "reach": "2.574.000"
      },
      "Snapchat": {
        "reach": "31.000"
      }
    }
  },
  "Cabriolets": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "155.000"
      },
      "theTradeDesk": {
        "reach": "127.000"
      },
      "Adform": {
        "reach": "113.000"
      },
      "Verizon": {
        "reach": "110.000"
      },
      "Xandr": {
        "reach": "130.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "69.000"
      },
      "theTradeDesk": {
        "reach": "56.000"
      },
      "Adform": {
        "reach": "48.000"
      },
      "Verizon": {
        "reach": "48.000"
      },
      "Xandr": {
        "reach": "56.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "210.000"
      },
      "theTradeDesk": {
        "reach": "168.000"
      },
      "Adform": {
        "reach": "146.000"
      },
      "Verizon": {
        "reach": "145.000"
      },
      "Xandr": {
        "reach": "165.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "243.000"
      },
      "theTradeDesk": {
        "reach": "197.000"
      },
      "Adform": {
        "reach": "171.000"
      },
      "Verizon": {
        "reach": "177.000"
      },
      "Xandr": {
        "reach": "200.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "105.000"
      },
      "theTradeDesk": {
        "reach": "34.000"
      },
      "Adform": {
        "reach": "73.000"
      },
      "Verizon": {
        "reach": "73.000"
      },
      "Xandr": {
        "reach": "82.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "296.000"
      },
      "theTradeDesk": {
        "reach": "246.000"
      },
      "Adform": {
        "reach": "204.000"
      },
      "Verizon": {
        "reach": "204.000"
      },
      "Xandr": {
        "reach": "237.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "73.000"
      },
      "theTradeDesk": {
        "reach": "58.000"
      },
      "Adform": {
        "reach": "51.000"
      },
      "Verizon": {
        "reach": "51.000"
      },
      "Xandr": {
        "reach": "58.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "40.000"
      },
      "RMS": {
        "reach": "47.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "147.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "59.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "82.000"
      },
      "theTradeDesk": {
        "reach": "65.000"
      },
      "Adform": {
        "reach": "56.000"
      },
      "Verizon": {
        "reach": "58.000"
      },
      "Xandr": {
        "reach": "64.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "150.000"
      },
      "Instagram": {
        "reach": "74.000"
      },
      "YouTube": {
        "reach": "284.000"
      },
      "Snapchat": {
        "reach": "3.000"
      }
    }
  },
  "Kombiwagen": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.469.000"
      },
      "theTradeDesk": {
        "reach": "1.982.000"
      },
      "Adform": {
        "reach": "1.654.000"
      },
      "Verizon": {
        "reach": "1.677.000"
      },
      "Xandr": {
        "reach": "1.923.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.058.000"
      },
      "theTradeDesk": {
        "reach": "837.000"
      },
      "Adform": {
        "reach": "739.000"
      },
      "Verizon": {
        "reach": "744.000"
      },
      "Xandr": {
        "reach": "823.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.155.000"
      },
      "theTradeDesk": {
        "reach": "2.506.000"
      },
      "Adform": {
        "reach": "2.182.000"
      },
      "Verizon": {
        "reach": "2.199.000"
      },
      "Xandr": {
        "reach": "2.584.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.737.000"
      },
      "theTradeDesk": {
        "reach": "3.071.000"
      },
      "Adform": {
        "reach": "2.648.000"
      },
      "Verizon": {
        "reach": "2.587.000"
      },
      "Xandr": {
        "reach": "2.931.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.595.000"
      },
      "theTradeDesk": {
        "reach": "513.000"
      },
      "Adform": {
        "reach": "1.094.000"
      },
      "Verizon": {
        "reach": "1.148.000"
      },
      "Xandr": {
        "reach": "1.290.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.481.000"
      },
      "theTradeDesk": {
        "reach": "3.512.000"
      },
      "Adform": {
        "reach": "3.195.000"
      },
      "Verizon": {
        "reach": "3.129.000"
      },
      "Xandr": {
        "reach": "3.587.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.123.000"
      },
      "theTradeDesk": {
        "reach": "882.000"
      },
      "Adform": {
        "reach": "746.000"
      },
      "Verizon": {
        "reach": "774.000"
      },
      "Xandr": {
        "reach": "879.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "475.000"
      },
      "RMS": {
        "reach": "694.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.267.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "887.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.234.000"
      },
      "theTradeDesk": {
        "reach": "959.000"
      },
      "Adform": {
        "reach": "854.000"
      },
      "Verizon": {
        "reach": "867.000"
      },
      "Xandr": {
        "reach": "982.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.264.000"
      },
      "Instagram": {
        "reach": "1.151.000"
      },
      "YouTube": {
        "reach": "4.337.000"
      },
      "Snapchat": {
        "reach": "153.000"
      }
    }
  },
  "Vans": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.492.000"
      },
      "theTradeDesk": {
        "reach": "1.134.000"
      },
      "Adform": {
        "reach": "964.000"
      },
      "Verizon": {
        "reach": "1.013.000"
      },
      "Xandr": {
        "reach": "1.116.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "630.000"
      },
      "theTradeDesk": {
        "reach": "480.000"
      },
      "Adform": {
        "reach": "419.000"
      },
      "Verizon": {
        "reach": "420.000"
      },
      "Xandr": {
        "reach": "497.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.870.000"
      },
      "theTradeDesk": {
        "reach": "1.442.000"
      },
      "Adform": {
        "reach": "1.272.000"
      },
      "Verizon": {
        "reach": "1.305.000"
      },
      "Xandr": {
        "reach": "1.451.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.169.000"
      },
      "theTradeDesk": {
        "reach": "1.757.000"
      },
      "Adform": {
        "reach": "1.502.000"
      },
      "Verizon": {
        "reach": "1.526.000"
      },
      "Xandr": {
        "reach": "1.761.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "928.000"
      },
      "theTradeDesk": {
        "reach": "294.000"
      },
      "Adform": {
        "reach": "649.000"
      },
      "Verizon": {
        "reach": "640.000"
      },
      "Xandr": {
        "reach": "758.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.669.000"
      },
      "theTradeDesk": {
        "reach": "2.102.000"
      },
      "Adform": {
        "reach": "1.799.000"
      },
      "Verizon": {
        "reach": "1.881.000"
      },
      "Xandr": {
        "reach": "2.083.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "633.000"
      },
      "theTradeDesk": {
        "reach": "512.000"
      },
      "Adform": {
        "reach": "450.000"
      },
      "Verizon": {
        "reach": "456.000"
      },
      "Xandr": {
        "reach": "534.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "292.000"
      },
      "RMS": {
        "reach": "413.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.337.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "505.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "736.000"
      },
      "theTradeDesk": {
        "reach": "576.000"
      },
      "Adform": {
        "reach": "514.000"
      },
      "Verizon": {
        "reach": "503.000"
      },
      "Xandr": {
        "reach": "580.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.329.000"
      },
      "Instagram": {
        "reach": "683.000"
      },
      "YouTube": {
        "reach": "2.583.000"
      },
      "Snapchat": {
        "reach": "91.000"
      }
    }
  },
  "Der preisbewusste Rationalist (Preissensibel)": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.970.000"
      },
      "theTradeDesk": {
        "reach": "1.565.000"
      },
      "Adform": {
        "reach": "1.385.000"
      },
      "Verizon": {
        "reach": "1.387.000"
      },
      "Xandr": {
        "reach": "1.614.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "834.000"
      },
      "theTradeDesk": {
        "reach": "695.000"
      },
      "Adform": {
        "reach": "602.000"
      },
      "Verizon": {
        "reach": "608.000"
      },
      "Xandr": {
        "reach": "672.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.595.000"
      },
      "theTradeDesk": {
        "reach": "2.095.000"
      },
      "Adform": {
        "reach": "1.728.000"
      },
      "Verizon": {
        "reach": "1.790.000"
      },
      "Xandr": {
        "reach": "2.030.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.195.000"
      },
      "theTradeDesk": {
        "reach": "2.395.000"
      },
      "Adform": {
        "reach": "2.143.000"
      },
      "Verizon": {
        "reach": "2.194.000"
      },
      "Xandr": {
        "reach": "2.427.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.323.000"
      },
      "theTradeDesk": {
        "reach": "422.000"
      },
      "Adform": {
        "reach": "942.000"
      },
      "Verizon": {
        "reach": "915.000"
      },
      "Xandr": {
        "reach": "1.020.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.701.000"
      },
      "theTradeDesk": {
        "reach": "3.008.000"
      },
      "Adform": {
        "reach": "2.482.000"
      },
      "Verizon": {
        "reach": "2.538.000"
      },
      "Xandr": {
        "reach": "3.027.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "922.000"
      },
      "theTradeDesk": {
        "reach": "715.000"
      },
      "Adform": {
        "reach": "615.000"
      },
      "Verizon": {
        "reach": "632.000"
      },
      "Xandr": {
        "reach": "732.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "477.000"
      },
      "RMS": {
        "reach": "581.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.795.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "720.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "993.000"
      },
      "theTradeDesk": {
        "reach": "787.000"
      },
      "Adform": {
        "reach": "699.000"
      },
      "Verizon": {
        "reach": "728.000"
      },
      "Xandr": {
        "reach": "789.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.891.000"
      },
      "Instagram": {
        "reach": "937.000"
      },
      "YouTube": {
        "reach": "3.354.000"
      },
      "Snapchat": {
        "reach": "420.000"
      }
    }
  },
  "Der jüngere Kleinwagenfahrer (geringe Kaufkraft)": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.930.000"
      },
      "theTradeDesk": {
        "reach": "1.430.000"
      },
      "Adform": {
        "reach": "1.329.000"
      },
      "Verizon": {
        "reach": "1.374.000"
      },
      "Xandr": {
        "reach": "1.514.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "801.000"
      },
      "theTradeDesk": {
        "reach": "657.000"
      },
      "Adform": {
        "reach": "581.000"
      },
      "Verizon": {
        "reach": "585.000"
      },
      "Xandr": {
        "reach": "652.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.472.000"
      },
      "theTradeDesk": {
        "reach": "1.853.000"
      },
      "Adform": {
        "reach": "1.699.000"
      },
      "Verizon": {
        "reach": "1.723.000"
      },
      "Xandr": {
        "reach": "1.914.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.903.000"
      },
      "theTradeDesk": {
        "reach": "2.400.000"
      },
      "Adform": {
        "reach": "2.102.000"
      },
      "Verizon": {
        "reach": "2.053.000"
      },
      "Xandr": {
        "reach": "2.282.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.239.000"
      },
      "theTradeDesk": {
        "reach": "401.000"
      },
      "Adform": {
        "reach": "828.000"
      },
      "Verizon": {
        "reach": "845.000"
      },
      "Xandr": {
        "reach": "964.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.587.000"
      },
      "theTradeDesk": {
        "reach": "2.842.000"
      },
      "Adform": {
        "reach": "2.547.000"
      },
      "Verizon": {
        "reach": "2.357.000"
      },
      "Xandr": {
        "reach": "2.684.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "873.000"
      },
      "theTradeDesk": {
        "reach": "679.000"
      },
      "Adform": {
        "reach": "606.000"
      },
      "Verizon": {
        "reach": "613.000"
      },
      "Xandr": {
        "reach": "675.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "383.000"
      },
      "RMS": {
        "reach": "566.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.772.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "687.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "943.000"
      },
      "theTradeDesk": {
        "reach": "746.000"
      },
      "Adform": {
        "reach": "682.000"
      },
      "Verizon": {
        "reach": "665.000"
      },
      "Xandr": {
        "reach": "745.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.832.000"
      },
      "Instagram": {
        "reach": "933.000"
      },
      "YouTube": {
        "reach": "3.370.000"
      },
      "Snapchat": {
        "reach": "235.000"
      }
    }
  },
  "Der zweckorientierte Gebrauchtwagenfahrer (": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.959.000"
      },
      "theTradeDesk": {
        "reach": "2.336.000"
      },
      "Adform": {
        "reach": "2.048.000"
      },
      "Verizon": {
        "reach": "2.021.000"
      },
      "Xandr": {
        "reach": "2.426.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.267.000"
      },
      "theTradeDesk": {
        "reach": "1.024.000"
      },
      "Adform": {
        "reach": "856.000"
      },
      "Verizon": {
        "reach": "906.000"
      },
      "Xandr": {
        "reach": "1.007.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.803.000"
      },
      "theTradeDesk": {
        "reach": "2.871.000"
      },
      "Adform": {
        "reach": "2.671.000"
      },
      "Verizon": {
        "reach": "2.600.000"
      },
      "Xandr": {
        "reach": "3.015.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.629.000"
      },
      "theTradeDesk": {
        "reach": "3.685.000"
      },
      "Adform": {
        "reach": "3.191.000"
      },
      "Verizon": {
        "reach": "3.148.000"
      },
      "Xandr": {
        "reach": "3.617.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.955.000"
      },
      "theTradeDesk": {
        "reach": "641.000"
      },
      "Adform": {
        "reach": "1.349.000"
      },
      "Verizon": {
        "reach": "1.337.000"
      },
      "Xandr": {
        "reach": "1.558.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.426.000"
      },
      "theTradeDesk": {
        "reach": "4.287.000"
      },
      "Adform": {
        "reach": "3.707.000"
      },
      "Verizon": {
        "reach": "3.818.000"
      },
      "Xandr": {
        "reach": "4.193.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.310.000"
      },
      "theTradeDesk": {
        "reach": "1.031.000"
      },
      "Adform": {
        "reach": "926.000"
      },
      "Verizon": {
        "reach": "909.000"
      },
      "Xandr": {
        "reach": "1.067.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "589.000"
      },
      "RMS": {
        "reach": "842.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.674.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.057.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.462.000"
      },
      "theTradeDesk": {
        "reach": "1.110.000"
      },
      "Adform": {
        "reach": "1.016.000"
      },
      "Verizon": {
        "reach": "1.037.000"
      },
      "Xandr": {
        "reach": "1.175.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.697.000"
      },
      "Instagram": {
        "reach": "1.443.000"
      },
      "YouTube": {
        "reach": "5.041.000"
      },
      "Snapchat": {
        "reach": "183.000"
      }
    }
  },
  "Der konservative untere Mittelklassenfahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.553.000"
      },
      "theTradeDesk": {
        "reach": "2.086.000"
      },
      "Adform": {
        "reach": "1.785.000"
      },
      "Verizon": {
        "reach": "1.838.000"
      },
      "Xandr": {
        "reach": "2.062.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.079.000"
      },
      "theTradeDesk": {
        "reach": "904.000"
      },
      "Adform": {
        "reach": "778.000"
      },
      "Verizon": {
        "reach": "788.000"
      },
      "Xandr": {
        "reach": "899.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.282.000"
      },
      "theTradeDesk": {
        "reach": "2.788.000"
      },
      "Adform": {
        "reach": "2.391.000"
      },
      "Verizon": {
        "reach": "2.405.000"
      },
      "Xandr": {
        "reach": "2.693.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.005.000"
      },
      "theTradeDesk": {
        "reach": "3.086.000"
      },
      "Adform": {
        "reach": "2.766.000"
      },
      "Verizon": {
        "reach": "2.779.000"
      },
      "Xandr": {
        "reach": "3.226.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.699.000"
      },
      "theTradeDesk": {
        "reach": "555.000"
      },
      "Adform": {
        "reach": "1.209.000"
      },
      "Verizon": {
        "reach": "1.214.000"
      },
      "Xandr": {
        "reach": "1.292.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.733.000"
      },
      "theTradeDesk": {
        "reach": "3.963.000"
      },
      "Adform": {
        "reach": "3.335.000"
      },
      "Verizon": {
        "reach": "3.279.000"
      },
      "Xandr": {
        "reach": "3.927.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.142.000"
      },
      "theTradeDesk": {
        "reach": "951.000"
      },
      "Adform": {
        "reach": "786.000"
      },
      "Verizon": {
        "reach": "798.000"
      },
      "Xandr": {
        "reach": "910.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "520.000"
      },
      "RMS": {
        "reach": "739.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.339.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "903.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.281.000"
      },
      "theTradeDesk": {
        "reach": "1.037.000"
      },
      "Adform": {
        "reach": "931.000"
      },
      "Verizon": {
        "reach": "900.000"
      },
      "Xandr": {
        "reach": "1.034.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.420.000"
      },
      "Instagram": {
        "reach": "1.305.000"
      },
      "YouTube": {
        "reach": "4.667.000"
      },
      "Snapchat": {
        "reach": "111.000"
      }
    }
  },
  "Der qualitätsbewusste Mittelklassenfahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.250.000"
      },
      "theTradeDesk": {
        "reach": "1.889.000"
      },
      "Adform": {
        "reach": "1.587.000"
      },
      "Verizon": {
        "reach": "1.644.000"
      },
      "Xandr": {
        "reach": "1.910.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "989.000"
      },
      "theTradeDesk": {
        "reach": "775.000"
      },
      "Adform": {
        "reach": "682.000"
      },
      "Verizon": {
        "reach": "694.000"
      },
      "Xandr": {
        "reach": "801.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.909.000"
      },
      "theTradeDesk": {
        "reach": "2.450.000"
      },
      "Adform": {
        "reach": "2.078.000"
      },
      "Verizon": {
        "reach": "2.074.000"
      },
      "Xandr": {
        "reach": "2.318.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.502.000"
      },
      "theTradeDesk": {
        "reach": "2.821.000"
      },
      "Adform": {
        "reach": "2.477.000"
      },
      "Verizon": {
        "reach": "2.482.000"
      },
      "Xandr": {
        "reach": "2.798.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.513.000"
      },
      "theTradeDesk": {
        "reach": "478.000"
      },
      "Adform": {
        "reach": "1.074.000"
      },
      "Verizon": {
        "reach": "1.070.000"
      },
      "Xandr": {
        "reach": "1.184.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.046.000"
      },
      "theTradeDesk": {
        "reach": "3.379.000"
      },
      "Adform": {
        "reach": "3.062.000"
      },
      "Verizon": {
        "reach": "2.895.000"
      },
      "Xandr": {
        "reach": "3.274.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.042.000"
      },
      "theTradeDesk": {
        "reach": "822.000"
      },
      "Adform": {
        "reach": "710.000"
      },
      "Verizon": {
        "reach": "727.000"
      },
      "Xandr": {
        "reach": "826.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "586.000"
      },
      "RMS": {
        "reach": "657.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.047.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "841.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.132.000"
      },
      "theTradeDesk": {
        "reach": "921.000"
      },
      "Adform": {
        "reach": "826.000"
      },
      "Verizon": {
        "reach": "796.000"
      },
      "Xandr": {
        "reach": "913.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.128.000"
      },
      "Instagram": {
        "reach": "1.081.000"
      },
      "YouTube": {
        "reach": "4.052.000"
      },
      "Snapchat": {
        "reach": "143.000"
      }
    }
  },
  "Der familienorientierte Kombifahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.919.000"
      },
      "theTradeDesk": {
        "reach": "1.557.000"
      },
      "Adform": {
        "reach": "1.360.000"
      },
      "Verizon": {
        "reach": "1.365.000"
      },
      "Xandr": {
        "reach": "1.579.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "849.000"
      },
      "theTradeDesk": {
        "reach": "679.000"
      },
      "Adform": {
        "reach": "580.000"
      },
      "Verizon": {
        "reach": "571.000"
      },
      "Xandr": {
        "reach": "682.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.512.000"
      },
      "theTradeDesk": {
        "reach": "1.966.000"
      },
      "Adform": {
        "reach": "1.733.000"
      },
      "Verizon": {
        "reach": "1.748.000"
      },
      "Xandr": {
        "reach": "1.972.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.118.000"
      },
      "theTradeDesk": {
        "reach": "2.263.000"
      },
      "Adform": {
        "reach": "2.155.000"
      },
      "Verizon": {
        "reach": "2.055.000"
      },
      "Xandr": {
        "reach": "2.350.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.184.000"
      },
      "theTradeDesk": {
        "reach": "401.000"
      },
      "Adform": {
        "reach": "886.000"
      },
      "Verizon": {
        "reach": "865.000"
      },
      "Xandr": {
        "reach": "1.040.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.593.000"
      },
      "theTradeDesk": {
        "reach": "2.834.000"
      },
      "Adform": {
        "reach": "2.552.000"
      },
      "Verizon": {
        "reach": "2.520.000"
      },
      "Xandr": {
        "reach": "2.852.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "898.000"
      },
      "theTradeDesk": {
        "reach": "715.000"
      },
      "Adform": {
        "reach": "603.000"
      },
      "Verizon": {
        "reach": "617.000"
      },
      "Xandr": {
        "reach": "710.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "389.000"
      },
      "RMS": {
        "reach": "534.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.733.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "706.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "992.000"
      },
      "theTradeDesk": {
        "reach": "781.000"
      },
      "Adform": {
        "reach": "673.000"
      },
      "Verizon": {
        "reach": "665.000"
      },
      "Xandr": {
        "reach": "758.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.807.000"
      },
      "Instagram": {
        "reach": "919.000"
      },
      "YouTube": {
        "reach": "3.333.000"
      },
      "Snapchat": {
        "reach": "83.000"
      }
    }
  },
  "Der markenbewusste Oberklassenfahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.283.000"
      },
      "theTradeDesk": {
        "reach": "978.000"
      },
      "Adform": {
        "reach": "931.000"
      },
      "Verizon": {
        "reach": "897.000"
      },
      "Xandr": {
        "reach": "1.046.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "534.000"
      },
      "theTradeDesk": {
        "reach": "466.000"
      },
      "Adform": {
        "reach": "390.000"
      },
      "Verizon": {
        "reach": "379.000"
      },
      "Xandr": {
        "reach": "448.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.633.000"
      },
      "theTradeDesk": {
        "reach": "1.288.000"
      },
      "Adform": {
        "reach": "1.183.000"
      },
      "Verizon": {
        "reach": "1.109.000"
      },
      "Xandr": {
        "reach": "1.360.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.945.000"
      },
      "theTradeDesk": {
        "reach": "1.595.000"
      },
      "Adform": {
        "reach": "1.394.000"
      },
      "Verizon": {
        "reach": "1.377.000"
      },
      "Xandr": {
        "reach": "1.558.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "812.000"
      },
      "theTradeDesk": {
        "reach": "265.000"
      },
      "Adform": {
        "reach": "575.000"
      },
      "Verizon": {
        "reach": "589.000"
      },
      "Xandr": {
        "reach": "658.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.285.000"
      },
      "theTradeDesk": {
        "reach": "1.909.000"
      },
      "Adform": {
        "reach": "1.665.000"
      },
      "Verizon": {
        "reach": "1.670.000"
      },
      "Xandr": {
        "reach": "1.878.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "570.000"
      },
      "theTradeDesk": {
        "reach": "470.000"
      },
      "Adform": {
        "reach": "412.000"
      },
      "Verizon": {
        "reach": "403.000"
      },
      "Xandr": {
        "reach": "466.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "318.000"
      },
      "RMS": {
        "reach": "369.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.144.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "477.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "608.000"
      },
      "theTradeDesk": {
        "reach": "501.000"
      },
      "Adform": {
        "reach": "447.000"
      },
      "Verizon": {
        "reach": "444.000"
      },
      "Xandr": {
        "reach": "512.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.210.000"
      },
      "Instagram": {
        "reach": "621.000"
      },
      "YouTube": {
        "reach": "2.193.000"
      },
      "Snapchat": {
        "reach": "54.000"
      }
    }
  },
  "Der prestige- und Leistungsorientierte Fahrer": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.044.000"
      },
      "theTradeDesk": {
        "reach": "875.000"
      },
      "Adform": {
        "reach": "722.000"
      },
      "Verizon": {
        "reach": "737.000"
      },
      "Xandr": {
        "reach": "849.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "453.000"
      },
      "theTradeDesk": {
        "reach": "358.000"
      },
      "Adform": {
        "reach": "333.000"
      },
      "Verizon": {
        "reach": "317.000"
      },
      "Xandr": {
        "reach": "362.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.340.000"
      },
      "theTradeDesk": {
        "reach": "1.078.000"
      },
      "Adform": {
        "reach": "940.000"
      },
      "Verizon": {
        "reach": "975.000"
      },
      "Xandr": {
        "reach": "1.076.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.596.000"
      },
      "theTradeDesk": {
        "reach": "1.290.000"
      },
      "Adform": {
        "reach": "1.128.000"
      },
      "Verizon": {
        "reach": "1.144.000"
      },
      "Xandr": {
        "reach": "1.293.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "689.000"
      },
      "theTradeDesk": {
        "reach": "226.000"
      },
      "Adform": {
        "reach": "475.000"
      },
      "Verizon": {
        "reach": "478.000"
      },
      "Xandr": {
        "reach": "555.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.945.000"
      },
      "theTradeDesk": {
        "reach": "1.570.000"
      },
      "Adform": {
        "reach": "1.412.000"
      },
      "Verizon": {
        "reach": "1.369.000"
      },
      "Xandr": {
        "reach": "1.604.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "471.000"
      },
      "theTradeDesk": {
        "reach": "373.000"
      },
      "Adform": {
        "reach": "335.000"
      },
      "Verizon": {
        "reach": "334.000"
      },
      "Xandr": {
        "reach": "381.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "262.000"
      },
      "RMS": {
        "reach": "289.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.005.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "380.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "504.000"
      },
      "theTradeDesk": {
        "reach": "420.000"
      },
      "Adform": {
        "reach": "378.000"
      },
      "Verizon": {
        "reach": "365.000"
      },
      "Xandr": {
        "reach": "419.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "985.000"
      },
      "Instagram": {
        "reach": "503.000"
      },
      "YouTube": {
        "reach": "1.812.000"
      },
      "Snapchat": {
        "reach": "22.000"
      }
    }
  },
  "Wechselbereitschaft -niedrig": {
    "cpm": "3,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.694.000"
      },
      "theTradeDesk": {
        "reach": "3.071.000"
      },
      "Adform": {
        "reach": "2.610.000"
      },
      "Verizon": {
        "reach": "2.519.000"
      },
      "Xandr": {
        "reach": "3.022.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.624.000"
      },
      "theTradeDesk": {
        "reach": "1.275.000"
      },
      "Adform": {
        "reach": "1.098.000"
      },
      "Verizon": {
        "reach": "1.131.000"
      },
      "Xandr": {
        "reach": "1.268.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.760.000"
      },
      "theTradeDesk": {
        "reach": "3.775.000"
      },
      "Adform": {
        "reach": "3.333.000"
      },
      "Verizon": {
        "reach": "3.358.000"
      },
      "Xandr": {
        "reach": "3.771.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.602.000"
      },
      "theTradeDesk": {
        "reach": "4.555.000"
      },
      "Adform": {
        "reach": "3.894.000"
      },
      "Verizon": {
        "reach": "4.024.000"
      },
      "Xandr": {
        "reach": "4.613.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.432.000"
      },
      "theTradeDesk": {
        "reach": "796.000"
      },
      "Adform": {
        "reach": "1.700.000"
      },
      "Verizon": {
        "reach": "1.729.000"
      },
      "Xandr": {
        "reach": "1.921.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.563.000"
      },
      "theTradeDesk": {
        "reach": "5.442.000"
      },
      "Adform": {
        "reach": "4.760.000"
      },
      "Verizon": {
        "reach": "4.818.000"
      },
      "Xandr": {
        "reach": "5.299.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.647.000"
      },
      "theTradeDesk": {
        "reach": "1.285.000"
      },
      "Adform": {
        "reach": "1.178.000"
      },
      "Verizon": {
        "reach": "1.156.000"
      },
      "Xandr": {
        "reach": "1.271.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "903.000"
      },
      "RMS": {
        "reach": "1.064.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.294.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.305.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.844.000"
      },
      "theTradeDesk": {
        "reach": "1.435.000"
      },
      "Adform": {
        "reach": "1.304.000"
      },
      "Verizon": {
        "reach": "1.239.000"
      },
      "Xandr": {
        "reach": "1.466.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.362.000"
      },
      "Instagram": {
        "reach": "1.732.000"
      },
      "YouTube": {
        "reach": "6.462.000"
      },
      "Snapchat": {
        "reach": "785.000"
      }
    }
  },
  "Wechselbereitschaft -hoch": {
    "cpm": "3,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.796.000"
      },
      "theTradeDesk": {
        "reach": "4.739.000"
      },
      "Adform": {
        "reach": "4.240.000"
      },
      "Verizon": {
        "reach": "4.077.000"
      },
      "Xandr": {
        "reach": "4.719.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.526.000"
      },
      "theTradeDesk": {
        "reach": "1.997.000"
      },
      "Adform": {
        "reach": "1.784.000"
      },
      "Verizon": {
        "reach": "1.691.000"
      },
      "Xandr": {
        "reach": "2.028.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "7.412.000"
      },
      "theTradeDesk": {
        "reach": "5.917.000"
      },
      "Adform": {
        "reach": "5.043.000"
      },
      "Verizon": {
        "reach": "5.096.000"
      },
      "Xandr": {
        "reach": "5.966.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "8.411.000"
      },
      "theTradeDesk": {
        "reach": "6.766.000"
      },
      "Adform": {
        "reach": "6.347.000"
      },
      "Verizon": {
        "reach": "6.133.000"
      },
      "Xandr": {
        "reach": "7.015.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.746.000"
      },
      "theTradeDesk": {
        "reach": "1.174.000"
      },
      "Adform": {
        "reach": "2.685.000"
      },
      "Verizon": {
        "reach": "2.643.000"
      },
      "Xandr": {
        "reach": "3.000.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "10.166.000"
      },
      "theTradeDesk": {
        "reach": "8.574.000"
      },
      "Adform": {
        "reach": "7.513.000"
      },
      "Verizon": {
        "reach": "7.586.000"
      },
      "Xandr": {
        "reach": "8.354.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.540.000"
      },
      "theTradeDesk": {
        "reach": "2.014.000"
      },
      "Adform": {
        "reach": "1.818.000"
      },
      "Verizon": {
        "reach": "1.859.000"
      },
      "Xandr": {
        "reach": "2.137.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.470.000"
      },
      "RMS": {
        "reach": "1.662.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.205.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.078.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.865.000"
      },
      "theTradeDesk": {
        "reach": "2.383.000"
      },
      "Adform": {
        "reach": "1.996.000"
      },
      "Verizon": {
        "reach": "2.086.000"
      },
      "Xandr": {
        "reach": "2.282.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.503.000"
      },
      "Instagram": {
        "reach": "2.754.000"
      },
      "YouTube": {
        "reach": "10.078.000"
      },
      "Snapchat": {
        "reach": "1.178.000"
      }
    }
  },
  "Kino & Film - Interessierte": {
    "cpm": "3,10€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.327.000"
      },
      "theTradeDesk": {
        "reach": "5.031.000"
      },
      "Adform": {
        "reach": "4.592.000"
      },
      "Verizon": {
        "reach": "4.635.000"
      },
      "Xandr": {
        "reach": "5.254.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.760.000"
      },
      "theTradeDesk": {
        "reach": "2.200.000"
      },
      "Adform": {
        "reach": "1.947.000"
      },
      "Verizon": {
        "reach": "1.912.000"
      },
      "Xandr": {
        "reach": "2.261.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "8.146.000"
      },
      "theTradeDesk": {
        "reach": "6.604.000"
      },
      "Adform": {
        "reach": "5.770.000"
      },
      "Verizon": {
        "reach": "5.815.000"
      },
      "Xandr": {
        "reach": "6.624.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "9.847.000"
      },
      "theTradeDesk": {
        "reach": "7.789.000"
      },
      "Adform": {
        "reach": "7.174.000"
      },
      "Verizon": {
        "reach": "6.787.000"
      },
      "Xandr": {
        "reach": "7.945.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.156.000"
      },
      "theTradeDesk": {
        "reach": "1.339.000"
      },
      "Adform": {
        "reach": "2.992.000"
      },
      "Verizon": {
        "reach": "2.833.000"
      },
      "Xandr": {
        "reach": "3.373.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "11.852.000"
      },
      "theTradeDesk": {
        "reach": "9.471.000"
      },
      "Adform": {
        "reach": "8.338.000"
      },
      "Verizon": {
        "reach": "8.307.000"
      },
      "Xandr": {
        "reach": "9.412.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.903.000"
      },
      "theTradeDesk": {
        "reach": "2.379.000"
      },
      "Adform": {
        "reach": "1.995.000"
      },
      "Verizon": {
        "reach": "1.991.000"
      },
      "Xandr": {
        "reach": "2.298.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.145.000"
      },
      "RMS": {
        "reach": "1.814.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.835.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.319.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.247.000"
      },
      "theTradeDesk": {
        "reach": "2.564.000"
      },
      "Adform": {
        "reach": "2.257.000"
      },
      "Verizon": {
        "reach": "2.147.000"
      },
      "Xandr": {
        "reach": "2.611.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.968.000"
      },
      "Instagram": {
        "reach": "3.173.000"
      },
      "YouTube": {
        "reach": "10.864.000"
      },
      "Snapchat": {
        "reach": "549.000"
      }
    }
  },
  "Kneipen, Bar und Nachtclub- Besucher": {
    "cpm": "3,10€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.586.000"
      },
      "theTradeDesk": {
        "reach": "1.311.000"
      },
      "Adform": {
        "reach": "1.130.000"
      },
      "Verizon": {
        "reach": "1.157.000"
      },
      "Xandr": {
        "reach": "1.298.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "695.000"
      },
      "theTradeDesk": {
        "reach": "539.000"
      },
      "Adform": {
        "reach": "489.000"
      },
      "Verizon": {
        "reach": "499.000"
      },
      "Xandr": {
        "reach": "572.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.143.000"
      },
      "theTradeDesk": {
        "reach": "1.668.000"
      },
      "Adform": {
        "reach": "1.440.000"
      },
      "Verizon": {
        "reach": "1.472.000"
      },
      "Xandr": {
        "reach": "1.703.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.539.000"
      },
      "theTradeDesk": {
        "reach": "1.968.000"
      },
      "Adform": {
        "reach": "1.751.000"
      },
      "Verizon": {
        "reach": "1.772.000"
      },
      "Xandr": {
        "reach": "2.003.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.061.000"
      },
      "theTradeDesk": {
        "reach": "346.000"
      },
      "Adform": {
        "reach": "760.000"
      },
      "Verizon": {
        "reach": "728.000"
      },
      "Xandr": {
        "reach": "869.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.976.000"
      },
      "theTradeDesk": {
        "reach": "2.347.000"
      },
      "Adform": {
        "reach": "2.066.000"
      },
      "Verizon": {
        "reach": "2.173.000"
      },
      "Xandr": {
        "reach": "2.448.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "708.000"
      },
      "theTradeDesk": {
        "reach": "588.000"
      },
      "Adform": {
        "reach": "513.000"
      },
      "Verizon": {
        "reach": "521.000"
      },
      "Xandr": {
        "reach": "592.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "410.000"
      },
      "RMS": {
        "reach": "463.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.478.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "573.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "794.000"
      },
      "theTradeDesk": {
        "reach": "648.000"
      },
      "Adform": {
        "reach": "553.000"
      },
      "Verizon": {
        "reach": "569.000"
      },
      "Xandr": {
        "reach": "651.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.550.000"
      },
      "Instagram": {
        "reach": "806.000"
      },
      "YouTube": {
        "reach": "2.882.000"
      },
      "Snapchat": {
        "reach": "344.000"
      }
    }
  },
  "Elektrogroß- und Kleingeräte": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "11.484.000"
      },
      "theTradeDesk": {
        "reach": "9.056.000"
      },
      "Adform": {
        "reach": "7.974.000"
      },
      "Verizon": {
        "reach": "7.990.000"
      },
      "Xandr": {
        "reach": "8.908.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.832.000"
      },
      "theTradeDesk": {
        "reach": "3.815.000"
      },
      "Adform": {
        "reach": "3.549.000"
      },
      "Verizon": {
        "reach": "3.349.000"
      },
      "Xandr": {
        "reach": "3.673.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "13.824.000"
      },
      "theTradeDesk": {
        "reach": "11.180.000"
      },
      "Adform": {
        "reach": "10.002.000"
      },
      "Verizon": {
        "reach": "10.224.000"
      },
      "Xandr": {
        "reach": "11.248.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "16.828.000"
      },
      "theTradeDesk": {
        "reach": "13.639.000"
      },
      "Adform": {
        "reach": "12.270.000"
      },
      "Verizon": {
        "reach": "11.935.000"
      },
      "Xandr": {
        "reach": "13.586.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "7.265.000"
      },
      "theTradeDesk": {
        "reach": "2.383.000"
      },
      "Adform": {
        "reach": "5.184.000"
      },
      "Verizon": {
        "reach": "5.384.000"
      },
      "Xandr": {
        "reach": "5.583.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "21.159.000"
      },
      "theTradeDesk": {
        "reach": "16.207.000"
      },
      "Adform": {
        "reach": "14.293.000"
      },
      "Verizon": {
        "reach": "14.635.000"
      },
      "Xandr": {
        "reach": "16.505.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.208.000"
      },
      "theTradeDesk": {
        "reach": "4.039.000"
      },
      "Adform": {
        "reach": "3.576.000"
      },
      "Verizon": {
        "reach": "3.408.000"
      },
      "Xandr": {
        "reach": "4.083.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.565.000"
      },
      "RMS": {
        "reach": "3.249.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "10.183.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.148.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "5.726.000"
      },
      "theTradeDesk": {
        "reach": "4.480.000"
      },
      "Adform": {
        "reach": "3.991.000"
      },
      "Verizon": {
        "reach": "4.001.000"
      },
      "Xandr": {
        "reach": "4.478.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "10.136.000"
      },
      "Instagram": {
        "reach": "5.539.000"
      },
      "YouTube": {
        "reach": "19.390.000"
      },
      "Snapchat": {
        "reach": "943.000"
      }
    }
  },
  "Telekommunikation": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "12.897.000"
      },
      "theTradeDesk": {
        "reach": "10.761.000"
      },
      "Adform": {
        "reach": "9.362.000"
      },
      "Verizon": {
        "reach": "9.321.000"
      },
      "Xandr": {
        "reach": "10.215.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.592.000"
      },
      "theTradeDesk": {
        "reach": "4.557.000"
      },
      "Adform": {
        "reach": "4.067.000"
      },
      "Verizon": {
        "reach": "4.082.000"
      },
      "Xandr": {
        "reach": "4.670.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "16.989.000"
      },
      "theTradeDesk": {
        "reach": "13.748.000"
      },
      "Adform": {
        "reach": "12.301.000"
      },
      "Verizon": {
        "reach": "12.064.000"
      },
      "Xandr": {
        "reach": "13.804.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "21.015.000"
      },
      "theTradeDesk": {
        "reach": "16.953.000"
      },
      "Adform": {
        "reach": "13.508.000"
      },
      "Verizon": {
        "reach": "14.923.000"
      },
      "Xandr": {
        "reach": "15.608.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "8.542.000"
      },
      "theTradeDesk": {
        "reach": "2.732.000"
      },
      "Adform": {
        "reach": "6.101.000"
      },
      "Verizon": {
        "reach": "6.017.000"
      },
      "Xandr": {
        "reach": "6.918.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "24.690.000"
      },
      "theTradeDesk": {
        "reach": "19.533.000"
      },
      "Adform": {
        "reach": "17.182.000"
      },
      "Verizon": {
        "reach": "17.203.000"
      },
      "Xandr": {
        "reach": "19.239.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "6.098.000"
      },
      "theTradeDesk": {
        "reach": "4.684.000"
      },
      "Adform": {
        "reach": "4.193.000"
      },
      "Verizon": {
        "reach": "4.338.000"
      },
      "Xandr": {
        "reach": "4.766.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.475.000"
      },
      "RMS": {
        "reach": "3.773.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "12.353.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.842.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.531.000"
      },
      "theTradeDesk": {
        "reach": "5.267.000"
      },
      "Adform": {
        "reach": "4.707.000"
      },
      "Verizon": {
        "reach": "4.736.000"
      },
      "Xandr": {
        "reach": "5.185.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "12.517.000"
      },
      "Instagram": {
        "reach": "6.600.000"
      },
      "YouTube": {
        "reach": "23.799.000"
      },
      "Snapchat": {
        "reach": "2.813.000"
      }
    }
  },
  "Informationstechnologie": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "398.000"
      },
      "theTradeDesk": {
        "reach": "315.000"
      },
      "Adform": {
        "reach": "280.000"
      },
      "Verizon": {
        "reach": "288.000"
      },
      "Xandr": {
        "reach": "323.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "185.000"
      },
      "theTradeDesk": {
        "reach": "140.000"
      },
      "Adform": {
        "reach": "122.000"
      },
      "Verizon": {
        "reach": "120.000"
      },
      "Xandr": {
        "reach": "141.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "516.000"
      },
      "theTradeDesk": {
        "reach": "425.000"
      },
      "Adform": {
        "reach": "363.000"
      },
      "Verizon": {
        "reach": "361.000"
      },
      "Xandr": {
        "reach": "423.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "611.000"
      },
      "theTradeDesk": {
        "reach": "498.000"
      },
      "Adform": {
        "reach": "442.000"
      },
      "Verizon": {
        "reach": "432.000"
      },
      "Xandr": {
        "reach": "516.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "267.000"
      },
      "theTradeDesk": {
        "reach": "87.000"
      },
      "Adform": {
        "reach": "190.000"
      },
      "Verizon": {
        "reach": "183.000"
      },
      "Xandr": {
        "reach": "207.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "767.000"
      },
      "theTradeDesk": {
        "reach": "580.000"
      },
      "Adform": {
        "reach": "512.000"
      },
      "Verizon": {
        "reach": "537.000"
      },
      "Xandr": {
        "reach": "594.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "187.000"
      },
      "theTradeDesk": {
        "reach": "147.000"
      },
      "Adform": {
        "reach": "129.000"
      },
      "Verizon": {
        "reach": "128.000"
      },
      "Xandr": {
        "reach": "150.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "101.000"
      },
      "RMS": {
        "reach": "117.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "366.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "145.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "198.000"
      },
      "theTradeDesk": {
        "reach": "159.000"
      },
      "Adform": {
        "reach": "146.000"
      },
      "Verizon": {
        "reach": "144.000"
      },
      "Xandr": {
        "reach": "161.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "369.000"
      },
      "Instagram": {
        "reach": "192.000"
      },
      "YouTube": {
        "reach": "695.000"
      },
      "Snapchat": {
        "reach": "85.000"
      }
    }
  },
  "Unterhaltungselektronik": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "26.341.000"
      },
      "theTradeDesk": {
        "reach": "20.736.000"
      },
      "Adform": {
        "reach": "18.530.000"
      },
      "Verizon": {
        "reach": "18.454.000"
      },
      "Xandr": {
        "reach": "21.592.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "10.962.000"
      },
      "theTradeDesk": {
        "reach": "8.912.000"
      },
      "Adform": {
        "reach": "7.906.000"
      },
      "Verizon": {
        "reach": "7.866.000"
      },
      "Xandr": {
        "reach": "8.902.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "32.736.000"
      },
      "theTradeDesk": {
        "reach": "27.421.000"
      },
      "Adform": {
        "reach": "24.496.000"
      },
      "Verizon": {
        "reach": "24.196.000"
      },
      "Xandr": {
        "reach": "26.774.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "39.012.000"
      },
      "theTradeDesk": {
        "reach": "31.955.000"
      },
      "Adform": {
        "reach": "28.228.000"
      },
      "Verizon": {
        "reach": "28.299.000"
      },
      "Xandr": {
        "reach": "32.720.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "17.177.000"
      },
      "theTradeDesk": {
        "reach": "5.558.000"
      },
      "Adform": {
        "reach": "11.984.000"
      },
      "Verizon": {
        "reach": "11.597.000"
      },
      "Xandr": {
        "reach": "13.433.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "46.534.000"
      },
      "theTradeDesk": {
        "reach": "39.065.000"
      },
      "Adform": {
        "reach": "33.870.000"
      },
      "Verizon": {
        "reach": "33.808.000"
      },
      "Xandr": {
        "reach": "39.039.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "11.616.000"
      },
      "theTradeDesk": {
        "reach": "9.560.000"
      },
      "Adform": {
        "reach": "8.213.000"
      },
      "Verizon": {
        "reach": "8.147.000"
      },
      "Xandr": {
        "reach": "9.277.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "6.504.000"
      },
      "RMS": {
        "reach": "7.580.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "24.295.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "9.696.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "13.000.000"
      },
      "theTradeDesk": {
        "reach": "10.420.000"
      },
      "Adform": {
        "reach": "9.410.000"
      },
      "Verizon": {
        "reach": "9.218.000"
      },
      "Xandr": {
        "reach": "10.642.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "24.474.000"
      },
      "Instagram": {
        "reach": "12.483.000"
      },
      "YouTube": {
        "reach": "46.643.000"
      },
      "Snapchat": {
        "reach": "3.305.000"
      }
    }
  },
  "Foto": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "7.074.000"
      },
      "theTradeDesk": {
        "reach": "5.486.000"
      },
      "Adform": {
        "reach": "4.766.000"
      },
      "Verizon": {
        "reach": "5.057.000"
      },
      "Xandr": {
        "reach": "5.506.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.939.000"
      },
      "theTradeDesk": {
        "reach": "2.373.000"
      },
      "Adform": {
        "reach": "1.986.000"
      },
      "Verizon": {
        "reach": "2.012.000"
      },
      "Xandr": {
        "reach": "2.388.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "8.443.000"
      },
      "theTradeDesk": {
        "reach": "6.857.000"
      },
      "Adform": {
        "reach": "6.055.000"
      },
      "Verizon": {
        "reach": "6.220.000"
      },
      "Xandr": {
        "reach": "7.028.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "10.371.000"
      },
      "theTradeDesk": {
        "reach": "8.368.000"
      },
      "Adform": {
        "reach": "7.252.000"
      },
      "Verizon": {
        "reach": "7.428.000"
      },
      "Xandr": {
        "reach": "8.239.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.646.000"
      },
      "theTradeDesk": {
        "reach": "1.464.000"
      },
      "Adform": {
        "reach": "3.046.000"
      },
      "Verizon": {
        "reach": "3.185.000"
      },
      "Xandr": {
        "reach": "3.665.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "12.405.000"
      },
      "theTradeDesk": {
        "reach": "10.304.000"
      },
      "Adform": {
        "reach": "8.875.000"
      },
      "Verizon": {
        "reach": "9.140.000"
      },
      "Xandr": {
        "reach": "10.311.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.048.000"
      },
      "theTradeDesk": {
        "reach": "2.545.000"
      },
      "Adform": {
        "reach": "2.178.000"
      },
      "Verizon": {
        "reach": "2.160.000"
      },
      "Xandr": {
        "reach": "2.520.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.724.000"
      },
      "RMS": {
        "reach": "1.992.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "6.177.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.507.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.288.000"
      },
      "theTradeDesk": {
        "reach": "2.803.000"
      },
      "Adform": {
        "reach": "2.428.000"
      },
      "Verizon": {
        "reach": "2.437.000"
      },
      "Xandr": {
        "reach": "2.753.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "6.514.000"
      },
      "Instagram": {
        "reach": "3.334.000"
      },
      "YouTube": {
        "reach": "11.882.000"
      },
      "Snapchat": {
        "reach": "1.506.000"
      }
    }
  },
  "Elektronik, Computer & Elektrogroßgeräte - niedrige Kaufkraft": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "12.515.000"
      },
      "theTradeDesk": {
        "reach": "10.281.000"
      },
      "Adform": {
        "reach": "8.784.000"
      },
      "Verizon": {
        "reach": "9.231.000"
      },
      "Xandr": {
        "reach": "10.139.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.523.000"
      },
      "theTradeDesk": {
        "reach": "4.440.000"
      },
      "Adform": {
        "reach": "3.822.000"
      },
      "Verizon": {
        "reach": "3.808.000"
      },
      "Xandr": {
        "reach": "4.284.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "16.595.000"
      },
      "theTradeDesk": {
        "reach": "12.915.000"
      },
      "Adform": {
        "reach": "11.745.000"
      },
      "Verizon": {
        "reach": "11.481.000"
      },
      "Xandr": {
        "reach": "12.952.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "19.749.000"
      },
      "theTradeDesk": {
        "reach": "15.341.000"
      },
      "Adform": {
        "reach": "13.573.000"
      },
      "Verizon": {
        "reach": "12.932.000"
      },
      "Xandr": {
        "reach": "15.012.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "8.322.000"
      },
      "theTradeDesk": {
        "reach": "2.592.000"
      },
      "Adform": {
        "reach": "5.855.000"
      },
      "Verizon": {
        "reach": "5.867.000"
      },
      "Xandr": {
        "reach": "6.548.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "24.114.000"
      },
      "theTradeDesk": {
        "reach": "17.566.000"
      },
      "Adform": {
        "reach": "15.908.000"
      },
      "Verizon": {
        "reach": "16.414.000"
      },
      "Xandr": {
        "reach": "18.980.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.648.000"
      },
      "theTradeDesk": {
        "reach": "4.618.000"
      },
      "Adform": {
        "reach": "3.937.000"
      },
      "Verizon": {
        "reach": "3.911.000"
      },
      "Xandr": {
        "reach": "4.659.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.175.000"
      },
      "RMS": {
        "reach": "3.677.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "11.568.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.495.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.446.000"
      },
      "theTradeDesk": {
        "reach": "5.068.000"
      },
      "Adform": {
        "reach": "4.287.000"
      },
      "Verizon": {
        "reach": "4.336.000"
      },
      "Xandr": {
        "reach": "4.847.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "11.744.000"
      },
      "Instagram": {
        "reach": "6.137.000"
      },
      "YouTube": {
        "reach": "22.714.000"
      },
      "Snapchat": {
        "reach": "2.720.000"
      }
    }
  },
  "Elektronik, Computer & Elektrogroßgeräte - mittlere Kaufkraft": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "7.460.000"
      },
      "theTradeDesk": {
        "reach": "5.866.000"
      },
      "Adform": {
        "reach": "5.066.000"
      },
      "Verizon": {
        "reach": "5.085.000"
      },
      "Xandr": {
        "reach": "5.715.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.255.000"
      },
      "theTradeDesk": {
        "reach": "2.543.000"
      },
      "Adform": {
        "reach": "2.086.000"
      },
      "Verizon": {
        "reach": "2.159.000"
      },
      "Xandr": {
        "reach": "2.467.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "9.452.000"
      },
      "theTradeDesk": {
        "reach": "7.295.000"
      },
      "Adform": {
        "reach": "6.864.000"
      },
      "Verizon": {
        "reach": "6.594.000"
      },
      "Xandr": {
        "reach": "7.712.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "11.224.000"
      },
      "theTradeDesk": {
        "reach": "9.010.000"
      },
      "Adform": {
        "reach": "8.034.000"
      },
      "Verizon": {
        "reach": "8.283.000"
      },
      "Xandr": {
        "reach": "9.273.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.626.000"
      },
      "theTradeDesk": {
        "reach": "1.555.000"
      },
      "Adform": {
        "reach": "3.361.000"
      },
      "Verizon": {
        "reach": "3.322.000"
      },
      "Xandr": {
        "reach": "3.883.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "13.362.000"
      },
      "theTradeDesk": {
        "reach": "10.693.000"
      },
      "Adform": {
        "reach": "9.671.000"
      },
      "Verizon": {
        "reach": "9.463.000"
      },
      "Xandr": {
        "reach": "10.808.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.317.000"
      },
      "theTradeDesk": {
        "reach": "2.672.000"
      },
      "Adform": {
        "reach": "2.314.000"
      },
      "Verizon": {
        "reach": "2.316.000"
      },
      "Xandr": {
        "reach": "2.669.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.828.000"
      },
      "RMS": {
        "reach": "2.100.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "6.528.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.697.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.597.000"
      },
      "theTradeDesk": {
        "reach": "2.931.000"
      },
      "Adform": {
        "reach": "2.576.000"
      },
      "Verizon": {
        "reach": "2.552.000"
      },
      "Xandr": {
        "reach": "2.974.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "6.996.000"
      },
      "Instagram": {
        "reach": "3.545.000"
      },
      "YouTube": {
        "reach": "13.131.000"
      },
      "Snapchat": {
        "reach": "1.562.000"
      }
    }
  },
  "Elektronik, Computer & Elektrogroßgeräte - hohe Kaufkraft": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "95.000"
      },
      "theTradeDesk": {
        "reach": "79.000"
      },
      "Adform": {
        "reach": "64.000"
      },
      "Verizon": {
        "reach": "66.000"
      },
      "Xandr": {
        "reach": "77.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "41.000"
      },
      "theTradeDesk": {
        "reach": "33.000"
      },
      "Adform": {
        "reach": "29.000"
      },
      "Verizon": {
        "reach": "29.000"
      },
      "Xandr": {
        "reach": "33.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "127.000"
      },
      "theTradeDesk": {
        "reach": "99.000"
      },
      "Adform": {
        "reach": "85.000"
      },
      "Verizon": {
        "reach": "85.000"
      },
      "Xandr": {
        "reach": "94.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "140.000"
      },
      "theTradeDesk": {
        "reach": "116.000"
      },
      "Adform": {
        "reach": "103.000"
      },
      "Verizon": {
        "reach": "102.000"
      },
      "Xandr": {
        "reach": "115.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "63.000"
      },
      "theTradeDesk": {
        "reach": "19.000"
      },
      "Adform": {
        "reach": "45.000"
      },
      "Verizon": {
        "reach": "45.000"
      },
      "Xandr": {
        "reach": "49.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "179.000"
      },
      "theTradeDesk": {
        "reach": "131.000"
      },
      "Adform": {
        "reach": "119.000"
      },
      "Verizon": {
        "reach": "125.000"
      },
      "Xandr": {
        "reach": "146.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "43.000"
      },
      "theTradeDesk": {
        "reach": "33.000"
      },
      "Adform": {
        "reach": "29.000"
      },
      "Verizon": {
        "reach": "30.000"
      },
      "Xandr": {
        "reach": "35.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "24.000"
      },
      "RMS": {
        "reach": "28.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "85.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "35.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "48.000"
      },
      "theTradeDesk": {
        "reach": "38.000"
      },
      "Adform": {
        "reach": "33.000"
      },
      "Verizon": {
        "reach": "33.000"
      },
      "Xandr": {
        "reach": "39.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "88.000"
      },
      "Instagram": {
        "reach": "47.000"
      },
      "YouTube": {
        "reach": "166.000"
      },
      "Snapchat": {
        "reach": "20.000"
      }
    }
  },
  "Fast-Food: Spontankunden": {
    "cpm": "3,10€",
    "Desktop - Display": {
      "DV360": {
        "reach": "13.139.000"
      },
      "theTradeDesk": {
        "reach": "10.300.000"
      },
      "Adform": {
        "reach": "9.269.000"
      },
      "Verizon": {
        "reach": "9.172.000"
      },
      "Xandr": {
        "reach": "10.332.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.512.000"
      },
      "theTradeDesk": {
        "reach": "4.339.000"
      },
      "Adform": {
        "reach": "3.829.000"
      },
      "Verizon": {
        "reach": "3.845.000"
      },
      "Xandr": {
        "reach": "4.546.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "16.625.000"
      },
      "theTradeDesk": {
        "reach": "13.220.000"
      },
      "Adform": {
        "reach": "11.721.000"
      },
      "Verizon": {
        "reach": "11.432.000"
      },
      "Xandr": {
        "reach": "12.598.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "19.445.000"
      },
      "theTradeDesk": {
        "reach": "15.562.000"
      },
      "Adform": {
        "reach": "13.711.000"
      },
      "Verizon": {
        "reach": "14.107.000"
      },
      "Xandr": {
        "reach": "15.895.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "8.378.000"
      },
      "theTradeDesk": {
        "reach": "2.629.000"
      },
      "Adform": {
        "reach": "6.054.000"
      },
      "Verizon": {
        "reach": "5.940.000"
      },
      "Xandr": {
        "reach": "6.986.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "24.517.000"
      },
      "theTradeDesk": {
        "reach": "19.359.000"
      },
      "Adform": {
        "reach": "16.416.000"
      },
      "Verizon": {
        "reach": "16.792.000"
      },
      "Xandr": {
        "reach": "18.860.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.765.000"
      },
      "theTradeDesk": {
        "reach": "4.755.000"
      },
      "Adform": {
        "reach": "4.105.000"
      },
      "Verizon": {
        "reach": "4.080.000"
      },
      "Xandr": {
        "reach": "4.800.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.587.000"
      },
      "RMS": {
        "reach": "3.787.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "11.809.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.516.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.466.000"
      },
      "theTradeDesk": {
        "reach": "5.137.000"
      },
      "Adform": {
        "reach": "4.565.000"
      },
      "Verizon": {
        "reach": "4.618.000"
      },
      "Xandr": {
        "reach": "5.262.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "11.737.000"
      },
      "Instagram": {
        "reach": "6.286.000"
      },
      "YouTube": {
        "reach": "22.681.000"
      },
      "Snapchat": {
        "reach": "1.883.000"
      }
    }
  },
  "Fast-Food: Regulars": {
    "cpm": "3,10€",
    "Desktop - Display": {
      "DV360": {
        "reach": "4.812.000"
      },
      "theTradeDesk": {
        "reach": "3.876.000"
      },
      "Adform": {
        "reach": "3.339.000"
      },
      "Verizon": {
        "reach": "3.450.000"
      },
      "Xandr": {
        "reach": "4.005.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.070.000"
      },
      "theTradeDesk": {
        "reach": "1.665.000"
      },
      "Adform": {
        "reach": "1.469.000"
      },
      "Verizon": {
        "reach": "1.442.000"
      },
      "Xandr": {
        "reach": "1.647.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "6.103.000"
      },
      "theTradeDesk": {
        "reach": "4.865.000"
      },
      "Adform": {
        "reach": "4.300.000"
      },
      "Verizon": {
        "reach": "4.298.000"
      },
      "Xandr": {
        "reach": "5.037.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "7.448.000"
      },
      "theTradeDesk": {
        "reach": "6.036.000"
      },
      "Adform": {
        "reach": "5.035.000"
      },
      "Verizon": {
        "reach": "5.565.000"
      },
      "Xandr": {
        "reach": "5.681.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.120.000"
      },
      "theTradeDesk": {
        "reach": "997.000"
      },
      "Adform": {
        "reach": "2.172.000"
      },
      "Verizon": {
        "reach": "2.260.000"
      },
      "Xandr": {
        "reach": "2.538.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "8.982.000"
      },
      "theTradeDesk": {
        "reach": "7.088.000"
      },
      "Adform": {
        "reach": "6.160.000"
      },
      "Verizon": {
        "reach": "6.191.000"
      },
      "Xandr": {
        "reach": "7.117.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.145.000"
      },
      "theTradeDesk": {
        "reach": "1.764.000"
      },
      "Adform": {
        "reach": "1.552.000"
      },
      "Verizon": {
        "reach": "1.519.000"
      },
      "Xandr": {
        "reach": "1.733.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.177.000"
      },
      "RMS": {
        "reach": "1.351.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "4.427.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.749.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.440.000"
      },
      "theTradeDesk": {
        "reach": "1.937.000"
      },
      "Adform": {
        "reach": "1.721.000"
      },
      "Verizon": {
        "reach": "1.694.000"
      },
      "Xandr": {
        "reach": "1.956.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "4.562.000"
      },
      "Instagram": {
        "reach": "2.361.000"
      },
      "YouTube": {
        "reach": "8.495.000"
      },
      "Snapchat": {
        "reach": "1.025.000"
      }
    }
  },
  "Fast-Food: Enthusiasten": {
    "cpm": "3,10€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.538.000"
      },
      "theTradeDesk": {
        "reach": "1.205.000"
      },
      "Adform": {
        "reach": "1.060.000"
      },
      "Verizon": {
        "reach": "1.050.000"
      },
      "Xandr": {
        "reach": "1.246.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "619.000"
      },
      "theTradeDesk": {
        "reach": "507.000"
      },
      "Adform": {
        "reach": "458.000"
      },
      "Verizon": {
        "reach": "456.000"
      },
      "Xandr": {
        "reach": "522.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.972.000"
      },
      "theTradeDesk": {
        "reach": "1.516.000"
      },
      "Adform": {
        "reach": "1.365.000"
      },
      "Verizon": {
        "reach": "1.369.000"
      },
      "Xandr": {
        "reach": "1.596.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.404.000"
      },
      "theTradeDesk": {
        "reach": "1.839.000"
      },
      "Adform": {
        "reach": "1.669.000"
      },
      "Verizon": {
        "reach": "1.607.000"
      },
      "Xandr": {
        "reach": "1.890.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.006.000"
      },
      "theTradeDesk": {
        "reach": "317.000"
      },
      "Adform": {
        "reach": "699.000"
      },
      "Verizon": {
        "reach": "698.000"
      },
      "Xandr": {
        "reach": "799.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.727.000"
      },
      "theTradeDesk": {
        "reach": "2.262.000"
      },
      "Adform": {
        "reach": "1.885.000"
      },
      "Verizon": {
        "reach": "1.948.000"
      },
      "Xandr": {
        "reach": "2.198.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "711.000"
      },
      "theTradeDesk": {
        "reach": "557.000"
      },
      "Adform": {
        "reach": "490.000"
      },
      "Verizon": {
        "reach": "493.000"
      },
      "Xandr": {
        "reach": "552.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "388.000"
      },
      "RMS": {
        "reach": "444.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.337.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "539.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "757.000"
      },
      "theTradeDesk": {
        "reach": "618.000"
      },
      "Adform": {
        "reach": "527.000"
      },
      "Verizon": {
        "reach": "541.000"
      },
      "Xandr": {
        "reach": "583.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.457.000"
      },
      "Instagram": {
        "reach": "727.000"
      },
      "YouTube": {
        "reach": "2.681.000"
      },
      "Snapchat": {
        "reach": "316.000"
      }
    }
  },
  "Nahrungsmittel": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "16.867.000"
      },
      "theTradeDesk": {
        "reach": "13.739.000"
      },
      "Adform": {
        "reach": "11.240.000"
      },
      "Verizon": {
        "reach": "12.116.000"
      },
      "Xandr": {
        "reach": "13.414.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "7.200.000"
      },
      "theTradeDesk": {
        "reach": "5.793.000"
      },
      "Adform": {
        "reach": "5.186.000"
      },
      "Verizon": {
        "reach": "5.130.000"
      },
      "Xandr": {
        "reach": "5.743.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "21.639.000"
      },
      "theTradeDesk": {
        "reach": "17.023.000"
      },
      "Adform": {
        "reach": "15.057.000"
      },
      "Verizon": {
        "reach": "14.836.000"
      },
      "Xandr": {
        "reach": "16.977.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "25.549.000"
      },
      "theTradeDesk": {
        "reach": "20.675.000"
      },
      "Adform": {
        "reach": "17.882.000"
      },
      "Verizon": {
        "reach": "17.935.000"
      },
      "Xandr": {
        "reach": "20.653.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "11.201.000"
      },
      "theTradeDesk": {
        "reach": "3.459.000"
      },
      "Adform": {
        "reach": "7.559.000"
      },
      "Verizon": {
        "reach": "7.710.000"
      },
      "Xandr": {
        "reach": "8.674.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "31.044.000"
      },
      "theTradeDesk": {
        "reach": "25.672.000"
      },
      "Adform": {
        "reach": "21.515.000"
      },
      "Verizon": {
        "reach": "21.385.000"
      },
      "Xandr": {
        "reach": "24.081.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "7.830.000"
      },
      "theTradeDesk": {
        "reach": "6.042.000"
      },
      "Adform": {
        "reach": "5.254.000"
      },
      "Verizon": {
        "reach": "5.318.000"
      },
      "Xandr": {
        "reach": "6.019.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.270.000"
      },
      "RMS": {
        "reach": "4.689.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "15.336.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "6.085.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "8.414.000"
      },
      "theTradeDesk": {
        "reach": "6.722.000"
      },
      "Adform": {
        "reach": "5.789.000"
      },
      "Verizon": {
        "reach": "5.882.000"
      },
      "Xandr": {
        "reach": "6.621.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "15.266.000"
      },
      "Instagram": {
        "reach": "8.174.000"
      },
      "YouTube": {
        "reach": "29.817.000"
      },
      "Snapchat": {
        "reach": "2.067.000"
      }
    }
  },
  "Nahrungsmittel - Premium Präferenz": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.088.000"
      },
      "theTradeDesk": {
        "reach": "2.470.000"
      },
      "Adform": {
        "reach": "2.211.000"
      },
      "Verizon": {
        "reach": "2.161.000"
      },
      "Xandr": {
        "reach": "2.542.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.333.000"
      },
      "theTradeDesk": {
        "reach": "1.080.000"
      },
      "Adform": {
        "reach": "949.000"
      },
      "Verizon": {
        "reach": "924.000"
      },
      "Xandr": {
        "reach": "1.107.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.959.000"
      },
      "theTradeDesk": {
        "reach": "3.279.000"
      },
      "Adform": {
        "reach": "2.793.000"
      },
      "Verizon": {
        "reach": "2.754.000"
      },
      "Xandr": {
        "reach": "3.305.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.798.000"
      },
      "theTradeDesk": {
        "reach": "3.834.000"
      },
      "Adform": {
        "reach": "3.412.000"
      },
      "Verizon": {
        "reach": "3.339.000"
      },
      "Xandr": {
        "reach": "3.784.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.010.000"
      },
      "theTradeDesk": {
        "reach": "639.000"
      },
      "Adform": {
        "reach": "1.425.000"
      },
      "Verizon": {
        "reach": "1.418.000"
      },
      "Xandr": {
        "reach": "1.644.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "5.672.000"
      },
      "theTradeDesk": {
        "reach": "4.420.000"
      },
      "Adform": {
        "reach": "4.150.000"
      },
      "Verizon": {
        "reach": "3.890.000"
      },
      "Xandr": {
        "reach": "4.529.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.409.000"
      },
      "theTradeDesk": {
        "reach": "1.096.000"
      },
      "Adform": {
        "reach": "983.000"
      },
      "Verizon": {
        "reach": "966.000"
      },
      "Xandr": {
        "reach": "1.111.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "781.000"
      },
      "RMS": {
        "reach": "854.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.833.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.166.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.586.000"
      },
      "theTradeDesk": {
        "reach": "1.230.000"
      },
      "Adform": {
        "reach": "1.124.000"
      },
      "Verizon": {
        "reach": "1.091.000"
      },
      "Xandr": {
        "reach": "1.242.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.883.000"
      },
      "Instagram": {
        "reach": "1.463.000"
      },
      "YouTube": {
        "reach": "5.522.000"
      },
      "Snapchat": {
        "reach": "654.000"
      }
    }
  },
  "Alkoholfreie Getränke": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "16.309.000"
      },
      "theTradeDesk": {
        "reach": "13.836.000"
      },
      "Adform": {
        "reach": "11.678.000"
      },
      "Verizon": {
        "reach": "12.275.000"
      },
      "Xandr": {
        "reach": "13.498.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "7.592.000"
      },
      "theTradeDesk": {
        "reach": "5.910.000"
      },
      "Adform": {
        "reach": "5.113.000"
      },
      "Verizon": {
        "reach": "5.175.000"
      },
      "Xandr": {
        "reach": "5.781.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "22.495.000"
      },
      "theTradeDesk": {
        "reach": "17.105.000"
      },
      "Adform": {
        "reach": "15.233.000"
      },
      "Verizon": {
        "reach": "15.241.000"
      },
      "Xandr": {
        "reach": "17.864.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "25.518.000"
      },
      "theTradeDesk": {
        "reach": "20.451.000"
      },
      "Adform": {
        "reach": "19.207.000"
      },
      "Verizon": {
        "reach": "18.617.000"
      },
      "Xandr": {
        "reach": "20.600.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "11.272.000"
      },
      "theTradeDesk": {
        "reach": "3.556.000"
      },
      "Adform": {
        "reach": "7.897.000"
      },
      "Verizon": {
        "reach": "7.963.000"
      },
      "Xandr": {
        "reach": "9.156.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "31.517.000"
      },
      "theTradeDesk": {
        "reach": "25.526.000"
      },
      "Adform": {
        "reach": "22.064.000"
      },
      "Verizon": {
        "reach": "22.080.000"
      },
      "Xandr": {
        "reach": "24.806.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "8.085.000"
      },
      "theTradeDesk": {
        "reach": "6.023.000"
      },
      "Adform": {
        "reach": "5.553.000"
      },
      "Verizon": {
        "reach": "5.595.000"
      },
      "Xandr": {
        "reach": "6.357.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.383.000"
      },
      "RMS": {
        "reach": "4.765.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "15.673.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "6.392.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "8.498.000"
      },
      "theTradeDesk": {
        "reach": "6.904.000"
      },
      "Adform": {
        "reach": "5.922.000"
      },
      "Verizon": {
        "reach": "6.095.000"
      },
      "Xandr": {
        "reach": "6.945.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "16.486.000"
      },
      "Instagram": {
        "reach": "8.410.000"
      },
      "YouTube": {
        "reach": "30.111.000"
      },
      "Snapchat": {
        "reach": "2.189.000"
      }
    }
  },
  "Alkoholische Getränke": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "12.581.000"
      },
      "theTradeDesk": {
        "reach": "9.952.000"
      },
      "Adform": {
        "reach": "8.300.000"
      },
      "Verizon": {
        "reach": "8.576.000"
      },
      "Xandr": {
        "reach": "9.631.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.216.000"
      },
      "theTradeDesk": {
        "reach": "4.236.000"
      },
      "Adform": {
        "reach": "3.641.000"
      },
      "Verizon": {
        "reach": "3.549.000"
      },
      "Xandr": {
        "reach": "4.211.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "15.518.000"
      },
      "theTradeDesk": {
        "reach": "12.631.000"
      },
      "Adform": {
        "reach": "11.276.000"
      },
      "Verizon": {
        "reach": "11.357.000"
      },
      "Xandr": {
        "reach": "12.708.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "18.976.000"
      },
      "theTradeDesk": {
        "reach": "15.335.000"
      },
      "Adform": {
        "reach": "13.098.000"
      },
      "Verizon": {
        "reach": "13.453.000"
      },
      "Xandr": {
        "reach": "15.055.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "8.073.000"
      },
      "theTradeDesk": {
        "reach": "2.473.000"
      },
      "Adform": {
        "reach": "5.693.000"
      },
      "Verizon": {
        "reach": "5.712.000"
      },
      "Xandr": {
        "reach": "6.731.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "23.045.000"
      },
      "theTradeDesk": {
        "reach": "18.032.000"
      },
      "Adform": {
        "reach": "16.094.000"
      },
      "Verizon": {
        "reach": "15.515.000"
      },
      "Xandr": {
        "reach": "18.527.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.475.000"
      },
      "theTradeDesk": {
        "reach": "4.246.000"
      },
      "Adform": {
        "reach": "3.915.000"
      },
      "Verizon": {
        "reach": "3.883.000"
      },
      "Xandr": {
        "reach": "4.500.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.484.000"
      },
      "RMS": {
        "reach": "3.545.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "11.033.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.510.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.278.000"
      },
      "theTradeDesk": {
        "reach": "4.651.000"
      },
      "Adform": {
        "reach": "4.101.000"
      },
      "Verizon": {
        "reach": "4.371.000"
      },
      "Xandr": {
        "reach": "5.052.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "11.399.000"
      },
      "Instagram": {
        "reach": "6.015.000"
      },
      "YouTube": {
        "reach": "21.659.000"
      },
      "Snapchat": {
        "reach": "1.499.000"
      }
    }
  },
  "Tabakwaren": {
    "cpm": "2,80€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.962.000"
      },
      "theTradeDesk": {
        "reach": "5.575.000"
      },
      "Adform": {
        "reach": "4.995.000"
      },
      "Verizon": {
        "reach": "4.824.000"
      },
      "Xandr": {
        "reach": "5.593.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.022.000"
      },
      "theTradeDesk": {
        "reach": "2.467.000"
      },
      "Adform": {
        "reach": "2.140.000"
      },
      "Verizon": {
        "reach": "2.168.000"
      },
      "Xandr": {
        "reach": "2.406.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "9.148.000"
      },
      "theTradeDesk": {
        "reach": "7.335.000"
      },
      "Adform": {
        "reach": "6.251.000"
      },
      "Verizon": {
        "reach": "6.261.000"
      },
      "Xandr": {
        "reach": "7.215.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "10.540.000"
      },
      "theTradeDesk": {
        "reach": "8.656.000"
      },
      "Adform": {
        "reach": "7.684.000"
      },
      "Verizon": {
        "reach": "7.473.000"
      },
      "Xandr": {
        "reach": "8.709.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.482.000"
      },
      "theTradeDesk": {
        "reach": "1.491.000"
      },
      "Adform": {
        "reach": "3.129.000"
      },
      "Verizon": {
        "reach": "3.185.000"
      },
      "Xandr": {
        "reach": "3.697.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "13.013.000"
      },
      "theTradeDesk": {
        "reach": "10.454.000"
      },
      "Adform": {
        "reach": "9.219.000"
      },
      "Verizon": {
        "reach": "8.869.000"
      },
      "Xandr": {
        "reach": "10.803.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.259.000"
      },
      "theTradeDesk": {
        "reach": "2.594.000"
      },
      "Adform": {
        "reach": "2.230.000"
      },
      "Verizon": {
        "reach": "2.185.000"
      },
      "Xandr": {
        "reach": "2.508.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.740.000"
      },
      "RMS": {
        "reach": "2.052.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "6.331.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.548.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.375.000"
      },
      "theTradeDesk": {
        "reach": "2.898.000"
      },
      "Adform": {
        "reach": "2.483.000"
      },
      "Verizon": {
        "reach": "2.338.000"
      },
      "Xandr": {
        "reach": "2.744.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "6.579.000"
      },
      "Instagram": {
        "reach": "3.437.000"
      },
      "YouTube": {
        "reach": "12.060.000"
      },
      "Snapchat": {
        "reach": "886.000"
      }
    }
  },
  "Wasch-, Putz- und Reinigungsmittel": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "8.344.000"
      },
      "theTradeDesk": {
        "reach": "6.807.000"
      },
      "Adform": {
        "reach": "6.068.000"
      },
      "Verizon": {
        "reach": "5.777.000"
      },
      "Xandr": {
        "reach": "6.838.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.630.000"
      },
      "theTradeDesk": {
        "reach": "2.935.000"
      },
      "Adform": {
        "reach": "2.472.000"
      },
      "Verizon": {
        "reach": "2.543.000"
      },
      "Xandr": {
        "reach": "2.906.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "11.003.000"
      },
      "theTradeDesk": {
        "reach": "8.389.000"
      },
      "Adform": {
        "reach": "7.726.000"
      },
      "Verizon": {
        "reach": "7.992.000"
      },
      "Xandr": {
        "reach": "8.794.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "13.418.000"
      },
      "theTradeDesk": {
        "reach": "10.223.000"
      },
      "Adform": {
        "reach": "9.242.000"
      },
      "Verizon": {
        "reach": "9.411.000"
      },
      "Xandr": {
        "reach": "10.318.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.607.000"
      },
      "theTradeDesk": {
        "reach": "1.795.000"
      },
      "Adform": {
        "reach": "3.839.000"
      },
      "Verizon": {
        "reach": "3.766.000"
      },
      "Xandr": {
        "reach": "4.584.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "15.808.000"
      },
      "theTradeDesk": {
        "reach": "12.910.000"
      },
      "Adform": {
        "reach": "10.733.000"
      },
      "Verizon": {
        "reach": "10.823.000"
      },
      "Xandr": {
        "reach": "12.546.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.979.000"
      },
      "theTradeDesk": {
        "reach": "3.101.000"
      },
      "Adform": {
        "reach": "2.642.000"
      },
      "Verizon": {
        "reach": "2.714.000"
      },
      "Xandr": {
        "reach": "3.032.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.121.000"
      },
      "RMS": {
        "reach": "2.454.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "8.040.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.255.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "4.327.000"
      },
      "theTradeDesk": {
        "reach": "3.283.000"
      },
      "Adform": {
        "reach": "2.919.000"
      },
      "Verizon": {
        "reach": "2.941.000"
      },
      "Xandr": {
        "reach": "3.455.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "7.956.000"
      },
      "Instagram": {
        "reach": "4.039.000"
      },
      "YouTube": {
        "reach": "15.132.000"
      },
      "Snapchat": {
        "reach": "1.077.000"
      }
    }
  },
  "Körperpflege": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "15.574.000"
      },
      "theTradeDesk": {
        "reach": "12.261.000"
      },
      "Adform": {
        "reach": "10.463.000"
      },
      "Verizon": {
        "reach": "10.415.000"
      },
      "Xandr": {
        "reach": "12.242.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "6.504.000"
      },
      "theTradeDesk": {
        "reach": "5.348.000"
      },
      "Adform": {
        "reach": "4.648.000"
      },
      "Verizon": {
        "reach": "4.599.000"
      },
      "Xandr": {
        "reach": "5.362.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "19.458.000"
      },
      "theTradeDesk": {
        "reach": "15.681.000"
      },
      "Adform": {
        "reach": "13.915.000"
      },
      "Verizon": {
        "reach": "13.584.000"
      },
      "Xandr": {
        "reach": "15.109.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "24.009.000"
      },
      "theTradeDesk": {
        "reach": "18.187.000"
      },
      "Adform": {
        "reach": "15.859.000"
      },
      "Verizon": {
        "reach": "15.982.000"
      },
      "Xandr": {
        "reach": "18.734.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "9.996.000"
      },
      "theTradeDesk": {
        "reach": "3.273.000"
      },
      "Adform": {
        "reach": "6.866.000"
      },
      "Verizon": {
        "reach": "6.895.000"
      },
      "Xandr": {
        "reach": "8.054.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "28.267.000"
      },
      "theTradeDesk": {
        "reach": "22.880.000"
      },
      "Adform": {
        "reach": "19.613.000"
      },
      "Verizon": {
        "reach": "19.710.000"
      },
      "Xandr": {
        "reach": "22.281.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "6.639.000"
      },
      "theTradeDesk": {
        "reach": "5.589.000"
      },
      "Adform": {
        "reach": "4.987.000"
      },
      "Verizon": {
        "reach": "4.770.000"
      },
      "Xandr": {
        "reach": "5.369.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.066.000"
      },
      "RMS": {
        "reach": "4.502.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "13.984.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.513.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "7.589.000"
      },
      "theTradeDesk": {
        "reach": "6.024.000"
      },
      "Adform": {
        "reach": "5.345.000"
      },
      "Verizon": {
        "reach": "5.113.000"
      },
      "Xandr": {
        "reach": "6.207.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "14.222.000"
      },
      "Instagram": {
        "reach": "7.215.000"
      },
      "YouTube": {
        "reach": "26.414.000"
      },
      "Snapchat": {
        "reach": "1.921.000"
      }
    }
  },
  "Damenmode": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.086.000"
      },
      "theTradeDesk": {
        "reach": "4.949.000"
      },
      "Adform": {
        "reach": "4.259.000"
      },
      "Verizon": {
        "reach": "4.213.000"
      },
      "Xandr": {
        "reach": "4.879.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.574.000"
      },
      "theTradeDesk": {
        "reach": "2.045.000"
      },
      "Adform": {
        "reach": "1.845.000"
      },
      "Verizon": {
        "reach": "1.811.000"
      },
      "Xandr": {
        "reach": "2.066.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "7.869.000"
      },
      "theTradeDesk": {
        "reach": "5.865.000"
      },
      "Adform": {
        "reach": "5.626.000"
      },
      "Verizon": {
        "reach": "5.429.000"
      },
      "Xandr": {
        "reach": "6.372.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "9.333.000"
      },
      "theTradeDesk": {
        "reach": "7.173.000"
      },
      "Adform": {
        "reach": "6.408.000"
      },
      "Verizon": {
        "reach": "6.452.000"
      },
      "Xandr": {
        "reach": "7.655.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.987.000"
      },
      "theTradeDesk": {
        "reach": "1.245.000"
      },
      "Adform": {
        "reach": "2.730.000"
      },
      "Verizon": {
        "reach": "2.777.000"
      },
      "Xandr": {
        "reach": "3.113.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "11.259.000"
      },
      "theTradeDesk": {
        "reach": "8.984.000"
      },
      "Adform": {
        "reach": "7.833.000"
      },
      "Verizon": {
        "reach": "7.861.000"
      },
      "Xandr": {
        "reach": "8.720.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.734.000"
      },
      "theTradeDesk": {
        "reach": "2.103.000"
      },
      "Adform": {
        "reach": "1.905.000"
      },
      "Verizon": {
        "reach": "1.887.000"
      },
      "Xandr": {
        "reach": "2.213.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.521.000"
      },
      "RMS": {
        "reach": "1.683.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.432.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.116.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.052.000"
      },
      "theTradeDesk": {
        "reach": "2.352.000"
      },
      "Adform": {
        "reach": "2.053.000"
      },
      "Verizon": {
        "reach": "2.176.000"
      },
      "Xandr": {
        "reach": "2.393.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.700.000"
      },
      "Instagram": {
        "reach": "2.897.000"
      },
      "YouTube": {
        "reach": "10.480.000"
      },
      "Snapchat": {
        "reach": "1.243.000"
      }
    }
  },
  "Herrenmode": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "7.863.000"
      },
      "theTradeDesk": {
        "reach": "6.158.000"
      },
      "Adform": {
        "reach": "5.494.000"
      },
      "Verizon": {
        "reach": "5.436.000"
      },
      "Xandr": {
        "reach": "6.295.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.401.000"
      },
      "theTradeDesk": {
        "reach": "2.575.000"
      },
      "Adform": {
        "reach": "2.306.000"
      },
      "Verizon": {
        "reach": "2.431.000"
      },
      "Xandr": {
        "reach": "2.755.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "9.859.000"
      },
      "theTradeDesk": {
        "reach": "7.800.000"
      },
      "Adform": {
        "reach": "7.077.000"
      },
      "Verizon": {
        "reach": "6.918.000"
      },
      "Xandr": {
        "reach": "7.988.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "11.846.000"
      },
      "theTradeDesk": {
        "reach": "9.626.000"
      },
      "Adform": {
        "reach": "8.090.000"
      },
      "Verizon": {
        "reach": "8.703.000"
      },
      "Xandr": {
        "reach": "9.456.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.278.000"
      },
      "theTradeDesk": {
        "reach": "1.560.000"
      },
      "Adform": {
        "reach": "3.489.000"
      },
      "Verizon": {
        "reach": "3.509.000"
      },
      "Xandr": {
        "reach": "4.104.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "14.319.000"
      },
      "theTradeDesk": {
        "reach": "11.678.000"
      },
      "Adform": {
        "reach": "9.827.000"
      },
      "Verizon": {
        "reach": "9.710.000"
      },
      "Xandr": {
        "reach": "11.462.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.450.000"
      },
      "theTradeDesk": {
        "reach": "2.761.000"
      },
      "Adform": {
        "reach": "2.399.000"
      },
      "Verizon": {
        "reach": "2.507.000"
      },
      "Xandr": {
        "reach": "2.725.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.881.000"
      },
      "RMS": {
        "reach": "2.144.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "7.189.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.854.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.871.000"
      },
      "theTradeDesk": {
        "reach": "3.126.000"
      },
      "Adform": {
        "reach": "2.730.000"
      },
      "Verizon": {
        "reach": "2.691.000"
      },
      "Xandr": {
        "reach": "3.086.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "6.998.000"
      },
      "Instagram": {
        "reach": "3.692.000"
      },
      "YouTube": {
        "reach": "13.656.000"
      },
      "Snapchat": {
        "reach": "1.577.000"
      }
    }
  },
  "Kindermode": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.230.000"
      },
      "theTradeDesk": {
        "reach": "1.811.000"
      },
      "Adform": {
        "reach": "1.495.000"
      },
      "Verizon": {
        "reach": "1.543.000"
      },
      "Xandr": {
        "reach": "1.715.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "976.000"
      },
      "theTradeDesk": {
        "reach": "744.000"
      },
      "Adform": {
        "reach": "651.000"
      },
      "Verizon": {
        "reach": "667.000"
      },
      "Xandr": {
        "reach": "753.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.815.000"
      },
      "theTradeDesk": {
        "reach": "2.304.000"
      },
      "Adform": {
        "reach": "1.980.000"
      },
      "Verizon": {
        "reach": "1.966.000"
      },
      "Xandr": {
        "reach": "2.244.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.349.000"
      },
      "theTradeDesk": {
        "reach": "2.734.000"
      },
      "Adform": {
        "reach": "2.330.000"
      },
      "Verizon": {
        "reach": "2.351.000"
      },
      "Xandr": {
        "reach": "2.698.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.458.000"
      },
      "theTradeDesk": {
        "reach": "444.000"
      },
      "Adform": {
        "reach": "975.000"
      },
      "Verizon": {
        "reach": "1.016.000"
      },
      "Xandr": {
        "reach": "1.164.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.001.000"
      },
      "theTradeDesk": {
        "reach": "3.186.000"
      },
      "Adform": {
        "reach": "2.844.000"
      },
      "Verizon": {
        "reach": "2.901.000"
      },
      "Xandr": {
        "reach": "3.139.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "979.000"
      },
      "theTradeDesk": {
        "reach": "785.000"
      },
      "Adform": {
        "reach": "701.000"
      },
      "Verizon": {
        "reach": "654.000"
      },
      "Xandr": {
        "reach": "813.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "558.000"
      },
      "RMS": {
        "reach": "626.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.920.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "813.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.084.000"
      },
      "theTradeDesk": {
        "reach": "898.000"
      },
      "Adform": {
        "reach": "754.000"
      },
      "Verizon": {
        "reach": "792.000"
      },
      "Xandr": {
        "reach": "905.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.012.000"
      },
      "Instagram": {
        "reach": "1.099.000"
      },
      "YouTube": {
        "reach": "3.764.000"
      },
      "Snapchat": {
        "reach": "471.000"
      }
    }
  },
  "Wäsche": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.200.000"
      },
      "theTradeDesk": {
        "reach": "4.903.000"
      },
      "Adform": {
        "reach": "4.534.000"
      },
      "Verizon": {
        "reach": "4.364.000"
      },
      "Xandr": {
        "reach": "5.280.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.706.000"
      },
      "theTradeDesk": {
        "reach": "2.141.000"
      },
      "Adform": {
        "reach": "1.928.000"
      },
      "Verizon": {
        "reach": "1.924.000"
      },
      "Xandr": {
        "reach": "2.141.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "8.355.000"
      },
      "theTradeDesk": {
        "reach": "6.553.000"
      },
      "Adform": {
        "reach": "5.812.000"
      },
      "Verizon": {
        "reach": "5.951.000"
      },
      "Xandr": {
        "reach": "6.585.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "9.424.000"
      },
      "theTradeDesk": {
        "reach": "7.868.000"
      },
      "Adform": {
        "reach": "6.839.000"
      },
      "Verizon": {
        "reach": "6.896.000"
      },
      "Xandr": {
        "reach": "7.487.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.104.000"
      },
      "theTradeDesk": {
        "reach": "1.229.000"
      },
      "Adform": {
        "reach": "2.880.000"
      },
      "Verizon": {
        "reach": "2.862.000"
      },
      "Xandr": {
        "reach": "3.282.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "11.798.000"
      },
      "theTradeDesk": {
        "reach": "8.960.000"
      },
      "Adform": {
        "reach": "7.858.000"
      },
      "Verizon": {
        "reach": "8.079.000"
      },
      "Xandr": {
        "reach": "8.959.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.822.000"
      },
      "theTradeDesk": {
        "reach": "2.249.000"
      },
      "Adform": {
        "reach": "1.927.000"
      },
      "Verizon": {
        "reach": "1.978.000"
      },
      "Xandr": {
        "reach": "2.251.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.598.000"
      },
      "RMS": {
        "reach": "1.842.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.661.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.313.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.236.000"
      },
      "theTradeDesk": {
        "reach": "2.523.000"
      },
      "Adform": {
        "reach": "2.116.000"
      },
      "Verizon": {
        "reach": "2.163.000"
      },
      "Xandr": {
        "reach": "2.487.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.935.000"
      },
      "Instagram": {
        "reach": "2.965.000"
      },
      "YouTube": {
        "reach": "10.980.000"
      },
      "Snapchat": {
        "reach": "1.283.000"
      }
    }
  },
  "Strumpfwaren": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "15.377.000"
      },
      "theTradeDesk": {
        "reach": "12.788.000"
      },
      "Adform": {
        "reach": "11.211.000"
      },
      "Verizon": {
        "reach": "10.880.000"
      },
      "Xandr": {
        "reach": "12.221.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "6.836.000"
      },
      "theTradeDesk": {
        "reach": "5.400.000"
      },
      "Adform": {
        "reach": "4.817.000"
      },
      "Verizon": {
        "reach": "4.633.000"
      },
      "Xandr": {
        "reach": "5.237.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "20.652.000"
      },
      "theTradeDesk": {
        "reach": "15.184.000"
      },
      "Adform": {
        "reach": "13.988.000"
      },
      "Verizon": {
        "reach": "13.994.000"
      },
      "Xandr": {
        "reach": "16.182.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "23.702.000"
      },
      "theTradeDesk": {
        "reach": "19.257.000"
      },
      "Adform": {
        "reach": "16.387.000"
      },
      "Verizon": {
        "reach": "16.710.000"
      },
      "Xandr": {
        "reach": "19.160.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "10.103.000"
      },
      "theTradeDesk": {
        "reach": "3.341.000"
      },
      "Adform": {
        "reach": "7.013.000"
      },
      "Verizon": {
        "reach": "7.314.000"
      },
      "Xandr": {
        "reach": "8.025.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "27.762.000"
      },
      "theTradeDesk": {
        "reach": "22.807.000"
      },
      "Adform": {
        "reach": "19.868.000"
      },
      "Verizon": {
        "reach": "19.576.000"
      },
      "Xandr": {
        "reach": "22.169.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "6.922.000"
      },
      "theTradeDesk": {
        "reach": "5.696.000"
      },
      "Adform": {
        "reach": "4.808.000"
      },
      "Verizon": {
        "reach": "5.011.000"
      },
      "Xandr": {
        "reach": "5.441.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.097.000"
      },
      "RMS": {
        "reach": "4.224.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "13.982.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.560.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "7.780.000"
      },
      "theTradeDesk": {
        "reach": "6.274.000"
      },
      "Adform": {
        "reach": "5.403.000"
      },
      "Verizon": {
        "reach": "5.557.000"
      },
      "Xandr": {
        "reach": "6.053.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "14.542.000"
      },
      "Instagram": {
        "reach": "7.152.000"
      },
      "YouTube": {
        "reach": "26.480.000"
      },
      "Snapchat": {
        "reach": "1.958.000"
      }
    }
  },
  "Schuhe": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "16.650.000"
      },
      "theTradeDesk": {
        "reach": "13.300.000"
      },
      "Adform": {
        "reach": "11.611.000"
      },
      "Verizon": {
        "reach": "11.029.000"
      },
      "Xandr": {
        "reach": "12.483.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "7.096.000"
      },
      "theTradeDesk": {
        "reach": "5.391.000"
      },
      "Adform": {
        "reach": "4.779.000"
      },
      "Verizon": {
        "reach": "4.849.000"
      },
      "Xandr": {
        "reach": "5.648.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "20.847.000"
      },
      "theTradeDesk": {
        "reach": "17.086.000"
      },
      "Adform": {
        "reach": "15.048.000"
      },
      "Verizon": {
        "reach": "14.330.000"
      },
      "Xandr": {
        "reach": "16.600.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "24.920.000"
      },
      "theTradeDesk": {
        "reach": "19.774.000"
      },
      "Adform": {
        "reach": "17.838.000"
      },
      "Verizon": {
        "reach": "17.453.000"
      },
      "Xandr": {
        "reach": "20.159.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "10.878.000"
      },
      "theTradeDesk": {
        "reach": "3.358.000"
      },
      "Adform": {
        "reach": "7.511.000"
      },
      "Verizon": {
        "reach": "7.547.000"
      },
      "Xandr": {
        "reach": "8.252.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "31.285.000"
      },
      "theTradeDesk": {
        "reach": "24.071.000"
      },
      "Adform": {
        "reach": "21.387.000"
      },
      "Verizon": {
        "reach": "21.036.000"
      },
      "Xandr": {
        "reach": "24.293.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "7.212.000"
      },
      "theTradeDesk": {
        "reach": "5.880.000"
      },
      "Adform": {
        "reach": "5.170.000"
      },
      "Verizon": {
        "reach": "5.192.000"
      },
      "Xandr": {
        "reach": "5.893.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.237.000"
      },
      "RMS": {
        "reach": "4.526.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "14.599.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.943.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "8.273.000"
      },
      "theTradeDesk": {
        "reach": "6.421.000"
      },
      "Adform": {
        "reach": "5.761.000"
      },
      "Verizon": {
        "reach": "5.825.000"
      },
      "Xandr": {
        "reach": "6.368.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "14.849.000"
      },
      "Instagram": {
        "reach": "7.919.000"
      },
      "YouTube": {
        "reach": "28.283.000"
      },
      "Snapchat": {
        "reach": "2.773.000"
      }
    }
  },
  "Lederwaren": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "502.000"
      },
      "theTradeDesk": {
        "reach": "407.000"
      },
      "Adform": {
        "reach": "351.000"
      },
      "Verizon": {
        "reach": "354.000"
      },
      "Xandr": {
        "reach": "421.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "223.000"
      },
      "theTradeDesk": {
        "reach": "177.000"
      },
      "Adform": {
        "reach": "153.000"
      },
      "Verizon": {
        "reach": "152.000"
      },
      "Xandr": {
        "reach": "173.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "662.000"
      },
      "theTradeDesk": {
        "reach": "515.000"
      },
      "Adform": {
        "reach": "461.000"
      },
      "Verizon": {
        "reach": "458.000"
      },
      "Xandr": {
        "reach": "526.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "792.000"
      },
      "theTradeDesk": {
        "reach": "641.000"
      },
      "Adform": {
        "reach": "543.000"
      },
      "Verizon": {
        "reach": "562.000"
      },
      "Xandr": {
        "reach": "633.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "336.000"
      },
      "theTradeDesk": {
        "reach": "106.000"
      },
      "Adform": {
        "reach": "229.000"
      },
      "Verizon": {
        "reach": "234.000"
      },
      "Xandr": {
        "reach": "269.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "943.000"
      },
      "theTradeDesk": {
        "reach": "777.000"
      },
      "Adform": {
        "reach": "665.000"
      },
      "Verizon": {
        "reach": "673.000"
      },
      "Xandr": {
        "reach": "768.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "234.000"
      },
      "theTradeDesk": {
        "reach": "186.000"
      },
      "Adform": {
        "reach": "162.000"
      },
      "Verizon": {
        "reach": "164.000"
      },
      "Xandr": {
        "reach": "181.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "123.000"
      },
      "RMS": {
        "reach": "143.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "459.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "186.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "260.000"
      },
      "theTradeDesk": {
        "reach": "204.000"
      },
      "Adform": {
        "reach": "187.000"
      },
      "Verizon": {
        "reach": "178.000"
      },
      "Xandr": {
        "reach": "206.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "497.000"
      },
      "Instagram": {
        "reach": "248.000"
      },
      "YouTube": {
        "reach": "903.000"
      },
      "Snapchat": {
        "reach": "107.000"
      }
    }
  },
  "Hausrat, Glas, Porzellan": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "7.951.000"
      },
      "theTradeDesk": {
        "reach": "6.197.000"
      },
      "Adform": {
        "reach": "5.391.000"
      },
      "Verizon": {
        "reach": "5.341.000"
      },
      "Xandr": {
        "reach": "6.111.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.267.000"
      },
      "theTradeDesk": {
        "reach": "2.637.000"
      },
      "Adform": {
        "reach": "2.187.000"
      },
      "Verizon": {
        "reach": "2.336.000"
      },
      "Xandr": {
        "reach": "2.668.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "9.898.000"
      },
      "theTradeDesk": {
        "reach": "8.072.000"
      },
      "Adform": {
        "reach": "6.856.000"
      },
      "Verizon": {
        "reach": "6.963.000"
      },
      "Xandr": {
        "reach": "7.490.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "11.708.000"
      },
      "theTradeDesk": {
        "reach": "9.443.000"
      },
      "Adform": {
        "reach": "8.021.000"
      },
      "Verizon": {
        "reach": "8.102.000"
      },
      "Xandr": {
        "reach": "9.397.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.914.000"
      },
      "theTradeDesk": {
        "reach": "1.639.000"
      },
      "Adform": {
        "reach": "3.441.000"
      },
      "Verizon": {
        "reach": "3.457.000"
      },
      "Xandr": {
        "reach": "3.967.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "13.984.000"
      },
      "theTradeDesk": {
        "reach": "11.232.000"
      },
      "Adform": {
        "reach": "9.763.000"
      },
      "Verizon": {
        "reach": "9.658.000"
      },
      "Xandr": {
        "reach": "11.170.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.389.000"
      },
      "theTradeDesk": {
        "reach": "2.769.000"
      },
      "Adform": {
        "reach": "2.465.000"
      },
      "Verizon": {
        "reach": "2.414.000"
      },
      "Xandr": {
        "reach": "2.745.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.560.000"
      },
      "RMS": {
        "reach": "2.182.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "6.648.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.877.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.837.000"
      },
      "theTradeDesk": {
        "reach": "2.969.000"
      },
      "Adform": {
        "reach": "2.578.000"
      },
      "Verizon": {
        "reach": "2.530.000"
      },
      "Xandr": {
        "reach": "3.032.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "7.384.000"
      },
      "Instagram": {
        "reach": "3.664.000"
      },
      "YouTube": {
        "reach": "13.589.000"
      },
      "Snapchat": {
        "reach": "988.000"
      }
    }
  },
  "Gesundheit": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.631.000"
      },
      "theTradeDesk": {
        "reach": "4.574.000"
      },
      "Adform": {
        "reach": "4.027.000"
      },
      "Verizon": {
        "reach": "3.811.000"
      },
      "Xandr": {
        "reach": "4.486.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.327.000"
      },
      "theTradeDesk": {
        "reach": "1.976.000"
      },
      "Adform": {
        "reach": "1.663.000"
      },
      "Verizon": {
        "reach": "1.732.000"
      },
      "Xandr": {
        "reach": "1.905.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "7.375.000"
      },
      "theTradeDesk": {
        "reach": "5.878.000"
      },
      "Adform": {
        "reach": "5.172.000"
      },
      "Verizon": {
        "reach": "5.026.000"
      },
      "Xandr": {
        "reach": "5.637.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "8.652.000"
      },
      "theTradeDesk": {
        "reach": "6.617.000"
      },
      "Adform": {
        "reach": "5.998.000"
      },
      "Verizon": {
        "reach": "5.844.000"
      },
      "Xandr": {
        "reach": "6.791.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.694.000"
      },
      "theTradeDesk": {
        "reach": "1.215.000"
      },
      "Adform": {
        "reach": "2.615.000"
      },
      "Verizon": {
        "reach": "2.536.000"
      },
      "Xandr": {
        "reach": "3.035.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "10.122.000"
      },
      "theTradeDesk": {
        "reach": "8.051.000"
      },
      "Adform": {
        "reach": "7.379.000"
      },
      "Verizon": {
        "reach": "7.292.000"
      },
      "Xandr": {
        "reach": "8.317.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.576.000"
      },
      "theTradeDesk": {
        "reach": "2.059.000"
      },
      "Adform": {
        "reach": "1.760.000"
      },
      "Verizon": {
        "reach": "1.765.000"
      },
      "Xandr": {
        "reach": "1.999.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.437.000"
      },
      "RMS": {
        "reach": "1.595.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.170.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.055.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.852.000"
      },
      "theTradeDesk": {
        "reach": "2.191.000"
      },
      "Adform": {
        "reach": "1.893.000"
      },
      "Verizon": {
        "reach": "1.957.000"
      },
      "Xandr": {
        "reach": "2.254.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.290.000"
      },
      "Instagram": {
        "reach": "2.665.000"
      },
      "YouTube": {
        "reach": "9.977.000"
      },
      "Snapchat": {
        "reach": "735.000"
      }
    }
  },
  "Optik - Accessoire": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "4.443.000"
      },
      "theTradeDesk": {
        "reach": "3.482.000"
      },
      "Adform": {
        "reach": "3.109.000"
      },
      "Verizon": {
        "reach": "3.101.000"
      },
      "Xandr": {
        "reach": "3.571.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.821.000"
      },
      "theTradeDesk": {
        "reach": "1.465.000"
      },
      "Adform": {
        "reach": "1.326.000"
      },
      "Verizon": {
        "reach": "1.355.000"
      },
      "Xandr": {
        "reach": "1.513.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "5.588.000"
      },
      "theTradeDesk": {
        "reach": "4.548.000"
      },
      "Adform": {
        "reach": "4.119.000"
      },
      "Verizon": {
        "reach": "3.934.000"
      },
      "Xandr": {
        "reach": "4.531.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "6.749.000"
      },
      "theTradeDesk": {
        "reach": "5.338.000"
      },
      "Adform": {
        "reach": "4.860.000"
      },
      "Verizon": {
        "reach": "4.801.000"
      },
      "Xandr": {
        "reach": "5.335.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.898.000"
      },
      "theTradeDesk": {
        "reach": "945.000"
      },
      "Adform": {
        "reach": "2.021.000"
      },
      "Verizon": {
        "reach": "1.975.000"
      },
      "Xandr": {
        "reach": "2.308.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "8.183.000"
      },
      "theTradeDesk": {
        "reach": "6.579.000"
      },
      "Adform": {
        "reach": "5.777.000"
      },
      "Verizon": {
        "reach": "5.896.000"
      },
      "Xandr": {
        "reach": "6.671.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.961.000"
      },
      "theTradeDesk": {
        "reach": "1.624.000"
      },
      "Adform": {
        "reach": "1.379.000"
      },
      "Verizon": {
        "reach": "1.369.000"
      },
      "Xandr": {
        "reach": "1.579.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "903.000"
      },
      "RMS": {
        "reach": "1.281.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "4.186.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.608.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.200.000"
      },
      "theTradeDesk": {
        "reach": "1.743.000"
      },
      "Adform": {
        "reach": "1.573.000"
      },
      "Verizon": {
        "reach": "1.557.000"
      },
      "Xandr": {
        "reach": "1.791.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "4.136.000"
      },
      "Instagram": {
        "reach": "2.168.000"
      },
      "YouTube": {
        "reach": "8.059.000"
      },
      "Snapchat": {
        "reach": "553.000"
      }
    }
  },
  "Optik - Sehhilfen": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "10.495.000"
      },
      "theTradeDesk": {
        "reach": "8.012.000"
      },
      "Adform": {
        "reach": "7.119.000"
      },
      "Verizon": {
        "reach": "6.873.000"
      },
      "Xandr": {
        "reach": "8.407.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.215.000"
      },
      "theTradeDesk": {
        "reach": "3.463.000"
      },
      "Adform": {
        "reach": "3.019.000"
      },
      "Verizon": {
        "reach": "3.070.000"
      },
      "Xandr": {
        "reach": "3.339.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "13.355.000"
      },
      "theTradeDesk": {
        "reach": "9.994.000"
      },
      "Adform": {
        "reach": "9.150.000"
      },
      "Verizon": {
        "reach": "8.784.000"
      },
      "Xandr": {
        "reach": "10.674.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "15.473.000"
      },
      "theTradeDesk": {
        "reach": "12.839.000"
      },
      "Adform": {
        "reach": "10.753.000"
      },
      "Verizon": {
        "reach": "10.838.000"
      },
      "Xandr": {
        "reach": "12.716.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "6.557.000"
      },
      "theTradeDesk": {
        "reach": "2.152.000"
      },
      "Adform": {
        "reach": "4.677.000"
      },
      "Verizon": {
        "reach": "4.790.000"
      },
      "Xandr": {
        "reach": "5.296.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "18.547.000"
      },
      "theTradeDesk": {
        "reach": "15.081.000"
      },
      "Adform": {
        "reach": "13.116.000"
      },
      "Verizon": {
        "reach": "13.287.000"
      },
      "Xandr": {
        "reach": "14.850.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.512.000"
      },
      "theTradeDesk": {
        "reach": "3.569.000"
      },
      "Adform": {
        "reach": "3.237.000"
      },
      "Verizon": {
        "reach": "3.296.000"
      },
      "Xandr": {
        "reach": "3.672.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.513.000"
      },
      "RMS": {
        "reach": "2.868.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "9.188.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.674.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "5.182.000"
      },
      "theTradeDesk": {
        "reach": "3.881.000"
      },
      "Adform": {
        "reach": "3.506.000"
      },
      "Verizon": {
        "reach": "3.626.000"
      },
      "Xandr": {
        "reach": "4.002.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "9.487.000"
      },
      "Instagram": {
        "reach": "4.995.000"
      },
      "YouTube": {
        "reach": "17.564.000"
      },
      "Snapchat": {
        "reach": "1.315.000"
      }
    }
  },
  "Spielwaren": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "4.528.000"
      },
      "theTradeDesk": {
        "reach": "3.676.000"
      },
      "Adform": {
        "reach": "3.244.000"
      },
      "Verizon": {
        "reach": "3.122.000"
      },
      "Xandr": {
        "reach": "3.612.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.947.000"
      },
      "theTradeDesk": {
        "reach": "1.561.000"
      },
      "Adform": {
        "reach": "1.393.000"
      },
      "Verizon": {
        "reach": "1.309.000"
      },
      "Xandr": {
        "reach": "1.504.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "5.851.000"
      },
      "theTradeDesk": {
        "reach": "4.712.000"
      },
      "Adform": {
        "reach": "4.032.000"
      },
      "Verizon": {
        "reach": "4.079.000"
      },
      "Xandr": {
        "reach": "4.663.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "7.099.000"
      },
      "theTradeDesk": {
        "reach": "5.666.000"
      },
      "Adform": {
        "reach": "4.864.000"
      },
      "Verizon": {
        "reach": "4.861.000"
      },
      "Xandr": {
        "reach": "5.527.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.988.000"
      },
      "theTradeDesk": {
        "reach": "950.000"
      },
      "Adform": {
        "reach": "2.106.000"
      },
      "Verizon": {
        "reach": "2.023.000"
      },
      "Xandr": {
        "reach": "2.356.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "8.119.000"
      },
      "theTradeDesk": {
        "reach": "6.937.000"
      },
      "Adform": {
        "reach": "5.932.000"
      },
      "Verizon": {
        "reach": "5.831.000"
      },
      "Xandr": {
        "reach": "6.489.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.069.000"
      },
      "theTradeDesk": {
        "reach": "1.657.000"
      },
      "Adform": {
        "reach": "1.409.000"
      },
      "Verizon": {
        "reach": "1.436.000"
      },
      "Xandr": {
        "reach": "1.628.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.104.000"
      },
      "RMS": {
        "reach": "1.275.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "4.037.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.666.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.205.000"
      },
      "theTradeDesk": {
        "reach": "1.820.000"
      },
      "Adform": {
        "reach": "1.602.000"
      },
      "Verizon": {
        "reach": "1.580.000"
      },
      "Xandr": {
        "reach": "1.830.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "4.184.000"
      },
      "Instagram": {
        "reach": "2.169.000"
      },
      "YouTube": {
        "reach": "7.765.000"
      },
      "Snapchat": {
        "reach": "745.000"
      }
    }
  },
  "Hobbys, Musikinstrumente": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.440.000"
      },
      "theTradeDesk": {
        "reach": "4.570.000"
      },
      "Adform": {
        "reach": "4.012.000"
      },
      "Verizon": {
        "reach": "3.931.000"
      },
      "Xandr": {
        "reach": "4.631.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.410.000"
      },
      "theTradeDesk": {
        "reach": "1.903.000"
      },
      "Adform": {
        "reach": "1.599.000"
      },
      "Verizon": {
        "reach": "1.742.000"
      },
      "Xandr": {
        "reach": "1.912.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "7.361.000"
      },
      "theTradeDesk": {
        "reach": "5.666.000"
      },
      "Adform": {
        "reach": "5.109.000"
      },
      "Verizon": {
        "reach": "5.104.000"
      },
      "Xandr": {
        "reach": "5.744.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "8.621.000"
      },
      "theTradeDesk": {
        "reach": "7.031.000"
      },
      "Adform": {
        "reach": "6.045.000"
      },
      "Verizon": {
        "reach": "6.407.000"
      },
      "Xandr": {
        "reach": "6.690.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.599.000"
      },
      "theTradeDesk": {
        "reach": "1.195.000"
      },
      "Adform": {
        "reach": "2.592.000"
      },
      "Verizon": {
        "reach": "2.561.000"
      },
      "Xandr": {
        "reach": "2.940.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "10.605.000"
      },
      "theTradeDesk": {
        "reach": "8.139.000"
      },
      "Adform": {
        "reach": "7.192.000"
      },
      "Verizon": {
        "reach": "7.048.000"
      },
      "Xandr": {
        "reach": "8.269.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.537.000"
      },
      "theTradeDesk": {
        "reach": "2.003.000"
      },
      "Adform": {
        "reach": "1.794.000"
      },
      "Verizon": {
        "reach": "1.840.000"
      },
      "Xandr": {
        "reach": "2.028.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.368.000"
      },
      "RMS": {
        "reach": "1.554.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.188.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.087.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.868.000"
      },
      "theTradeDesk": {
        "reach": "2.343.000"
      },
      "Adform": {
        "reach": "1.934.000"
      },
      "Verizon": {
        "reach": "1.908.000"
      },
      "Xandr": {
        "reach": "2.192.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.174.000"
      },
      "Instagram": {
        "reach": "2.725.000"
      },
      "YouTube": {
        "reach": "9.589.000"
      },
      "Snapchat": {
        "reach": "826.000"
      }
    }
  },
  "Bücher, Zeitungen, Zeitschriften,": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "24.172.000"
      },
      "theTradeDesk": {
        "reach": "20.209.000"
      },
      "Adform": {
        "reach": "17.626.000"
      },
      "Verizon": {
        "reach": "17.536.000"
      },
      "Xandr": {
        "reach": "20.358.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "11.038.000"
      },
      "theTradeDesk": {
        "reach": "8.352.000"
      },
      "Adform": {
        "reach": "7.092.000"
      },
      "Verizon": {
        "reach": "7.718.000"
      },
      "Xandr": {
        "reach": "8.546.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "32.405.000"
      },
      "theTradeDesk": {
        "reach": "26.868.000"
      },
      "Adform": {
        "reach": "22.046.000"
      },
      "Verizon": {
        "reach": "22.756.000"
      },
      "Xandr": {
        "reach": "26.209.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "37.537.000"
      },
      "theTradeDesk": {
        "reach": "30.790.000"
      },
      "Adform": {
        "reach": "26.549.000"
      },
      "Verizon": {
        "reach": "27.319.000"
      },
      "Xandr": {
        "reach": "31.149.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "16.692.000"
      },
      "theTradeDesk": {
        "reach": "5.354.000"
      },
      "Adform": {
        "reach": "11.673.000"
      },
      "Verizon": {
        "reach": "11.319.000"
      },
      "Xandr": {
        "reach": "13.497.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "46.459.000"
      },
      "theTradeDesk": {
        "reach": "37.248.000"
      },
      "Adform": {
        "reach": "31.922.000"
      },
      "Verizon": {
        "reach": "33.546.000"
      },
      "Xandr": {
        "reach": "37.496.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "11.690.000"
      },
      "theTradeDesk": {
        "reach": "8.874.000"
      },
      "Adform": {
        "reach": "7.833.000"
      },
      "Verizon": {
        "reach": "7.912.000"
      },
      "Xandr": {
        "reach": "8.917.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "4.791.000"
      },
      "RMS": {
        "reach": "7.130.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "23.024.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "9.353.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "12.643.000"
      },
      "theTradeDesk": {
        "reach": "9.871.000"
      },
      "Adform": {
        "reach": "8.694.000"
      },
      "Verizon": {
        "reach": "8.818.000"
      },
      "Xandr": {
        "reach": "10.360.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "23.557.000"
      },
      "Instagram": {
        "reach": "12.380.000"
      },
      "YouTube": {
        "reach": "44.050.000"
      },
      "Snapchat": {
        "reach": "2.089.000"
      }
    }
  },
  "Schreibwaren": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "4.617.000"
      },
      "theTradeDesk": {
        "reach": "3.846.000"
      },
      "Adform": {
        "reach": "3.167.000"
      },
      "Verizon": {
        "reach": "3.254.000"
      },
      "Xandr": {
        "reach": "3.678.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.968.000"
      },
      "theTradeDesk": {
        "reach": "1.576.000"
      },
      "Adform": {
        "reach": "1.437.000"
      },
      "Verizon": {
        "reach": "1.389.000"
      },
      "Xandr": {
        "reach": "1.577.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "6.208.000"
      },
      "theTradeDesk": {
        "reach": "4.875.000"
      },
      "Adform": {
        "reach": "4.152.000"
      },
      "Verizon": {
        "reach": "4.186.000"
      },
      "Xandr": {
        "reach": "4.893.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "7.205.000"
      },
      "theTradeDesk": {
        "reach": "5.827.000"
      },
      "Adform": {
        "reach": "4.966.000"
      },
      "Verizon": {
        "reach": "4.899.000"
      },
      "Xandr": {
        "reach": "5.706.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.977.000"
      },
      "theTradeDesk": {
        "reach": "983.000"
      },
      "Adform": {
        "reach": "2.161.000"
      },
      "Verizon": {
        "reach": "2.143.000"
      },
      "Xandr": {
        "reach": "2.397.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "8.525.000"
      },
      "theTradeDesk": {
        "reach": "6.889.000"
      },
      "Adform": {
        "reach": "6.172.000"
      },
      "Verizon": {
        "reach": "5.910.000"
      },
      "Xandr": {
        "reach": "7.055.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.056.000"
      },
      "theTradeDesk": {
        "reach": "1.725.000"
      },
      "Adform": {
        "reach": "1.514.000"
      },
      "Verizon": {
        "reach": "1.503.000"
      },
      "Xandr": {
        "reach": "1.711.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.170.000"
      },
      "RMS": {
        "reach": "1.337.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "4.330.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.706.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.379.000"
      },
      "theTradeDesk": {
        "reach": "1.869.000"
      },
      "Adform": {
        "reach": "1.579.000"
      },
      "Verizon": {
        "reach": "1.645.000"
      },
      "Xandr": {
        "reach": "1.946.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "4.560.000"
      },
      "Instagram": {
        "reach": "2.336.000"
      },
      "YouTube": {
        "reach": "8.239.000"
      },
      "Snapchat": {
        "reach": "985.000"
      }
    }
  },
  "Uhren und Schmuck - Normalpreisig": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "10.520.000"
      },
      "theTradeDesk": {
        "reach": "8.335.000"
      },
      "Adform": {
        "reach": "7.200.000"
      },
      "Verizon": {
        "reach": "7.233.000"
      },
      "Xandr": {
        "reach": "8.374.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.582.000"
      },
      "theTradeDesk": {
        "reach": "3.592.000"
      },
      "Adform": {
        "reach": "3.181.000"
      },
      "Verizon": {
        "reach": "3.200.000"
      },
      "Xandr": {
        "reach": "3.614.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "13.516.000"
      },
      "theTradeDesk": {
        "reach": "10.865.000"
      },
      "Adform": {
        "reach": "9.391.000"
      },
      "Verizon": {
        "reach": "9.577.000"
      },
      "Xandr": {
        "reach": "10.651.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "16.107.000"
      },
      "theTradeDesk": {
        "reach": "13.268.000"
      },
      "Adform": {
        "reach": "11.137.000"
      },
      "Verizon": {
        "reach": "11.255.000"
      },
      "Xandr": {
        "reach": "12.872.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "6.810.000"
      },
      "theTradeDesk": {
        "reach": "2.191.000"
      },
      "Adform": {
        "reach": "4.724.000"
      },
      "Verizon": {
        "reach": "4.807.000"
      },
      "Xandr": {
        "reach": "5.637.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "19.515.000"
      },
      "theTradeDesk": {
        "reach": "15.531.000"
      },
      "Adform": {
        "reach": "13.489.000"
      },
      "Verizon": {
        "reach": "13.432.000"
      },
      "Xandr": {
        "reach": "15.647.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.754.000"
      },
      "theTradeDesk": {
        "reach": "3.820.000"
      },
      "Adform": {
        "reach": "3.381.000"
      },
      "Verizon": {
        "reach": "3.338.000"
      },
      "Xandr": {
        "reach": "3.772.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.561.000"
      },
      "RMS": {
        "reach": "2.965.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "9.643.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.958.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "5.323.000"
      },
      "theTradeDesk": {
        "reach": "4.178.000"
      },
      "Adform": {
        "reach": "3.682.000"
      },
      "Verizon": {
        "reach": "3.790.000"
      },
      "Xandr": {
        "reach": "4.164.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "9.687.000"
      },
      "Instagram": {
        "reach": "5.015.000"
      },
      "YouTube": {
        "reach": "17.390.000"
      },
      "Snapchat": {
        "reach": "1.090.000"
      }
    }
  },
  "Uhren und Schmuck - Premium Präferenz": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.485.000"
      },
      "theTradeDesk": {
        "reach": "1.921.000"
      },
      "Adform": {
        "reach": "1.778.000"
      },
      "Verizon": {
        "reach": "1.792.000"
      },
      "Xandr": {
        "reach": "2.012.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.076.000"
      },
      "theTradeDesk": {
        "reach": "842.000"
      },
      "Adform": {
        "reach": "735.000"
      },
      "Verizon": {
        "reach": "751.000"
      },
      "Xandr": {
        "reach": "869.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.290.000"
      },
      "theTradeDesk": {
        "reach": "2.578.000"
      },
      "Adform": {
        "reach": "2.271.000"
      },
      "Verizon": {
        "reach": "2.256.000"
      },
      "Xandr": {
        "reach": "2.571.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.751.000"
      },
      "theTradeDesk": {
        "reach": "3.147.000"
      },
      "Adform": {
        "reach": "2.710.000"
      },
      "Verizon": {
        "reach": "2.699.000"
      },
      "Xandr": {
        "reach": "3.062.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.624.000"
      },
      "theTradeDesk": {
        "reach": "495.000"
      },
      "Adform": {
        "reach": "1.127.000"
      },
      "Verizon": {
        "reach": "1.177.000"
      },
      "Xandr": {
        "reach": "1.337.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.579.000"
      },
      "theTradeDesk": {
        "reach": "3.678.000"
      },
      "Adform": {
        "reach": "3.322.000"
      },
      "Verizon": {
        "reach": "3.236.000"
      },
      "Xandr": {
        "reach": "3.546.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.187.000"
      },
      "theTradeDesk": {
        "reach": "907.000"
      },
      "Adform": {
        "reach": "784.000"
      },
      "Verizon": {
        "reach": "770.000"
      },
      "Xandr": {
        "reach": "893.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "618.000"
      },
      "RMS": {
        "reach": "739.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.297.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "919.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.296.000"
      },
      "theTradeDesk": {
        "reach": "1.003.000"
      },
      "Adform": {
        "reach": "875.000"
      },
      "Verizon": {
        "reach": "895.000"
      },
      "Xandr": {
        "reach": "980.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.365.000"
      },
      "Instagram": {
        "reach": "1.232.000"
      },
      "YouTube": {
        "reach": "4.418.000"
      },
      "Snapchat": {
        "reach": "368.000"
      }
    }
  },
  "Baumarktspezifisches Sortiment": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "8.531.000"
      },
      "theTradeDesk": {
        "reach": "6.340.000"
      },
      "Adform": {
        "reach": "5.592.000"
      },
      "Verizon": {
        "reach": "5.610.000"
      },
      "Xandr": {
        "reach": "6.547.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.467.000"
      },
      "theTradeDesk": {
        "reach": "2.740.000"
      },
      "Adform": {
        "reach": "2.437.000"
      },
      "Verizon": {
        "reach": "2.477.000"
      },
      "Xandr": {
        "reach": "2.746.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "10.515.000"
      },
      "theTradeDesk": {
        "reach": "8.512.000"
      },
      "Adform": {
        "reach": "7.409.000"
      },
      "Verizon": {
        "reach": "7.678.000"
      },
      "Xandr": {
        "reach": "8.148.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "12.738.000"
      },
      "theTradeDesk": {
        "reach": "9.928.000"
      },
      "Adform": {
        "reach": "8.484.000"
      },
      "Verizon": {
        "reach": "8.509.000"
      },
      "Xandr": {
        "reach": "10.063.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.452.000"
      },
      "theTradeDesk": {
        "reach": "1.685.000"
      },
      "Adform": {
        "reach": "3.806.000"
      },
      "Verizon": {
        "reach": "3.678.000"
      },
      "Xandr": {
        "reach": "4.234.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "15.096.000"
      },
      "theTradeDesk": {
        "reach": "12.006.000"
      },
      "Adform": {
        "reach": "10.730.000"
      },
      "Verizon": {
        "reach": "10.429.000"
      },
      "Xandr": {
        "reach": "12.245.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.687.000"
      },
      "theTradeDesk": {
        "reach": "2.944.000"
      },
      "Adform": {
        "reach": "2.609.000"
      },
      "Verizon": {
        "reach": "2.641.000"
      },
      "Xandr": {
        "reach": "2.991.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.999.000"
      },
      "RMS": {
        "reach": "2.279.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "7.541.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.026.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.971.000"
      },
      "theTradeDesk": {
        "reach": "3.187.000"
      },
      "Adform": {
        "reach": "2.959.000"
      },
      "Verizon": {
        "reach": "2.809.000"
      },
      "Xandr": {
        "reach": "3.214.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "7.617.000"
      },
      "Instagram": {
        "reach": "4.010.000"
      },
      "YouTube": {
        "reach": "14.085.000"
      },
      "Snapchat": {
        "reach": "1.036.000"
      }
    }
  },
  "Kfz-Ersatzteile und Zubehör": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "10.666.000"
      },
      "theTradeDesk": {
        "reach": "9.003.000"
      },
      "Adform": {
        "reach": "7.945.000"
      },
      "Verizon": {
        "reach": "7.757.000"
      },
      "Xandr": {
        "reach": "9.172.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.796.000"
      },
      "theTradeDesk": {
        "reach": "3.838.000"
      },
      "Adform": {
        "reach": "3.393.000"
      },
      "Verizon": {
        "reach": "3.405.000"
      },
      "Xandr": {
        "reach": "3.853.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "14.284.000"
      },
      "theTradeDesk": {
        "reach": "11.341.000"
      },
      "Adform": {
        "reach": "10.342.000"
      },
      "Verizon": {
        "reach": "9.821.000"
      },
      "Xandr": {
        "reach": "11.263.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "16.511.000"
      },
      "theTradeDesk": {
        "reach": "13.450.000"
      },
      "Adform": {
        "reach": "11.721.000"
      },
      "Verizon": {
        "reach": "11.949.000"
      },
      "Xandr": {
        "reach": "13.188.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "7.224.000"
      },
      "theTradeDesk": {
        "reach": "2.313.000"
      },
      "Adform": {
        "reach": "5.102.000"
      },
      "Verizon": {
        "reach": "5.099.000"
      },
      "Xandr": {
        "reach": "5.668.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "20.576.000"
      },
      "theTradeDesk": {
        "reach": "15.967.000"
      },
      "Adform": {
        "reach": "14.777.000"
      },
      "Verizon": {
        "reach": "14.215.000"
      },
      "Xandr": {
        "reach": "16.277.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.045.000"
      },
      "theTradeDesk": {
        "reach": "3.927.000"
      },
      "Adform": {
        "reach": "3.393.000"
      },
      "Verizon": {
        "reach": "3.544.000"
      },
      "Xandr": {
        "reach": "3.777.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.840.000"
      },
      "RMS": {
        "reach": "3.217.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "10.116.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.041.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "5.585.000"
      },
      "theTradeDesk": {
        "reach": "4.602.000"
      },
      "Adform": {
        "reach": "3.901.000"
      },
      "Verizon": {
        "reach": "3.899.000"
      },
      "Xandr": {
        "reach": "4.421.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "10.552.000"
      },
      "Instagram": {
        "reach": "5.349.000"
      },
      "YouTube": {
        "reach": "19.057.000"
      },
      "Snapchat": {
        "reach": "1.365.000"
      }
    }
  },
  "Fahrräder und Zubehör": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "18.952.000"
      },
      "theTradeDesk": {
        "reach": "15.815.000"
      },
      "Adform": {
        "reach": "13.197.000"
      },
      "Verizon": {
        "reach": "12.737.000"
      },
      "Xandr": {
        "reach": "15.707.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "7.991.000"
      },
      "theTradeDesk": {
        "reach": "6.514.000"
      },
      "Adform": {
        "reach": "5.681.000"
      },
      "Verizon": {
        "reach": "5.608.000"
      },
      "Xandr": {
        "reach": "6.606.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "24.638.000"
      },
      "theTradeDesk": {
        "reach": "18.827.000"
      },
      "Adform": {
        "reach": "17.474.000"
      },
      "Verizon": {
        "reach": "17.842.000"
      },
      "Xandr": {
        "reach": "20.367.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "28.581.000"
      },
      "theTradeDesk": {
        "reach": "23.580.000"
      },
      "Adform": {
        "reach": "20.573.000"
      },
      "Verizon": {
        "reach": "19.641.000"
      },
      "Xandr": {
        "reach": "22.696.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "12.218.000"
      },
      "theTradeDesk": {
        "reach": "4.068.000"
      },
      "Adform": {
        "reach": "8.662.000"
      },
      "Verizon": {
        "reach": "8.421.000"
      },
      "Xandr": {
        "reach": "9.563.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "34.574.000"
      },
      "theTradeDesk": {
        "reach": "27.671.000"
      },
      "Adform": {
        "reach": "24.377.000"
      },
      "Verizon": {
        "reach": "24.374.000"
      },
      "Xandr": {
        "reach": "27.539.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "8.654.000"
      },
      "theTradeDesk": {
        "reach": "6.848.000"
      },
      "Adform": {
        "reach": "6.253.000"
      },
      "Verizon": {
        "reach": "5.836.000"
      },
      "Xandr": {
        "reach": "6.792.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "4.911.000"
      },
      "RMS": {
        "reach": "5.396.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "17.340.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "7.058.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "9.125.000"
      },
      "theTradeDesk": {
        "reach": "7.563.000"
      },
      "Adform": {
        "reach": "6.647.000"
      },
      "Verizon": {
        "reach": "6.460.000"
      },
      "Xandr": {
        "reach": "7.836.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "17.397.000"
      },
      "Instagram": {
        "reach": "9.164.000"
      },
      "YouTube": {
        "reach": "32.577.000"
      },
      "Snapchat": {
        "reach": "2.412.000"
      }
    }
  },
  "Sportgeräte": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "12.902.000"
      },
      "theTradeDesk": {
        "reach": "10.227.000"
      },
      "Adform": {
        "reach": "9.124.000"
      },
      "Verizon": {
        "reach": "9.006.000"
      },
      "Xandr": {
        "reach": "10.198.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.667.000"
      },
      "theTradeDesk": {
        "reach": "4.433.000"
      },
      "Adform": {
        "reach": "4.092.000"
      },
      "Verizon": {
        "reach": "3.740.000"
      },
      "Xandr": {
        "reach": "4.294.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "16.037.000"
      },
      "theTradeDesk": {
        "reach": "13.237.000"
      },
      "Adform": {
        "reach": "11.561.000"
      },
      "Verizon": {
        "reach": "11.710.000"
      },
      "Xandr": {
        "reach": "13.110.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "20.019.000"
      },
      "theTradeDesk": {
        "reach": "15.091.000"
      },
      "Adform": {
        "reach": "13.607.000"
      },
      "Verizon": {
        "reach": "13.915.000"
      },
      "Xandr": {
        "reach": "14.876.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "8.216.000"
      },
      "theTradeDesk": {
        "reach": "2.690.000"
      },
      "Adform": {
        "reach": "5.827.000"
      },
      "Verizon": {
        "reach": "6.032.000"
      },
      "Xandr": {
        "reach": "6.736.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "23.392.000"
      },
      "theTradeDesk": {
        "reach": "18.780.000"
      },
      "Adform": {
        "reach": "16.740.000"
      },
      "Verizon": {
        "reach": "16.121.000"
      },
      "Xandr": {
        "reach": "18.329.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.659.000"
      },
      "theTradeDesk": {
        "reach": "4.737.000"
      },
      "Adform": {
        "reach": "4.080.000"
      },
      "Verizon": {
        "reach": "4.083.000"
      },
      "Xandr": {
        "reach": "4.659.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.243.000"
      },
      "RMS": {
        "reach": "3.588.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "11.376.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.578.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.363.000"
      },
      "theTradeDesk": {
        "reach": "5.102.000"
      },
      "Adform": {
        "reach": "4.444.000"
      },
      "Verizon": {
        "reach": "4.372.000"
      },
      "Xandr": {
        "reach": "5.153.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "11.444.000"
      },
      "Instagram": {
        "reach": "6.240.000"
      },
      "YouTube": {
        "reach": "22.040.000"
      },
      "Snapchat": {
        "reach": "1.592.000"
      }
    }
  },
  "Sportbekleidung": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "16.696.000"
      },
      "theTradeDesk": {
        "reach": "13.311.000"
      },
      "Adform": {
        "reach": "11.074.000"
      },
      "Verizon": {
        "reach": "11.884.000"
      },
      "Xandr": {
        "reach": "13.284.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "7.057.000"
      },
      "theTradeDesk": {
        "reach": "5.629.000"
      },
      "Adform": {
        "reach": "4.777.000"
      },
      "Verizon": {
        "reach": "4.770.000"
      },
      "Xandr": {
        "reach": "5.568.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "20.720.000"
      },
      "theTradeDesk": {
        "reach": "17.258.000"
      },
      "Adform": {
        "reach": "15.042.000"
      },
      "Verizon": {
        "reach": "14.621.000"
      },
      "Xandr": {
        "reach": "16.721.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "24.765.000"
      },
      "theTradeDesk": {
        "reach": "20.469.000"
      },
      "Adform": {
        "reach": "16.799.000"
      },
      "Verizon": {
        "reach": "18.477.000"
      },
      "Xandr": {
        "reach": "20.610.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "10.878.000"
      },
      "theTradeDesk": {
        "reach": "3.450.000"
      },
      "Adform": {
        "reach": "7.750.000"
      },
      "Verizon": {
        "reach": "7.624.000"
      },
      "Xandr": {
        "reach": "8.595.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "30.957.000"
      },
      "theTradeDesk": {
        "reach": "23.672.000"
      },
      "Adform": {
        "reach": "20.990.000"
      },
      "Verizon": {
        "reach": "21.315.000"
      },
      "Xandr": {
        "reach": "24.473.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "7.406.000"
      },
      "theTradeDesk": {
        "reach": "6.036.000"
      },
      "Adform": {
        "reach": "5.215.000"
      },
      "Verizon": {
        "reach": "5.214.000"
      },
      "Xandr": {
        "reach": "5.825.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "4.023.000"
      },
      "RMS": {
        "reach": "4.784.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "14.614.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.961.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "8.635.000"
      },
      "theTradeDesk": {
        "reach": "6.559.000"
      },
      "Adform": {
        "reach": "6.061.000"
      },
      "Verizon": {
        "reach": "5.793.000"
      },
      "Xandr": {
        "reach": "6.607.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "15.345.000"
      },
      "Instagram": {
        "reach": "7.720.000"
      },
      "YouTube": {
        "reach": "29.810.000"
      },
      "Snapchat": {
        "reach": "2.083.000"
      }
    }
  },
  "Sportschuhe": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "16.700.000"
      },
      "theTradeDesk": {
        "reach": "13.438.000"
      },
      "Adform": {
        "reach": "11.648.000"
      },
      "Verizon": {
        "reach": "11.521.000"
      },
      "Xandr": {
        "reach": "13.189.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "7.299.000"
      },
      "theTradeDesk": {
        "reach": "5.484.000"
      },
      "Adform": {
        "reach": "4.964.000"
      },
      "Verizon": {
        "reach": "5.089.000"
      },
      "Xandr": {
        "reach": "5.669.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "21.181.000"
      },
      "theTradeDesk": {
        "reach": "17.184.000"
      },
      "Adform": {
        "reach": "14.357.000"
      },
      "Verizon": {
        "reach": "14.687.000"
      },
      "Xandr": {
        "reach": "17.409.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "25.930.000"
      },
      "theTradeDesk": {
        "reach": "19.953.000"
      },
      "Adform": {
        "reach": "18.547.000"
      },
      "Verizon": {
        "reach": "17.456.000"
      },
      "Xandr": {
        "reach": "19.870.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "11.156.000"
      },
      "theTradeDesk": {
        "reach": "3.464.000"
      },
      "Adform": {
        "reach": "7.295.000"
      },
      "Verizon": {
        "reach": "7.666.000"
      },
      "Xandr": {
        "reach": "8.830.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "30.364.000"
      },
      "theTradeDesk": {
        "reach": "24.354.000"
      },
      "Adform": {
        "reach": "22.100.000"
      },
      "Verizon": {
        "reach": "21.591.000"
      },
      "Xandr": {
        "reach": "24.775.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "7.376.000"
      },
      "theTradeDesk": {
        "reach": "6.047.000"
      },
      "Adform": {
        "reach": "5.296.000"
      },
      "Verizon": {
        "reach": "5.309.000"
      },
      "Xandr": {
        "reach": "5.946.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "4.121.000"
      },
      "RMS": {
        "reach": "4.677.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "15.066.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.821.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "8.380.000"
      },
      "theTradeDesk": {
        "reach": "6.743.000"
      },
      "Adform": {
        "reach": "5.894.000"
      },
      "Verizon": {
        "reach": "5.778.000"
      },
      "Xandr": {
        "reach": "6.639.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "15.404.000"
      },
      "Instagram": {
        "reach": "7.827.000"
      },
      "YouTube": {
        "reach": "29.962.000"
      },
      "Snapchat": {
        "reach": "2.089.000"
      }
    }
  },
  "bespielte Tonträger": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.254.000"
      },
      "theTradeDesk": {
        "reach": "4.270.000"
      },
      "Adform": {
        "reach": "3.654.000"
      },
      "Verizon": {
        "reach": "3.718.000"
      },
      "Xandr": {
        "reach": "4.204.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.229.000"
      },
      "theTradeDesk": {
        "reach": "1.863.000"
      },
      "Adform": {
        "reach": "1.593.000"
      },
      "Verizon": {
        "reach": "1.568.000"
      },
      "Xandr": {
        "reach": "1.828.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "6.743.000"
      },
      "theTradeDesk": {
        "reach": "5.194.000"
      },
      "Adform": {
        "reach": "4.672.000"
      },
      "Verizon": {
        "reach": "4.748.000"
      },
      "Xandr": {
        "reach": "5.260.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "8.486.000"
      },
      "theTradeDesk": {
        "reach": "6.398.000"
      },
      "Adform": {
        "reach": "5.494.000"
      },
      "Verizon": {
        "reach": "5.787.000"
      },
      "Xandr": {
        "reach": "6.477.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.373.000"
      },
      "theTradeDesk": {
        "reach": "1.105.000"
      },
      "Adform": {
        "reach": "2.440.000"
      },
      "Verizon": {
        "reach": "2.328.000"
      },
      "Xandr": {
        "reach": "2.821.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "9.406.000"
      },
      "theTradeDesk": {
        "reach": "7.929.000"
      },
      "Adform": {
        "reach": "6.838.000"
      },
      "Verizon": {
        "reach": "6.873.000"
      },
      "Xandr": {
        "reach": "7.841.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.395.000"
      },
      "theTradeDesk": {
        "reach": "1.901.000"
      },
      "Adform": {
        "reach": "1.631.000"
      },
      "Verizon": {
        "reach": "1.670.000"
      },
      "Xandr": {
        "reach": "1.987.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.280.000"
      },
      "RMS": {
        "reach": "1.528.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "4.738.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.973.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.597.000"
      },
      "theTradeDesk": {
        "reach": "2.070.000"
      },
      "Adform": {
        "reach": "1.888.000"
      },
      "Verizon": {
        "reach": "1.818.000"
      },
      "Xandr": {
        "reach": "2.155.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "4.998.000"
      },
      "Instagram": {
        "reach": "2.587.000"
      },
      "YouTube": {
        "reach": "9.277.000"
      },
      "Snapchat": {
        "reach": "655.000"
      }
    }
  },
  "Möbel": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "16.918.000"
      },
      "theTradeDesk": {
        "reach": "13.951.000"
      },
      "Adform": {
        "reach": "12.084.000"
      },
      "Verizon": {
        "reach": "12.369.000"
      },
      "Xandr": {
        "reach": "13.479.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "7.150.000"
      },
      "theTradeDesk": {
        "reach": "5.912.000"
      },
      "Adform": {
        "reach": "5.238.000"
      },
      "Verizon": {
        "reach": "5.167.000"
      },
      "Xandr": {
        "reach": "5.821.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "22.033.000"
      },
      "theTradeDesk": {
        "reach": "18.086.000"
      },
      "Adform": {
        "reach": "15.369.000"
      },
      "Verizon": {
        "reach": "14.976.000"
      },
      "Xandr": {
        "reach": "17.916.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "26.442.000"
      },
      "theTradeDesk": {
        "reach": "20.750.000"
      },
      "Adform": {
        "reach": "18.116.000"
      },
      "Verizon": {
        "reach": "17.889.000"
      },
      "Xandr": {
        "reach": "20.388.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "11.283.000"
      },
      "theTradeDesk": {
        "reach": "3.520.000"
      },
      "Adform": {
        "reach": "7.880.000"
      },
      "Verizon": {
        "reach": "7.772.000"
      },
      "Xandr": {
        "reach": "8.946.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "31.425.000"
      },
      "theTradeDesk": {
        "reach": "24.454.000"
      },
      "Adform": {
        "reach": "21.956.000"
      },
      "Verizon": {
        "reach": "22.967.000"
      },
      "Xandr": {
        "reach": "25.515.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "8.022.000"
      },
      "theTradeDesk": {
        "reach": "6.173.000"
      },
      "Adform": {
        "reach": "5.154.000"
      },
      "Verizon": {
        "reach": "5.442.000"
      },
      "Xandr": {
        "reach": "6.375.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "4.231.000"
      },
      "RMS": {
        "reach": "4.902.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "15.910.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "6.112.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "8.580.000"
      },
      "theTradeDesk": {
        "reach": "6.711.000"
      },
      "Adform": {
        "reach": "5.919.000"
      },
      "Verizon": {
        "reach": "5.920.000"
      },
      "Xandr": {
        "reach": "6.924.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "15.477.000"
      },
      "Instagram": {
        "reach": "8.488.000"
      },
      "YouTube": {
        "reach": "28.835.000"
      },
      "Snapchat": {
        "reach": "2.159.000"
      }
    }
  },
  "Haus- & Heimtextilien, sonst. Einrichtungsgegenstände": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "16.582.000"
      },
      "theTradeDesk": {
        "reach": "13.896.000"
      },
      "Adform": {
        "reach": "12.066.000"
      },
      "Verizon": {
        "reach": "12.107.000"
      },
      "Xandr": {
        "reach": "13.953.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "7.130.000"
      },
      "theTradeDesk": {
        "reach": "5.847.000"
      },
      "Adform": {
        "reach": "5.195.000"
      },
      "Verizon": {
        "reach": "5.154.000"
      },
      "Xandr": {
        "reach": "5.894.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "21.730.000"
      },
      "theTradeDesk": {
        "reach": "17.960.000"
      },
      "Adform": {
        "reach": "15.031.000"
      },
      "Verizon": {
        "reach": "15.703.000"
      },
      "Xandr": {
        "reach": "17.511.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "26.607.000"
      },
      "theTradeDesk": {
        "reach": "21.449.000"
      },
      "Adform": {
        "reach": "17.899.000"
      },
      "Verizon": {
        "reach": "18.194.000"
      },
      "Xandr": {
        "reach": "20.900.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "11.058.000"
      },
      "theTradeDesk": {
        "reach": "3.520.000"
      },
      "Adform": {
        "reach": "7.634.000"
      },
      "Verizon": {
        "reach": "7.713.000"
      },
      "Xandr": {
        "reach": "9.034.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "31.206.000"
      },
      "theTradeDesk": {
        "reach": "24.904.000"
      },
      "Adform": {
        "reach": "21.404.000"
      },
      "Verizon": {
        "reach": "22.098.000"
      },
      "Xandr": {
        "reach": "25.330.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "7.823.000"
      },
      "theTradeDesk": {
        "reach": "6.078.000"
      },
      "Adform": {
        "reach": "5.320.000"
      },
      "Verizon": {
        "reach": "5.648.000"
      },
      "Xandr": {
        "reach": "6.130.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "4.278.000"
      },
      "RMS": {
        "reach": "5.071.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "15.049.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "6.287.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "8.498.000"
      },
      "theTradeDesk": {
        "reach": "7.092.000"
      },
      "Adform": {
        "reach": "6.125.000"
      },
      "Verizon": {
        "reach": "5.852.000"
      },
      "Xandr": {
        "reach": "6.935.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "15.610.000"
      },
      "Instagram": {
        "reach": "8.196.000"
      },
      "YouTube": {
        "reach": "28.948.000"
      },
      "Snapchat": {
        "reach": "2.147.000"
      }
    }
  },
  "Möbel & Einrichtung - niedrige Kaufkraft": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "13.386.000"
      },
      "theTradeDesk": {
        "reach": "10.853.000"
      },
      "Adform": {
        "reach": "9.458.000"
      },
      "Verizon": {
        "reach": "9.343.000"
      },
      "Xandr": {
        "reach": "10.488.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.939.000"
      },
      "theTradeDesk": {
        "reach": "4.525.000"
      },
      "Adform": {
        "reach": "4.077.000"
      },
      "Verizon": {
        "reach": "4.060.000"
      },
      "Xandr": {
        "reach": "4.688.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "17.488.000"
      },
      "theTradeDesk": {
        "reach": "14.000.000"
      },
      "Adform": {
        "reach": "12.562.000"
      },
      "Verizon": {
        "reach": "12.742.000"
      },
      "Xandr": {
        "reach": "13.646.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "20.790.000"
      },
      "theTradeDesk": {
        "reach": "16.978.000"
      },
      "Adform": {
        "reach": "14.479.000"
      },
      "Verizon": {
        "reach": "14.222.000"
      },
      "Xandr": {
        "reach": "16.729.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "8.914.000"
      },
      "theTradeDesk": {
        "reach": "2.822.000"
      },
      "Adform": {
        "reach": "5.912.000"
      },
      "Verizon": {
        "reach": "6.358.000"
      },
      "Xandr": {
        "reach": "7.109.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "24.554.000"
      },
      "theTradeDesk": {
        "reach": "20.447.000"
      },
      "Adform": {
        "reach": "17.693.000"
      },
      "Verizon": {
        "reach": "17.873.000"
      },
      "Xandr": {
        "reach": "20.568.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "6.105.000"
      },
      "theTradeDesk": {
        "reach": "4.853.000"
      },
      "Adform": {
        "reach": "4.355.000"
      },
      "Verizon": {
        "reach": "4.365.000"
      },
      "Xandr": {
        "reach": "5.012.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.323.000"
      },
      "RMS": {
        "reach": "3.786.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "12.635.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "5.053.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.861.000"
      },
      "theTradeDesk": {
        "reach": "5.561.000"
      },
      "Adform": {
        "reach": "4.880.000"
      },
      "Verizon": {
        "reach": "4.777.000"
      },
      "Xandr": {
        "reach": "5.551.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "12.659.000"
      },
      "Instagram": {
        "reach": "6.648.000"
      },
      "YouTube": {
        "reach": "23.797.000"
      },
      "Snapchat": {
        "reach": "1.752.000"
      }
    }
  },
  "Möbel & Einrichtung - mittlere Kaufkraft": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "8.485.000"
      },
      "theTradeDesk": {
        "reach": "7.042.000"
      },
      "Adform": {
        "reach": "5.832.000"
      },
      "Verizon": {
        "reach": "5.814.000"
      },
      "Xandr": {
        "reach": "6.684.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.632.000"
      },
      "theTradeDesk": {
        "reach": "2.914.000"
      },
      "Adform": {
        "reach": "2.633.000"
      },
      "Verizon": {
        "reach": "2.574.000"
      },
      "Xandr": {
        "reach": "2.865.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "11.034.000"
      },
      "theTradeDesk": {
        "reach": "8.563.000"
      },
      "Adform": {
        "reach": "7.321.000"
      },
      "Verizon": {
        "reach": "7.445.000"
      },
      "Xandr": {
        "reach": "8.833.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "13.002.000"
      },
      "theTradeDesk": {
        "reach": "10.434.000"
      },
      "Adform": {
        "reach": "9.295.000"
      },
      "Verizon": {
        "reach": "9.356.000"
      },
      "Xandr": {
        "reach": "10.403.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.534.000"
      },
      "theTradeDesk": {
        "reach": "1.747.000"
      },
      "Adform": {
        "reach": "3.833.000"
      },
      "Verizon": {
        "reach": "3.710.000"
      },
      "Xandr": {
        "reach": "4.366.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "15.395.000"
      },
      "theTradeDesk": {
        "reach": "12.197.000"
      },
      "Adform": {
        "reach": "11.032.000"
      },
      "Verizon": {
        "reach": "10.476.000"
      },
      "Xandr": {
        "reach": "12.153.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.847.000"
      },
      "theTradeDesk": {
        "reach": "2.992.000"
      },
      "Adform": {
        "reach": "2.703.000"
      },
      "Verizon": {
        "reach": "2.720.000"
      },
      "Xandr": {
        "reach": "3.014.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.109.000"
      },
      "RMS": {
        "reach": "2.422.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "7.555.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.113.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "4.184.000"
      },
      "theTradeDesk": {
        "reach": "3.321.000"
      },
      "Adform": {
        "reach": "2.959.000"
      },
      "Verizon": {
        "reach": "2.881.000"
      },
      "Xandr": {
        "reach": "3.426.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "7.761.000"
      },
      "Instagram": {
        "reach": "4.098.000"
      },
      "YouTube": {
        "reach": "14.790.000"
      },
      "Snapchat": {
        "reach": "1.028.000"
      }
    }
  },
  "Möbel & Einrichtung - hohe Kaufkraft": {
    "cpm": "2,90€",
    "Desktop - Display": {
      "DV360": {
        "reach": "918.000"
      },
      "theTradeDesk": {
        "reach": "730.000"
      },
      "Adform": {
        "reach": "643.000"
      },
      "Verizon": {
        "reach": "643.000"
      },
      "Xandr": {
        "reach": "778.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "401.000"
      },
      "theTradeDesk": {
        "reach": "313.000"
      },
      "Adform": {
        "reach": "280.000"
      },
      "Verizon": {
        "reach": "282.000"
      },
      "Xandr": {
        "reach": "304.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.206.000"
      },
      "theTradeDesk": {
        "reach": "951.000"
      },
      "Adform": {
        "reach": "824.000"
      },
      "Verizon": {
        "reach": "819.000"
      },
      "Xandr": {
        "reach": "973.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.427.000"
      },
      "theTradeDesk": {
        "reach": "1.129.000"
      },
      "Adform": {
        "reach": "957.000"
      },
      "Verizon": {
        "reach": "970.000"
      },
      "Xandr": {
        "reach": "1.141.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "587.000"
      },
      "theTradeDesk": {
        "reach": "194.000"
      },
      "Adform": {
        "reach": "409.000"
      },
      "Verizon": {
        "reach": "423.000"
      },
      "Xandr": {
        "reach": "503.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.677.000"
      },
      "theTradeDesk": {
        "reach": "1.324.000"
      },
      "Adform": {
        "reach": "1.188.000"
      },
      "Verizon": {
        "reach": "1.201.000"
      },
      "Xandr": {
        "reach": "1.375.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "411.000"
      },
      "theTradeDesk": {
        "reach": "337.000"
      },
      "Adform": {
        "reach": "294.000"
      },
      "Verizon": {
        "reach": "290.000"
      },
      "Xandr": {
        "reach": "329.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "229.000"
      },
      "RMS": {
        "reach": "263.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "846.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "337.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "452.000"
      },
      "theTradeDesk": {
        "reach": "369.000"
      },
      "Adform": {
        "reach": "330.000"
      },
      "Verizon": {
        "reach": "317.000"
      },
      "Xandr": {
        "reach": "367.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "861.000"
      },
      "Instagram": {
        "reach": "447.000"
      },
      "YouTube": {
        "reach": "1.564.000"
      },
      "Snapchat": {
        "reach": "114.000"
      }
    }
  },
  "18-24": {
    "cpm": "1,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.510.000"
      },
      "theTradeDesk": {
        "reach": "2.059.000"
      },
      "Adform": {
        "reach": "1.745.000"
      },
      "Verizon": {
        "reach": "1.775.000"
      },
      "Xandr": {
        "reach": "1.938.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.103.000"
      },
      "theTradeDesk": {
        "reach": "888.000"
      },
      "Adform": {
        "reach": "758.000"
      },
      "Verizon": {
        "reach": "750.000"
      },
      "Xandr": {
        "reach": "879.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.260.000"
      },
      "theTradeDesk": {
        "reach": "2.598.000"
      },
      "Adform": {
        "reach": "2.203.000"
      },
      "Verizon": {
        "reach": "2.256.000"
      },
      "Xandr": {
        "reach": "2.597.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.780.000"
      },
      "theTradeDesk": {
        "reach": "3.174.000"
      },
      "Adform": {
        "reach": "2.685.000"
      },
      "Verizon": {
        "reach": "2.625.000"
      },
      "Xandr": {
        "reach": "3.205.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.700.000"
      },
      "theTradeDesk": {
        "reach": "524.000"
      },
      "Adform": {
        "reach": "1.133.000"
      },
      "Verizon": {
        "reach": "1.189.000"
      },
      "Xandr": {
        "reach": "1.341.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.689.000"
      },
      "theTradeDesk": {
        "reach": "3.790.000"
      },
      "Adform": {
        "reach": "3.341.000"
      },
      "Verizon": {
        "reach": "3.233.000"
      },
      "Xandr": {
        "reach": "3.739.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.113.000"
      },
      "theTradeDesk": {
        "reach": "923.000"
      },
      "Adform": {
        "reach": "788.000"
      },
      "Verizon": {
        "reach": "793.000"
      },
      "Xandr": {
        "reach": "895.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "601.000"
      },
      "RMS": {
        "reach": "697.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.328.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "930.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.261.000"
      },
      "theTradeDesk": {
        "reach": "1.014.000"
      },
      "Adform": {
        "reach": "886.000"
      },
      "Verizon": {
        "reach": "866.000"
      },
      "Xandr": {
        "reach": "1.049.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.364.000"
      },
      "Instagram": {
        "reach": "1.243.000"
      },
      "YouTube": {
        "reach": "4.368.000"
      },
      "Snapchat": {
        "reach": "545.000"
      }
    }
  },
  "25-39": {
    "cpm": "1,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.261.000"
      },
      "theTradeDesk": {
        "reach": "5.078.000"
      },
      "Adform": {
        "reach": "4.420.000"
      },
      "Verizon": {
        "reach": "4.524.000"
      },
      "Xandr": {
        "reach": "5.096.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.760.000"
      },
      "theTradeDesk": {
        "reach": "2.192.000"
      },
      "Adform": {
        "reach": "1.904.000"
      },
      "Verizon": {
        "reach": "1.862.000"
      },
      "Xandr": {
        "reach": "2.103.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "8.387.000"
      },
      "theTradeDesk": {
        "reach": "6.623.000"
      },
      "Adform": {
        "reach": "5.757.000"
      },
      "Verizon": {
        "reach": "5.831.000"
      },
      "Xandr": {
        "reach": "6.573.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "9.893.000"
      },
      "theTradeDesk": {
        "reach": "7.570.000"
      },
      "Adform": {
        "reach": "6.865.000"
      },
      "Verizon": {
        "reach": "6.854.000"
      },
      "Xandr": {
        "reach": "7.771.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.203.000"
      },
      "theTradeDesk": {
        "reach": "1.365.000"
      },
      "Adform": {
        "reach": "2.859.000"
      },
      "Verizon": {
        "reach": "2.792.000"
      },
      "Xandr": {
        "reach": "3.338.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "11.519.000"
      },
      "theTradeDesk": {
        "reach": "9.460.000"
      },
      "Adform": {
        "reach": "8.303.000"
      },
      "Verizon": {
        "reach": "8.023.000"
      },
      "Xandr": {
        "reach": "9.677.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.932.000"
      },
      "theTradeDesk": {
        "reach": "2.337.000"
      },
      "Adform": {
        "reach": "1.986.000"
      },
      "Verizon": {
        "reach": "1.950.000"
      },
      "Xandr": {
        "reach": "2.251.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.634.000"
      },
      "RMS": {
        "reach": "1.789.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.743.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.320.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.165.000"
      },
      "theTradeDesk": {
        "reach": "2.542.000"
      },
      "Adform": {
        "reach": "2.210.000"
      },
      "Verizon": {
        "reach": "2.172.000"
      },
      "Xandr": {
        "reach": "2.523.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.842.000"
      },
      "Instagram": {
        "reach": "3.072.000"
      },
      "YouTube": {
        "reach": "11.167.000"
      },
      "Snapchat": {
        "reach": "1.341.000"
      }
    }
  },
  "40-59": {
    "cpm": "1,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "9.671.000"
      },
      "theTradeDesk": {
        "reach": "7.691.000"
      },
      "Adform": {
        "reach": "6.728.000"
      },
      "Verizon": {
        "reach": "6.899.000"
      },
      "Xandr": {
        "reach": "7.565.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.903.000"
      },
      "theTradeDesk": {
        "reach": "3.337.000"
      },
      "Adform": {
        "reach": "2.951.000"
      },
      "Verizon": {
        "reach": "2.857.000"
      },
      "Xandr": {
        "reach": "3.330.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "12.630.000"
      },
      "theTradeDesk": {
        "reach": "10.095.000"
      },
      "Adform": {
        "reach": "8.201.000"
      },
      "Verizon": {
        "reach": "8.697.000"
      },
      "Xandr": {
        "reach": "9.838.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "14.549.000"
      },
      "theTradeDesk": {
        "reach": "11.981.000"
      },
      "Adform": {
        "reach": "10.138.000"
      },
      "Verizon": {
        "reach": "10.436.000"
      },
      "Xandr": {
        "reach": "11.829.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "6.470.000"
      },
      "theTradeDesk": {
        "reach": "2.046.000"
      },
      "Adform": {
        "reach": "4.411.000"
      },
      "Verizon": {
        "reach": "4.200.000"
      },
      "Xandr": {
        "reach": "5.050.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "16.892.000"
      },
      "theTradeDesk": {
        "reach": "14.158.000"
      },
      "Adform": {
        "reach": "12.183.000"
      },
      "Verizon": {
        "reach": "12.397.000"
      },
      "Xandr": {
        "reach": "14.331.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.327.000"
      },
      "theTradeDesk": {
        "reach": "3.505.000"
      },
      "Adform": {
        "reach": "3.078.000"
      },
      "Verizon": {
        "reach": "3.105.000"
      },
      "Xandr": {
        "reach": "3.542.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.399.000"
      },
      "RMS": {
        "reach": "2.727.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "8.832.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.544.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "4.718.000"
      },
      "theTradeDesk": {
        "reach": "3.778.000"
      },
      "Adform": {
        "reach": "3.351.000"
      },
      "Verizon": {
        "reach": "3.263.000"
      },
      "Xandr": {
        "reach": "3.805.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "8.748.000"
      },
      "Instagram": {
        "reach": "4.561.000"
      },
      "YouTube": {
        "reach": "17.030.000"
      },
      "Snapchat": {
        "reach": "2.016.000"
      }
    }
  },
  "60+": {
    "cpm": "1,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.767.000"
      },
      "theTradeDesk": {
        "reach": "3.037.000"
      },
      "Adform": {
        "reach": "2.651.000"
      },
      "Verizon": {
        "reach": "2.667.000"
      },
      "Xandr": {
        "reach": "3.053.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.615.000"
      },
      "theTradeDesk": {
        "reach": "1.284.000"
      },
      "Adform": {
        "reach": "1.111.000"
      },
      "Verizon": {
        "reach": "1.128.000"
      },
      "Xandr": {
        "reach": "1.348.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.825.000"
      },
      "theTradeDesk": {
        "reach": "3.809.000"
      },
      "Adform": {
        "reach": "3.332.000"
      },
      "Verizon": {
        "reach": "3.421.000"
      },
      "Xandr": {
        "reach": "3.763.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.660.000"
      },
      "theTradeDesk": {
        "reach": "4.754.000"
      },
      "Adform": {
        "reach": "3.963.000"
      },
      "Verizon": {
        "reach": "3.918.000"
      },
      "Xandr": {
        "reach": "4.558.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.431.000"
      },
      "theTradeDesk": {
        "reach": "1.934.000"
      },
      "Adform": {
        "reach": "1.728.000"
      },
      "Verizon": {
        "reach": "1.750.000"
      },
      "Xandr": {
        "reach": "1.914.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.965.000"
      },
      "theTradeDesk": {
        "reach": "5.615.000"
      },
      "Adform": {
        "reach": "4.870.000"
      },
      "Verizon": {
        "reach": "4.953.000"
      },
      "Xandr": {
        "reach": "5.505.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.711.000"
      },
      "theTradeDesk": {
        "reach": "1.379.000"
      },
      "Adform": {
        "reach": "1.224.000"
      },
      "Verizon": {
        "reach": "1.170.000"
      },
      "Xandr": {
        "reach": "1.346.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.351.000"
      },
      "RMS": {
        "reach": "1.064.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.303.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.386.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.919.000"
      },
      "theTradeDesk": {
        "reach": "1.486.000"
      },
      "Adform": {
        "reach": "1.330.000"
      },
      "Verizon": {
        "reach": "1.300.000"
      },
      "Xandr": {
        "reach": "1.510.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.447.000"
      },
      "Instagram": {
        "reach": "1.805.000"
      },
      "YouTube": {
        "reach": "6.405.000"
      },
      "Snapchat": {
        "reach": "2.016.000"
      }
    }
  },
  "Hochschulreife": {
    "cpm": "1,10€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.647.000"
      },
      "theTradeDesk": {
        "reach": "2.982.000"
      },
      "Adform": {
        "reach": "2.655.000"
      },
      "Verizon": {
        "reach": "2.673.000"
      },
      "Xandr": {
        "reach": "3.011.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.649.000"
      },
      "theTradeDesk": {
        "reach": "1.286.000"
      },
      "Adform": {
        "reach": "1.085.000"
      },
      "Verizon": {
        "reach": "1.117.000"
      },
      "Xandr": {
        "reach": "1.335.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.650.000"
      },
      "theTradeDesk": {
        "reach": "3.866.000"
      },
      "Adform": {
        "reach": "3.295.000"
      },
      "Verizon": {
        "reach": "3.398.000"
      },
      "Xandr": {
        "reach": "3.799.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.742.000"
      },
      "theTradeDesk": {
        "reach": "4.687.000"
      },
      "Adform": {
        "reach": "3.979.000"
      },
      "Verizon": {
        "reach": "4.005.000"
      },
      "Xandr": {
        "reach": "4.636.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.399.000"
      },
      "theTradeDesk": {
        "reach": "787.000"
      },
      "Adform": {
        "reach": "1.712.000"
      },
      "Verizon": {
        "reach": "1.681.000"
      },
      "Xandr": {
        "reach": "1.956.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.897.000"
      },
      "theTradeDesk": {
        "reach": "5.420.000"
      },
      "Adform": {
        "reach": "5.042.000"
      },
      "Verizon": {
        "reach": "4.967.000"
      },
      "Xandr": {
        "reach": "5.465.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.696.000"
      },
      "theTradeDesk": {
        "reach": "1.355.000"
      },
      "Adform": {
        "reach": "1.193.000"
      },
      "Verizon": {
        "reach": "1.216.000"
      },
      "Xandr": {
        "reach": "1.354.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "916.000"
      },
      "RMS": {
        "reach": "1.053.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.529.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.398.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.844.000"
      },
      "theTradeDesk": {
        "reach": "1.506.000"
      },
      "Adform": {
        "reach": "1.281.000"
      },
      "Verizon": {
        "reach": "1.303.000"
      },
      "Xandr": {
        "reach": "1.485.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.441.000"
      },
      "Instagram": {
        "reach": "1.783.000"
      },
      "YouTube": {
        "reach": "6.745.000"
      },
      "Snapchat": {
        "reach": "542.000"
      }
    }
  },
  "Berufsausbildung": {
    "cpm": "1,10€",
    "Desktop - Display": {
      "DV360": {
        "reach": "12.566.000"
      },
      "theTradeDesk": {
        "reach": "10.010.000"
      },
      "Adform": {
        "reach": "8.652.000"
      },
      "Verizon": {
        "reach": "8.896.000"
      },
      "Xandr": {
        "reach": "10.039.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.377.000"
      },
      "theTradeDesk": {
        "reach": "4.243.000"
      },
      "Adform": {
        "reach": "3.729.000"
      },
      "Verizon": {
        "reach": "3.703.000"
      },
      "Xandr": {
        "reach": "4.077.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "15.752.000"
      },
      "theTradeDesk": {
        "reach": "12.618.000"
      },
      "Adform": {
        "reach": "11.366.000"
      },
      "Verizon": {
        "reach": "10.966.000"
      },
      "Xandr": {
        "reach": "12.554.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "19.187.000"
      },
      "theTradeDesk": {
        "reach": "15.165.000"
      },
      "Adform": {
        "reach": "13.033.000"
      },
      "Verizon": {
        "reach": "13.331.000"
      },
      "Xandr": {
        "reach": "15.299.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "8.114.000"
      },
      "theTradeDesk": {
        "reach": "2.567.000"
      },
      "Adform": {
        "reach": "5.707.000"
      },
      "Verizon": {
        "reach": "5.943.000"
      },
      "Xandr": {
        "reach": "6.498.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "21.994.000"
      },
      "theTradeDesk": {
        "reach": "18.280.000"
      },
      "Adform": {
        "reach": "16.253.000"
      },
      "Verizon": {
        "reach": "15.624.000"
      },
      "Xandr": {
        "reach": "18.283.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.544.000"
      },
      "theTradeDesk": {
        "reach": "4.412.000"
      },
      "Adform": {
        "reach": "3.829.000"
      },
      "Verizon": {
        "reach": "4.087.000"
      },
      "Xandr": {
        "reach": "4.570.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.040.000"
      },
      "RMS": {
        "reach": "3.620.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "11.134.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.496.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.171.000"
      },
      "theTradeDesk": {
        "reach": "5.031.000"
      },
      "Adform": {
        "reach": "4.341.000"
      },
      "Verizon": {
        "reach": "4.344.000"
      },
      "Xandr": {
        "reach": "4.970.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "11.357.000"
      },
      "Instagram": {
        "reach": "5.931.000"
      },
      "YouTube": {
        "reach": "21.756.000"
      },
      "Snapchat": {
        "reach": "1.886.000"
      }
    }
  },
  "Hochschulabschluss": {
    "cpm": "1,10€",
    "Desktop - Display": {
      "DV360": {
        "reach": "4.746.000"
      },
      "theTradeDesk": {
        "reach": "3.754.000"
      },
      "Adform": {
        "reach": "3.211.000"
      },
      "Verizon": {
        "reach": "3.246.000"
      },
      "Xandr": {
        "reach": "3.655.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.927.000"
      },
      "theTradeDesk": {
        "reach": "1.602.000"
      },
      "Adform": {
        "reach": "1.418.000"
      },
      "Verizon": {
        "reach": "1.337.000"
      },
      "Xandr": {
        "reach": "1.639.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "6.013.000"
      },
      "theTradeDesk": {
        "reach": "4.767.000"
      },
      "Adform": {
        "reach": "4.141.000"
      },
      "Verizon": {
        "reach": "4.144.000"
      },
      "Xandr": {
        "reach": "4.645.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "6.998.000"
      },
      "theTradeDesk": {
        "reach": "5.684.000"
      },
      "Adform": {
        "reach": "4.958.000"
      },
      "Verizon": {
        "reach": "4.807.000"
      },
      "Xandr": {
        "reach": "5.602.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.966.000"
      },
      "theTradeDesk": {
        "reach": "965.000"
      },
      "Adform": {
        "reach": "2.197.000"
      },
      "Verizon": {
        "reach": "2.128.000"
      },
      "Xandr": {
        "reach": "2.433.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "8.394.000"
      },
      "theTradeDesk": {
        "reach": "7.027.000"
      },
      "Adform": {
        "reach": "5.947.000"
      },
      "Verizon": {
        "reach": "5.916.000"
      },
      "Xandr": {
        "reach": "6.824.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.005.000"
      },
      "theTradeDesk": {
        "reach": "1.657.000"
      },
      "Adform": {
        "reach": "1.420.000"
      },
      "Verizon": {
        "reach": "1.432.000"
      },
      "Xandr": {
        "reach": "1.635.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.142.000"
      },
      "RMS": {
        "reach": "1.268.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "4.241.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.699.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "2.306.000"
      },
      "theTradeDesk": {
        "reach": "1.790.000"
      },
      "Adform": {
        "reach": "1.564.000"
      },
      "Verizon": {
        "reach": "1.570.000"
      },
      "Xandr": {
        "reach": "1.816.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "4.246.000"
      },
      "Instagram": {
        "reach": "2.197.000"
      },
      "YouTube": {
        "reach": "8.027.000"
      },
      "Snapchat": {
        "reach": "671.000"
      }
    }
  },
  "Studenten": {
    "cpm": "1,10€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.203.000"
      },
      "theTradeDesk": {
        "reach": "937.000"
      },
      "Adform": {
        "reach": "824.000"
      },
      "Verizon": {
        "reach": "830.000"
      },
      "Xandr": {
        "reach": "936.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "515.000"
      },
      "theTradeDesk": {
        "reach": "403.000"
      },
      "Adform": {
        "reach": "354.000"
      },
      "Verizon": {
        "reach": "340.000"
      },
      "Xandr": {
        "reach": "391.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.537.000"
      },
      "theTradeDesk": {
        "reach": "1.182.000"
      },
      "Adform": {
        "reach": "1.051.000"
      },
      "Verizon": {
        "reach": "1.049.000"
      },
      "Xandr": {
        "reach": "1.208.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.759.000"
      },
      "theTradeDesk": {
        "reach": "1.406.000"
      },
      "Adform": {
        "reach": "1.236.000"
      },
      "Verizon": {
        "reach": "1.234.000"
      },
      "Xandr": {
        "reach": "1.394.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "767.000"
      },
      "theTradeDesk": {
        "reach": "242.000"
      },
      "Adform": {
        "reach": "535.000"
      },
      "Verizon": {
        "reach": "513.000"
      },
      "Xandr": {
        "reach": "635.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.100.000"
      },
      "theTradeDesk": {
        "reach": "1.724.000"
      },
      "Adform": {
        "reach": "1.481.000"
      },
      "Verizon": {
        "reach": "1.467.000"
      },
      "Xandr": {
        "reach": "1.739.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "534.000"
      },
      "theTradeDesk": {
        "reach": "429.000"
      },
      "Adform": {
        "reach": "361.000"
      },
      "Verizon": {
        "reach": "368.000"
      },
      "Xandr": {
        "reach": "419.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "285.000"
      },
      "RMS": {
        "reach": "332.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.048.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "418.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "568.000"
      },
      "theTradeDesk": {
        "reach": "474.000"
      },
      "Adform": {
        "reach": "406.000"
      },
      "Verizon": {
        "reach": "410.000"
      },
      "Xandr": {
        "reach": "454.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.050.000"
      },
      "Instagram": {
        "reach": "566.000"
      },
      "YouTube": {
        "reach": "2.036.000"
      },
      "Snapchat": {
        "reach": "175.000"
      }
    }
  },
  "Alleinstehende Senioren": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "788.000"
      },
      "theTradeDesk": {
        "reach": "639.000"
      },
      "Adform": {
        "reach": "548.000"
      },
      "Verizon": {
        "reach": "563.000"
      },
      "Xandr": {
        "reach": "632.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "350.000"
      },
      "theTradeDesk": {
        "reach": "275.000"
      },
      "Adform": {
        "reach": "236.000"
      },
      "Verizon": {
        "reach": "242.000"
      },
      "Xandr": {
        "reach": "273.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.023.000"
      },
      "theTradeDesk": {
        "reach": "812.000"
      },
      "Adform": {
        "reach": "728.000"
      },
      "Verizon": {
        "reach": "724.000"
      },
      "Xandr": {
        "reach": "805.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.266.000"
      },
      "theTradeDesk": {
        "reach": "1.005.000"
      },
      "Adform": {
        "reach": "821.000"
      },
      "Verizon": {
        "reach": "845.000"
      },
      "Xandr": {
        "reach": "988.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "518.000"
      },
      "theTradeDesk": {
        "reach": "421.000"
      },
      "Adform": {
        "reach": "362.000"
      },
      "Verizon": {
        "reach": "378.000"
      },
      "Xandr": {
        "reach": "430.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.454.000"
      },
      "theTradeDesk": {
        "reach": "1.208.000"
      },
      "Adform": {
        "reach": "999.000"
      },
      "Verizon": {
        "reach": "1.073.000"
      },
      "Xandr": {
        "reach": "1.171.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "362.000"
      },
      "theTradeDesk": {
        "reach": "285.000"
      },
      "Adform": {
        "reach": "251.000"
      },
      "Verizon": {
        "reach": "253.000"
      },
      "Xandr": {
        "reach": "286.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "497.000"
      },
      "RMS": {
        "reach": "221.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "739.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "286.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "399.000"
      },
      "theTradeDesk": {
        "reach": "325.000"
      },
      "Adform": {
        "reach": "278.000"
      },
      "Verizon": {
        "reach": "271.000"
      },
      "Xandr": {
        "reach": "323.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "730.000"
      },
      "Instagram": {
        "reach": "384.000"
      },
      "YouTube": {
        "reach": "1.408.000"
      },
      "Snapchat": {
        "reach": "42.000"
      }
    }
  },
  "Ältere Paare": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.486.000"
      },
      "theTradeDesk": {
        "reach": "2.012.000"
      },
      "Adform": {
        "reach": "1.786.000"
      },
      "Verizon": {
        "reach": "1.845.000"
      },
      "Xandr": {
        "reach": "1.981.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.083.000"
      },
      "theTradeDesk": {
        "reach": "869.000"
      },
      "Adform": {
        "reach": "742.000"
      },
      "Verizon": {
        "reach": "752.000"
      },
      "Xandr": {
        "reach": "872.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.141.000"
      },
      "theTradeDesk": {
        "reach": "2.563.000"
      },
      "Adform": {
        "reach": "2.363.000"
      },
      "Verizon": {
        "reach": "2.263.000"
      },
      "Xandr": {
        "reach": "2.601.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.887.000"
      },
      "theTradeDesk": {
        "reach": "3.200.000"
      },
      "Adform": {
        "reach": "2.681.000"
      },
      "Verizon": {
        "reach": "2.643.000"
      },
      "Xandr": {
        "reach": "3.130.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.665.000"
      },
      "theTradeDesk": {
        "reach": "1.312.000"
      },
      "Adform": {
        "reach": "1.154.000"
      },
      "Verizon": {
        "reach": "1.147.000"
      },
      "Xandr": {
        "reach": "1.327.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.616.000"
      },
      "theTradeDesk": {
        "reach": "3.728.000"
      },
      "Adform": {
        "reach": "3.243.000"
      },
      "Verizon": {
        "reach": "3.173.000"
      },
      "Xandr": {
        "reach": "3.754.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.140.000"
      },
      "theTradeDesk": {
        "reach": "868.000"
      },
      "Adform": {
        "reach": "785.000"
      },
      "Verizon": {
        "reach": "799.000"
      },
      "Xandr": {
        "reach": "899.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.551.000"
      },
      "RMS": {
        "reach": "704.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.304.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "918.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.236.000"
      },
      "theTradeDesk": {
        "reach": "974.000"
      },
      "Adform": {
        "reach": "917.000"
      },
      "Verizon": {
        "reach": "853.000"
      },
      "Xandr": {
        "reach": "1.045.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.310.000"
      },
      "Instagram": {
        "reach": "1.258.000"
      },
      "YouTube": {
        "reach": "4.455.000"
      },
      "Snapchat": {
        "reach": "268.000"
      }
    }
  },
  "Ältere Mehrpersonenhaushalte": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.648.000"
      },
      "theTradeDesk": {
        "reach": "2.131.000"
      },
      "Adform": {
        "reach": "1.899.000"
      },
      "Verizon": {
        "reach": "1.891.000"
      },
      "Xandr": {
        "reach": "2.122.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.120.000"
      },
      "theTradeDesk": {
        "reach": "914.000"
      },
      "Adform": {
        "reach": "806.000"
      },
      "Verizon": {
        "reach": "821.000"
      },
      "Xandr": {
        "reach": "917.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.364.000"
      },
      "theTradeDesk": {
        "reach": "2.748.000"
      },
      "Adform": {
        "reach": "2.357.000"
      },
      "Verizon": {
        "reach": "2.380.000"
      },
      "Xandr": {
        "reach": "2.715.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.038.000"
      },
      "theTradeDesk": {
        "reach": "3.297.000"
      },
      "Adform": {
        "reach": "2.791.000"
      },
      "Verizon": {
        "reach": "2.908.000"
      },
      "Xandr": {
        "reach": "3.257.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.691.000"
      },
      "theTradeDesk": {
        "reach": "1.376.000"
      },
      "Adform": {
        "reach": "1.259.000"
      },
      "Verizon": {
        "reach": "1.216.000"
      },
      "Xandr": {
        "reach": "1.351.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.747.000"
      },
      "theTradeDesk": {
        "reach": "3.919.000"
      },
      "Adform": {
        "reach": "3.473.000"
      },
      "Verizon": {
        "reach": "3.490.000"
      },
      "Xandr": {
        "reach": "3.869.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.169.000"
      },
      "theTradeDesk": {
        "reach": "962.000"
      },
      "Adform": {
        "reach": "818.000"
      },
      "Verizon": {
        "reach": "850.000"
      },
      "Xandr": {
        "reach": "936.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.669.000"
      },
      "RMS": {
        "reach": "763.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.413.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "973.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.280.000"
      },
      "theTradeDesk": {
        "reach": "1.040.000"
      },
      "Adform": {
        "reach": "965.000"
      },
      "Verizon": {
        "reach": "911.000"
      },
      "Xandr": {
        "reach": "1.056.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.435.000"
      },
      "Instagram": {
        "reach": "1.269.000"
      },
      "YouTube": {
        "reach": "4.558.000"
      },
      "Snapchat": {
        "reach": "269.000"
      }
    }
  },
  "Singles": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.947.000"
      },
      "theTradeDesk": {
        "reach": "1.587.000"
      },
      "Adform": {
        "reach": "1.428.000"
      },
      "Verizon": {
        "reach": "1.371.000"
      },
      "Xandr": {
        "reach": "1.610.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "806.000"
      },
      "theTradeDesk": {
        "reach": "678.000"
      },
      "Adform": {
        "reach": "592.000"
      },
      "Verizon": {
        "reach": "593.000"
      },
      "Xandr": {
        "reach": "659.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.538.000"
      },
      "theTradeDesk": {
        "reach": "2.083.000"
      },
      "Adform": {
        "reach": "1.768.000"
      },
      "Verizon": {
        "reach": "1.861.000"
      },
      "Xandr": {
        "reach": "2.118.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.177.000"
      },
      "theTradeDesk": {
        "reach": "2.390.000"
      },
      "Adform": {
        "reach": "2.137.000"
      },
      "Verizon": {
        "reach": "2.140.000"
      },
      "Xandr": {
        "reach": "2.550.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.322.000"
      },
      "theTradeDesk": {
        "reach": "408.000"
      },
      "Adform": {
        "reach": "923.000"
      },
      "Verizon": {
        "reach": "923.000"
      },
      "Xandr": {
        "reach": "1.067.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.725.000"
      },
      "theTradeDesk": {
        "reach": "2.828.000"
      },
      "Adform": {
        "reach": "2.575.000"
      },
      "Verizon": {
        "reach": "2.531.000"
      },
      "Xandr": {
        "reach": "2.855.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "870.000"
      },
      "theTradeDesk": {
        "reach": "721.000"
      },
      "Adform": {
        "reach": "632.000"
      },
      "Verizon": {
        "reach": "633.000"
      },
      "Xandr": {
        "reach": "720.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "493.000"
      },
      "RMS": {
        "reach": "536.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.808.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "721.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.014.000"
      },
      "theTradeDesk": {
        "reach": "804.000"
      },
      "Adform": {
        "reach": "721.000"
      },
      "Verizon": {
        "reach": "695.000"
      },
      "Xandr": {
        "reach": "794.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.841.000"
      },
      "Instagram": {
        "reach": "991.000"
      },
      "YouTube": {
        "reach": "3.468.000"
      },
      "Snapchat": {
        "reach": "245.000"
      }
    }
  },
  "Paare": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "11.252.000"
      },
      "theTradeDesk": {
        "reach": "8.718.000"
      },
      "Adform": {
        "reach": "7.634.000"
      },
      "Verizon": {
        "reach": "7.585.000"
      },
      "Xandr": {
        "reach": "8.785.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.800.000"
      },
      "theTradeDesk": {
        "reach": "3.920.000"
      },
      "Adform": {
        "reach": "3.428.000"
      },
      "Verizon": {
        "reach": "3.351.000"
      },
      "Xandr": {
        "reach": "3.792.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "14.851.000"
      },
      "theTradeDesk": {
        "reach": "11.665.000"
      },
      "Adform": {
        "reach": "10.313.000"
      },
      "Verizon": {
        "reach": "10.099.000"
      },
      "Xandr": {
        "reach": "11.611.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "16.302.000"
      },
      "theTradeDesk": {
        "reach": "13.494.000"
      },
      "Adform": {
        "reach": "11.931.000"
      },
      "Verizon": {
        "reach": "11.948.000"
      },
      "Xandr": {
        "reach": "13.067.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "7.159.000"
      },
      "theTradeDesk": {
        "reach": "2.306.000"
      },
      "Adform": {
        "reach": "5.037.000"
      },
      "Verizon": {
        "reach": "4.937.000"
      },
      "Xandr": {
        "reach": "5.886.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "20.821.000"
      },
      "theTradeDesk": {
        "reach": "16.417.000"
      },
      "Adform": {
        "reach": "14.302.000"
      },
      "Verizon": {
        "reach": "14.870.000"
      },
      "Xandr": {
        "reach": "16.138.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.190.000"
      },
      "theTradeDesk": {
        "reach": "3.918.000"
      },
      "Adform": {
        "reach": "3.509.000"
      },
      "Verizon": {
        "reach": "3.486.000"
      },
      "Xandr": {
        "reach": "4.040.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.798.000"
      },
      "RMS": {
        "reach": "3.256.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "10.309.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.988.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "5.541.000"
      },
      "theTradeDesk": {
        "reach": "4.328.000"
      },
      "Adform": {
        "reach": "3.986.000"
      },
      "Verizon": {
        "reach": "3.886.000"
      },
      "Xandr": {
        "reach": "4.475.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "10.575.000"
      },
      "Instagram": {
        "reach": "5.374.000"
      },
      "YouTube": {
        "reach": "19.758.000"
      },
      "Snapchat": {
        "reach": "1.446.000"
      }
    }
  },
  "Familien mit Kind": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.679.000"
      },
      "theTradeDesk": {
        "reach": "5.218.000"
      },
      "Adform": {
        "reach": "4.509.000"
      },
      "Verizon": {
        "reach": "4.526.000"
      },
      "Xandr": {
        "reach": "5.449.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.751.000"
      },
      "theTradeDesk": {
        "reach": "2.176.000"
      },
      "Adform": {
        "reach": "1.928.000"
      },
      "Verizon": {
        "reach": "1.957.000"
      },
      "Xandr": {
        "reach": "2.148.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "8.046.000"
      },
      "theTradeDesk": {
        "reach": "6.614.000"
      },
      "Adform": {
        "reach": "5.699.000"
      },
      "Verizon": {
        "reach": "5.695.000"
      },
      "Xandr": {
        "reach": "6.667.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "10.476.000"
      },
      "theTradeDesk": {
        "reach": "7.959.000"
      },
      "Adform": {
        "reach": "6.932.000"
      },
      "Verizon": {
        "reach": "7.066.000"
      },
      "Xandr": {
        "reach": "7.741.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.195.000"
      },
      "theTradeDesk": {
        "reach": "1.386.000"
      },
      "Adform": {
        "reach": "3.006.000"
      },
      "Verizon": {
        "reach": "3.007.000"
      },
      "Xandr": {
        "reach": "3.462.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "11.845.000"
      },
      "theTradeDesk": {
        "reach": "9.719.000"
      },
      "Adform": {
        "reach": "8.623.000"
      },
      "Verizon": {
        "reach": "8.421.000"
      },
      "Xandr": {
        "reach": "9.652.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.938.000"
      },
      "theTradeDesk": {
        "reach": "2.353.000"
      },
      "Adform": {
        "reach": "2.047.000"
      },
      "Verizon": {
        "reach": "2.033.000"
      },
      "Xandr": {
        "reach": "2.366.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.566.000"
      },
      "RMS": {
        "reach": "1.823.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "6.057.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.355.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.212.000"
      },
      "theTradeDesk": {
        "reach": "2.630.000"
      },
      "Adform": {
        "reach": "2.296.000"
      },
      "Verizon": {
        "reach": "2.311.000"
      },
      "Xandr": {
        "reach": "2.552.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "6.008.000"
      },
      "Instagram": {
        "reach": "3.088.000"
      },
      "YouTube": {
        "reach": "11.351.000"
      },
      "Snapchat": {
        "reach": "833.000"
      }
    }
  },
  "Junge Singles": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.330.000"
      },
      "theTradeDesk": {
        "reach": "1.916.000"
      },
      "Adform": {
        "reach": "1.681.000"
      },
      "Verizon": {
        "reach": "1.672.000"
      },
      "Xandr": {
        "reach": "1.860.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.038.000"
      },
      "theTradeDesk": {
        "reach": "812.000"
      },
      "Adform": {
        "reach": "721.000"
      },
      "Verizon": {
        "reach": "729.000"
      },
      "Xandr": {
        "reach": "792.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "3.052.000"
      },
      "theTradeDesk": {
        "reach": "2.442.000"
      },
      "Adform": {
        "reach": "2.138.000"
      },
      "Verizon": {
        "reach": "2.195.000"
      },
      "Xandr": {
        "reach": "2.413.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.568.000"
      },
      "theTradeDesk": {
        "reach": "2.972.000"
      },
      "Adform": {
        "reach": "2.501.000"
      },
      "Verizon": {
        "reach": "2.599.000"
      },
      "Xandr": {
        "reach": "2.936.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.588.000"
      },
      "theTradeDesk": {
        "reach": "488.000"
      },
      "Adform": {
        "reach": "1.087.000"
      },
      "Verizon": {
        "reach": "1.137.000"
      },
      "Xandr": {
        "reach": "1.300.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.366.000"
      },
      "theTradeDesk": {
        "reach": "3.506.000"
      },
      "Adform": {
        "reach": "3.129.000"
      },
      "Verizon": {
        "reach": "3.120.000"
      },
      "Xandr": {
        "reach": "3.538.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.084.000"
      },
      "theTradeDesk": {
        "reach": "888.000"
      },
      "Adform": {
        "reach": "755.000"
      },
      "Verizon": {
        "reach": "751.000"
      },
      "Xandr": {
        "reach": "867.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "597.000"
      },
      "RMS": {
        "reach": "671.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.185.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "875.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.221.000"
      },
      "theTradeDesk": {
        "reach": "977.000"
      },
      "Adform": {
        "reach": "832.000"
      },
      "Verizon": {
        "reach": "825.000"
      },
      "Xandr": {
        "reach": "973.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.162.000"
      },
      "Instagram": {
        "reach": "1.159.000"
      },
      "YouTube": {
        "reach": "4.068.000"
      },
      "Snapchat": {
        "reach": "452.000"
      }
    }
  },
  "Junge Paare": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.309.000"
      },
      "theTradeDesk": {
        "reach": "5.193.000"
      },
      "Adform": {
        "reach": "4.433.000"
      },
      "Verizon": {
        "reach": "4.548.000"
      },
      "Xandr": {
        "reach": "4.956.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.644.000"
      },
      "theTradeDesk": {
        "reach": "2.175.000"
      },
      "Adform": {
        "reach": "1.935.000"
      },
      "Verizon": {
        "reach": "1.913.000"
      },
      "Xandr": {
        "reach": "2.175.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "8.027.000"
      },
      "theTradeDesk": {
        "reach": "6.608.000"
      },
      "Adform": {
        "reach": "5.889.000"
      },
      "Verizon": {
        "reach": "5.732.000"
      },
      "Xandr": {
        "reach": "6.530.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "9.799.000"
      },
      "theTradeDesk": {
        "reach": "7.735.000"
      },
      "Adform": {
        "reach": "7.086.000"
      },
      "Verizon": {
        "reach": "6.821.000"
      },
      "Xandr": {
        "reach": "8.237.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "4.251.000"
      },
      "theTradeDesk": {
        "reach": "1.370.000"
      },
      "Adform": {
        "reach": "2.932.000"
      },
      "Verizon": {
        "reach": "2.986.000"
      },
      "Xandr": {
        "reach": "3.326.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "11.511.000"
      },
      "theTradeDesk": {
        "reach": "9.405.000"
      },
      "Adform": {
        "reach": "8.202.000"
      },
      "Verizon": {
        "reach": "8.578.000"
      },
      "Xandr": {
        "reach": "9.217.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.895.000"
      },
      "theTradeDesk": {
        "reach": "2.311.000"
      },
      "Adform": {
        "reach": "2.010.000"
      },
      "Verizon": {
        "reach": "1.983.000"
      },
      "Xandr": {
        "reach": "2.297.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.639.000"
      },
      "RMS": {
        "reach": "1.933.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "6.028.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.307.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.096.000"
      },
      "theTradeDesk": {
        "reach": "2.614.000"
      },
      "Adform": {
        "reach": "2.266.000"
      },
      "Verizon": {
        "reach": "2.232.000"
      },
      "Xandr": {
        "reach": "2.504.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.797.000"
      },
      "Instagram": {
        "reach": "3.057.000"
      },
      "YouTube": {
        "reach": "10.844.000"
      },
      "Snapchat": {
        "reach": "1.208.000"
      }
    }
  },
  "Junge Familien mit Kind": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.215.000"
      },
      "theTradeDesk": {
        "reach": "2.622.000"
      },
      "Adform": {
        "reach": "2.206.000"
      },
      "Verizon": {
        "reach": "2.230.000"
      },
      "Xandr": {
        "reach": "2.502.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.401.000"
      },
      "theTradeDesk": {
        "reach": "1.105.000"
      },
      "Adform": {
        "reach": "959.000"
      },
      "Verizon": {
        "reach": "979.000"
      },
      "Xandr": {
        "reach": "1.108.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.129.000"
      },
      "theTradeDesk": {
        "reach": "3.246.000"
      },
      "Adform": {
        "reach": "2.894.000"
      },
      "Verizon": {
        "reach": "2.905.000"
      },
      "Xandr": {
        "reach": "3.360.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "4.835.000"
      },
      "theTradeDesk": {
        "reach": "3.956.000"
      },
      "Adform": {
        "reach": "3.417.000"
      },
      "Verizon": {
        "reach": "3.418.000"
      },
      "Xandr": {
        "reach": "3.961.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.065.000"
      },
      "theTradeDesk": {
        "reach": "658.000"
      },
      "Adform": {
        "reach": "1.456.000"
      },
      "Verizon": {
        "reach": "1.505.000"
      },
      "Xandr": {
        "reach": "1.628.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.046.000"
      },
      "theTradeDesk": {
        "reach": "4.902.000"
      },
      "Adform": {
        "reach": "4.216.000"
      },
      "Verizon": {
        "reach": "4.005.000"
      },
      "Xandr": {
        "reach": "4.602.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.432.000"
      },
      "theTradeDesk": {
        "reach": "1.118.000"
      },
      "Adform": {
        "reach": "974.000"
      },
      "Verizon": {
        "reach": "984.000"
      },
      "Xandr": {
        "reach": "1.161.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "787.000"
      },
      "RMS": {
        "reach": "928.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.874.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.206.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.608.000"
      },
      "theTradeDesk": {
        "reach": "1.326.000"
      },
      "Adform": {
        "reach": "1.116.000"
      },
      "Verizon": {
        "reach": "1.119.000"
      },
      "Xandr": {
        "reach": "1.265.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.046.000"
      },
      "Instagram": {
        "reach": "1.556.000"
      },
      "YouTube": {
        "reach": "5.483.000"
      },
      "Snapchat": {
        "reach": "545.000"
      }
    }
  },
  "Niedrigster Status": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "6.105.000"
      },
      "theTradeDesk": {
        "reach": "4.743.000"
      },
      "Adform": {
        "reach": "4.309.000"
      },
      "Verizon": {
        "reach": "4.325.000"
      },
      "Xandr": {
        "reach": "5.035.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.699.000"
      },
      "theTradeDesk": {
        "reach": "2.016.000"
      },
      "Adform": {
        "reach": "1.875.000"
      },
      "Verizon": {
        "reach": "1.842.000"
      },
      "Xandr": {
        "reach": "2.094.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "7.840.000"
      },
      "theTradeDesk": {
        "reach": "6.361.000"
      },
      "Adform": {
        "reach": "5.491.000"
      },
      "Verizon": {
        "reach": "5.636.000"
      },
      "Xandr": {
        "reach": "6.032.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "9.186.000"
      },
      "theTradeDesk": {
        "reach": "7.599.000"
      },
      "Adform": {
        "reach": "6.943.000"
      },
      "Verizon": {
        "reach": "6.874.000"
      },
      "Xandr": {
        "reach": "7.784.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.953.000"
      },
      "theTradeDesk": {
        "reach": "1.302.000"
      },
      "Adform": {
        "reach": "2.886.000"
      },
      "Verizon": {
        "reach": "2.854.000"
      },
      "Xandr": {
        "reach": "3.192.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "11.625.000"
      },
      "theTradeDesk": {
        "reach": "9.020.000"
      },
      "Adform": {
        "reach": "7.742.000"
      },
      "Verizon": {
        "reach": "8.248.000"
      },
      "Xandr": {
        "reach": "9.294.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.736.000"
      },
      "theTradeDesk": {
        "reach": "2.238.000"
      },
      "Adform": {
        "reach": "1.897.000"
      },
      "Verizon": {
        "reach": "1.966.000"
      },
      "Xandr": {
        "reach": "2.272.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.566.000"
      },
      "RMS": {
        "reach": "1.738.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.707.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.286.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.101.000"
      },
      "theTradeDesk": {
        "reach": "2.442.000"
      },
      "Adform": {
        "reach": "2.080.000"
      },
      "Verizon": {
        "reach": "2.210.000"
      },
      "Xandr": {
        "reach": "2.465.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.767.000"
      },
      "Instagram": {
        "reach": "2.923.000"
      },
      "YouTube": {
        "reach": "10.666.000"
      },
      "Snapchat": {
        "reach": "1.064.000"
      }
    }
  },
  "Unterdurchschnittlicher Status": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "5.864.000"
      },
      "theTradeDesk": {
        "reach": "4.705.000"
      },
      "Adform": {
        "reach": "4.168.000"
      },
      "Verizon": {
        "reach": "4.198.000"
      },
      "Xandr": {
        "reach": "4.600.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "2.546.000"
      },
      "theTradeDesk": {
        "reach": "2.090.000"
      },
      "Adform": {
        "reach": "1.744.000"
      },
      "Verizon": {
        "reach": "1.735.000"
      },
      "Xandr": {
        "reach": "2.066.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "7.585.000"
      },
      "theTradeDesk": {
        "reach": "6.134.000"
      },
      "Adform": {
        "reach": "5.447.000"
      },
      "Verizon": {
        "reach": "5.296.000"
      },
      "Xandr": {
        "reach": "6.143.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "9.070.000"
      },
      "theTradeDesk": {
        "reach": "7.299.000"
      },
      "Adform": {
        "reach": "6.296.000"
      },
      "Verizon": {
        "reach": "6.510.000"
      },
      "Xandr": {
        "reach": "7.203.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "3.791.000"
      },
      "theTradeDesk": {
        "reach": "1.240.000"
      },
      "Adform": {
        "reach": "2.674.000"
      },
      "Verizon": {
        "reach": "2.698.000"
      },
      "Xandr": {
        "reach": "3.092.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "11.053.000"
      },
      "theTradeDesk": {
        "reach": "8.807.000"
      },
      "Adform": {
        "reach": "7.480.000"
      },
      "Verizon": {
        "reach": "7.725.000"
      },
      "Xandr": {
        "reach": "8.956.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "2.677.000"
      },
      "theTradeDesk": {
        "reach": "2.139.000"
      },
      "Adform": {
        "reach": "1.846.000"
      },
      "Verizon": {
        "reach": "1.890.000"
      },
      "Xandr": {
        "reach": "2.126.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.476.000"
      },
      "RMS": {
        "reach": "1.688.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "5.363.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.203.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "3.030.000"
      },
      "theTradeDesk": {
        "reach": "2.373.000"
      },
      "Adform": {
        "reach": "2.077.000"
      },
      "Verizon": {
        "reach": "2.065.000"
      },
      "Xandr": {
        "reach": "2.311.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "5.456.000"
      },
      "Instagram": {
        "reach": "2.850.000"
      },
      "YouTube": {
        "reach": "10.219.000"
      },
      "Snapchat": {
        "reach": "983.000"
      }
    }
  },
  "Durchschnittlicher Status": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "8.312.000"
      },
      "theTradeDesk": {
        "reach": "6.704.000"
      },
      "Adform": {
        "reach": "5.697.000"
      },
      "Verizon": {
        "reach": "5.946.000"
      },
      "Xandr": {
        "reach": "6.625.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.603.000"
      },
      "theTradeDesk": {
        "reach": "2.800.000"
      },
      "Adform": {
        "reach": "2.492.000"
      },
      "Verizon": {
        "reach": "2.472.000"
      },
      "Xandr": {
        "reach": "2.851.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "10.969.000"
      },
      "theTradeDesk": {
        "reach": "8.203.000"
      },
      "Adform": {
        "reach": "7.258.000"
      },
      "Verizon": {
        "reach": "7.436.000"
      },
      "Xandr": {
        "reach": "8.451.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "12.876.000"
      },
      "theTradeDesk": {
        "reach": "10.274.000"
      },
      "Adform": {
        "reach": "8.664.000"
      },
      "Verizon": {
        "reach": "8.622.000"
      },
      "Xandr": {
        "reach": "10.057.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.566.000"
      },
      "theTradeDesk": {
        "reach": "1.738.000"
      },
      "Adform": {
        "reach": "3.692.000"
      },
      "Verizon": {
        "reach": "3.647.000"
      },
      "Xandr": {
        "reach": "4.223.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "15.454.000"
      },
      "theTradeDesk": {
        "reach": "11.284.000"
      },
      "Adform": {
        "reach": "10.559.000"
      },
      "Verizon": {
        "reach": "10.327.000"
      },
      "Xandr": {
        "reach": "11.905.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.702.000"
      },
      "theTradeDesk": {
        "reach": "2.924.000"
      },
      "Adform": {
        "reach": "2.593.000"
      },
      "Verizon": {
        "reach": "2.660.000"
      },
      "Xandr": {
        "reach": "2.926.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "1.987.000"
      },
      "RMS": {
        "reach": "2.326.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "7.659.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "2.981.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "4.155.000"
      },
      "theTradeDesk": {
        "reach": "3.256.000"
      },
      "Adform": {
        "reach": "2.915.000"
      },
      "Verizon": {
        "reach": "2.908.000"
      },
      "Xandr": {
        "reach": "3.248.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "7.535.000"
      },
      "Instagram": {
        "reach": "4.063.000"
      },
      "YouTube": {
        "reach": "14.634.000"
      },
      "Snapchat": {
        "reach": "1.216.000"
      }
    }
  },
  "Überdurchschnittlicher Status": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.708.000"
      },
      "theTradeDesk": {
        "reach": "1.327.000"
      },
      "Adform": {
        "reach": "1.162.000"
      },
      "Verizon": {
        "reach": "1.223.000"
      },
      "Xandr": {
        "reach": "1.379.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "758.000"
      },
      "theTradeDesk": {
        "reach": "583.000"
      },
      "Adform": {
        "reach": "517.000"
      },
      "Verizon": {
        "reach": "503.000"
      },
      "Xandr": {
        "reach": "577.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.145.000"
      },
      "theTradeDesk": {
        "reach": "1.761.000"
      },
      "Adform": {
        "reach": "1.537.000"
      },
      "Verizon": {
        "reach": "1.517.000"
      },
      "Xandr": {
        "reach": "1.767.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "2.753.000"
      },
      "theTradeDesk": {
        "reach": "2.101.000"
      },
      "Adform": {
        "reach": "1.856.000"
      },
      "Verizon": {
        "reach": "1.831.000"
      },
      "Xandr": {
        "reach": "2.075.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.092.000"
      },
      "theTradeDesk": {
        "reach": "357.000"
      },
      "Adform": {
        "reach": "786.000"
      },
      "Verizon": {
        "reach": "759.000"
      },
      "Xandr": {
        "reach": "905.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.192.000"
      },
      "theTradeDesk": {
        "reach": "2.533.000"
      },
      "Adform": {
        "reach": "2.227.000"
      },
      "Verizon": {
        "reach": "2.294.000"
      },
      "Xandr": {
        "reach": "2.503.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "760.000"
      },
      "theTradeDesk": {
        "reach": "622.000"
      },
      "Adform": {
        "reach": "533.000"
      },
      "Verizon": {
        "reach": "542.000"
      },
      "Xandr": {
        "reach": "613.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "434.000"
      },
      "RMS": {
        "reach": "481.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.522.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "627.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "847.000"
      },
      "theTradeDesk": {
        "reach": "681.000"
      },
      "Adform": {
        "reach": "603.000"
      },
      "Verizon": {
        "reach": "590.000"
      },
      "Xandr": {
        "reach": "658.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.599.000"
      },
      "Instagram": {
        "reach": "815.000"
      },
      "YouTube": {
        "reach": "2.982.000"
      },
      "Snapchat": {
        "reach": "176.000"
      }
    }
  },
  "Höchster Status": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "11.055.000"
      },
      "theTradeDesk": {
        "reach": "8.981.000"
      },
      "Adform": {
        "reach": "7.933.000"
      },
      "Verizon": {
        "reach": "8.094.000"
      },
      "Xandr": {
        "reach": "9.329.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "4.866.000"
      },
      "theTradeDesk": {
        "reach": "3.801.000"
      },
      "Adform": {
        "reach": "3.445.000"
      },
      "Verizon": {
        "reach": "3.363.000"
      },
      "Xandr": {
        "reach": "3.755.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "14.436.000"
      },
      "theTradeDesk": {
        "reach": "11.490.000"
      },
      "Adform": {
        "reach": "10.002.000"
      },
      "Verizon": {
        "reach": "10.373.000"
      },
      "Xandr": {
        "reach": "11.447.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "17.509.000"
      },
      "theTradeDesk": {
        "reach": "13.624.000"
      },
      "Adform": {
        "reach": "12.152.000"
      },
      "Verizon": {
        "reach": "12.580.000"
      },
      "Xandr": {
        "reach": "14.026.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "7.369.000"
      },
      "theTradeDesk": {
        "reach": "2.398.000"
      },
      "Adform": {
        "reach": "5.100.000"
      },
      "Verizon": {
        "reach": "5.172.000"
      },
      "Xandr": {
        "reach": "5.820.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "21.529.000"
      },
      "theTradeDesk": {
        "reach": "16.832.000"
      },
      "Adform": {
        "reach": "14.848.000"
      },
      "Verizon": {
        "reach": "15.235.000"
      },
      "Xandr": {
        "reach": "16.873.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "4.988.000"
      },
      "theTradeDesk": {
        "reach": "3.948.000"
      },
      "Adform": {
        "reach": "3.650.000"
      },
      "Verizon": {
        "reach": "3.472.000"
      },
      "Xandr": {
        "reach": "4.248.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.851.000"
      },
      "RMS": {
        "reach": "3.206.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "10.394.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.064.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "5.585.000"
      },
      "theTradeDesk": {
        "reach": "4.353.000"
      },
      "Adform": {
        "reach": "3.977.000"
      },
      "Verizon": {
        "reach": "3.875.000"
      },
      "Xandr": {
        "reach": "4.491.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "10.774.000"
      },
      "Instagram": {
        "reach": "5.394.000"
      },
      "YouTube": {
        "reach": "19.597.000"
      },
      "Snapchat": {
        "reach": "702.000"
      }
    }
  },
  "Gelernte und Facharbeiter": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "12.609.000"
      },
      "theTradeDesk": {
        "reach": "9.656.000"
      },
      "Adform": {
        "reach": "8.701.000"
      },
      "Verizon": {
        "reach": "8.784.000"
      },
      "Xandr": {
        "reach": "9.452.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "5.248.000"
      },
      "theTradeDesk": {
        "reach": "4.273.000"
      },
      "Adform": {
        "reach": "3.722.000"
      },
      "Verizon": {
        "reach": "3.637.000"
      },
      "Xandr": {
        "reach": "4.274.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "14.897.000"
      },
      "theTradeDesk": {
        "reach": "12.389.000"
      },
      "Adform": {
        "reach": "11.083.000"
      },
      "Verizon": {
        "reach": "11.333.000"
      },
      "Xandr": {
        "reach": "12.648.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "18.502.000"
      },
      "theTradeDesk": {
        "reach": "14.733.000"
      },
      "Adform": {
        "reach": "13.524.000"
      },
      "Verizon": {
        "reach": "12.977.000"
      },
      "Xandr": {
        "reach": "14.944.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "7.674.000"
      },
      "theTradeDesk": {
        "reach": "2.695.000"
      },
      "Adform": {
        "reach": "5.695.000"
      },
      "Verizon": {
        "reach": "5.428.000"
      },
      "Xandr": {
        "reach": "6.297.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "21.940.000"
      },
      "theTradeDesk": {
        "reach": "17.055.000"
      },
      "Adform": {
        "reach": "15.754.000"
      },
      "Verizon": {
        "reach": "15.408.000"
      },
      "Xandr": {
        "reach": "17.853.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "5.349.000"
      },
      "theTradeDesk": {
        "reach": "4.198.000"
      },
      "Adform": {
        "reach": "3.883.000"
      },
      "Verizon": {
        "reach": "3.788.000"
      },
      "Xandr": {
        "reach": "4.315.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.156.000"
      },
      "RMS": {
        "reach": "3.241.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "11.190.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.388.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.123.000"
      },
      "theTradeDesk": {
        "reach": "4.870.000"
      },
      "Adform": {
        "reach": "4.288.000"
      },
      "Verizon": {
        "reach": "4.219.000"
      },
      "Xandr": {
        "reach": "4.772.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "11.131.000"
      },
      "Instagram": {
        "reach": "5.903.000"
      },
      "YouTube": {
        "reach": "20.349.000"
      },
      "Snapchat": {
        "reach": "1.512.000"
      }
    }
  },
  "Freiberufler / mit akademischem Abschluss": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.415.000"
      },
      "theTradeDesk": {
        "reach": "2.763.000"
      },
      "Adform": {
        "reach": "2.320.000"
      },
      "Verizon": {
        "reach": "2.388.000"
      },
      "Xandr": {
        "reach": "2.786.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.473.000"
      },
      "theTradeDesk": {
        "reach": "1.197.000"
      },
      "Adform": {
        "reach": "1.041.000"
      },
      "Verizon": {
        "reach": "1.039.000"
      },
      "Xandr": {
        "reach": "1.178.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.485.000"
      },
      "theTradeDesk": {
        "reach": "3.428.000"
      },
      "Adform": {
        "reach": "3.072.000"
      },
      "Verizon": {
        "reach": "3.193.000"
      },
      "Xandr": {
        "reach": "3.458.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.183.000"
      },
      "theTradeDesk": {
        "reach": "4.224.000"
      },
      "Adform": {
        "reach": "3.682.000"
      },
      "Verizon": {
        "reach": "3.689.000"
      },
      "Xandr": {
        "reach": "4.151.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.315.000"
      },
      "theTradeDesk": {
        "reach": "716.000"
      },
      "Adform": {
        "reach": "1.534.000"
      },
      "Verizon": {
        "reach": "1.570.000"
      },
      "Xandr": {
        "reach": "1.738.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.244.000"
      },
      "theTradeDesk": {
        "reach": "5.078.000"
      },
      "Adform": {
        "reach": "4.302.000"
      },
      "Verizon": {
        "reach": "4.463.000"
      },
      "Xandr": {
        "reach": "5.103.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.544.000"
      },
      "theTradeDesk": {
        "reach": "1.256.000"
      },
      "Adform": {
        "reach": "1.073.000"
      },
      "Verizon": {
        "reach": "1.078.000"
      },
      "Xandr": {
        "reach": "1.217.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "819.000"
      },
      "RMS": {
        "reach": "976.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "3.096.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.263.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.684.000"
      },
      "theTradeDesk": {
        "reach": "1.402.000"
      },
      "Adform": {
        "reach": "1.185.000"
      },
      "Verizon": {
        "reach": "1.171.000"
      },
      "Xandr": {
        "reach": "1.368.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.221.000"
      },
      "Instagram": {
        "reach": "1.651.000"
      },
      "YouTube": {
        "reach": "6.141.000"
      },
      "Snapchat": {
        "reach": "289.000"
      }
    }
  },
  "Agrarwirte": {
    "cpm": "1,25€",
    "Desktop - Display": {
      "DV360": {
        "reach": "105.000"
      },
      "theTradeDesk": {
        "reach": "86.000"
      },
      "Adform": {
        "reach": "74.000"
      },
      "Verizon": {
        "reach": "72.000"
      },
      "Xandr": {
        "reach": "86.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "46.000"
      },
      "theTradeDesk": {
        "reach": "36.000"
      },
      "Adform": {
        "reach": "33.000"
      },
      "Verizon": {
        "reach": "32.000"
      },
      "Xandr": {
        "reach": "37.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "137.000"
      },
      "theTradeDesk": {
        "reach": "107.000"
      },
      "Adform": {
        "reach": "98.000"
      },
      "Verizon": {
        "reach": "94.000"
      },
      "Xandr": {
        "reach": "112.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "166.000"
      },
      "theTradeDesk": {
        "reach": "132.000"
      },
      "Adform": {
        "reach": "115.000"
      },
      "Verizon": {
        "reach": "114.000"
      },
      "Xandr": {
        "reach": "131.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "70.000"
      },
      "theTradeDesk": {
        "reach": "24.000"
      },
      "Adform": {
        "reach": "47.000"
      },
      "Verizon": {
        "reach": "50.000"
      },
      "Xandr": {
        "reach": "56.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "197.000"
      },
      "theTradeDesk": {
        "reach": "158.000"
      },
      "Adform": {
        "reach": "134.000"
      },
      "Verizon": {
        "reach": "137.000"
      },
      "Xandr": {
        "reach": "152.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "49.000"
      },
      "theTradeDesk": {
        "reach": "37.000"
      },
      "Adform": {
        "reach": "35.000"
      },
      "Verizon": {
        "reach": "33.000"
      },
      "Xandr": {
        "reach": "38.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "27.000"
      },
      "RMS": {
        "reach": "30.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "96.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "40.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "53.000"
      },
      "theTradeDesk": {
        "reach": "42.000"
      },
      "Adform": {
        "reach": "38.000"
      },
      "Verizon": {
        "reach": "37.000"
      },
      "Xandr": {
        "reach": "42.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "100.000"
      },
      "Instagram": {
        "reach": "51.000"
      },
      "YouTube": {
        "reach": "187.000"
      },
      "Snapchat": {
        "reach": "2.000"
      }
    }
  },
  "Wissenschaftler": {
    "cpm": "1,40€",
    "Desktop - Display": {
      "DV360": {
        "reach": "164.000"
      },
      "theTradeDesk": {
        "reach": "130.000"
      },
      "Adform": {
        "reach": "114.000"
      },
      "Verizon": {
        "reach": "120.000"
      },
      "Xandr": {
        "reach": "131.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "69.000"
      },
      "theTradeDesk": {
        "reach": "54.000"
      },
      "Adform": {
        "reach": "51.000"
      },
      "Verizon": {
        "reach": "48.000"
      },
      "Xandr": {
        "reach": "56.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "204.000"
      },
      "theTradeDesk": {
        "reach": "167.000"
      },
      "Adform": {
        "reach": "146.000"
      },
      "Verizon": {
        "reach": "147.000"
      },
      "Xandr": {
        "reach": "167.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "243.000"
      },
      "theTradeDesk": {
        "reach": "198.000"
      },
      "Adform": {
        "reach": "175.000"
      },
      "Verizon": {
        "reach": "171.000"
      },
      "Xandr": {
        "reach": "201.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "104.000"
      },
      "theTradeDesk": {
        "reach": "34.000"
      },
      "Adform": {
        "reach": "72.000"
      },
      "Verizon": {
        "reach": "71.000"
      },
      "Xandr": {
        "reach": "85.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "297.000"
      },
      "theTradeDesk": {
        "reach": "233.000"
      },
      "Adform": {
        "reach": "211.000"
      },
      "Verizon": {
        "reach": "200.000"
      },
      "Xandr": {
        "reach": "235.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "73.000"
      },
      "theTradeDesk": {
        "reach": "58.000"
      },
      "Adform": {
        "reach": "51.000"
      },
      "Verizon": {
        "reach": "50.000"
      },
      "Xandr": {
        "reach": "56.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "41.000"
      },
      "RMS": {
        "reach": "47.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "143.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "58.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "79.000"
      },
      "theTradeDesk": {
        "reach": "67.000"
      },
      "Adform": {
        "reach": "56.000"
      },
      "Verizon": {
        "reach": "56.000"
      },
      "Xandr": {
        "reach": "62.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "150.000"
      },
      "Instagram": {
        "reach": "76.000"
      },
      "YouTube": {
        "reach": "287.000"
      },
      "Snapchat": {
        "reach": "3.000"
      }
    }
  },
  "Verlage, Telekommunikation und IT": {
    "cpm": "3,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "727.000"
      },
      "theTradeDesk": {
        "reach": "587.000"
      },
      "Adform": {
        "reach": "516.000"
      },
      "Verizon": {
        "reach": "519.000"
      },
      "Xandr": {
        "reach": "591.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "306.000"
      },
      "theTradeDesk": {
        "reach": "254.000"
      },
      "Adform": {
        "reach": "224.000"
      },
      "Verizon": {
        "reach": "220.000"
      },
      "Xandr": {
        "reach": "257.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "914.000"
      },
      "theTradeDesk": {
        "reach": "737.000"
      },
      "Adform": {
        "reach": "637.000"
      },
      "Verizon": {
        "reach": "661.000"
      },
      "Xandr": {
        "reach": "748.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.142.000"
      },
      "theTradeDesk": {
        "reach": "915.000"
      },
      "Adform": {
        "reach": "746.000"
      },
      "Verizon": {
        "reach": "778.000"
      },
      "Xandr": {
        "reach": "905.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "470.000"
      },
      "theTradeDesk": {
        "reach": "152.000"
      },
      "Adform": {
        "reach": "341.000"
      },
      "Verizon": {
        "reach": "326.000"
      },
      "Xandr": {
        "reach": "381.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.300.000"
      },
      "theTradeDesk": {
        "reach": "1.097.000"
      },
      "Adform": {
        "reach": "897.000"
      },
      "Verizon": {
        "reach": "947.000"
      },
      "Xandr": {
        "reach": "1.045.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "340.000"
      },
      "theTradeDesk": {
        "reach": "264.000"
      },
      "Adform": {
        "reach": "230.000"
      },
      "Verizon": {
        "reach": "236.000"
      },
      "Xandr": {
        "reach": "264.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "179.000"
      },
      "RMS": {
        "reach": "213.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "674.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "265.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "378.000"
      },
      "theTradeDesk": {
        "reach": "291.000"
      },
      "Adform": {
        "reach": "254.000"
      },
      "Verizon": {
        "reach": "258.000"
      },
      "Xandr": {
        "reach": "298.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "658.000"
      },
      "Instagram": {
        "reach": "348.000"
      },
      "YouTube": {
        "reach": "1.245.000"
      },
      "Snapchat": {
        "reach": "31.000"
      }
    }
  },
  "Finanzen und Versicherungen": {
    "cpm": "3,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "427.000"
      },
      "theTradeDesk": {
        "reach": "363.000"
      },
      "Adform": {
        "reach": "316.000"
      },
      "Verizon": {
        "reach": "312.000"
      },
      "Xandr": {
        "reach": "347.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "198.000"
      },
      "theTradeDesk": {
        "reach": "152.000"
      },
      "Adform": {
        "reach": "130.000"
      },
      "Verizon": {
        "reach": "136.000"
      },
      "Xandr": {
        "reach": "153.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "556.000"
      },
      "theTradeDesk": {
        "reach": "456.000"
      },
      "Adform": {
        "reach": "390.000"
      },
      "Verizon": {
        "reach": "397.000"
      },
      "Xandr": {
        "reach": "455.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "673.000"
      },
      "theTradeDesk": {
        "reach": "554.000"
      },
      "Adform": {
        "reach": "483.000"
      },
      "Verizon": {
        "reach": "473.000"
      },
      "Xandr": {
        "reach": "531.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "282.000"
      },
      "theTradeDesk": {
        "reach": "90.000"
      },
      "Adform": {
        "reach": "197.000"
      },
      "Verizon": {
        "reach": "197.000"
      },
      "Xandr": {
        "reach": "231.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "813.000"
      },
      "theTradeDesk": {
        "reach": "640.000"
      },
      "Adform": {
        "reach": "562.000"
      },
      "Verizon": {
        "reach": "581.000"
      },
      "Xandr": {
        "reach": "641.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "205.000"
      },
      "theTradeDesk": {
        "reach": "157.000"
      },
      "Adform": {
        "reach": "144.000"
      },
      "Verizon": {
        "reach": "144.000"
      },
      "Xandr": {
        "reach": "157.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "115.000"
      },
      "RMS": {
        "reach": "125.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "387.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "167.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "224.000"
      },
      "theTradeDesk": {
        "reach": "175.000"
      },
      "Adform": {
        "reach": "155.000"
      },
      "Verizon": {
        "reach": "151.000"
      },
      "Xandr": {
        "reach": "176.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "410.000"
      },
      "Instagram": {
        "reach": "214.000"
      },
      "YouTube": {
        "reach": "770.000"
      },
      "Snapchat": {
        "reach": "58.000"
      }
    }
  },
  "Grundstücks- und Wohnungswesen": {
    "cpm": "3,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.285.000"
      },
      "theTradeDesk": {
        "reach": "993.000"
      },
      "Adform": {
        "reach": "885.000"
      },
      "Verizon": {
        "reach": "884.000"
      },
      "Xandr": {
        "reach": "982.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "558.000"
      },
      "theTradeDesk": {
        "reach": "430.000"
      },
      "Adform": {
        "reach": "373.000"
      },
      "Verizon": {
        "reach": "388.000"
      },
      "Xandr": {
        "reach": "419.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.551.000"
      },
      "theTradeDesk": {
        "reach": "1.277.000"
      },
      "Adform": {
        "reach": "1.148.000"
      },
      "Verizon": {
        "reach": "1.146.000"
      },
      "Xandr": {
        "reach": "1.301.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.912.000"
      },
      "theTradeDesk": {
        "reach": "1.568.000"
      },
      "Adform": {
        "reach": "1.370.000"
      },
      "Verizon": {
        "reach": "1.328.000"
      },
      "Xandr": {
        "reach": "1.550.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "820.000"
      },
      "theTradeDesk": {
        "reach": "252.000"
      },
      "Adform": {
        "reach": "566.000"
      },
      "Verizon": {
        "reach": "559.000"
      },
      "Xandr": {
        "reach": "662.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.307.000"
      },
      "theTradeDesk": {
        "reach": "1.851.000"
      },
      "Adform": {
        "reach": "1.591.000"
      },
      "Verizon": {
        "reach": "1.628.000"
      },
      "Xandr": {
        "reach": "1.860.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "573.000"
      },
      "theTradeDesk": {
        "reach": "455.000"
      },
      "Adform": {
        "reach": "391.000"
      },
      "Verizon": {
        "reach": "409.000"
      },
      "Xandr": {
        "reach": "460.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "314.000"
      },
      "RMS": {
        "reach": "344.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.131.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "464.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "620.000"
      },
      "theTradeDesk": {
        "reach": "479.000"
      },
      "Adform": {
        "reach": "417.000"
      },
      "Verizon": {
        "reach": "439.000"
      },
      "Xandr": {
        "reach": "497.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.155.000"
      },
      "Instagram": {
        "reach": "592.000"
      },
      "YouTube": {
        "reach": "2.148.000"
      },
      "Snapchat": {
        "reach": "52.000"
      }
    }
  },
  "Dienstleistungen (alle)": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "13.090.000"
      },
      "theTradeDesk": {
        "reach": "11.222.000"
      },
      "Adform": {
        "reach": "9.519.000"
      },
      "Verizon": {
        "reach": "9.453.000"
      },
      "Xandr": {
        "reach": "10.604.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "6.000.000"
      },
      "theTradeDesk": {
        "reach": "4.623.000"
      },
      "Adform": {
        "reach": "4.030.000"
      },
      "Verizon": {
        "reach": "4.051.000"
      },
      "Xandr": {
        "reach": "4.637.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "17.623.000"
      },
      "theTradeDesk": {
        "reach": "13.648.000"
      },
      "Adform": {
        "reach": "12.158.000"
      },
      "Verizon": {
        "reach": "12.597.000"
      },
      "Xandr": {
        "reach": "13.661.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "20.970.000"
      },
      "theTradeDesk": {
        "reach": "16.492.000"
      },
      "Adform": {
        "reach": "14.619.000"
      },
      "Verizon": {
        "reach": "14.387.000"
      },
      "Xandr": {
        "reach": "16.487.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "8.908.000"
      },
      "theTradeDesk": {
        "reach": "2.887.000"
      },
      "Adform": {
        "reach": "6.175.000"
      },
      "Verizon": {
        "reach": "6.324.000"
      },
      "Xandr": {
        "reach": "7.187.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "24.074.000"
      },
      "theTradeDesk": {
        "reach": "20.220.000"
      },
      "Adform": {
        "reach": "16.686.000"
      },
      "Verizon": {
        "reach": "17.259.000"
      },
      "Xandr": {
        "reach": "19.855.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "6.087.000"
      },
      "theTradeDesk": {
        "reach": "4.904.000"
      },
      "Adform": {
        "reach": "4.299.000"
      },
      "Verizon": {
        "reach": "4.264.000"
      },
      "Xandr": {
        "reach": "4.727.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "3.522.000"
      },
      "RMS": {
        "reach": "3.872.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "12.621.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "4.909.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "6.920.000"
      },
      "theTradeDesk": {
        "reach": "5.474.000"
      },
      "Adform": {
        "reach": "4.751.000"
      },
      "Verizon": {
        "reach": "4.786.000"
      },
      "Xandr": {
        "reach": "5.314.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "12.106.000"
      },
      "Instagram": {
        "reach": "6.655.000"
      },
      "YouTube": {
        "reach": "23.450.000"
      },
      "Snapchat": {
        "reach": "1.125.000"
      }
    }
  },
  "Gesundheitswesen und Sozialwesen": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.224.000"
      },
      "theTradeDesk": {
        "reach": "1.823.000"
      },
      "Adform": {
        "reach": "1.631.000"
      },
      "Verizon": {
        "reach": "1.569.000"
      },
      "Xandr": {
        "reach": "1.737.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "973.000"
      },
      "theTradeDesk": {
        "reach": "760.000"
      },
      "Adform": {
        "reach": "667.000"
      },
      "Verizon": {
        "reach": "683.000"
      },
      "Xandr": {
        "reach": "790.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.918.000"
      },
      "theTradeDesk": {
        "reach": "2.367.000"
      },
      "Adform": {
        "reach": "2.044.000"
      },
      "Verizon": {
        "reach": "1.942.000"
      },
      "Xandr": {
        "reach": "2.220.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.455.000"
      },
      "theTradeDesk": {
        "reach": "2.803.000"
      },
      "Adform": {
        "reach": "2.396.000"
      },
      "Verizon": {
        "reach": "2.388.000"
      },
      "Xandr": {
        "reach": "2.744.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.526.000"
      },
      "theTradeDesk": {
        "reach": "453.000"
      },
      "Adform": {
        "reach": "1.057.000"
      },
      "Verizon": {
        "reach": "1.001.000"
      },
      "Xandr": {
        "reach": "1.164.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.250.000"
      },
      "theTradeDesk": {
        "reach": "3.425.000"
      },
      "Adform": {
        "reach": "2.953.000"
      },
      "Verizon": {
        "reach": "2.842.000"
      },
      "Xandr": {
        "reach": "3.396.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.006.000"
      },
      "theTradeDesk": {
        "reach": "809.000"
      },
      "Adform": {
        "reach": "699.000"
      },
      "Verizon": {
        "reach": "693.000"
      },
      "Xandr": {
        "reach": "811.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "569.000"
      },
      "RMS": {
        "reach": "639.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.034.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "825.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.134.000"
      },
      "theTradeDesk": {
        "reach": "906.000"
      },
      "Adform": {
        "reach": "801.000"
      },
      "Verizon": {
        "reach": "790.000"
      },
      "Xandr": {
        "reach": "898.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.116.000"
      },
      "Instagram": {
        "reach": "1.072.000"
      },
      "YouTube": {
        "reach": "4.043.000"
      },
      "Snapchat": {
        "reach": "284.000"
      }
    }
  },
  "Land- und Forstwirtschaft": {
    "cpm": "4,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "240.000"
      },
      "theTradeDesk": {
        "reach": "193.000"
      },
      "Adform": {
        "reach": "166.000"
      },
      "Verizon": {
        "reach": "168.000"
      },
      "Xandr": {
        "reach": "188.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "102.000"
      },
      "theTradeDesk": {
        "reach": "81.000"
      },
      "Adform": {
        "reach": "70.000"
      },
      "Verizon": {
        "reach": "70.000"
      },
      "Xandr": {
        "reach": "78.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "307.000"
      },
      "theTradeDesk": {
        "reach": "248.000"
      },
      "Adform": {
        "reach": "220.000"
      },
      "Verizon": {
        "reach": "211.000"
      },
      "Xandr": {
        "reach": "243.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "365.000"
      },
      "theTradeDesk": {
        "reach": "303.000"
      },
      "Adform": {
        "reach": "248.000"
      },
      "Verizon": {
        "reach": "258.000"
      },
      "Xandr": {
        "reach": "292.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "161.000"
      },
      "theTradeDesk": {
        "reach": "50.000"
      },
      "Adform": {
        "reach": "109.000"
      },
      "Verizon": {
        "reach": "110.000"
      },
      "Xandr": {
        "reach": "125.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "445.000"
      },
      "theTradeDesk": {
        "reach": "355.000"
      },
      "Adform": {
        "reach": "314.000"
      },
      "Verizon": {
        "reach": "300.000"
      },
      "Xandr": {
        "reach": "363.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "112.000"
      },
      "theTradeDesk": {
        "reach": "88.000"
      },
      "Adform": {
        "reach": "75.000"
      },
      "Verizon": {
        "reach": "76.000"
      },
      "Xandr": {
        "reach": "88.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "59.000"
      },
      "RMS": {
        "reach": "69.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "218.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "91.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "121.000"
      },
      "theTradeDesk": {
        "reach": "97.000"
      },
      "Adform": {
        "reach": "82.000"
      },
      "Verizon": {
        "reach": "82.000"
      },
      "Xandr": {
        "reach": "95.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "223.000"
      },
      "Instagram": {
        "reach": "120.000"
      },
      "YouTube": {
        "reach": "421.000"
      },
      "Snapchat": {
        "reach": "5.000"
      }
    }
  },
  "Verarbeitendes und Produzierendes Gewerbe": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "3.391.000"
      },
      "theTradeDesk": {
        "reach": "2.684.000"
      },
      "Adform": {
        "reach": "2.382.000"
      },
      "Verizon": {
        "reach": "2.418.000"
      },
      "Xandr": {
        "reach": "2.679.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "1.453.000"
      },
      "theTradeDesk": {
        "reach": "1.176.000"
      },
      "Adform": {
        "reach": "1.042.000"
      },
      "Verizon": {
        "reach": "1.002.000"
      },
      "Xandr": {
        "reach": "1.106.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "4.383.000"
      },
      "theTradeDesk": {
        "reach": "3.441.000"
      },
      "Adform": {
        "reach": "3.071.000"
      },
      "Verizon": {
        "reach": "2.974.000"
      },
      "Xandr": {
        "reach": "3.540.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "5.199.000"
      },
      "theTradeDesk": {
        "reach": "3.999.000"
      },
      "Adform": {
        "reach": "3.639.000"
      },
      "Verizon": {
        "reach": "3.615.000"
      },
      "Xandr": {
        "reach": "4.223.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "2.258.000"
      },
      "theTradeDesk": {
        "reach": "736.000"
      },
      "Adform": {
        "reach": "1.552.000"
      },
      "Verizon": {
        "reach": "1.525.000"
      },
      "Xandr": {
        "reach": "1.748.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "6.128.000"
      },
      "theTradeDesk": {
        "reach": "5.107.000"
      },
      "Adform": {
        "reach": "4.353.000"
      },
      "Verizon": {
        "reach": "4.464.000"
      },
      "Xandr": {
        "reach": "4.945.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "1.496.000"
      },
      "theTradeDesk": {
        "reach": "1.218.000"
      },
      "Adform": {
        "reach": "1.042.000"
      },
      "Verizon": {
        "reach": "1.036.000"
      },
      "Xandr": {
        "reach": "1.200.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "806.000"
      },
      "RMS": {
        "reach": "983.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.979.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "1.205.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.683.000"
      },
      "theTradeDesk": {
        "reach": "1.316.000"
      },
      "Adform": {
        "reach": "1.208.000"
      },
      "Verizon": {
        "reach": "1.198.000"
      },
      "Xandr": {
        "reach": "1.377.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "3.056.000"
      },
      "Instagram": {
        "reach": "1.646.000"
      },
      "YouTube": {
        "reach": "5.770.000"
      },
      "Snapchat": {
        "reach": "139.000"
      }
    }
  },
  "Handwerk": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.200.000"
      },
      "theTradeDesk": {
        "reach": "1.794.000"
      },
      "Adform": {
        "reach": "1.526.000"
      },
      "Verizon": {
        "reach": "1.578.000"
      },
      "Xandr": {
        "reach": "1.785.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "943.000"
      },
      "theTradeDesk": {
        "reach": "774.000"
      },
      "Adform": {
        "reach": "643.000"
      },
      "Verizon": {
        "reach": "646.000"
      },
      "Xandr": {
        "reach": "769.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.870.000"
      },
      "theTradeDesk": {
        "reach": "2.371.000"
      },
      "Adform": {
        "reach": "1.976.000"
      },
      "Verizon": {
        "reach": "2.003.000"
      },
      "Xandr": {
        "reach": "2.340.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.351.000"
      },
      "theTradeDesk": {
        "reach": "2.716.000"
      },
      "Adform": {
        "reach": "2.397.000"
      },
      "Verizon": {
        "reach": "2.375.000"
      },
      "Xandr": {
        "reach": "2.747.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.466.000"
      },
      "theTradeDesk": {
        "reach": "467.000"
      },
      "Adform": {
        "reach": "1.010.000"
      },
      "Verizon": {
        "reach": "1.034.000"
      },
      "Xandr": {
        "reach": "1.160.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "4.011.000"
      },
      "theTradeDesk": {
        "reach": "3.257.000"
      },
      "Adform": {
        "reach": "2.882.000"
      },
      "Verizon": {
        "reach": "2.973.000"
      },
      "Xandr": {
        "reach": "3.310.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "998.000"
      },
      "theTradeDesk": {
        "reach": "798.000"
      },
      "Adform": {
        "reach": "700.000"
      },
      "Verizon": {
        "reach": "712.000"
      },
      "Xandr": {
        "reach": "785.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "565.000"
      },
      "RMS": {
        "reach": "612.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "2.017.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "803.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.128.000"
      },
      "theTradeDesk": {
        "reach": "883.000"
      },
      "Adform": {
        "reach": "788.000"
      },
      "Verizon": {
        "reach": "781.000"
      },
      "Xandr": {
        "reach": "916.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "2.078.000"
      },
      "Instagram": {
        "reach": "1.076.000"
      },
      "YouTube": {
        "reach": "3.783.000"
      },
      "Snapchat": {
        "reach": "141.000"
      }
    }
  },
  "Baugewerbe": {
    "cpm": "3,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.026.000"
      },
      "theTradeDesk": {
        "reach": "825.000"
      },
      "Adform": {
        "reach": "703.000"
      },
      "Verizon": {
        "reach": "720.000"
      },
      "Xandr": {
        "reach": "833.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "441.000"
      },
      "theTradeDesk": {
        "reach": "353.000"
      },
      "Adform": {
        "reach": "311.000"
      },
      "Verizon": {
        "reach": "311.000"
      },
      "Xandr": {
        "reach": "356.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.272.000"
      },
      "theTradeDesk": {
        "reach": "1.037.000"
      },
      "Adform": {
        "reach": "908.000"
      },
      "Verizon": {
        "reach": "939.000"
      },
      "Xandr": {
        "reach": "1.058.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.609.000"
      },
      "theTradeDesk": {
        "reach": "1.241.000"
      },
      "Adform": {
        "reach": "1.105.000"
      },
      "Verizon": {
        "reach": "1.065.000"
      },
      "Xandr": {
        "reach": "1.270.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "681.000"
      },
      "theTradeDesk": {
        "reach": "211.000"
      },
      "Adform": {
        "reach": "479.000"
      },
      "Verizon": {
        "reach": "471.000"
      },
      "Xandr": {
        "reach": "545.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.820.000"
      },
      "theTradeDesk": {
        "reach": "1.517.000"
      },
      "Adform": {
        "reach": "1.279.000"
      },
      "Verizon": {
        "reach": "1.322.000"
      },
      "Xandr": {
        "reach": "1.508.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "459.000"
      },
      "theTradeDesk": {
        "reach": "370.000"
      },
      "Adform": {
        "reach": "331.000"
      },
      "Verizon": {
        "reach": "328.000"
      },
      "Xandr": {
        "reach": "351.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "244.000"
      },
      "RMS": {
        "reach": "292.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "926.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "365.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "512.000"
      },
      "theTradeDesk": {
        "reach": "403.000"
      },
      "Adform": {
        "reach": "356.000"
      },
      "Verizon": {
        "reach": "352.000"
      },
      "Xandr": {
        "reach": "418.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "970.000"
      },
      "Instagram": {
        "reach": "505.000"
      },
      "YouTube": {
        "reach": "1.780.000"
      },
      "Snapchat": {
        "reach": "43.000"
      }
    }
  },
  "Logistik": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.173.000"
      },
      "theTradeDesk": {
        "reach": "971.000"
      },
      "Adform": {
        "reach": "853.000"
      },
      "Verizon": {
        "reach": "815.000"
      },
      "Xandr": {
        "reach": "941.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "520.000"
      },
      "theTradeDesk": {
        "reach": "423.000"
      },
      "Adform": {
        "reach": "359.000"
      },
      "Verizon": {
        "reach": "348.000"
      },
      "Xandr": {
        "reach": "395.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.556.000"
      },
      "theTradeDesk": {
        "reach": "1.246.000"
      },
      "Adform": {
        "reach": "1.063.000"
      },
      "Verizon": {
        "reach": "1.092.000"
      },
      "Xandr": {
        "reach": "1.235.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.810.000"
      },
      "theTradeDesk": {
        "reach": "1.469.000"
      },
      "Adform": {
        "reach": "1.282.000"
      },
      "Verizon": {
        "reach": "1.320.000"
      },
      "Xandr": {
        "reach": "1.473.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "794.000"
      },
      "theTradeDesk": {
        "reach": "257.000"
      },
      "Adform": {
        "reach": "536.000"
      },
      "Verizon": {
        "reach": "533.000"
      },
      "Xandr": {
        "reach": "631.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.279.000"
      },
      "theTradeDesk": {
        "reach": "1.819.000"
      },
      "Adform": {
        "reach": "1.523.000"
      },
      "Verizon": {
        "reach": "1.566.000"
      },
      "Xandr": {
        "reach": "1.776.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "545.000"
      },
      "theTradeDesk": {
        "reach": "421.000"
      },
      "Adform": {
        "reach": "393.000"
      },
      "Verizon": {
        "reach": "375.000"
      },
      "Xandr": {
        "reach": "426.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "299.000"
      },
      "RMS": {
        "reach": "343.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.102.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "446.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "605.000"
      },
      "theTradeDesk": {
        "reach": "491.000"
      },
      "Adform": {
        "reach": "405.000"
      },
      "Verizon": {
        "reach": "427.000"
      },
      "Xandr": {
        "reach": "486.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.125.000"
      },
      "Instagram": {
        "reach": "571.000"
      },
      "YouTube": {
        "reach": "2.240.000"
      },
      "Snapchat": {
        "reach": "101.000"
      }
    }
  },
  "Behörden": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "1.184.000"
      },
      "theTradeDesk": {
        "reach": "949.000"
      },
      "Adform": {
        "reach": "843.000"
      },
      "Verizon": {
        "reach": "812.000"
      },
      "Xandr": {
        "reach": "991.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "517.000"
      },
      "theTradeDesk": {
        "reach": "416.000"
      },
      "Adform": {
        "reach": "360.000"
      },
      "Verizon": {
        "reach": "357.000"
      },
      "Xandr": {
        "reach": "414.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.593.000"
      },
      "theTradeDesk": {
        "reach": "1.199.000"
      },
      "Adform": {
        "reach": "1.096.000"
      },
      "Verizon": {
        "reach": "1.068.000"
      },
      "Xandr": {
        "reach": "1.176.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.921.000"
      },
      "theTradeDesk": {
        "reach": "1.470.000"
      },
      "Adform": {
        "reach": "1.289.000"
      },
      "Verizon": {
        "reach": "1.266.000"
      },
      "Xandr": {
        "reach": "1.482.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "763.000"
      },
      "theTradeDesk": {
        "reach": "250.000"
      },
      "Adform": {
        "reach": "558.000"
      },
      "Verizon": {
        "reach": "553.000"
      },
      "Xandr": {
        "reach": "619.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "2.198.000"
      },
      "theTradeDesk": {
        "reach": "1.797.000"
      },
      "Adform": {
        "reach": "1.557.000"
      },
      "Verizon": {
        "reach": "1.514.000"
      },
      "Xandr": {
        "reach": "1.739.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "563.000"
      },
      "theTradeDesk": {
        "reach": "437.000"
      },
      "Adform": {
        "reach": "369.000"
      },
      "Verizon": {
        "reach": "378.000"
      },
      "Xandr": {
        "reach": "449.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "302.000"
      },
      "RMS": {
        "reach": "338.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.092.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "434.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "607.000"
      },
      "theTradeDesk": {
        "reach": "478.000"
      },
      "Adform": {
        "reach": "417.000"
      },
      "Verizon": {
        "reach": "423.000"
      },
      "Xandr": {
        "reach": "487.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.113.000"
      },
      "Instagram": {
        "reach": "575.000"
      },
      "YouTube": {
        "reach": "2.107.000"
      },
      "Snapchat": {
        "reach": "103.000"
      }
    }
  },
  "Ver- und Entsorger": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "592.000"
      },
      "theTradeDesk": {
        "reach": "473.000"
      },
      "Adform": {
        "reach": "414.000"
      },
      "Verizon": {
        "reach": "416.000"
      },
      "Xandr": {
        "reach": "490.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "268.000"
      },
      "theTradeDesk": {
        "reach": "209.000"
      },
      "Adform": {
        "reach": "181.000"
      },
      "Verizon": {
        "reach": "181.000"
      },
      "Xandr": {
        "reach": "206.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "765.000"
      },
      "theTradeDesk": {
        "reach": "626.000"
      },
      "Adform": {
        "reach": "543.000"
      },
      "Verizon": {
        "reach": "539.000"
      },
      "Xandr": {
        "reach": "624.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "938.000"
      },
      "theTradeDesk": {
        "reach": "729.000"
      },
      "Adform": {
        "reach": "641.000"
      },
      "Verizon": {
        "reach": "636.000"
      },
      "Xandr": {
        "reach": "736.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "402.000"
      },
      "theTradeDesk": {
        "reach": "130.000"
      },
      "Adform": {
        "reach": "274.000"
      },
      "Verizon": {
        "reach": "280.000"
      },
      "Xandr": {
        "reach": "310.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.102.000"
      },
      "theTradeDesk": {
        "reach": "921.000"
      },
      "Adform": {
        "reach": "752.000"
      },
      "Verizon": {
        "reach": "768.000"
      },
      "Xandr": {
        "reach": "866.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "275.000"
      },
      "theTradeDesk": {
        "reach": "211.000"
      },
      "Adform": {
        "reach": "192.000"
      },
      "Verizon": {
        "reach": "192.000"
      },
      "Xandr": {
        "reach": "217.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "150.000"
      },
      "RMS": {
        "reach": "169.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "541.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "221.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "303.000"
      },
      "theTradeDesk": {
        "reach": "236.000"
      },
      "Adform": {
        "reach": "214.000"
      },
      "Verizon": {
        "reach": "214.000"
      },
      "Xandr": {
        "reach": "240.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "580.000"
      },
      "Instagram": {
        "reach": "289.000"
      },
      "YouTube": {
        "reach": "1.029.000"
      },
      "Snapchat": {
        "reach": "50.000"
      }
    }
  },
  "Groß- und Einzelhandel": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "2.071.000"
      },
      "theTradeDesk": {
        "reach": "1.677.000"
      },
      "Adform": {
        "reach": "1.490.000"
      },
      "Verizon": {
        "reach": "1.433.000"
      },
      "Xandr": {
        "reach": "1.602.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "870.000"
      },
      "theTradeDesk": {
        "reach": "736.000"
      },
      "Adform": {
        "reach": "622.000"
      },
      "Verizon": {
        "reach": "622.000"
      },
      "Xandr": {
        "reach": "687.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "2.753.000"
      },
      "theTradeDesk": {
        "reach": "2.088.000"
      },
      "Adform": {
        "reach": "1.871.000"
      },
      "Verizon": {
        "reach": "1.886.000"
      },
      "Xandr": {
        "reach": "2.016.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "3.151.000"
      },
      "theTradeDesk": {
        "reach": "2.611.000"
      },
      "Adform": {
        "reach": "2.234.000"
      },
      "Verizon": {
        "reach": "2.251.000"
      },
      "Xandr": {
        "reach": "2.476.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "1.356.000"
      },
      "theTradeDesk": {
        "reach": "426.000"
      },
      "Adform": {
        "reach": "926.000"
      },
      "Verizon": {
        "reach": "940.000"
      },
      "Xandr": {
        "reach": "1.074.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "3.818.000"
      },
      "theTradeDesk": {
        "reach": "3.052.000"
      },
      "Adform": {
        "reach": "2.634.000"
      },
      "Verizon": {
        "reach": "2.644.000"
      },
      "Xandr": {
        "reach": "3.181.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "954.000"
      },
      "theTradeDesk": {
        "reach": "742.000"
      },
      "Adform": {
        "reach": "681.000"
      },
      "Verizon": {
        "reach": "665.000"
      },
      "Xandr": {
        "reach": "717.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "532.000"
      },
      "RMS": {
        "reach": "598.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "1.912.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "786.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "1.057.000"
      },
      "theTradeDesk": {
        "reach": "842.000"
      },
      "Adform": {
        "reach": "718.000"
      },
      "Verizon": {
        "reach": "726.000"
      },
      "Xandr": {
        "reach": "816.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "1.930.000"
      },
      "Instagram": {
        "reach": "966.000"
      },
      "YouTube": {
        "reach": "3.557.000"
      },
      "Snapchat": {
        "reach": "172.000"
      }
    }
  },
  "Gastgewerbe": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "404.000"
      },
      "theTradeDesk": {
        "reach": "326.000"
      },
      "Adform": {
        "reach": "274.000"
      },
      "Verizon": {
        "reach": "277.000"
      },
      "Xandr": {
        "reach": "323.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "168.000"
      },
      "theTradeDesk": {
        "reach": "138.000"
      },
      "Adform": {
        "reach": "119.000"
      },
      "Verizon": {
        "reach": "121.000"
      },
      "Xandr": {
        "reach": "140.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "520.000"
      },
      "theTradeDesk": {
        "reach": "407.000"
      },
      "Adform": {
        "reach": "364.000"
      },
      "Verizon": {
        "reach": "362.000"
      },
      "Xandr": {
        "reach": "406.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "635.000"
      },
      "theTradeDesk": {
        "reach": "486.000"
      },
      "Adform": {
        "reach": "429.000"
      },
      "Verizon": {
        "reach": "433.000"
      },
      "Xandr": {
        "reach": "492.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "270.000"
      },
      "theTradeDesk": {
        "reach": "88.000"
      },
      "Adform": {
        "reach": "176.000"
      },
      "Verizon": {
        "reach": "180.000"
      },
      "Xandr": {
        "reach": "209.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "713.000"
      },
      "theTradeDesk": {
        "reach": "584.000"
      },
      "Adform": {
        "reach": "506.000"
      },
      "Verizon": {
        "reach": "521.000"
      },
      "Xandr": {
        "reach": "610.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "185.000"
      },
      "theTradeDesk": {
        "reach": "143.000"
      },
      "Adform": {
        "reach": "127.000"
      },
      "Verizon": {
        "reach": "126.000"
      },
      "Xandr": {
        "reach": "149.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "102.000"
      },
      "RMS": {
        "reach": "115.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "358.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "148.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "199.000"
      },
      "theTradeDesk": {
        "reach": "163.000"
      },
      "Adform": {
        "reach": "142.000"
      },
      "Verizon": {
        "reach": "139.000"
      },
      "Xandr": {
        "reach": "164.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "373.000"
      },
      "Instagram": {
        "reach": "193.000"
      },
      "YouTube": {
        "reach": "707.000"
      },
      "Snapchat": {
        "reach": "33.000"
      }
    }
  },
  "Schulen": {
    "cpm": "2,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "305.000"
      },
      "theTradeDesk": {
        "reach": "241.000"
      },
      "Adform": {
        "reach": "209.000"
      },
      "Verizon": {
        "reach": "212.000"
      },
      "Xandr": {
        "reach": "236.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "128.000"
      },
      "theTradeDesk": {
        "reach": "103.000"
      },
      "Adform": {
        "reach": "93.000"
      },
      "Verizon": {
        "reach": "87.000"
      },
      "Xandr": {
        "reach": "104.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "390.000"
      },
      "theTradeDesk": {
        "reach": "308.000"
      },
      "Adform": {
        "reach": "273.000"
      },
      "Verizon": {
        "reach": "270.000"
      },
      "Xandr": {
        "reach": "303.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "452.000"
      },
      "theTradeDesk": {
        "reach": "356.000"
      },
      "Adform": {
        "reach": "326.000"
      },
      "Verizon": {
        "reach": "322.000"
      },
      "Xandr": {
        "reach": "353.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "197.000"
      },
      "theTradeDesk": {
        "reach": "61.000"
      },
      "Adform": {
        "reach": "137.000"
      },
      "Verizon": {
        "reach": "136.000"
      },
      "Xandr": {
        "reach": "154.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "555.000"
      },
      "theTradeDesk": {
        "reach": "444.000"
      },
      "Adform": {
        "reach": "393.000"
      },
      "Verizon": {
        "reach": "396.000"
      },
      "Xandr": {
        "reach": "438.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "140.000"
      },
      "theTradeDesk": {
        "reach": "110.000"
      },
      "Adform": {
        "reach": "96.000"
      },
      "Verizon": {
        "reach": "95.000"
      },
      "Xandr": {
        "reach": "105.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "74.000"
      },
      "RMS": {
        "reach": "86.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "279.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "110.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "155.000"
      },
      "theTradeDesk": {
        "reach": "123.000"
      },
      "Adform": {
        "reach": "105.000"
      },
      "Verizon": {
        "reach": "103.000"
      },
      "Xandr": {
        "reach": "119.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "270.000"
      },
      "Instagram": {
        "reach": "143.000"
      },
      "YouTube": {
        "reach": "528.000"
      },
      "Snapchat": {
        "reach": "25.000"
      }
    }
  },
  "1 bis 9 Mitarbeiter": {
    "cpm": "1,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "818.000"
      },
      "theTradeDesk": {
        "reach": "658.000"
      },
      "Adform": {
        "reach": "585.000"
      },
      "Verizon": {
        "reach": "547.000"
      },
      "Xandr": {
        "reach": "656.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "348.000"
      },
      "theTradeDesk": {
        "reach": "287.000"
      },
      "Adform": {
        "reach": "240.000"
      },
      "Verizon": {
        "reach": "239.000"
      },
      "Xandr": {
        "reach": "283.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "1.046.000"
      },
      "theTradeDesk": {
        "reach": "858.000"
      },
      "Adform": {
        "reach": "722.000"
      },
      "Verizon": {
        "reach": "718.000"
      },
      "Xandr": {
        "reach": "830.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "1.261.000"
      },
      "theTradeDesk": {
        "reach": "973.000"
      },
      "Adform": {
        "reach": "849.000"
      },
      "Verizon": {
        "reach": "888.000"
      },
      "Xandr": {
        "reach": "985.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "528.000"
      },
      "theTradeDesk": {
        "reach": "170.000"
      },
      "Adform": {
        "reach": "380.000"
      },
      "Verizon": {
        "reach": "373.000"
      },
      "Xandr": {
        "reach": "442.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "1.528.000"
      },
      "theTradeDesk": {
        "reach": "1.187.000"
      },
      "Adform": {
        "reach": "1.070.000"
      },
      "Verizon": {
        "reach": "1.042.000"
      },
      "Xandr": {
        "reach": "1.196.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "363.000"
      },
      "theTradeDesk": {
        "reach": "292.000"
      },
      "Adform": {
        "reach": "261.000"
      },
      "Verizon": {
        "reach": "259.000"
      },
      "Xandr": {
        "reach": "301.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "195.000"
      },
      "RMS": {
        "reach": "232.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "745.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "305.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "407.000"
      },
      "theTradeDesk": {
        "reach": "319.000"
      },
      "Adform": {
        "reach": "275.000"
      },
      "Verizon": {
        "reach": "274.000"
      },
      "Xandr": {
        "reach": "326.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "747.000"
      },
      "Instagram": {
        "reach": "393.000"
      },
      "YouTube": {
        "reach": "1.428.000"
      },
      "Snapchat": {
        "reach": "169.000"
      }
    }
  },
  "10 bis 49 Mitarbeiter": {
    "cpm": "1,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "162.000"
      },
      "theTradeDesk": {
        "reach": "131.000"
      },
      "Adform": {
        "reach": "110.000"
      },
      "Verizon": {
        "reach": "110.000"
      },
      "Xandr": {
        "reach": "126.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "69.000"
      },
      "theTradeDesk": {
        "reach": "56.000"
      },
      "Adform": {
        "reach": "48.000"
      },
      "Verizon": {
        "reach": "47.000"
      },
      "Xandr": {
        "reach": "55.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "212.000"
      },
      "theTradeDesk": {
        "reach": "164.000"
      },
      "Adform": {
        "reach": "143.000"
      },
      "Verizon": {
        "reach": "147.000"
      },
      "Xandr": {
        "reach": "160.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "242.000"
      },
      "theTradeDesk": {
        "reach": "195.000"
      },
      "Adform": {
        "reach": "172.000"
      },
      "Verizon": {
        "reach": "170.000"
      },
      "Xandr": {
        "reach": "193.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "104.000"
      },
      "theTradeDesk": {
        "reach": "33.000"
      },
      "Adform": {
        "reach": "76.000"
      },
      "Verizon": {
        "reach": "73.000"
      },
      "Xandr": {
        "reach": "84.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "290.000"
      },
      "theTradeDesk": {
        "reach": "228.000"
      },
      "Adform": {
        "reach": "201.000"
      },
      "Verizon": {
        "reach": "206.000"
      },
      "Xandr": {
        "reach": "231.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "72.000"
      },
      "theTradeDesk": {
        "reach": "59.000"
      },
      "Adform": {
        "reach": "49.000"
      },
      "Verizon": {
        "reach": "49.000"
      },
      "Xandr": {
        "reach": "56.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "40.000"
      },
      "RMS": {
        "reach": "45.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "147.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "59.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "78.000"
      },
      "theTradeDesk": {
        "reach": "61.000"
      },
      "Adform": {
        "reach": "55.000"
      },
      "Verizon": {
        "reach": "56.000"
      },
      "Xandr": {
        "reach": "64.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "148.000"
      },
      "Instagram": {
        "reach": "79.000"
      },
      "YouTube": {
        "reach": "291.000"
      },
      "Snapchat": {
        "reach": "33.000"
      }
    }
  },
  "50 bis 399 Mitarbeiter": {
    "cpm": "1,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "30.000"
      },
      "theTradeDesk": {
        "reach": "24.000"
      },
      "Adform": {
        "reach": "21.000"
      },
      "Verizon": {
        "reach": "21.000"
      },
      "Xandr": {
        "reach": "24.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "13.000"
      },
      "theTradeDesk": {
        "reach": "10.000"
      },
      "Adform": {
        "reach": "9.000"
      },
      "Verizon": {
        "reach": "9.000"
      },
      "Xandr": {
        "reach": "10.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "38.000"
      },
      "theTradeDesk": {
        "reach": "31.000"
      },
      "Adform": {
        "reach": "27.000"
      },
      "Verizon": {
        "reach": "27.000"
      },
      "Xandr": {
        "reach": "30.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "45.000"
      },
      "theTradeDesk": {
        "reach": "38.000"
      },
      "Adform": {
        "reach": "32.000"
      },
      "Verizon": {
        "reach": "32.000"
      },
      "Xandr": {
        "reach": "38.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "19.000"
      },
      "theTradeDesk": {
        "reach": "6.000"
      },
      "Adform": {
        "reach": "14.000"
      },
      "Verizon": {
        "reach": "14.000"
      },
      "Xandr": {
        "reach": "15.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "54.000"
      },
      "theTradeDesk": {
        "reach": "44.000"
      },
      "Adform": {
        "reach": "39.000"
      },
      "Verizon": {
        "reach": "39.000"
      },
      "Xandr": {
        "reach": "43.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "13.000"
      },
      "theTradeDesk": {
        "reach": "11.000"
      },
      "Adform": {
        "reach": "10.000"
      },
      "Verizon": {
        "reach": "9.000"
      },
      "Xandr": {
        "reach": "11.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "7.000"
      },
      "RMS": {
        "reach": "8.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "27.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "11.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "15.000"
      },
      "theTradeDesk": {
        "reach": "12.000"
      },
      "Adform": {
        "reach": "10.000"
      },
      "Verizon": {
        "reach": "11.000"
      },
      "Xandr": {
        "reach": "12.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "28.000"
      },
      "Instagram": {
        "reach": "15.000"
      },
      "YouTube": {
        "reach": "52.000"
      },
      "Snapchat": {
        "reach": "6.000"
      }
    }
  },
  "400 und mehr Mitarbeiter": {
    "cpm": "1,50€",
    "Desktop - Display": {
      "DV360": {
        "reach": "8.000"
      },
      "theTradeDesk": {
        "reach": "6.000"
      },
      "Adform": {
        "reach": "5.000"
      },
      "Verizon": {
        "reach": "5.000"
      },
      "Xandr": {
        "reach": "6.000"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "3.000"
      },
      "theTradeDesk": {
        "reach": "3.000"
      },
      "Adform": {
        "reach": "2.000"
      },
      "Verizon": {
        "reach": "2.000"
      },
      "Xandr": {
        "reach": "3.000"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "10.000"
      },
      "theTradeDesk": {
        "reach": "8.000"
      },
      "Adform": {
        "reach": "7.000"
      },
      "Verizon": {
        "reach": "7.000"
      },
      "Xandr": {
        "reach": "8.000"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "11.000"
      },
      "theTradeDesk": {
        "reach": "9.000"
      },
      "Adform": {
        "reach": "8.000"
      },
      "Verizon": {
        "reach": "8.000"
      },
      "Xandr": {
        "reach": "9.000"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "5.000"
      },
      "theTradeDesk": {
        "reach": "2.000"
      },
      "Adform": {
        "reach": "3.000"
      },
      "Verizon": {
        "reach": "3.000"
      },
      "Xandr": {
        "reach": "4.000"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "14.000"
      },
      "theTradeDesk": {
        "reach": "11.000"
      },
      "Adform": {
        "reach": "10.000"
      },
      "Verizon": {
        "reach": "9.000"
      },
      "Xandr": {
        "reach": "11.000"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "3.000"
      },
      "theTradeDesk": {
        "reach": "3.000"
      },
      "Adform": {
        "reach": "2.000"
      },
      "Verizon": {
        "reach": "2.000"
      },
      "Xandr": {
        "reach": "3.000"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "2.000"
      },
      "RMS": {
        "reach": "2.000"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "7.000"
      },
      "WallDecaux (DOOH)": {
        "reach": "3.000"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "4.000"
      },
      "theTradeDesk": {
        "reach": "3.000"
      },
      "Adform": {
        "reach": "3.000"
      },
      "Verizon": {
        "reach": "3.000"
      },
      "Xandr": {
        "reach": "3.000"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "7.000"
      },
      "Instagram": {
        "reach": "4.000"
      },
      "YouTube": {
        "reach": "13.000"
      },
      "Snapchat": {
        "reach": "2.000"
      }
    }
  },
  "Fußgänger": {
    "cpm": "5,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "variabel"
      },
      "RMS": {
        "reach": "variabel"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "variabel"
      },
      "WallDecaux (DOOH)": {
        "reach": "variabel"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "variabel"
      },
      "Instagram": {
        "reach": "variabel"
      },
      "YouTube": {
        "reach": "variabel"
      },
      "Snapchat": {
        "reach": "variabel"
      }
    }
  },
  "Stausteher": {
    "cpm": "6,00€",
    "Desktop - Display": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Desktop - Bewegtbild": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Desktop - Native": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Mobile - Display": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Mobile - Bewegtbild": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Mobile - Native": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Programmatic/ Advanced TV": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Programmatic Audio": {
      "Spotify": {
        "reach": "variabel"
      },
      "RMS": {
        "reach": "variabel"
      }
    },
    "Digital Out-of-Home": {
      "Ströer (DOOH)": {
        "reach": "variabel"
      },
      "WallDecaux (DOOH)": {
        "reach": "variabel"
      }
    },
    "In-Game": {
      "DV360": {
        "reach": "variabel"
      },
      "theTradeDesk": {
        "reach": "variabel"
      },
      "Adform": {
        "reach": "variabel"
      },
      "Verizon": {
        "reach": "variabel"
      },
      "Xandr": {
        "reach": "variabel"
      }
    },
    "Social & Social Video": {
      "Facebook": {
        "reach": "variabel"
      },
      "Instagram": {
        "reach": "variabel"
      },
      "YouTube": {
        "reach": "variabel"
      },
      "Snapchat": {
        "reach": "variabel"
      }
    }
  }
};