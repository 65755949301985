import React from 'react';
import { Grid } from '@material-ui/core';
import { platformsReach } from '../../../../../../constants/targeting-platform-matrix';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import numeral from 'numeral';

const useStyles = makeStyles({
  reachCell: {
    minWidth: 150,
    maxWidth: 150,
  },
  borderedCell: {
    border: '1px solid rgba(224, 224, 224, 1);'
  },
  headerBordered: {
    border: '1px solid rgba(224, 224, 224, 1);',
    borderTop: 'none',
  },
  platform: {
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  channel: {
    fontSize: 16,
  },
  platformReach: {
    fontSize: 16
  },
  header: {
    fontSize: 14,
    letterSpacing: 1,
    textTransform: 'uppercase',
    color: '#979797'
  }
});


const TargetingSummaryWithTableBody = ({ targeting, channels, paymentModel }) => {
  const classes = useStyles();
  const filteredChannels = Object.keys(channels).filter((key) => !channels[key].removed);

  const getReach = (reach) => {
    if (reach === 'variabel') {
      return 'Variabel';
    }
    return numeral(reach.replace(/\./g, '')).format('0.0 a').replace('.', ',').toUpperCase();
  };

  const getCPM = (cpm) => {
    if (paymentModel === 'bundled') {
      return 'Inkludiert';
    }
    return cpm;
  };

  const buildPlatforms = (channel) => (platform) => {
    if (!platformsReach[targeting][channel][platform]) {
      return 'XXX';
    }
    const reach = platformsReach[targeting][channel][platform].reach;
    return (
      <Grid item container key={platform} direction='column' className={classes.reachCell}>
        <Grid item className={classes.platform}>
          {platform}
        </Grid>
        <Grid item className={classes.platformReach}>
          {
            getReach(reach)
          }
        </Grid>
      </Grid>
    )
  };

  return (
    <TableContainer>
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell><span className={classes.header}>CHANNELL</span></TableCell>
            <TableCell className={classes.headerBordered}><span
              className={classes.header}>MAX. REICHWEITE</span></TableCell>
            <TableCell><span className={classes.header}>CPM</span></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filteredChannels.map((key) => {
              const { channel } = channels[key];
              return (
                <TableRow key={key}>
                  <TableCell>{channel}</TableCell>
                  <TableCell className={classes.borderedCell}>

                    <Grid container wrap="nowrap">
                      {channels[key].platforms.map(buildPlatforms(channel))}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {getCPM(platformsReach[targeting].cpm)}
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TargetingSummaryWithTableBody;




