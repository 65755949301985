import React from 'react';
import { Grid, Box, IconButton } from '@material-ui/core';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { HintIcon } from './hint-icon';

const useStyles = makeStyles({
  selectedTargetingBox: {
    background: 'rgba(31, 182, 83, 0.06)',
    border: '1px solid #1FB653',
    borderRadius: 10,
    padding: 16,
  },
  targetingBig: {
    color: '#1FB653',
    fontSize: 18,
  },
  targetingSmall: {
    color: '#000000',
    fontSize: 14,
    opacity: 0.6,
  },
  detailsHeader: {
    color: '#828282',
    fontSize: 12,
  },
  detailsValue: {
    color: '#000000',
    fontSize: 18,
    // textTransform: 'capitalize'
  },
  arrowIcon: {
    fontSize: 10,
    marginBottom: '-2px',
  },
});

export const SelectedTargeting = (props) => {
  const { targeting, type, onRemove, paymentModel } = props;
  const { category, reach, cpm } = targeting;
  const styles = useStyles();

  const hint = category?.hint || targeting.description;
  const onDeleteClick = () => {
    onRemove(type || targeting.targeting);
  };

  const getReach = (reach) => {
    if (reach === 'variabel') {
      return 'variabel';
    }
    return numeral(reach).format('0.0 a').replace('.', ',')
  };

  const getCPM = (CPM) => {
    if (paymentModel === 'bundled') {
      return 'inkludiert';
    }
    return CPM;
  };


  const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  // TODO: dont be lazy, fix selected target format across selectors and make specific components instead of conditions ;)
  let box;

  if (type === 'dataScience') {
    box = (
      <>
        <div className={styles.targetingBig}>Custom Data Science</div>
      </>
    );
  }

  if (type === 'situational') {
    box = (<>
      <div className={styles.targetingBig}>{category.category}</div>
      <Box mb={1} />
      <Grid container alignItems="center" className={styles.targetingSmall}>
        {capitalize(type || '')}
        <Box ml={1} display="inline-block" />
        <ArrowForwardIosIcon className={styles.arrowIcon} />
        <Box mr={1} display="inline-block" />
        {capitalize(category.category || '')}
        <Box ml={1} display="inline-block" />
        {targeting.intensity && (<>
          <ArrowForwardIosIcon className={styles.arrowIcon} />
          <Box mr={1} display="inline-block" />
          {capitalize(targeting.intensity)}
        </>)}
      </Grid>
    </>);
  }

  if (type === undefined) {
    box = (
      <>
        <div className={styles.targetingBig}>{targeting.targeting}</div>
        <Box mb={1} />
        <Grid container alignItems="center" className={styles.targetingSmall}>
          {category}
          <Box ml={1} display="inline-block" />
          <ArrowForwardIosIcon className={styles.arrowIcon} />
          <Box mr={1} display="inline-block" />
          {targeting.subCategory}
          <Box ml={1} display="inline-block" />
          <ArrowForwardIosIcon className={styles.arrowIcon} />
          <Box mr={1} display="inline-block" />
          {targeting.targeting}
        </Grid>
      </>
    );
  }
  return (
    <Box p={2}
         pr={3}
         pl={3}
         justifyContent='space-between'
         alignItems="center"
         className={styles.selectedTargetingBox}
         display="flex"
    >
      <Grid item>
        <CheckCircleIcon color="primary" style={{ fontSize: 24 }} />
      </Grid>
      <Box mr={2} />
      <Grid item xs>
        {box}
      </Grid>
      <Grid item l={4}>
        <Grid container direction="row" spacing={4}>
          {reach && (
            <Grid item>
              <div className={styles.detailsHeader}>REICHWEITE</div>
              <Box mb={1} />
              <div className={styles.detailsValue}>{capitalize(getReach(reach))}</div>
            </Grid>
          )}
          {cpm && (
            <Grid item>
              <div className={styles.detailsHeader}>CPM</div>
              <Box mb={1} />
              <div className={styles.detailsValue}>{capitalize(getCPM(cpm))}</div>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box ml={7} />
      <Grid item>
        <HintIcon
          hint={hint}
          style={{ fontSize: 24 }}
        />
      </Grid>
      <Box mr={2} />
      <Grid item>
        <IconButton onClick={onDeleteClick}>
          <DeleteOutlineOutlinedIcon
            color="disabled"
            style={{ fontSize: 24 }}
          />
        </IconButton>
      </Grid>
    </Box>
  );
};
