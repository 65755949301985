import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';

import background from '../../components/icons/svg/unathorized-left-backroung.svg';
import cxFusionLogo from '../../components/icons/svg/cx-fusion-logo.svg';
import twinsLogo from '../../components/icons/svg/twins-digital-logo.svg';

const useStyles = makeStyles({
  backgroundImage: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100vh',
    position: 'relative',
    width: 600,
    backgroundImage: `url(${background});`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  contentContainer: {
    height: '100%',
    color: 'white',
  },
  poweredBy: {
    fontSize: 12,
  },
  alternativeTargeting: {
    fontSize: 24,
  },
});

export const LayoutLeft = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Grid container direction="column" justify="space-between" className={styles.contentContainer}>

        <Grid item>
          <Box ml={12} mt={5}>
            <img alt="" src={cxFusionLogo}/>
          </Box>
        </Grid>

        <Grid item>
          <Box ml={12} mb={8}>
            <div className={styles.alternativeTargeting}>Alternative Data Targeting</div>
            <Box mt={3} />
            <div className={styles.poweredBy}>POWERED BY</div>
            <Box mt={3} />
            <div>
              <img alt="" src={twinsLogo} />
            </div>
          </Box>
        </Grid>

      </Grid>
    </div>
  );
};
