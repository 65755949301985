import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Box, Snackbar } from '@material-ui/core';
import AuthorisedLayout from '../../layouts/AuthorisedLayout';
import { deleteUser, getAllUsers, updateUser } from '../../api';
import { useHistory } from "react-router-dom";
import { isAdmin } from '../../services/authService';

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);
  const [snackbarText, setSnackbarText] = useState(null);
  const history = useHistory();
  useEffect(() => {
    getUsers();
  }, []);

  if (!isAdmin()) {
    history.push('/');
  }

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Email', field: 'email' },
    { title: 'Is activated', field: 'isActivated', type: 'boolean' },
  ];

  const getUsers = async () => {
    const users = await getAllUsers();
    setUsers(users);
  };

  const onRowUpdate = async ({ _id, name, email, isActivated }, oldUser) => {
    try {
      const updatedUser = await updateUser(_id, {
        name, email, isActivated
      });
      users[users.indexOf(oldUser)] = updatedUser;
      setUsers([...users]);
      setSnackbarText('User successfully updated');
    } catch (error) {
      setSnackbarText(error);
      throw new Error();
    } finally {
      setIsSnackbarShown(true);
    }
  };

  const onRowDelete = async (oldData,) => {
    try {
      const { _id } = oldData;
      await deleteUser(_id);
      users.splice(users.indexOf(oldData), 1);
      setUsers([...users]);
      setSnackbarText('User successfully deleted');
    } catch (error) {
      setSnackbarText(error);
      throw new Error();
    } finally {
      setIsSnackbarShown(true);
    }
  };


  return (
    <AuthorisedLayout>
      <Box mt={1} p={2}>
        <MaterialTable
          title="Users"
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [5, 10, 30, 50],
          }}
          columns={columns}
          data={users}
          editable={{
            onRowUpdate,
            onRowDelete
          }}
        />
      </Box>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        key={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={isSnackbarShown}
        onClose={() => setIsSnackbarShown(false)}
        message={snackbarText}
      />
    </AuthorisedLayout>
  )
};

export default Admin;