import React from 'react';
import Panel from '../../../../components/Panel';
import TargetingSummaryWithTableHeader from './components/TargetingSummaryWithTablePanelHeader';
import TargetingSummaryWithTableBody from './components/TargetingSummaryWithTableBody';

const TargetingSummaryWithTable = ({ targeting, channels, onRemove, paymentModel }) => {
  const { targeting: targetingName } = targeting;
  return (
    <Panel>
      <TargetingSummaryWithTableHeader onRemove={onRemove} targeting={targeting} />
      <TargetingSummaryWithTableBody targeting={targetingName} channels={channels} paymentModel={paymentModel} />
    </Panel>
  )
};

export default TargetingSummaryWithTable;
